import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import DatePicker from "react-datepicker";
import BlockUi from "@availity/block-ui";
import { useForm, Controller } from "react-hook-form";
import moment from "moment/moment";
import {
  clearUserNameAndTokenInLocalStorage,
  getTokenFromLocalStorage,
} from "../../lib/common";
import NewDoAcknowledgement_AckModalTable from "./NewDOAcknowledgement_AckModalTable";
import { useNavigate } from "react-router-dom";

const config = JSON.parse(process.env.REACT_APP_CONFIG);

function NewDoAcknowledgement_AckModal(props) {
    const { rowData, onClose, handleShowAlert } = props;
    const [blocking, setBlocking] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [ItemList, setItemList] = useState("");
    const [refreshTrigger] = useState("");
    const [textInputs, setTextInputs] = useState({});

    const [SleSoNumber] = useState(rowData.SO_NUMBER);
    const [SleSoDate] = useState(moment(rowData.SO_DATE).isValid() ? new Date(rowData.SO_DATE) : null);
    const [SleDoNumber] = useState(rowData.DO_NUMBER);
    const [SleDoDate] = useState(moment(rowData.DO_DATE).isValid() ? new Date(rowData.DO_DATE) : null);
    const [CustomerName] = useState(rowData.CUSTOMER_NAME);
    const [ProjectName, setProjectName] = useState(rowData.PROJECT_NAME);
    const [ProjectList, setProjectList] = useState([]);
    const [AcknowledgementDate] = useState(new Date());
    const [SupplierDoAckNumber, setSupplierDoAckNumber] = useState("");
    const [SupplierDoAckDate, setSupplierDoAckDate] = useState("");
    const navigate = useNavigate();
    
    const {
        control,
		register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        defaultValues: {
        },
    });

    useEffect(() => {
        getData(config.do_ack_pending_link, rowData);
    }, [rowData]);

    const getData = async (url, params = {}) => {
        await fetch(url, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + getTokenFromLocalStorage(),
        },
        body: JSON.stringify({
            // so_number: params.SO_NUMBER,
            do_number: params.DO_NUMBER,
            // do_date_from: params.DO_DATE_FROM,
            // do_date_to: params.DO_DATE_TO,
            // customer_id: params.CUSTOMER_ID,
            // project_id: params.PROJECT_ID,
        }),
        })
        .then((response) => response.json())
        .then((jsonData) => {
            console.log(jsonData);
            setBlocking(false);
            if (
                jsonData.success !== true &&
                jsonData.message === "Access Denied! Invalid token"
            ) {
            clearUserNameAndTokenInLocalStorage();
            const state = { data: "Token expired, please login again" };
            navigate("/login", { state });
            throw new Error(jsonData.message);
            } else if (jsonData.success) {
                return jsonData.data;
            } else {
                throw new Error(jsonData.message);
            }
        })
        .then((data) => {
            setItemList(data);
        })
        .catch((error) => console.error(error));
    };

    const handleToggleBlocking = () => {
        setBlocking((prevState) => !prevState);
    };

    const toggleRowSelection = (rowId) => {
        if (selectedRows.includes(rowId)) {
            setSelectedRows(selectedRows.filter((id) => id !== rowId));
        } else {
            setSelectedRows([...selectedRows, rowId]);
        }
    }

    const submitNewDOAcknowledgement = async (event) => {
        if (selectedRows.length === 0) {
			handleShowAlert(
				"error",
				"New DO Acknowledgement",
				"Failed Saving New DO Acknowledgement",
				["Please select a Product"]
			);
			return;
		}
        const emptyQuantities = selectedRows.filter(rowId => !textInputs[rowId] || textInputs[rowId] === '');
        if (emptyQuantities.length > 0) {
            handleShowAlert(
                "error",
                "New DO Acknowledgement",
                "Failed Saving New DO Acknowledgement",
                ["Please enter quantity for selected items"]
            );
            return;
        }
        var item_qtis = Object.values(textInputs);
        const jsonOb = {
            do_number: SleDoNumber,
            so_number: SleSoNumber,
            do_ack_date: SupplierDoAckDate !== "" ? moment(SupplierDoAckDate).format("YYYY-MM-DD") : null,
            supplier_do_number: SupplierDoAckNumber,
            supplier_do_date: SupplierDoAckDate !== "" ? moment(SupplierDoAckDate).format("YYYY-MM-DD") : null,
            product_id: selectedRows,
            item_qty: item_qtis.map((item_qty) => parseInt(item_qty)),
        };
        console.log(jsonOb);
        try {
            const response = await fetch(config.do_ack_create_link, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + getTokenFromLocalStorage(),
            },
            body: JSON.stringify(jsonOb),
            });
            const data = await response.json();
            console.log(data);
            if (
            data.success !== true &&
            data.message === "Access Denied! Invalid token"
            ) {
            clearUserNameAndTokenInLocalStorage();
            const state = { data: "Token expired, please login again" };
            navigate("/login", { state });
            }
            if (data.success !== true) {
            const errorMessages = data.error.map((item) => item.msg);
            handleShowAlert("error", "New DO Acknowledgement", "Failed Saving New DO Acknowledgement :", errorMessages.join("<br>"));
            }
            if (data.success === true) {
            console.log("Success add New DO Acknowledgement");
            handleShowAlert(
                "ok",
                "New DO Acknowledgement",
                "Success saving New DO Acknowledgement",
                "The DO Ack Number is: " + data.do_ack_number
            );
            onClose();
            return "ok";
            } else {
            throw data;
            }
        } catch (error) {
            console.error("Error while saving:", error);
            handleShowAlert(
            "error",
            "New DO Acknowledgement",
            "Error while saving New DO Acknowledgement",
            error
            );
            return;
        }
    };
    const handleInputChange = (rowId, value) => {
		setTextInputs({ ...textInputs, [rowId]: value });
	};
    const handleToggleFromChild = (newValue) => {
		setBlocking(newValue);
	};

    return (
        <Modal show={true} onHide={onClose} size="xl" backdrop="static" centered>
        <BlockUi blocking={blocking}>
            <Form onSubmit={handleSubmit(submitNewDOAcknowledgement)}>
            <Modal.Header closeButton className="px-4">
                <Modal.Title className="ms-auto">New DO Acknowledgement</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row className="d-flex align-items-center justify-content-center">
                <Col md={10}>
                    <Row className="mb-2">
                    <Col md="7">
                        <Form.Group as={Row} controlId="SleSoNumber">
                        <Form.Label column sm="5" className="fw-bold">
                            SLE SO Number :
                        </Form.Label>
                        <Col sm="6">
                            <Form.Control
                                disabled
                                type="text"
                                placeholder="SLE SO Number"
                                value={SleSoNumber}
                            />
                        </Col>
                        </Form.Group>
                    </Col>
                    <Col md="5">
                        <Form.Group as={Row} controlId="SleSoDate">
                        <Form.Label column sm="6" className="fw-bold">
                            SO Date :
                        </Form.Label>
                        <Col sm="6">
                            <DatePicker
                                disabled
                                dateFormat="dd MMM yyyy"
                                className="form-control"
                                showIcon
                                selected={SleSoDate}
                            />
                        </Col>
                        </Form.Group>
                    </Col>
                    </Row>

                    <Row className="mb-2">
                    <Col md="7">
                        <Form.Group as={Row} controlId="SleDoNumber">
                        <Form.Label column sm="5" className="fw-bold">
                            SLE DO Number :
                        </Form.Label>
                        <Col sm="6">
                            <Form.Control
                                disabled
                                type="text"
                                placeholder="SLE DO Number"
                                value={SleDoNumber}
                            />
                        </Col>
                        </Form.Group>
                    </Col>
                    <Col md="5">
                        <Form.Group as={Row} controlId="SleDoDate">
                            <Form.Label column sm="6" className="fw-bold">
                                DO Date :
                            </Form.Label>
                            <Col sm="6">
                                <DatePicker
                                    disabled
                                    dateFormat="dd MMM yyyy"
                                    className="form-control"
                                    showIcon
                                    selected={SleDoDate}
                                />
                            </Col>
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row className="mb-2">
                    <Col md="7">
                        <Form.Group as={Row} controlId="CustomerName">
                        <Form.Label column sm="5" className="fw-bold">
                            Customer Name :
                        </Form.Label>
                        <Col sm="7">
                            {/* <Form.Select
                                value={CustomerName}
                                onChange={(e) => setCustomerName(e.target.value)}
                            >
                                <option key={""} value="" disabled>
                                    Please select customer name
                                </option>
                                {CustomerList.map((items) => (
                                <option
                                    key={items.CUSTOMER_ID}
                                    value={items.CUSTOMER_ID}
                                >
                                    {items.CUSTOMER_NAME}
                                </option>
                                ))}
                            </Form.Select> */}
                            <Form.Control
                                disabled
                                type="text"
                                placeholder=""
                                value={CustomerName}
                            />
                        </Col>
                        </Form.Group>
                    </Col>
                    </Row>

                    <Row className="mb-2">
                    <Col md="7">
                        <Form.Group as={Row} controlId="ProjectName">
                        <Form.Label column sm="5" className="fw-bold">
                            Project Name :
                        </Form.Label>
                        <Col sm="7">
                            {/* <Form.Select
                                value={ProjectName}
                                onChange={(e) => setProjectName(e.target.value)}
                                placeholder="Please select project name"
                            >
                                <option key={""} value="" disabled>
                                    Please select project name
                                </option>
                                {!CustomerName ? (
                                <option key={"selectcustomer"} value="" className="drop-down-message" disabled>
                                    Please select customer name first
                                </option>
                                ) : ProjectList.length <= 0 ? (
                                <option key={"noprojects"} value="" className="drop-down-message" disabled>
                                    This customer currently has no projects
                                </option>
                                ) : (
                                <>
                                    {ProjectList.map((items) => (
                                    <option
                                        key={items.PROJECT_ID}
                                        value={items.PROJECT_ID}
                                    >
                                        {items.PROJECT_NAME}
                                    </option>
                                    ))}
                                </>
                                )}
                            </Form.Select> */}
                            <Form.Control
                                disabled
                                type="text"
                                placeholder=""
                                value={ProjectName}
                            />
                        </Col>
                        </Form.Group>
                    </Col>
                    </Row>
                </Col>
                </Row>
                <hr />
                <Row className="d-flex align-items-center justify-content-center">
                <Col md={10}>
                    <Row className="mb-2 ">
                        <Col md="7">
                            <Form.Group as={Row} controlId="AcknowledgementDate" >
                                <Form.Label column sm="5" className="fw-bold">
                                    Acknowledgement Date :
                                </Form.Label>
                                <Col sm="6">
                                    <DatePicker
                                        disabled
                                        dateFormat="dd MMM yyyy"
                                        className="form-control"
                                        showIcon
                                        selected={AcknowledgementDate}
                                    />
                                </Col>
                            </Form.Group>
                        </Col>
                    </Row>
                    
                    <Row className="mb-2">
                    <Col md="7">
                        <Form.Group as={Row} controlId="SupplierDoAckNumber">
                        <Form.Label column  sm="5" className="fw-bold" >
                            Supplier Do Number :
                        </Form.Label>
                        <Col sm="6">
                            <Form.Control
                                type="text"
                                placeholder="Supplier Do Number"
                                value={SupplierDoAckNumber}
                                onInput={(e) => {
                                    setSupplierDoAckNumber(e.target.value);
                                }}
                                className={`form-control ${
                                    errors.SupplierDoAckNumber ? "is-invalid" : ""
                                }`}
                                {...register("SupplierDoAckNumber", {
                                validate: (value) => {
                                    return value !== "" || "This field is required";
                                },
                                })}
                            />
                            {errors.SupplierDoAckNumber && (
                                <Form.Text className="text-danger">
                                    {errors.SupplierDoAckNumber.message}
                                </Form.Text>
                            )}
                        </Col>
                        </Form.Group>
                    </Col>
                    <Col md="5">
                        <Form.Group as={Row} controlId="SupplierDoAckDate">
                            <Form.Label column sm="6" className="fw-bold">
                                Supplier Do Date :
                            </Form.Label>
                            <Col sm="6">
                                <Controller
                                    control={control}
                                    name="SupplierDoAckDate"
                                    rules={{ required: "This field is required" }}
                                    render={({ field }) => (
                                        <DatePicker
                                            showIcon
                                            dateFormat="dd MMM yyyy"
                                            className={`form-control ${
                                                errors.SupplierDoAckDate ? "is-invalid" : ""
                                            }`}
                                            selected={SupplierDoAckDate}
                                            onChange={(date) => {
                                                field.onChange(date);
                                                setSupplierDoAckDate(date);
                                            }}
                                            minDate={moment().subtract(1, "month").toDate()}
                                            maxDate={new Date()}
                                        />
                                    )}
                                />
                                {errors.SupplierDoAckDate && (
                                    <p className="text-danger">
                                        {errors.SupplierDoAckDate.message}
                                    </p>
                                )}
                            </Col>
                            </Form.Group>
                        </Col>
                    </Row>
                </Col>
                </Row>
                <hr />
                <Row className="mb-4 align-items-center">
                    <Col className="text-center">
                        <Form.Label>[ Item Acknowledgement ]</Form.Label>
                        <NewDoAcknowledgement_AckModalTable
							rowData={rowData}
							onChildToggleChange={handleToggleFromChild}
							onRowSelect={toggleRowSelection}
							onInputChange={handleInputChange}
							selectedRows={selectedRows}
							setSelectedRows={setSelectedRows}
							textInputs={textInputs}
							setTextInputs={setTextInputs}
							toggleRowSelection={toggleRowSelection}
							refreshTrigger={refreshTrigger}
						/>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onClose} variant="secondary">
                Close
                </Button>
                <Button type="submit" variant="primary">
                Save
                </Button>
            </Modal.Footer>
            </Form>
        </BlockUi>
        <style>
            {`
                .form-control:disabled {
                background: #F8F8F8 !important;
                }
            `}
        </style>
        </Modal>
    );
}

export default NewDoAcknowledgement_AckModal;
