import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import Pagination from "react-bootstrap/Pagination";
import Button from "react-bootstrap/Button";

function ProjectsAddressTable(props) {
  const { onEditClick } = props;
  const { onDeleteClick } = props;
  const { itemList } = props;
  const { fromEditModal } = props;
  const [data, setData] = useState([]);
  const [headerData] = useState([
    "Addr Type",
    "Addr 1",
    "Addr 2",
    "Addr 3",
    "Addr 4",
    "Action",
  ]);
  const [currentPage, setCurrentPage] = useState(1);
  const ROWS_PER_PAGE = 10;
  const totalPages = Math.ceil(data.length / ROWS_PER_PAGE);

  useEffect(() => {
    setData(itemList);
  }, [itemList]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  console.log(itemList);
  const renderTableData = () => {
    const startIndex = (currentPage - 1) * ROWS_PER_PAGE;
    const endIndex = startIndex + ROWS_PER_PAGE;
    return data.slice(startIndex, endIndex).map((row) => {
      const {
        PROJECT_ADDR_ID,
        ADDR_TYPE,
        ADDR1_NAME,
        ADDR2_NAME,
        ADDR3_NAME,
        ADDR4_NAME,
        COUNTRY,
        STATUS,
      } = row;
      return (
        <tr key={PROJECT_ADDR_ID}>
          <td>{ADDR_TYPE === "B" ? "Billing" : "Delivery"}</td>
          <td>{ADDR1_NAME}</td>
          <td>{ADDR2_NAME}</td>
          <td>{ADDR3_NAME}</td>
          <td>{ADDR4_NAME}</td>
          <td style={{ width: fromEditModal === false ? "215px" : "100px" }}>
            <Button
              style={{ marginRight: "5px" }}
              variant="primary"
              onClick={() => onEditClick(row)}
            >
              Edit
            </Button>
            {fromEditModal === false ? (
              <Button variant="danger" onClick={() => onDeleteClick(row)}>
                {/* <i class="fa-solid fa-xmark"></i> */}
                Delete
              </Button>
            ) : (
              ""
            )}
          </td>
        </tr>
      );
    });
  };

  const renderPageItems = () => {
    const pageItems = [];
    for (let i = 1; i <= totalPages; i++) {
      pageItems.push(
        <Pagination.Item
          key={i}
          active={i === currentPage}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </Pagination.Item>
      );
    }
    return pageItems;
  };

  if (data.length < 1) {
    return (
      <br></br>
    );
  } else {
    return (
      <div>
        <Table striped bordered hover className="mb-4">
          <thead>
            <tr>
              {headerData.map((header) => (
                <th key={header}>{header}</th>
              ))}
            </tr>
          </thead>
          <tbody>{renderTableData()}</tbody>
        </Table>
        <Pagination className="d-flex justify-content-center">
          {renderPageItems()}
        </Pagination>
      </div>
    );
  }
}

export default ProjectsAddressTable;
