import React, { useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';
import Pagination from 'react-bootstrap/Pagination';
import moment from 'moment/moment';
import { clearUserNameAndTokenInLocalStorage, getTokenFromLocalStorage } from '../../lib/common';
const config = JSON.parse(process.env.REACT_APP_CONFIG);

function OnholdTable(props) {
  const [data, setData] = useState([]);
  const { rowData, trigger } = props;
  const [headerData] = useState(["Date", "User Name", "Hold Expiry", "Remarks"]);
  const [currentPage, setCurrentPage] = useState(1);
  const ROWS_PER_PAGE = 5;
  const totalPages = Math.ceil(data.length / ROWS_PER_PAGE);

  useEffect(() => {
    getData(config.so_hold_get_link, rowData);
  }, [rowData, trigger]);

  const handleToggleBlocking = () => {
    props.onChildToggleChange(false);
  };


  const getData = async (url, params = {}) => {
    await fetch(url, {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + getTokenFromLocalStorage()
      },
      body: JSON.stringify(params.length === 0 ? params : {
        so_number: params.SO_NUMBER
      })
    })
      .then(response => response.json())
      .then(jsonData => {
        console.log(jsonData)
        if (jsonData.success !== true && jsonData.message === 'Access Denied! Invalid token') {
          clearUserNameAndTokenInLocalStorage();
          throw new Error(jsonData.message);
        } else if (jsonData.success) {
          return jsonData.data.HOLD_HISTORY;
        } else {
          throw new Error(jsonData.message);
        }
      })
      .then(data => setData(data))
      .catch(error => console.error(error));
  }

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const renderTableData = () => {
    const startIndex = (currentPage - 1) * ROWS_PER_PAGE;
    const endIndex = startIndex + ROWS_PER_PAGE;
    return data.slice(startIndex, endIndex).map((row) => {
      const { DATE_MODIFY, USER_MODIFY, HOLD_EXPIRY, REMARKS, HOLD_HIST_ID } = row;
      return (
        <tr key={HOLD_HIST_ID}>
          <td style={{ minWidth: '120px' }}>{ moment(DATE_MODIFY).utcOffset(480).format("DD MMM yyyy")}</td>
          <td style={{ minWidth: '150px' }}>{USER_MODIFY}</td>
          <td style={{ minWidth: '120px' }}>{ moment(HOLD_EXPIRY).utcOffset(480).format("DD MMM yyyy")}</td>
          <td>{REMARKS}</td>
        </tr>
      );
    });
  };

  const renderPageItems = () => {
    const pageItems = [];
    for (let i = 1; i <= totalPages; i++) {
      pageItems.push(
        <Pagination.Item key={i} active={i === currentPage} onClick={() => handlePageChange(i)}>
          {i}
        </Pagination.Item>
      );
    }
    return pageItems;
  };

  if (data.length < 1) {
    return (
      <div className="text-center"><h3>No Data</h3></div>
    )
  } else {
    return (
      <div>
        <Table striped bordered hover className="mb-4">
          <thead>
            <tr>{headerData.map((header) => <th key={header}>{header}</th>)}</tr>
          </thead>
          <tbody>{renderTableData()}</tbody>
        </Table>
        <Pagination className="d-flex justify-content-center" >{renderPageItems()}</Pagination>
      </div>
    );
  };
}

export default OnholdTable;