import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import DatePicker from "react-datepicker";
import BlockUi from "@availity/block-ui";
import { useForm } from "react-hook-form";
import moment from "moment/moment";
import {
  clearUserNameAndTokenInLocalStorage,
  getTokenFromLocalStorage,
} from "../../lib/common";
import ManageInvoice_ViewInvModalTable from "./ManageInvoice_ViewInvModalTable";
import ManageDOAcknowledgement_ViewAckModal from "../ManageDOAcknowledgement/ManageDOAcknowledgement_ViewAckModal";
import { useNavigate } from "react-router-dom";

const config = JSON.parse(process.env.REACT_APP_CONFIG);

function ManageInvoice_ViewInvModal(props) {
    const navigate = useNavigate();
    const formatNumber = (num) => {
        return new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(num);
    };
    const { rowData, onClose, handleShowAlert, handleCloseAlert } = props;
    const [blocking, setBlocking] = useState(false);
    const [selectedRowData, setSelectedRowData] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [ItemList, setItemList] = useState("");

    const [SleInvNumber] = useState(rowData.INVOICE_NUMBER);
    const [InvDate] = useState(rowData.INVOICE_DATE ? new Date(rowData.INVOICE_DATE) : '');
    const [CurrencyCode] = useState(rowData.CURRENCY_CODE);
    const [TaxRate] = useState(rowData.TAX_RATE);
    const [InvAmount] = useState(formatNumber(rowData.INVOICE_AMOUNT_W_TAX));
    const [TaxAmount] = useState(formatNumber(rowData.TAX_AMOUNT));
    const [CustomerName] = useState(rowData.CUSTOMER_NAME);
    const [CustomerId] = useState(rowData.CUSTOMER_ID);
    
    const {
        formState: { errors },
    } = useForm({
        defaultValues: {
        },
    });

    useEffect(() => {
        getData(config.invoice_get_link, rowData);
    }, [rowData]);

    const getData = async (url, params = {}) => {
        try {
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + getTokenFromLocalStorage(),
                },
                body: JSON.stringify({
                    invoice_number: params.INVOICE_NUMBER,
                }),
            });
    
            const jsonData = await response.json();
            setBlocking(false);
    
            if (jsonData.success !== true && jsonData.message === "Access Denied! Invalid token") {
                clearUserNameAndTokenInLocalStorage();
                const state = { data: "Token expired, please login again" };
                navigate("/login", { state });
                throw new Error(jsonData.message);
            } else if (jsonData.success && jsonData.data.length > 0) {
                const invoice = jsonData.data[0];
                if (invoice.INVOICE_ITEMS) {
                    const newData = invoice.INVOICE_ITEMS.map(item => ({
                        ...item,
                        DO_ACK_DATE: moment(item.DO_ACK_DATE).utcOffset(480).format("DD MMM yyyy")
                    }));
                    setItemList(newData);
                } else {
                    setItemList([]);
                }
            } else {
                setItemList([]);
                throw new Error(jsonData.message);
            }
        } catch (error) {
            setBlocking(false);
            handleShowAlert("error", "Fetch Data", `Error fetching data: ${error.message}`);
        }
    };
    
    const handleNewInvClick = (rowData) => {
        console.log(rowData);
        setSelectedRowData(rowData);
        setShowModal("newInv");
      };

    const handleToggleBlocking = () => {
        setBlocking((prevState) => !prevState);
    };
    const handleToggleFromChild = (newValue) => {
        setBlocking(newValue);
      };

    return (
        <Modal show={true} onHide={onClose} size="xl" backdrop="static" centered>
        <BlockUi blocking={blocking}>
            <Form>
            <Modal.Header closeButton className="px-4">
                <Modal.Title className="ms-auto">View Invoice</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row className="d-flex align-items-center justify-content-center">
                <Col md={10}>
                    <Row className="mb-2">
                    <Col md="7">
                        <Form.Group as={Row} controlId="SleInvNumber">
                        <Form.Label column sm="5" className="fw-bold">
                            SLE Inv Number :
                        </Form.Label>
                        <Col sm="6">
                            <Form.Control
                                disabled
                                type="text"
                                value={SleInvNumber}
                            />
                        </Col>
                        </Form.Group>
                    </Col>
                    <Col md="5">
                        <Form.Group as={Row} controlId="InvDate">
                        <Form.Label column sm="6" className="fw-bold">
                            Inv Date :
                        </Form.Label>
                        <Col sm="6">
                            <DatePicker
                                disabled
                                dateFormat="dd MMM yyyy"
                                className="form-control"
                                showIcon
                                selected={InvDate}
                            />
                        </Col>
                        </Form.Group>
                    </Col>
                    </Row>

                    <Row className="mb-2">
                    <Col md="7">
                        <Form.Group as={Row} controlId="CurrencyCode">
                        <Form.Label column sm="5" className="fw-bold">
                            Currency :
                        </Form.Label>
                        <Col sm="6">
                            <Form.Control
                                disabled
                                type="text"
                                value={CurrencyCode}
                            />
                        </Col>
                        </Form.Group>
                    </Col>
                    <Col md="5">
                        <Form.Group as={Row} controlId="TaxRate">
                            <Form.Label column sm="6" className="fw-bold">
                                Tax Rate :
                            </Form.Label>
                            <Col sm="6">
                                <Form.Control
                                    disabled
                                    type="text"
                                    value={TaxRate}
                                />
                            </Col>
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row className="mb-2">
                    <Col md="7">
                        <Form.Group as={Row} controlId="InvAmount">
                        <Form.Label column sm="5" className="fw-bold">
                            Inv Amt w/ Tax :
                        </Form.Label>
                        <Col sm="6">
                            <Form.Control
                                disabled
                                type="text"
                                value={InvAmount}
                            />
                        </Col>
                        </Form.Group>
                    </Col>
                    <Col md="5">
                        <Form.Group as={Row} controlId="TaxAmount">
                            <Form.Label column sm="6" className="fw-bold">
                                Tax Amount :
                            </Form.Label>
                            <Col sm="6">
                                <Form.Control
                                    disabled
                                    type="text"
                                    value={TaxAmount}
                                />
                            </Col>
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row className="mb-2">
                    <Col md="7">
                        <Form.Group as={Row} controlId="CustomerName">
                        <Form.Label column sm="5" className="fw-bold">
                            Customer Name :
                        </Form.Label>
                        <Col sm="7">
                            <Form.Control
                                disabled
                                type="text"
                                placeholder=""
                                value={CustomerName}
                            />
                        </Col>
                        </Form.Group>
                    </Col>
                    </Row>
                </Col>
                <hr />
                <Row className="mb-4 align-items-center">
                    <Col className="text-center">
                        <Form.Label>[ DO Ack ]</Form.Label>
                        {/* <ManageInvoice_ViewInvModalTable
                            ItemList={ItemList}
                            handleShowAlert={handleShowAlert}
                            rowData={selectedRowData}
                            onChildToggleChange={handleToggleFromChild}
                        /> */}
                        <ManageInvoice_ViewInvModalTable
                            ItemList={ItemList}
                            handleShowAlert={handleShowAlert}
                            onChildToggleChange={handleToggleFromChild}
                            rowData={selectedRowData}
                            onViewClick ={handleNewInvClick}
                        />
                    </Col>
                </Row>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onClose} variant="secondary">
                Close
                </Button>
            </Modal.Footer>
            </Form>
            {showModal === "newInv" ? (
            <ManageDOAcknowledgement_ViewAckModal
                handleCloseAlert={handleCloseAlert}
                handleShowAlert={handleShowAlert}
                rowData={selectedRowData}
                onClose={() => setShowModal(false)}
                />
            ) : (
                ""
            )}
        </BlockUi>
        <style>
            {`
                .form-control:disabled {
                background: #F8F8F8 !important;
                }
            `}
        </style>
        </Modal>
    );
}

export default ManageInvoice_ViewInvModal;
