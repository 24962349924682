import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment/moment";
import {
  clearUserNameAndTokenInLocalStorage,
  getUserNameFromLocalStorage,
  getTokenFromLocalStorage,
} from "../../lib/common";
import BlockUi from "@availity/block-ui";
import { useForm, Controller } from "react-hook-form";

const config = JSON.parse(process.env.REACT_APP_CONFIG);

function SLEInvoiceInvoiceModal(props) {
  const {
    control,
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    trigger,
    formState: { errors },
  } = useForm({
    defaultValues: {
      SleInvoiceDate: "",
    },
  });
  const { rowData, onClose, handleShowAlert } = props;
  const [SleSoNum] = useState(rowData.SO_NUMBER);
  // const [SlePoNum] = useState(rowData.PO_NUMBER);
  const [SleSoDate] = useState(new Date(rowData.SO_DATE));
  // const [SlePoDate] = useState(new Date(rowData.PO_DATE));
  const [SleDoNum] = useState(rowData.DO_NUMBER);
  const [SleDoDate] = useState(
    rowData.DO_DATE === null || rowData.DO_DATE === undefined
      ? ""
      : new Date(rowData.DO_DATE)
  );
  const [OrderType] = useState(rowData.SO_TYPE_DESC);
  const [PaymentTerm] = useState(rowData.PAYMENT_TERM);
  const [SleInvoiceDate, setSleInvoiceDate] = useState("");
  const [DeliveryId] = useState(rowData.DELIVERY_ID);
  const [blocking, setBlocking] = useState(false);
  const [maxDate] = useState(new Date());
  const [minDate] = useState(() => {
    const maxDate = new Date();
    maxDate.setDate(new Date().getDate() - 7);
    const formattedMaxDate = maxDate;
    return formattedMaxDate;
  });

  const handleSubmitInvoice = async (event) => {
    // event.preventDefault();
    try {
      handleToggleBlocking();
      await submitInvoice();
      console.log("Success saving Invoice");
      onClose();
      handleShowAlert("ok", "New Invoice", "Success saving New Invoice");
    } catch (error) {
      console.error("Error while saving Invoice:", error);
      handleShowAlert(
        "error",
        "New Invoice",
        "Error while saving New Invoice:",
        error
      );
    }
  };

  const handleToggleBlocking = () => {
    setBlocking((prevState) => !prevState);
  };

  const submitInvoice = async () => {
    try {
      const response = await fetch(config.invoice_create_link, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + getTokenFromLocalStorage(),
        },
        body: JSON.stringify({
          delivery_id: DeliveryId,
          do_number: SleDoNum,
          invoice_date: moment(getValues("SleInvoiceDate")).format("YYYY-MM-DD"),
          user_modify: getUserNameFromLocalStorage(),
        }),
      });

      const data = await response.json();
      console.log("creating new invoice response : ");
      console.log(data);
      if (
        data.success !== true &&
        data.message === "Access Denied! Invalid token"
      ) {
        clearUserNameAndTokenInLocalStorage();
      }

      if (data.success !== true) {
        var errorMessages = data.error.map((item) => item.msg);
        // handleShowAlert("error", "New Invoice", "Failed Saving New Invoice :", errorMessages.join("<br>"));
        throw errorMessages;
      }
      // setTrigger(moment().unix());
      if (data.success === true) {
        console.log("Success creating new invoice");
        // handleTrigger(moment().unix());
        return "ok";
      } else {
        throw data;
      }
    } catch (error) {
      console.error("Error while creating new invoice:", error);
      throw error;
    }
  };

  return (
    <Modal show={true} onHide={onClose} size="lg" backdrop="static" centered>
      <BlockUi blocking={blocking}>
        <Form onSubmit={handleSubmit(handleSubmitInvoice)}>
          <Modal.Header closeButton className="px-4">
            <Modal.Title className="ms-auto">New Invoice</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="mb-2 justify-content-center">
              <Col md="6">
                <Form.Group as={Row} controlId="SLESONumber">
                  <Form.Label column sm="6" className="fw-bold">
                    SLE SO Number :
                  </Form.Label>
                  <Col sm="6">
                    <Form.Control
                      readOnly
                      className="no-border"
                      type="text"
                      placeholder=""
                      value={SleSoNum}
                    />
                  </Col>
                </Form.Group>
              </Col>

              <Col md="6">
                <Form.Group as={Row} controlId="SLESODate">
                  <Form.Label column sm="6" className="fw-bold">
                    SLE SO Date :
                  </Form.Label>
                  <Col sm="6">
                    <DatePicker
                      readOnly
                      dateFormat="dd/MM/yyyy"
                      className="form-control no-border"
                      showIcon
                      selected={SleSoDate}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            {/* <Row className="mb-2 justify-content-center">
                <Col md="6">
                  <Form.Group as={Row} controlId="SLEPONumber">
                    <Form.Label column sm="6" className="fw-bold">
                      SLE PO Number :
                    </Form.Label>
                    <Col sm="6">
                      <Form.Control readOnly className="no-border" type="text" placeholder="" value={SlePoNum}/>
                    </Col>
                  </Form.Group>
                </Col>

                <Col md="6">
                  <Form.Group as={Row} controlId="SLEPODate">
                      <Form.Label column sm="6" className="fw-bold">
                        SLE PO Date :
                      </Form.Label>
                      <Col sm="6">
                        <DatePicker readOnly dateFormat="dd/MM/yyyy" className="form-control no-border" showIcon selected={SlePoDate} />
                      </Col>
                  </Form.Group>
                </Col>
              </Row> */}

            <Row className="mb-2 justify-content-center">
              <Col md="6">
                <Form.Group as={Row} controlId="SLEDONumber">
                  <Form.Label column sm="6" className="fw-bold">
                    SLE DO Number :
                  </Form.Label>
                  <Col sm="6">
                    <Form.Control
                      readOnly
                      className="no-border"
                      type="text"
                      placeholder=""
                      value={SleDoNum}
                    />
                  </Col>
                </Form.Group>
              </Col>

              <Col md="6">
                <Form.Group as={Row} controlId="SLEDODate">
                  <Form.Label column sm="6" className="fw-bold">
                    SLE DO Date :
                  </Form.Label>
                  <Col sm="6">
                    <DatePicker
                      readOnly
                      dateFormat="dd/MM/yyyy"
                      className="form-control no-border"
                      showIcon
                      selected={SleDoDate}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col md="6">
                <Form.Group as={Row} controlId="OrderType">
                  <Form.Label column sm="6" className="fw-bold">
                    Order Type :
                  </Form.Label>
                  <Col sm="6">
                    <Form.Control
                      readOnly
                      className="no-border"
                      type="text"
                      placeholder=""
                      value={OrderType}
                    />
                  </Col>
                </Form.Group>
              </Col>
              <Col md="6">
                <Form.Group as={Row} controlId="OrderType">
                  <Form.Label column sm="6" className="fw-bold">
                    Payment Term :
                  </Form.Label>
                  <Col sm="6">
                    <Form.Control
                      readOnly
                      className="no-border"
                      type="text"
                      placeholder=""
                      value={PaymentTerm}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <hr />
            <Row className="mb-2">
              <Col md="6">
                <Form.Group as={Row} controlId="SLEInvoiceDate">
                  <Form.Label column sm="6" className="fw-bold">
                    SLE Invoice Date :
                  </Form.Label>
                  <Col sm="6">
                    <Controller
                      name="SleInvoiceDate"
                      control={control}
                      rules={{
                        required: "Invoice date required",
                        pattern: {
                          value:
                            /^(0[1-9]|1\d|2\d|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/,
                          message: "Invalid date format",
                        },
                      }}
                      render={({ field }) => (
                        <>
                          <DatePicker
                            selected={field.value}
                            onChange={(date) => field.onChange(date)}
                            className={`form-control ${
                              errors.SleInvoiceDate ? "is-invalid" : ""
                            }`}
                            dateFormat="dd/MM/yyyy"
                            minDate={minDate}
                            maxDate={maxDate}
                          />
                          {errors.SleInvoiceDate && (
                            <Form.Text className="text-danger">
                              {errors.SleInvoiceDate.message}
                            </Form.Text>
                          )}
                        </>
                      )}
                    />
                    {/* <DatePicker
                      dateFormat="dd/MM/yyyy"
                      className="form-control"
                      showIcon
                      selected={SleInvoiceDate}
                      onChange={(date) => setSleInvoiceDate(date)}
                    /> */}
                  </Col>
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={onClose} variant="secondary">
              Close
            </Button>
            <Button
              type="submit"
              variant="primary"
            >
              Save
            </Button>
          </Modal.Footer>
        </Form>
      </BlockUi>
    </Modal>
  );
}

export default SLEInvoiceInvoiceModal;
