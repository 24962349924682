import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import "react-datepicker/dist/react-datepicker.css";
import { clearUserNameAndTokenInLocalStorage, getTokenFromLocalStorage } from '../../lib/common';
import BlockUi from '@availity/block-ui';
import { useNavigate } from "react-router-dom";

const config = JSON.parse(process.env.REACT_APP_CONFIG);

function ManageInvoiceDeleteModal(props) {
    const { rowData, onClose, handleShowAlert, onDeleteSuccess } = props;
    const [InvoiceNumber] = useState(rowData.INVOICE_NUMBER);
    const [blocking, setBlocking] = useState(false);
    const navigate = useNavigate();

    const handleToggleBlocking = () => {
        setBlocking(prevState => !prevState);
    };

    const handleCancelPI = async (event) => {
        event.preventDefault();
        handleToggleBlocking();
        try {
            await cancelInvoice();
            handleToggleBlocking();
            console.log("Success cancelling ");
            onClose();
            handleShowAlert("ok", "Delete Invoice", "Success Deleting Invoice");
        } catch (error) {
            handleToggleBlocking();
            console.error("Error while deleting Invoice :", error);
            handleShowAlert("error", "Delete Invoice", "Error while deleting Invoice: ", error.join("<br>"));
            return;
        }

    };

    const cancelInvoice = async () => {
        try {
            const response = await fetch(config.invoice_delete_link, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": "Bearer " + getTokenFromLocalStorage()
                },
                body: JSON.stringify({
                    invoice_number: InvoiceNumber,
                })
            });

            const data = await response.json();
            console.log("submit on hold response : ");
            console.log(data);
            if (
                data.success !== true &&
                data.message === "Access Denied! Invalid token"
            ) {
                clearUserNameAndTokenInLocalStorage();
                const state = { data: "Token expired, please login again" };
                navigate("/login", { state });
            }

            if (data.success !== true) {
                var errorMessages = data.error.map((item) => item.msg);
                // handleShowAlert("error", "New Invoice", "Failed Saving New Invoice :", errorMessages.join("<br>"));
                throw errorMessages;
            }
            if (data.success === true) {
                console.log("Success deleting Invoice");
                // handleTrigger(moment().unix());
                onDeleteSuccess();
                return "ok";
            } else {
                throw data;
            }
        } catch (error) {
            console.error("Error while deleting Invoice :", error);
            throw error;
        }
    }



    return (
        <Modal show={true} onHide={onClose} size="md" backdrop="static" centered>
            <BlockUi blocking={blocking}>

                <Form onSubmit={handleCancelPI}>
                    <Modal.Header closeButton className="px-4">
                        <Modal.Title className="ms-auto">Cancel Invoice</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row className="mb-2">
                            <Col md="12">
                                <div className='text-center'>Do you confirm to cancel the invoice</div>
                                <div className='text-center'><span className='fw-bold'>{InvoiceNumber}</span><span> ?</span></div>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button type="submit" variant="primary">
                            Yes
                        </Button>
                        <Button onClick={onClose} variant="secondary">
                            No
                        </Button>
                    </Modal.Footer>
                </Form>
            </BlockUi>
        </Modal>
    );
}

export default ManageInvoiceDeleteModal;