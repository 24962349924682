import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import DatePicker from "react-datepicker";
import BlockUi from "@availity/block-ui";
import { useForm } from "react-hook-form";
import moment from "moment/moment";
import {
  clearUserNameAndTokenInLocalStorage,
  getTokenFromLocalStorage,
} from "../../lib/common";
import ManageDOAcknowledgement_ViewAckModalTable from "./ManageDOAcknowledgement_ViewAckModalTable";
import { useNavigate } from "react-router-dom";

const config = JSON.parse(process.env.REACT_APP_CONFIG);

function ManageDOAcknowledgement_ViewAckModal(props) {
    const navigate = useNavigate();
    const { rowData, onClose, handleShowAlert } = props;
    const [blocking, setBlocking] = useState(false);
    const [selectedRowData] = useState({});
    const [ItemList, setItemList] = useState("");

    const [DoAckNumber, setDoAckNumber] = useState(rowData.DO_ACK_NUMBER || "");
    const [DoAckDate, setDoAckDate] = useState(rowData.DO_ACK_DATE ? new Date(rowData.DO_ACK_DATE) : null);
    const [SleDoNumber, setSleDoNumber] = useState(rowData.DO_NUMBER || "");
    const [SleDoDate, setSleDoDate] = useState(rowData.DO_DATE ? new Date(rowData.DO_DATE) : null);
    const [SleSoNumber, setSleSoNumber] = useState(rowData.SO_NUMBER || "");
    const [SleSoDate, setSleSoDate] = useState(rowData.SO_DATE ? new Date(rowData.SO_DATE) : null);
    const [OrderType, setOrderType] = useState(rowData.SO_TYPE_DESC || "");
    const [PaymentTerm, setPaymentTerm] = useState(rowData.PAYMENT_TERM || "");
    const [SupplierDoNumber, setSupplierDoNumber] = useState(rowData.SUPPLIER_DO_NUMBER || "");
    const [SupplierDoDate, setSupplierDoDate] = useState(rowData.SUPPLIER_DO_DATE ? new Date(rowData.SUPPLIER_DO_DATE) : null);
    const [CustomerName, setCustomerName] = useState(rowData.CUSTOMER_NAME || "");
    const [ProjectName, setProjectName] = useState(rowData.PROJECT_NAME || "");
    const [row, setRow] = useState({});

    useEffect(() => {
        getData(config.do_ack_get_link, rowData);
    }, [rowData]);

    const getData = async (url, params = {}) => {
        await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + getTokenFromLocalStorage(),
            },
            body: JSON.stringify({
                do_ack_number: params.DO_ACK_NUMBER,
            }),
        })
            .then((response) => response.json())
            .then((jsonData) => {
                console.log(jsonData);
                setRow(jsonData.data);
                setBlocking(false);
                if (
                    jsonData.success !== true &&
                    jsonData.message === "Access Denied! Invalid token"
                ) {
                    clearUserNameAndTokenInLocalStorage();
                    const state = { data: "Token expired, please login again" };
                    navigate("/login", { state });
                    throw new Error(jsonData.message);
                } else if (
                    jsonData.success &&
                    jsonData.data["DO ACKNOWLEDGE_ITEMS"]
                ) {
                    const newData = jsonData.data["DO ACKNOWLEDGE_ITEMS"].map(
                        (item) => {
                            const date = moment(item.DO_ACK_DATE)
                                .utcOffset(480)
                                .format("DD MMM yyyy");
                            item.DO_ACK_DATE = date;
                            return item;
                        }
                    );
                    return newData;
                } else {
                    setItemList([]);
                    throw new Error(jsonData.message);
                }
            })
            .then((data) => {
                setItemList(data);
            })
            .catch((error) => console.error(error));
    };

    useEffect(() => {
        setDoAckNumber(row.DO_ACK_NUMBER || "");
        setDoAckDate(row.DO_ACK_DATE ? new Date(row.DO_ACK_DATE) : null);
        setSleDoNumber(row.DO_NUMBER|| "");
        setSleDoDate(row.DO_DATE ? new Date(row.DO_DATE) : null);
        setSleSoNumber(row.SO_NUMBER|| "");
        setSleSoDate(row.SO_DATE ? new Date(row.SO_DATE) : null);
        setOrderType(row.SO_TYPE_DESC || "");
        setPaymentTerm(row.PAYMENT_TERM || "");
        setSupplierDoNumber(row.SUPPLIER_DO_NUMBER || "");
        setSupplierDoDate(row.SUPPLIER_DO_DATE ? new Date(row.SUPPLIER_DO_DATE) : null);
        setCustomerName(row.CUSTOMER_NAME || "");
        setProjectName(row.PROJECT_NAME || "");
    }, [row]);
    
    const handleToggleBlocking = () => {
        setBlocking((prevState) => !prevState);
    };

    return (
        <Modal show={true} onHide={onClose} size="xl" backdrop="static" centered style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}>
        <BlockUi blocking={blocking}>
            <Form>
            <Modal.Header closeButton className="px-4">
                <Modal.Title className="ms-auto">View DO Acknowledgement</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row className="d-flex align-items-center justify-content-center">
                <Col md={10}>
                    <Row className="mb-2">
                    <Col md="7">
                        <Form.Group as={Row} controlId="DoAckNumber">
                        <Form.Label column sm="5" className="fw-bold">
                            DO Ack Number :
                        </Form.Label>
                        <Col sm="6">
                            <Form.Control
                                disabled
                                type="text"
                                value={DoAckNumber}
                            />
                        </Col>
                        </Form.Group>
                    </Col>
                    <Col md="5">
                        <Form.Group as={Row} controlId="DoAckDate">
                        <Form.Label column sm="6" className="fw-bold">
                            DO Ack Date :
                        </Form.Label>
                        <Col sm="6">
                            <DatePicker
                                disabled
                                dateFormat="dd MMM yyyy"
                                className="form-control"
                                showIcon
                                selected={DoAckDate}
                            />
                        </Col>
                        </Form.Group>
                    </Col>
                    </Row>

                    <Row className="mb-2">
                    <Col md="7">
                        <Form.Group as={Row} controlId="SleDoNumber">
                        <Form.Label column sm="5" className="fw-bold">
                            SLE DO Number :
                        </Form.Label>
                        <Col sm="6">
                            <Form.Control
                                disabled
                                type="text"
                                value={SleDoNumber}
                            />
                        </Col>
                        </Form.Group>
                    </Col>
                    <Col md="5">
                        <Form.Group as={Row} controlId="SleDoDate">
                            <Form.Label column sm="6" className="fw-bold">
                                DO Date :
                            </Form.Label>
                            <Col sm="6">
                                <DatePicker
                                    disabled
                                    dateFormat="dd MMM yyyy"
                                    className="form-control"
                                    showIcon
                                    selected={SleDoDate ? new Date(SleDoDate) : null}
                                />
                            </Col>
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row className="mb-2">
                    <Col md="7">
                        <Form.Group as={Row} controlId="SleSoNumber">
                        <Form.Label column sm="5" className="fw-bold">
                            SLE SO Number :
                        </Form.Label>
                        <Col sm="6">
                            <Form.Control
                                disabled
                                type="text"
                                value={SleSoNumber}
                            />
                        </Col>
                        </Form.Group>
                    </Col>
                    <Col md="5">
                        <Form.Group as={Row} controlId="SleSoDate">
                            <Form.Label column sm="6" className="fw-bold">
                                SO Date :
                            </Form.Label>
                            <Col sm="6">
                                <DatePicker
                                    disabled
                                    dateFormat="dd MMM yyyy"
                                    className="form-control"
                                    showIcon
                                    selected={SleSoDate}
                                />
                            </Col>
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row className="mb-2">
                    <Col md="7">
                        <Form.Group as={Row} controlId="OrderType">
                        <Form.Label column sm="5" className="fw-bold">
                            Order Type :
                        </Form.Label>
                        <Col sm="6">
                            <Form.Control
                                disabled
                                type="text"
                                value={OrderType}
                            />
                        </Col>
                        </Form.Group>
                    </Col>
                    <Col md="5">
                        <Form.Group as={Row} controlId="PaymentTerm">
                            <Form.Label column sm="6" className="fw-bold">
                                Payment Term :
                            </Form.Label>
                            <Col sm="6">
                                <Form.Control
                                    disabled
                                    type="text"
                                    value={PaymentTerm}
                                />
                            </Col>
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row className="mb-2">
                    <Col md="7">
                        <Form.Group as={Row} controlId="SupplierDoNumber">
                        <Form.Label column sm="5" className="fw-bold">
                            Supplier DO Number :
                        </Form.Label>
                        <Col sm="6">
                            <Form.Control
                                disabled
                                type="text"
                                value={SupplierDoNumber}
                            />
                        </Col>
                        </Form.Group>
                    </Col>
                    <Col md="5">
                        <Form.Group as={Row} controlId="SupplierDoDate">
                            <Form.Label column sm="6" className="fw-bold">
                            Supplier DO Date :
                            </Form.Label>
                            <Col sm="6">
                                <DatePicker
                                    disabled
                                    dateFormat="dd MMM yyyy"
                                    className="form-control"
                                    showIcon
                                    selected={SupplierDoDate}
                                />
                            </Col>
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row className="mb-2">
                    <Col md="7">
                        <Form.Group as={Row} controlId="CustomerName">
                        <Form.Label column sm="5" className="fw-bold">
                            Customer Name :
                        </Form.Label>
                        <Col sm="7">
                            <Form.Control
                                disabled
                                type="text"
                                placeholder=""
                                value={CustomerName}
                            />
                        </Col>
                        </Form.Group>
                    </Col>
                    </Row>

                    <Row className="mb-2">
                    <Col md="7">
                        <Form.Group as={Row} controlId="ProjectName">
                        <Form.Label column sm="5" className="fw-bold">
                            Project Name :
                        </Form.Label>
                        <Col sm="7">
                            <Form.Control
                                disabled
                                type="text"
                                placeholder=""
                                value={ProjectName}
                            />
                        </Col>
                        </Form.Group>
                    </Col>
                    </Row>
                </Col>
                </Row>
                <hr />
                <Row className="mb-4 align-items-center">
                    <Col className="text-center">
                        <Form.Label>[ Item Acknowledgement ]</Form.Label>
                        <ManageDOAcknowledgement_ViewAckModalTable
                            ItemList={ItemList}
                            handleShowAlert={handleShowAlert}
                            rowData={selectedRowData}
                        />
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onClose} variant="secondary">
                Close
                </Button>
            </Modal.Footer>
            </Form>
        </BlockUi>
        <style>
            {`
                .form-control:disabled {
                background: #F8F8F8 !important;
                }
            `}
        </style>
        </Modal>
    );
}

export default ManageDOAcknowledgement_ViewAckModal;
