import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import NewSOItemsTable from "../page_components/NewSO/NewSO_ItemsTable";
import NewSOItemModal from "../page_components/NewSO/NewSO_NewSOItemModal";
import EditSOItemModal from "../page_components/NewSO/NewSO_EditSOItemModal";
import DeleteSOItemModal from "../page_components/NewSO/NewSO_DeleteSOItemModal";
import SLEPOTable from "../page_components/SLEPO/SLEPO_Table";
import SLEPOModal from "../page_components/SLEPO/SLEPO_Modal";
import { useNavigate } from "react-router-dom";
import {
  clearUserNameAndTokenInLocalStorage,
  getTokenFromLocalStorage,
  getUserNameFromLocalStorage,
} from "../lib/common";
import BlockUi from "@availity/block-ui";
import "@availity/block-ui/src/BlockUi.css";
import "@availity/block-ui/src/Loader.css";
import AlertModal from "../page_components/AlertModal";
import moment from "moment/moment";
import { IconContext } from "react-icons";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { useForm, Controller } from "react-hook-form";

const config = JSON.parse(process.env.REACT_APP_CONFIG);

function NewSoConsignment() {
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
    setValue,
    getValues,
    trigger,
  } = useForm({
    defaultValues: {
      CustomerId: "",
      ProjectId: "",
      OrderType: "C",
      PaymentTerm: "",
    },
  });
  const [CustomerId, setCustomerId] = useState("");
  const [CustomerList, setCustomerList] = useState([]);
  const [ProjectId, setProjectId] = useState("");
  const [ProjectList, setProjectList] = useState([]);
  const [OrderType, setOrderType] = useState("C");
  const [OrderTypeDesc] = useState("Consignment");
  const [OrderTypeList, setOrderTypeList] = useState([]);
  const [PaymentTerm, setPaymentTerm] = useState("");
  const [CustomerRef, setCustomerRef] = useState("");
  const [Remarks, setRemarks] = useState("");
  const [ItemList, setItemList] = useState([]);
  const [searchProps, setSearchProps] = useState({});
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [blocking, setBlocking] = useState(true);
  const [ShowAlert, setShowAlert] = useState(false);
  const [AlertTitle, setAlertTitle] = useState("");
  const [AlertMessage, setAlertMessage] = useState("");
  const [ApiResponse, setApiResponse] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (getTokenFromLocalStorage() === null) {
      const state = { data: "Token expired, please login again" };
      navigate("/login", { state });
    }
  });

  useEffect(() => {
    fetch(config.sotype_list_link, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getTokenFromLocalStorage(),
      },
      body: JSON.stringify({}),
    })
      .then((response) => response.json())
      .then((jsonData) => {
        handleToggleFromChild(false);
        if (
          jsonData.success !== true &&
          jsonData.message === "Access Denied! Invalid token"
        ) {
          clearUserNameAndTokenInLocalStorage();
          throw new Error(jsonData.message);
        } else if (jsonData.success) {
          return jsonData.data;
        } else {
          throw new Error(jsonData.message);
        }
      })
      .then((data) => {
        console.log(data);
        setOrderTypeList(data);
      })
      .catch((error) => console.error(error));
  }, []);

  useEffect(() => {
    fetch(config.customer_active_list_link, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getTokenFromLocalStorage(),
      },
      body: JSON.stringify({}),
    })
      .then((response) => response.json())
      .then((jsonData) => {
        handleToggleFromChild(false);
        if (
          jsonData.success !== true &&
          jsonData.message === "Access Denied! Invalid token"
        ) {
          clearUserNameAndTokenInLocalStorage();
          throw new Error(jsonData.message);
        } else if (jsonData.success) {
          return jsonData.data;
        } else {
          throw new Error(jsonData.message);
        }
      })
      .then((data) => {
        setCustomerList(data);
      })
      .catch((error) => console.error(error));
  }, []);

  useEffect(() => {
    if (CustomerId !== "") {
      fetch(config.project_list_link, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + getTokenFromLocalStorage(),
        },
        body: JSON.stringify({
          customer_id: CustomerId,
        }),
      })
        .then((response) => response.json())
        //   .then(res => console.log(res))
        .then((jsonData) => {
          if (
            jsonData.success !== true &&
            jsonData.message === "Access Denied! Invalid token"
          ) {
            clearUserNameAndTokenInLocalStorage();
            throw new Error(jsonData.message);
          } else if (jsonData.success) {
            return jsonData.data;
          } else {
            throw new Error(jsonData.message);
          }
        })
        .then((data) => {
          setProjectList(data);
          setValue("ProjectId", "");
          // trigger("ProjectId");
        })
        .catch((error) => console.error(error));
    }
  }, [CustomerId]);

  const handleShowAlert = async (status, title, message, apiResponse = []) => {
    setAlertTitle(title);
    if (status === "ok") {
      setAlertMessage(
        <>
          <p className="mb-1">
            <IconContext.Provider
              value={{
                color: "green",
                style: { fontSize: "40px", paddingRight: "5px" },
              }}
            >
              <AiOutlineCheckCircle />
              {message}
              <br></br>
            </IconContext.Provider>
          </p>
          <p className="ps-3">Sales Order Number : {apiResponse[0]}</p>
        </>
      );
    } else {
      setAlertMessage(
        <>
          <p className="mb-1">
            <IconContext.Provider
              value={{
                color: "red",
                style: { fontSize: "40px", paddingRight: "5px" },
              }}
            >
              <AiOutlineCloseCircle />
              {message}
            </IconContext.Provider>
          </p>
          {apiResponse.length > 0
            ? apiResponse.map((response) => (
                <p className="text-danger fst-italic ps-3">{response}</p>
              ))
            : ""}
        </>
      );
    }
    setShowAlert(true);
  };

  const handleCloseAlert = () => {
    setShowAlert(false);
  };

  const handleToggleBlocking = () => {
    setBlocking((prevState) => !prevState);
  };

  const handleToggleFromChild = (newValue) => {
    setBlocking(newValue);
  };

  const handleResetForm = () => {
    reset();
    setCustomerId("");
    setProjectId("");
    setProjectList([]);
    setPaymentTerm("");
    setItemList([]);

    // const searchProperties = {
    //   SleSoDateFrom: "",
    //   SleSoDateTo: "",
    //   SleSoNum: "",
    // };
    // setSearchProps(searchProperties);
  };
  // const handleSearch = (event) => {
  //   event.preventDefault();
  //   const searchProperties = {
  //     SleSoDateFrom: SleSoDateFrom,
  //     SleSoDateTo: SleSoDateTo,
  //     SleSoNum: SleSoNum,
  //   };
  //   setSearchProps(searchProperties);
  // };

  // const handleIssuePOClick = (rowData) => {
  //   setSelectedRowData(rowData);
  //   setShowModal(true);
  // };

  const handleAddItemList = (newValue) => {
    setItemList([...ItemList, newValue]);
  };

  const handleEditItemList = (newValue) => {
    const updatedItems = ItemList.map((item) => {
      if (item.ITEM_ID === newValue.ITEM_ID) {
        return newValue; // Replace the matching item
      }
      return item; // Keep other items unchanged
    });

    setItemList(updatedItems);
    // setItemList([...ItemList, newValue]);
  };

  const handleDeleteItemList = (newValue) => {
    const updatedItems = ItemList.filter(
      (item) => item.ITEM_ID !== newValue.ITEM_ID
    );

    console.log(updatedItems);

    setItemList(updatedItems);
    // setItemList([...ItemList, newValue]);
  };

  const handleNewSo = () => {
    setShowModal("newso");
  };
  const handleEditClick = (rowData) => {
    setSelectedRowData(rowData);
    setShowModal("edit");
  };

  const handleDeleteClick = (rowData) => {
    setSelectedRowData(rowData);
    setShowModal("delete");
  };

  const onSubmit = async () => {
    try {
      var arrProductId = [];
      var arrItemQty = [];
      var arrRestockReq = [];
      var arrRestockQty = [];
      var arrRestockDeadline = [];
      var arrSellingPrice = [];
      var arrCostPrice = [];
      var arrSupplierId = [];

      if (ItemList.length === 0) {
        handleShowAlert(
          "error",
          "New Sales Order",
          "Failed Saving New Sales Order :",
          ["Order Item is empty!"]
        );
        throw new Error("Order Item is empty!");
      }

      handleToggleBlocking();

      for (var i = 0; i < ItemList.length; i++) {
        arrProductId.push(ItemList[i].PRODUCT_ID);
        arrSupplierId.push(ItemList[i].SUPPLIER_ID);
        arrItemQty.push(parseInt(ItemList[i].ITEM_QTY));
        arrRestockReq.push(ItemList[i].RESTOCK_REQ);
        arrRestockQty.push(parseInt(ItemList[i].RESTOCK_QTY));
        arrRestockDeadline.push(
          ItemList[i].RESTOCK_DEADLINE
            ? moment(ItemList[i].RESTOCK_DEADLINE).format("YYYY-MM-DD")
            : ""
        );
        arrSellingPrice.push(parseFloat(ItemList[i].SELLING_PRICE).toFixed(2));
        arrCostPrice.push(
          parseFloat(ItemList[i].COST_PRICE).toFixed(2) !== "NaN"
            ? parseFloat(ItemList[i].COST_PRICE).toFixed(2)
            : null
        );
      }

      var jsonOb = {
        customer_id: getValues("CustomerId"),
        so_type: getValues("OrderType"),
        project_id: getValues("ProjectId"),
        payment_term: getValues("PaymentTerm"),
        product_id: arrProductId,
        supplier_id: arrSupplierId,
        item_qty: arrItemQty,
        restock_req: arrRestockReq,
        restock_qty: arrRestockQty,
        restock_deadline: arrRestockDeadline,
        selling_price: arrSellingPrice,
        cost_price: arrCostPrice,
      };
      console.log(jsonOb);
      const response = await fetch(config.so_create_link, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + getTokenFromLocalStorage(),
        },
        body: JSON.stringify(jsonOb),
      });

      const contentType = response.headers.get("content-type");
      const data =
        contentType && contentType.includes("application/json")
          ? await response.json()
          : await response.text();
      console.log("result :");
      console.log(data);
      if (data.message === "Access Denied! Invalid token") {
        clearUserNameAndTokenInLocalStorage();
        throw data.message;
      } else if (data.message) {
        throw data.message;
      }
      if (response.status !== 200) {
        var errorMessages = data.error.map((item) => item.msg);
        handleShowAlert(
          "error",
          "New Sales Order",
          "Failed Saving New Sales Order :",
          errorMessages
        );
        throw errorMessages.join("<br/>");
      } else {
        handleShowAlert(
          "ok",
          "New Sales Order",
          "Success Saving New Sales Order",
          [data.so_number]
        );
        handleResetForm();
        return "ok";
      }
    } catch (error) {
      console.error("Error while submitting New Sales Order:", error);
      throw error;
    } finally {
      handleToggleBlocking();
    }
  };

  return (
    <div>
      <Container className="mt-4">
        <BlockUi blocking={blocking}>
          <Row className="justify-content-center mb-4">
            <Col md={11}>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <Row className="mb-4">
                  <Col className="text-center">
                    <h2>New Sales Order (Consignment)</h2>
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="CustomerId"
                    >
                      <Form.Label
                        column
                        sm="4"
                        style={{ paddingLeft: "165px" }}
                      >
                        Customer Name
                      </Form.Label>
                      <Col sm="6" style={{ paddingRight: "3px" }}>
                        <Controller
                          name="CustomerId"
                          control={control}
                          rules={{ required: "This field is required" }}
                          render={({ field }) => (
                            <Form.Select
                              {...field}
                              onChange={(e) => {
                                setCustomerId(e.target.value);
                                setValue("CustomerId", e.target.value);
                                trigger("CustomerId");
                              }}
                              className={`form-select ${
                                errors.CustomerId ? "is-invalid" : ""
                              }`}
                            >
                              <option key={""} value="">
                                Please select customer name
                              </option>
                              {CustomerList.map((items) => (
                                <option
                                  key={items.CUSTOMER_ID}
                                  value={items.CUSTOMER_ID}
                                >
                                  {items.CUSTOMER_NAME}
                                </option>
                              ))}
                            </Form.Select>
                          )}
                        />
                        {errors.CustomerId && (
                          <Form.Text className="text-danger">
                            {errors.CustomerId.message}
                          </Form.Text>
                        )}
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col>
                    <Form.Group as={Row} className="mb-3" controlId="ProjectId">
                      <Form.Label
                        column
                        sm="4"
                        style={{ paddingLeft: "165px" }}
                      >
                        Project Name
                      </Form.Label>
                      <Col sm="6" style={{ paddingRight: "3px" }}>
                        {/* <Form.Select
                          value={ProjectId}
                          onChange={(e) => setProjectId(e.target.value)}
                        >
                          <option key={""} value="">
                            Not Applicable
                          </option>
                          {ProjectList.map((items) => (
                            <option
                              key={items.PROJECT_ID}
                              value={items.PROJECT_ID}
                            >
                              {items.PROJECT_NAME}
                            </option>
                          ))}
                        </Form.Select> */}
                        <Controller
                          name="ProjectId"
                          control={control}
                          defaultValue={ProjectId}
                          // rules={{ required: "This field is required" }}
                          rules={{ required: false }}
                          render={({ field }) => (
                            <Form.Select
                              {...field}
                              onChange={(e) => {
                                setProjectId(e.target.value);
                                setValue("ProjectId", e.target.value);
                                trigger("ProjectId");
                              }}
                              className={`form-select ${
                                errors.ProjectId ? "is-invalid" : ""
                              }`}
                            >
                              <option key={""} value="">
                                Please select project name
                              </option>
                              {ProjectList.map((items) => (
                                <option
                                  key={items.PROJECT_ID}
                                  value={items.PROJECT_ID}
                                >
                                  {items.PROJECT_NAME}
                                </option>
                              ))}
                            </Form.Select>
                          )}
                        />
                        {errors.ProjectId && (
                          <Form.Text className="text-danger">
                            {errors.ProjectId.message}
                          </Form.Text>
                        )}
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col>
                    <Form.Group as={Row} className="mb-3" controlId="OrderType">
                      <Form.Label
                        column
                        sm="4"
                        style={{ paddingLeft: "165px" }}
                      >
                        Order Type
                      </Form.Label>
                      <Col sm="6" style={{ paddingRight: "3px" }}>
                        {/* <Controller
                          name="OrderType"
                          control={control}
                          rules={{ required: "This field is required" }}
                          render={({ field }) => (
                            <Form.Select
                              {...field}
                              onChange={(e) => {
                                setOrderType(e.target.value);
                                setValue("OrderType", e.target.value);
                                trigger("OrderType");
                              }}
                              className={`form-select ${
                                errors.OrderType ? "is-invalid" : ""
                              }`}
                            >
                              <option key={""} value="">
                                Please select order type
                              </option>
                              {OrderTypeList.map((items) => (
                                <option
                                  key={items.SO_TYPE}
                                  value={items.SO_TYPE}
                                >
                                  {items.SO_TYPE_DESC}
                                </option>
                              ))}
                            </Form.Select>
                          )}
                        />
                        {errors.OrderType && (
                          <Form.Text className="text-danger">
                            {errors.OrderType.message}
                          </Form.Text>
                        )} */}
                        <Form.Control
                          readOnly
                          className="no-border"
                          type="text"
                          value={OrderTypeDesc}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="PaymentTerm"
                    >
                      <Form.Label
                        column
                        sm="4"
                        style={{ paddingLeft: "165px" }}
                      >
                        Payment Term
                      </Form.Label>
                      <Col sm="6" style={{ paddingRight: "3px" }}>
                        <Controller
                          name="PaymentTerm"
                          control={control}
                          rules={{ required: "This field is required" }}
                          render={({ field }) => (
                            <Form.Select
                              {...field}
                              className={`form-select ${
                                errors.PaymentTerm ? "is-invalid" : ""
                              }`}
                            >
                              <option key={""} value="">
                                Please select payment term
                              </option>
                              <option
                                key={"Cash Before Delivery"}
                                value={"CBD"}
                              >
                                {"Cash Before Delivery"}
                              </option>
                              <option key={"Cash On Delivery"} value={"COD"}>
                                {"Cash On Delivery"}
                              </option>
                            </Form.Select>
                          )}
                        />
                        {errors.PaymentTerm && (
                          <Form.Text className="text-danger">
                            {errors.PaymentTerm.message}
                          </Form.Text>
                        )}
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col>
                    <Form.Group as={Row} className="mb-3" controlId="CustomerRef">
                      <Form.Label
                        column
                        sm="4"
                        style={{ paddingLeft: "165px" }}
                      >
                        Customer Ref No.
                      </Form.Label>
                      <Col sm="6" style={{ paddingRight: "3px" }}>
                        <Form.Control
                          type="text"
                          placeholder=""
                          value={CustomerRef}
                          onInput={(e) => setCustomerRef(e.target.value)}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col>
                    <Form.Group as={Row} className="mb-3" controlId="Remarks">
                      <Form.Label
                        column
                        sm="4"
                        style={{ paddingLeft: "165px" }}
                      >
                        Remarks
                      </Form.Label>
                      <Col sm="6" style={{ paddingRight: "3px" }}>
                        <Form.Control
                          as="textarea"
                          rows={5}
                          placeholder="Input remarks if needed"
                          value={Remarks}
                          onInput={(e) => setRemarks(e.target.value)}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <hr />
                <br />
                <Row className="mb-4 align-items-center">
                  <Col sm="9" className="text-center">
                    <Form.Label>[ Order Item ]</Form.Label>
                  </Col>
                  <Col sm="3">
                    <Button
                      className="me-2"
                      variant="secondary"
                      onClick={handleNewSo}
                    >
                      Add Item
                    </Button>
                  </Col>
                </Row>
                <NewSOItemsTable
                  itemList={ItemList}
                  onEditClick={handleEditClick}
                  onDeleteClick={handleDeleteClick}
                />
                <Row className="mt-5">
                  <Col className="text-center">
                    <Button
                      className="me-2"
                      variant="secondary"
                      onClick={handleResetForm}
                    >
                      Reset
                    </Button>
                    <Button type="submit" variant="primary">
                      Save
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
          {showModal === "newso" ? (
            <NewSOItemModal
              handleAddItemList={handleAddItemList}
              handleCloseAlert={handleCloseAlert}
              handleShowAlert={handleShowAlert}
              itemList={ItemList}
              rowData={selectedRowData}
              onClose={() => setShowModal(false)}
              orderType={OrderType}
            />
          ) : (
            ""
          )}
          {showModal === "edit" ? (
            <EditSOItemModal
              handleEditItemList={handleEditItemList}
              handleShowAlert={handleShowAlert}
              itemList={ItemList}
              rowData={selectedRowData}
              onClose={() => setShowModal(false)}
              orderType={OrderType}
            />
          ) : (
            ""
          )}
          {showModal === "delete" ? (
            <DeleteSOItemModal
              handleDeleteItemList={handleDeleteItemList}
              handleShowAlert={handleShowAlert}
              rowData={selectedRowData}
              onClose={() => setShowModal(false)}
            />
          ) : (
            ""
          )}
        </BlockUi>
        {ShowAlert && (
          <AlertModal
            title={AlertTitle}
            message={AlertMessage}
            apiResponse={ApiResponse}
            onClose={handleCloseAlert}
          />
        )}
      </Container>
    </div>
  );
}

export default NewSoConsignment;
