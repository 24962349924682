import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ProjectsTable from "../page_components/Projects/Projects_ProjectsTable";
import NewProjectModal from "../page_components/Projects/Projects_NewProjectModal";
import EditProjectModal from "../page_components/Projects/Projects_EditProjectModal";
import { useNavigate } from "react-router-dom";
import BlockUi from "@availity/block-ui";
import "@availity/block-ui/src/BlockUi.css";
import "@availity/block-ui/src/Loader.css";
import AlertModal from "../page_components/AlertModal";
import { IconContext } from "react-icons";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { AiOutlineCloseCircle } from "react-icons/ai";
import {
  clearUserNameAndTokenInLocalStorage,
  getTokenFromLocalStorage,
} from "../lib/common";
import { useForm, Controller } from "react-hook-form";

const config = JSON.parse(process.env.REACT_APP_CONFIG);

function Projects() {
  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      ProjectName: "",
    },
  });
  const [CustomerName, setCustomerName] = useState("");
  const [CustomerList, setCustomerList] = useState([]);
  const [ProjectName, setProjectName] = useState("");
  const [searchProps, setSearchProps] = useState({});
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [blocking, setBlocking] = useState(true);
  const [ShowAlert, setShowAlert] = useState(false);
  const [AlertTitle, setAlertTitle] = useState("");
  const [AlertMessage, setAlertMessage] = useState("");
  const [ApiResponse, setApiResponse] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (getTokenFromLocalStorage() === null) {
      const state = { data: "Token expired, please login again" };
      navigate("/login", { state });
    }
  });

  useEffect(() => {
    fetch(config.customer_active_list_link, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getTokenFromLocalStorage(),
      },
      body: JSON.stringify({}),
    })
      .then((response) => response.json())
      .then((jsonData) => {
        console.log(jsonData);
        if (
          jsonData.success !== true &&
          jsonData.message === "Access Denied! Invalid token"
        ) {
          clearUserNameAndTokenInLocalStorage();
          throw new Error(jsonData.message);
        } else if (jsonData.success) {
          return jsonData.data;
        } else {
          throw new Error(jsonData.message);
        }
      })
      .then((data) => setCustomerList(data))
      .catch((error) => console.error(error));
  }, []);

  const handleShowAlert = async (status, title, message, apiResponse = []) => {
    setAlertTitle(title);
    if (status === "ok") {
      setAlertMessage(
        <>
          <p className="mb-1">
            <IconContext.Provider
              value={{
                color: "green",
                style: { fontSize: "40px", paddingRight: "5px" },
              }}
            >
              <AiOutlineCheckCircle />
              {message}
            </IconContext.Provider>
          </p>
        </>
      );
    } else {
      setAlertMessage(
        <>
          <p className="mb-1">
            <IconContext.Provider
              value={{
                color: "red",
                style: { fontSize: "40px", paddingRight: "5px" },
              }}
            >
              <AiOutlineCloseCircle />
              {message}
            </IconContext.Provider>
          </p>
          {apiResponse.length > 0
            ? apiResponse.map((response) => (
                <p className="text-danger fst-italic ps-3">{response}</p>
              ))
            : ""}
        </>
      );
    }
    setShowAlert(true);
  };

  const handleCloseAlert = () => {
    setShowAlert(false);
  };

  const handleToggleBlocking = () => {
    setBlocking((prevState) => !prevState);
  };

  const handleToggleFromChild = (newValue) => {
    setBlocking(newValue);
  };

  const handleResetForm = () => {
    reset();
    handleToggleBlocking();
    setCustomerName("");
    setProjectName("");

    const searchProperties = {
      CustomerName: "",
      ProjectName: "",
    };
    setSearchProps(searchProperties);
  };
  const handleSearch = () => {
    // event.preventDefault();
    handleToggleBlocking();

    const searchProperties = {
      CustomerName: CustomerName,
      ProjectName: ProjectName,
    };
    setSearchProps(searchProperties);
  };

  //   const handleIssuePOClick = (rowData) => {
  //     setSelectedRowData(rowData);
  //     setShowModal(true);
  //   };

  const handleNewCostumer = () => {
    setShowModal("newproject");
  };
  const handleEditClick = (rowData) => {
    setSelectedRowData(rowData);
    setShowModal("edit");
  };
  const customValidation = (e) => {
    e.preventDefault();
    // Perform your custom validation logic here
    if (CustomerName != "" || ProjectName != "") {
      // Validation is successful, you can proceed with form submission
      handleSubmit(handleSearch)();
    } else {
      // Validation failed, show an error message or take appropriate action
      // For example, show an alert
      handleShowAlert("error", "Criteria not found", "Criteria not specified!");
    }
  };
  return (
    <div>
      <Container className="mt-4">
        <BlockUi blocking={blocking}>
          <Row className="justify-content-center mb-4">
            <Col md={6}>
              <Form>
                <Row className="mb-4">
                  <Col className="text-center">
                    <h2>Manage Project</h2>
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="CustomerName"
                    >
                      <Form.Label column sm="4">
                        Customer Name
                      </Form.Label>
                      <Col sm="7" style={{ paddingRight: "3px" }}>
                        <Form.Select size="5"
                          value={CustomerName}
                          onChange={(e) => setCustomerName(e.target.value)}
                        >
                          <option key={""} value="">
                            Please select customer name
                          </option>
                          {CustomerList.map((items) => (
                            <option
                              key={items.CUSTOMER_ID}
                              value={items.CUSTOMER_ID}
                            >
                              {items.CUSTOMER_NAME}
                            </option>
                          ))}
                        </Form.Select>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="CustomerName"
                    >
                      <Form.Label column sm="4">
                        Project Name
                      </Form.Label>
                      <Col sm="7" style={{ paddingRight: "3px" }}>
                        <Form.Control
                          type="text"
                          placeholder="Project Name"
                          value={ProjectName}
                          onInput={(e) => setProjectName(e.target.value)}
                          {...register("ProjectName", {
                            pattern: {
                              value: /^(|.{3,})$/,
                              message: "Minimum 3 Character",
                            },
                          })}
                        />
                        {errors.ProjectName && (
                          <p className="text-danger">
                            {errors.ProjectName.message}
                          </p>
                        )}
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col className="text-center">
                    <Button
                      className="me-2"
                      variant="secondary"
                      onClick={handleResetForm}
                    >
                      Reset
                    </Button>
                    <Button
                      type="submit"
                      variant="primary"
                      onClick={customValidation}
                    >
                      Search
                    </Button>
                    <Button
                      className="ms-2"
                      variant="primary"
                      onClick={handleNewCostumer}
                    >
                      New Project
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
          <hr />
          <br />
          <ProjectsTable
            showAlert={ShowAlert}
            searchProps={searchProps}
            onChildToggleChange={handleToggleFromChild}
            onEditClick={handleEditClick}
          />
          {showModal === "newproject" ? (
            <NewProjectModal
              customerList={CustomerList}
              handleShowAlert={handleShowAlert}
              onClose={() => setShowModal(false)}
            />
          ) : (
            ""
          )}
          {showModal === "edit" ? (
            <EditProjectModal
              customerList={CustomerList}
              handleShowAlert={handleShowAlert}
              rowData={selectedRowData}
              onClose={() => setShowModal(false)}
            />
          ) : (
            ""
          )}
        </BlockUi>
        {ShowAlert && (
          <AlertModal
            title={AlertTitle}
            message={AlertMessage}
            apiResponse={ApiResponse}
            onClose={handleCloseAlert}
          />
        )}
      </Container>
    </div>
  );
}

export default Projects;
