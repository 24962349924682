import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import DatePicker from "react-datepicker";
import moment from "moment/moment";
import { useNavigate } from "react-router-dom";
import {
  clearUserNameAndTokenInLocalStorage,
  getUserNameFromLocalStorage,
  getTokenFromLocalStorage,
} from "../../lib/common";
import { v4 as uuidv4 } from "uuid";
import IssuePOModalConsignmentTable from "./PendingPO_IssuePOModalNonConsignmentTable";
import BlockUi from "@availity/block-ui";
import { useForm, Controller } from "react-hook-form";

const config = JSON.parse(process.env.REACT_APP_CONFIG);

function IssuePOModalNonConsignment(props) {
  const {
    control,
    register,
    handleSubmit,
    watch,
    setValue,
    trigger,
    formState: { errors },
  } = useForm({
    defaultValues: {
      ExpectedDeliveryDate: new Date(),
      Currency: "MYR",
      DeliveryTo: "W",
    },
  });
  const { rowData, onClose, handleShowAlert, handleCloseAlert } = props;
  const [SleSoNum] = useState(rowData.SO_NUMBER);
  const [SlePoNum, setSlePoNum] = useState("");
  const [SleMemoNum, setSleMemoNum] = useState("");
  const [SleDoNum, setSleDoNum] = useState("");
  const [CustomerId] = useState(rowData.CUSTOMER_ID);
  const [CustomerName, setCustomerName] = useState(rowData.CUSTOMER_NAME);
  const [ProjectId] = useState(rowData.PROJECT_ID);
  const [ProjectName, setProjectName] = useState(rowData.PROJECT_NAME);
  const [PaymentTerm] = useState(rowData.PAYMENT_TERM);
  const [SleSoDate] = useState(new Date(rowData.SO_DATE));
  const [SlePoDate, setSlePoDate] = useState(new Date());
  const [SleMemoDate, setSleMemoDate] = useState(new Date());
  const [PoDate, setPoDate] = useState(new Date());
  const [ExpectedDeliveryDate, setExpectedDeliveryDate] = useState(new Date());
  const [isIncludeSleDo, setIsIncludeSleDo] = useState(false);
  const [OrderType] = useState(rowData.SO_TYPE_DESC);
  const [OrderTypeCode] = useState(rowData.SO_TYPE);
  const [Remarks, setRemarks] = useState("");
  const [Trigger, setTrigger] = useState("");
  const [CurrencyList, setCurrencyList] = useState([]);
  const [Currency, setCurrency] = useState("MYR");
  const [DeliveryTo, setDeliveryTo] = useState("W");
  const [ItemList, setItemList] = useState([]);
  const [textInputs1, setTextInputs1] = useState({});
  const [textInputs2, setTextInputs2] = useState({});
  const [IsIssued, setIsIssued] = useState(false);
  const [IssuedMessage, setIssuedMessage] = useState("");
  const [blocking, setBlocking] = useState(false);
  const [minDate] = useState(new Date());
  const [maxDate] = useState(() => {
    const maxDate = new Date();
    maxDate.setMonth(new Date().getMonth() + 3);
    const formattedMinDate = maxDate;
    return formattedMinDate;
  });
  const [SupplierList, setSupplierList] = useState([]);
  const [SupplierId, setSupplierId] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    fetch(config.po_pending_get_supplier_link, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getTokenFromLocalStorage(),
      },
      body: JSON.stringify({
        so_number: SleSoNum,
        so_type: OrderTypeCode,
      }),
    })
      .then((response) => response.json())
      .then((jsonData) => {
        if (jsonData.message === "Access Denied! Invalid token") {
          clearUserNameAndTokenInLocalStorage();
          throw new Error(jsonData.message);
        }
        return jsonData;
      })
      .then((data) => {
        if (data.message){
          if (data.message.match(/All PO have been issued for the given SO/g) || data.message.match(/No valid supplier found. Pls ensure that the products in the SO have a valid supplier/g)){
            setIsIssued(true);
            setIssuedMessage(data.message);
          }
        }
        else {
          setIsIssued(false);
          setIssuedMessage("");
          setSupplierList(data.data);
        }
        console.log(data);
      })
      .catch((error) => console.error(error));
  }, []);

  useEffect(() => {
    fetch(config.currency_list_link, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getTokenFromLocalStorage(),
      },
      body: JSON.stringify({}),
    })
      .then((response) => response.json())
      .then((jsonData) => {
        if (jsonData.message === "Access Denied! Invalid token") {
          clearUserNameAndTokenInLocalStorage();
          throw new Error(jsonData.message);
        }
        return jsonData;
      })
      .then((data) => {
        setCurrencyList(data.data);
        console.log(data);
      })
      .catch((error) => console.error(error));
  }, []);

  useEffect(() => {
    function addIds(data) {
      return data.map((product) => {
        // Add the custom ID to each object
        var uuid = uuidv4();
        const productWithCustomID = { ...product, CUSTOM_ID: uuid };
        return productWithCustomID;
      });
    }
    if (SupplierId !== "") {
      fetch(config.po_pending_item_list_link, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + getTokenFromLocalStorage(),
        },
        body: JSON.stringify({
          so_number: SleSoNum,
          supplier_id: SupplierId,
          so_type: OrderTypeCode,
        }),
      })
        .then((response) => response.json())
        .then((jsonData) => {
          if (jsonData.message === "Access Denied! Invalid token") {
            clearUserNameAndTokenInLocalStorage();
            throw new Error(jsonData.message);
          }
          return jsonData;
        })
        .then((data) => {
          setItemList(addIds(data.data));
          console.log(data);
        })
        .catch((error) => console.error(error));
    } else {
      setItemList([]);
    }
  }, [SupplierId]);

  const handleIncludeSleDoCheckbox = (event) => {
    setIsIncludeSleDo(event.target.checked);
  };
  const handleToggleBlocking = () => {
    setBlocking((prevState) => !prevState);
  };

  const handleToggleFromChild = (newValue) => {
    setBlocking(newValue);
  };

  const handleInputChange1 = (rowId, value) => {
    setTextInputs1({ ...textInputs1, [rowId]: value });
  };
  const handleInputChange2 = (rowId, value) => {
    setTextInputs2({ ...textInputs2, [rowId]: value });
  };

  const handleSaveNewPo = async () => {
    try {
      handleToggleBlocking();
      var submitNewPoResponse = await submitNewPo();
      handleToggleBlocking();
      console.log("Success saving New Purchase Order");
      onClose();
      handleShowAlert(
        "ok",
        "New Purchase Order",
        "Success saving New Purchase Order",
        [submitNewPoResponse.po_number]
      );
    } catch (error) {
      handleToggleBlocking();
      console.error("Error while saving New Purchase Order:", error);
      handleShowAlert(
        "error",
        "New Purchase Order",
        "Error while saving New Purchase Order: ",
        error
      );
      return;
    }
  };

  const submitNewPo = async () => {
    try {
      var arrProductId = [];
      var arrItemQty = [];
      var arrPurchasePrice = [];
      // console.log(ItemList);
      // console.log(textInputs1);
      // console.log(textInputs2);
      for (var i = 0; i < ItemList.length; i++) {
        arrProductId.push(ItemList[i].PRODUCT_ID);
        arrItemQty.push(parseFloat(textInputs1[ItemList[i].ITEM_ID]).toFixed(4));
        arrPurchasePrice.push(parseFloat(textInputs2[ItemList[i].CUSTOM_ID]).toFixed(2));
      }
      console.log({
        po_type: "S",
        so_number: SleSoNum,
        po_date: moment(PoDate).format("YYYY-MM-DD"),
        supplier_id: SupplierId,
        expected_delivery_date: moment(ExpectedDeliveryDate).format("YYYY-MM-DD"),
        currency_code: Currency,
        remarks: Remarks,
        product_id: arrProductId,
        item_qty: arrItemQty,
        purchase_price: arrPurchasePrice,
        delivery_to: DeliveryTo
      });
      const response = await fetch(config.po_create_link, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + getTokenFromLocalStorage(),
        },
        body: JSON.stringify({
          po_type: "S",
          so_number: SleSoNum,
          po_date: moment(PoDate).format("YYYY-MM-DD"),
          supplier_id: SupplierId,
          expected_delivery_date: moment(ExpectedDeliveryDate).format("YYYY-MM-DD"),
          currency_code: Currency,
          remarks: Remarks,
          product_id: arrProductId,
          item_qty: arrItemQty,
          purchase_price: arrPurchasePrice,
          delivery_to: DeliveryTo
        }),
      });

      const data = await response.json();
      console.log("submit po response : ");
      console.log(data);
      if (
        data.success !== true &&
        data.message === "Access Denied! Invalid token"
      ) {
        clearUserNameAndTokenInLocalStorage();
      }

      if (data.success !== true) {
        var errorMessages = data.error.map((item) => item.msg);
        // handleShowAlert("error", "New Invoice", "Failed Saving New Invoice :", errorMessages.join("<br>"));
        throw errorMessages;
      }
      if (data.success === true) {
        console.log("Success Submitting New PO");
        // handleTrigger(moment().unix());
        return data;
      } else {
        throw data;
      }
    } catch (error) {
      console.error("Error while submitting PO:", error);
      throw error;
    }
  };

  const handleDeleteClick = (rowData) => {
    handleDeleteItemList(rowData);
  };

  const handleDeleteItemList = (newValue) => {
    if (ItemList.length==1){
      handleShowAlert(
        "error",
        "New Purchase Order",
        "Order Items cannot be empty!",
      );
      return;
    }
    const updatedItems = ItemList.filter(
      (item) => item.ITEM_ID !== newValue.ITEM_ID
    );

    console.log(updatedItems);

    setItemList(updatedItems);
    // setItemList([...ItemList, newValue]);
  };

  return (
    <Modal show={true} onHide={onClose} size="xl" backdrop="static" centered>
      <BlockUi blocking={blocking}>
        <Form onSubmit={handleSubmit(handleSaveNewPo)}>
          <Modal.Header closeButton className="px-4">
            <Modal.Title className="ms-auto">
              New PO (Non-consignment Order)
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className={IsIssued === true ? "d-none" : ""}>
            {/* <Row className="mb-2 " style={{paddingLeft : "120px"}}>
              <Col md="6">
                <Form.Group as={Row} controlId="SLESONumber">
                  <Form.Label column sm="4" className="fw-bold">
                    SLE SO Number :
                  </Form.Label>
                  <Col sm="7">
                    <Form.Control
                      readOnly
                      className="no-border"
                      type="text"
                      placeholder=""
                      value={SleSoNum}
                    />
                  </Col>
                </Form.Group>
              </Col>

              <Col md="6">
                <Form.Group as={Row} controlId="SLESODate">
                  <Form.Label column sm="4" className="fw-bold">
                    SLE SO Date :
                  </Form.Label>
                  <Col sm="7">
                    <DatePicker
                      readOnly
                      dateFormat="dd/MM/yyyy"
                      className="form-control no-border"
                      showIcon
                      selected={SleSoDate}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row> */}
            <Row className="mb-2 ">
              <Col md="5">
                <Form.Group as={Row} controlId="SLESONumber">
                  <Form.Label column sm="4" className="fw-bold">
                    SO Number :
                  </Form.Label>
                  <Col sm="6" style={{ marginLeft: "32px" }}>
                    <Form.Control
                      disabled
                      className="no-border"
                      type="text"
                      placeholder=""
                      value={SleSoNum}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-2 ">
              <Col md="12">
                <Form.Group as={Row} controlId="SLESONumber">
                  <Form.Label column sm="2" className="fw-bold">
                    Supplier :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Select
                      value={SupplierId}
                      onChange={(e) => setSupplierId(e.target.value)}
                    >
                      <option key={""} value="">
                        Please select supplier name
                      </option>
                      {SupplierList.map((items) => (
                        <option
                          key={items.SUPPLIER_ID}
                          value={items.SUPPLIER_ID}
                        >
                          {items.SUPPLIER_NAME}
                        </option>
                      ))}
                    </Form.Select>
                    {/* <Form.Control
                      readOnly
                      className="no-border"
                      type="text"
                      placeholder=""
                      value={SleSoNum}
                    /> */}
                  </Col>
                </Form.Group>
              </Col>

              {/* <Col md="6">
                <Form.Group as={Row} controlId="SLESODate">
                  <Form.Label column sm="4" className="fw-bold">
                    SLE SO Date :
                  </Form.Label>
                  <Col sm="7">
                    <DatePicker
                      readOnly
                      dateFormat="dd/MM/yyyy"
                      className="form-control no-border"
                      showIcon
                      selected={SleSoDate}
                    />
                  </Col>
                </Form.Group>
              </Col> */}
            </Row>
            <Row className="mb-2 ">
              <Col md="5">
                <Form.Group as={Row} controlId="SLESONumber">
                  <Form.Label column sm="4" className="fw-bold">
                    PO Date :
                  </Form.Label>
                  <Col sm="6" style={{ marginLeft: "32px" }}>
                  <DatePicker
                      readOnly
                      dateFormat="dd/MM/yyyy"
                      className="form-control no-border"
                      showIcon
                      selected={PoDate}
                    />
                  </Col>
                </Form.Group>
              </Col>

              <Col md="6">
                <Form.Group as={Row} controlId="SLESODate">
                  <Form.Label column sm="4" className="fw-bold">
                    Expected Delivery :
                  </Form.Label>
                  <Col sm="6">
                  <Controller
                      name="ExpectedDeliveryDate"
                      control={control}
                      rules={{
                        required: "This field is required",
                        pattern: {
                          value:
                            /^(0[1-9]|1\d|2\d|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/,
                          message: "Invalid date format",
                        },
                      }}
                      render={({ field }) => (
                        <>
                          <DatePicker
                            selected={field.value}
                            onChange={(date) => {
                              field.onChange(date);
                              setExpectedDeliveryDate(date);
                            }}
                            minDate={minDate}
                            maxDate={maxDate}
                            dateFormat="dd/MM/yyyy"
                            className={`form-control ${
                              errors.ExpectedDeliveryDate ? "is-invalid" : ""
                            }`}
                          />
                          {errors.ExpectedDeliveryDate && (
                            <Form.Text className="text-danger">
                              {errors.ExpectedDeliveryDate.message}
                            </Form.Text>
                          )}
                        </>
                      )}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-2 ">
              <Col md="5">
                <Form.Group as={Row} controlId="SLESONumber">
                  <Form.Label column sm="4" className="fw-bold">
                    Currency :
                  </Form.Label>
                  <Col sm="6" style={{ marginLeft: "32px" }}>
                    {/* <Form.Control
                      readOnly
                      className="no-border"
                      type="text"
                      placeholder=""
                      value={CustomerName}
                    /> */}
                    <Controller
                      name="Currency"
                      control={control}
                      rules={{ required: "This field is required" }}
                      defaultValue=""
                      className={`form-control ${
                        errors.Brand ? "is-invalid" : ""
                      }`}
                      render={({ field }) => (
                        <Form.Select
                          {...field}
                          onChange={(e) => {
                            // setSupplierId(e.target.value);
                            // setSupplierId(
                            //   e.target.options[e.target.selectedIndex].text
                            // );
                            setValue("Currency", e.target.value);
                            setCurrency(e.target.value);
                            trigger("Currency");
                          }}
                          className={`form-select ${
                            errors.Currency ? "is-invalid" : ""
                          }`}
                        >
                          <option key={""} value="" disabled>
                            Please select currency
                          </option>
                          {/* <option key={"HOWA"} value="HOWA">
                          HOWA
                        </option>
                        <option key={"GLINE"} value="GLINE">
                          GLINE
                        </option> */}
                          {CurrencyList.map((items) => (
                            <option
                              key={items.CURRENCY_CODE}
                              value={items.CURRENCY_CODE}
                            >
                              {items.CURRENCY_NAME}
                            </option>
                          ))}
                        </Form.Select>
                      )}
                    />
                    {errors.Currency && (
                      <Form.Text className="text-danger">
                        {errors.Currency.message}
                      </Form.Text>
                    )}
                  </Col>
                </Form.Group>
              </Col>

              <Col md="6">
                <Form.Group as={Row} controlId="SLESODate">
                  <Form.Label column sm="4" className="fw-bold">
                    Delivery To :
                  </Form.Label>
                  <Col sm="6">
                    <Controller
                      name="DeliveryTo"
                      control={control}
                      rules={{ required: "This field is required" }}
                      defaultValue=""
                      className={`form-control ${
                        errors.Brand ? "is-invalid" : ""
                      }`}
                      render={({ field }) => (
                        <Form.Select
                          {...field}
                          onChange={(e) => {
                            // setSupplierId(e.target.value);
                            // setSupplierId(
                            //   e.target.options[e.target.selectedIndex].text
                            // );
                            setValue("DeliveryTo", e.target.value);
                            setDeliveryTo(e.target.value);
                            trigger("DeliveryTo");
                          }}
                          className={`form-select ${
                            errors.DeliveryTo ? "is-invalid" : ""
                          }`}
                        >
                          {/* <option key={""} value="">
                            Please select currency
                          </option> */}
                          <option key={"C"} value="C" disabled>
                            Client's Premise
                          </option>
                          <option key={"W"} value="W">
                            Warehouse
                          </option>
                          {/* {CurrencyList.map((items) => (
                            <option
                              key={items.CURRENCY_CODE}
                              value={items.CURRENCY_CODE}
                            >
                              {items.CURRENCY_NAME}
                            </option>
                          ))} */}
                        </Form.Select>
                      )}
                    />
                    {errors.DeliveryTo && (
                      <Form.Text className="text-danger">
                        {errors.DeliveryTo.message}
                      </Form.Text>
                    )}
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <Form.Group as={Row} controlId="Remarks">
                  <Form.Label column sm="2" className="fw-bold">
                    Remarks :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      as="textarea"
                      rows={5}
                      placeholder="Input remarks if needed"
                      value={Remarks}
                      onInput={(e) => setRemarks(e.target.value)}
                    />
                  </Col>
                </Form.Group>
              </Col>

              {/* <Col md="6">
                <Form.Group as={Row} controlId="OrderType">
                  <Form.Label column sm="4" className="fw-bold">
                    Order Status :
                  </Form.Label>
                  <Col sm="7">
                    <Form.Control
                      readOnly
                      className="no-border"
                      type="text"
                      placeholder=""
                      value={PaymentTerm}
                    />
                  </Col>
                </Form.Group>
              </Col> */}
            </Row>
            <hr />
            <Col sm="12" className="text-center fw-bold">
              <p>Order Items</p>
            </Col>
            <hr />
            <IssuePOModalConsignmentTable
              isIssued={IsIssued}
              itemList={ItemList}
              control={control}
              register={register}
              errors={errors}
              textInputs1={textInputs1}
              setTextInputs1={setTextInputs1}
              onInputChange1={handleInputChange1}
              textInputs2={textInputs2}
              setTextInputs2={setTextInputs2}
              onInputChange2={handleInputChange2}
              onDeleteClick={handleDeleteClick}
              // searchProps={searchProps}
              // onChildToggleChange={handleToggleFromChild}
              // onOnHoldClick={handleOnHoldClick}
              // onRestockClick={handleRestockClick}
              // onIssuePOClick={handleIssuePOClick}
            />
            {/* <Row className="mb-2">
              <Col md="12">
                <Form.Group
                  as={Row}
                  controlId="PoDate"
                  className="justify-content-end"
                >
                  <Form.Label column sm="9" className="fw-bold text-end">
                    PO Date :
                  </Form.Label>
                  <Col sm="2">
                    <Controller
                      name="PoDate"
                      control={control}
                      rules={{
                        required: "This field is required",
                        pattern: {
                          value:
                            /^(0[1-9]|1\d|2\d|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/,
                          message: "Invalid date format",
                        },
                      }}
                      render={({ field }) => (
                        <>
                          <DatePicker
                            selected={field.value}
                            onChange={(date) => field.onChange(date)}
                            minDate={minDate}
                            maxDate={maxDate}
                            className={`form-control ${
                              errors.PoDate ? "is-invalid" : ""
                            }`}
                          />
                          {errors.PoDate && (
                            <Form.Text className="text-danger">
                              {errors.PoDate.message}
                            </Form.Text>
                          )}
                        </>
                      )}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row> */}
          </Modal.Body>
          {IsIssued === true ? (
            <div className="text-center">
              <h3 className="text-danger">{IssuedMessage}</h3>
            </div>
          ) : (
            ""
          )}
          <Modal.Footer>
            <Button className={IsIssued === true ? "d-none" : ""} onClick={onClose} variant="secondary">
              Cancel
            </Button>
            <Button className={IsIssued === true ? "d-none" : ""} type="submit" variant="primary">
              Save
            </Button>
          </Modal.Footer>
        </Form>
      </BlockUi>
    </Modal>
  );
}

export default IssuePOModalNonConsignment;
