import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import logo from "../assets/android-chrome-512x512.png";
import {
  MDBContainer,
  MDBCol,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBNavbarLink,
  MDBCollapse,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBListGroup,
  MDBListGroupItem,
  MDBRow,
  MDBIcon,
  MDBDropdownItem,
} from "mdb-react-ui-kit";

function NavigationBar() {
  const location = useLocation();
  const [active, setActive] = useState(
    localStorage.getItem("activeKey") || "default"
  );
  const [tokenExists, setTokenExists] = useState(
    !!localStorage.getItem("token")
  ); // Check if token exists

  const [showNavNoTogglerSecond, setShowNavNoTogglerSecond] = useState(false);
  const [showNestedSubMenu, setShowNestedSubMenu] = useState(false);
  const [showNestedSubMenuPO, setShowNestedSubMenuPO] = useState(false);
  const [showNestedSubMenuPI, setShowNestedSubMenuPI] = useState(false);
  const [showNestedSubMenuDOAcknowledgement, setShowNestedSubMenuDOAcknowledgement] = useState(false);
  const [showNestedSubMenuGRN, setShowNestedSubMenuGRN] = useState(false);
  const [showNestedSubMenuInvoice, setShowNestedSubMenuInvoice] = useState(false);
  const [customerId] = useState("");

  const [
    showNestedSubMenuOrderOrderFulfilment,
    setShowNestedSubMenuOrderFulfilment,
  ] = useState(false);
  useEffect(() => {
    const handleTokenChange = () => {
      setTokenExists(!!localStorage.getItem("token")); // Update tokenExists when token changes
    };

    // Listen for custom event 'tokenChange'
    window.addEventListener("tokenChange", handleTokenChange);

    return () => {
      window.removeEventListener("tokenChange", handleTokenChange); // Clean up event listener on component unmount
    };
  }, []);

  useEffect(() => {
    localStorage.setItem("activeKey", active);
  }, [active]);

  useEffect(() => {
    const path = location.pathname.split("/")[1];
    switch (path) {
      case "customers":
        setActive("customers");
        break;
      case "suppliers":
        setActive("suppliers");
        break;
      case "sleso":
        setActive("sleso");
        break;
      case "slepo":
        setActive("slepo");
        break;
      case "deliverydate":
        setActive("deliverydate");
        break;
      case "deliverystatus":
        setActive("deliverystatus");
        break;
      case "sleinvoice":
        setActive("sleinvoice");
        break;
      case "changepassword":
        setActive("changepassword");
        break;
      default:
        setActive("default");
    }
  }, [location]);

  // return (
  // <Navbar bg="dark" variant="dark" expand="lg">
  //   <Container>
  //     <Navbar.Brand href="/">
  //       <img
  //         src={logo}
  //         alt="Logo"
  //         className="d-inline-block align-top"
  //         style={{ width: '35px', height: 'auto' }}
  //       />
  //     </Navbar.Brand>
  //     <Navbar.Toggle aria-controls="basic-navbar-nav" />
  //     <Navbar.Collapse id="basic-navbar-nav">
  //       <Nav className="sub-nav" activeKey={active} onSelect={(selectedKey) => setActive(selectedKey)}>
  //         {tokenExists && (
  //           <>
  //             <Nav.Link className='navbar-link' eventKey="sleso" as={Link} to="/sleso">SLE SO</Nav.Link>
  //             <Nav.Link className='navbar-link' eventKey="slepo" as={Link} to="/slepo">SLE PO</Nav.Link>
  //             <Nav.Link className='navbar-link' eventKey="deliverydate" as={Link} to="/deliverydate">Delivery Date</Nav.Link>
  //             <Nav.Link className='navbar-link' eventKey="deliverystatus" as={Link} to="/deliverystatus">Delivery Status</Nav.Link>
  //             <Nav.Link className='navbar-link' eventKey="sleinvoice" as={Link} to="/sleinvoice">SLE Invoice</Nav.Link>
  //             <Nav.Link className='navbar-link' eventKey="changepassword" as={Link} to="/changepassword">Change Password</Nav.Link>
  //             <Nav.Link className='navbar-link' eventKey="logout" as={Link} to="/logout">Logout</Nav.Link>
  //           </>
  //         )}
  //       </Nav>
  //     </Navbar.Collapse>
  //   </Container>
  // </Navbar>
  // );

  return (
    <MDBNavbar expand="lg" dark bgColor="dark">
      <MDBContainer fluid>
        <MDBNavbarBrand href="/">
          <img
            src={logo}
            alt="Logo"
            className="align-top"
            style={{ width: "35px", height: "auto" }}
          />
        </MDBNavbarBrand>
        {tokenExists && (
          <>
            <MDBNavbarToggler
              type="button"
              data-target="#navbarTogglerDemo02"
              aria-controls="navbarTogglerDemo02"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={() => setShowNavNoTogglerSecond(!showNavNoTogglerSecond)}
            >
              <MDBIcon icon="bars" fas />
            </MDBNavbarToggler>
            <MDBCollapse navbar show={showNavNoTogglerSecond}>
              <MDBNavbarNav
                className="me-auto ps-lg-0"
                style={{ paddingLeft: "0.15rem" }}
              >
                <MDBNavbarItem className="position-static">
                  <MDBDropdown>
                    <MDBDropdownToggle
                      tag="a"
                      className="nav-link"
                      style={{ cursor: "pointer" }}
                    >
                      Setup
                    </MDBDropdownToggle>
                    <MDBDropdownMenu
                      className="mt-0 justify-content-center"
                      style={{
                        borderTopLeftRadius: "0",
                        borderTopRightRadius: "0",
                        minWidth: "225px",
                      }}
                    >
                      <MDBContainer>
                        <MDBRow className="my-2">
                          <MDBCol md="6" lg="12" className="mb-3 mb-lg-0">
                            <MDBListGroup flush>
                              <MDBListGroupItem
                                tag="a"
                                href="/customers"
                                action
                                noBorders={true}
                              >
                                Manage Customers
                              </MDBListGroupItem>

                              <MDBListGroupItem
                                tag="a"
                                href="/suppliers"
                                action
                                noBorders={true}
                              >
                                Manage Suppliers
                              </MDBListGroupItem>
                              <MDBListGroupItem
                                tag="a"
                                href="/projects"
                                action
                                noBorders={true}
                              >
                                Manage Projects
                              </MDBListGroupItem>
                              <MDBListGroupItem
                                tag="a"
                                href="/products"
                                action
                                noBorders={true}
                              >
                                Manage Products
                              </MDBListGroupItem>
                            </MDBListGroup>
                          </MDBCol>
                        </MDBRow>
                      </MDBContainer>
                    </MDBDropdownMenu>
                  </MDBDropdown>
                </MDBNavbarItem>
                <MDBNavbarItem className="position-static">
                  <MDBDropdown>
                    <MDBDropdownToggle
                      tag="a"
                      className="nav-link"
                      style={{ cursor: "pointer" }}
                    >
                      Sales Order
                    </MDBDropdownToggle>
                    <MDBDropdownMenu
                      className="mt-0 justify-content-center"
                      style={{
                        borderTopLeftRadius: "0",
                        borderTopRightRadius: "0",
                        width: "260px",
                      }}
                    >
                      <MDBContainer>
                        <MDBRow className="my-2">
                          <MDBCol md="6" lg="12" className="mb-3 mb-lg-0">
                            <MDBListGroup>
                              {/* <MDBListGroupItem tag="a" href="/newso" action noBorders={true}>
                                
                                New Sales Order
                              </MDBListGroupItem> */}
                              <MDBListGroupItem
                                tag="a"
                                href="/newsoconsignment"
                                action
                                noBorders={true}
                              >
                                New Sales Order (Consignment)
                              </MDBListGroupItem>
                              <MDBListGroupItem
                                tag="a"
                                href="/newsononconsignment"
                                action
                                noBorders={true}
                              >
                                New Sales Order (Non-consignment)
                              </MDBListGroupItem>
                              <MDBListGroupItem
                                tag="a"
                                href="/manageso"
                                action
                                noBorders={true}
                              >
                                Manage Sales Order
                              </MDBListGroupItem>
                              <MDBListGroupItem
                                tag="a"
                                href="/managerestockrequest"
                                action
                                noBorders={true}
                              >
                                Manage Restock Request
                              </MDBListGroupItem>
                            </MDBListGroup>
                          </MDBCol>
                        </MDBRow>
                      </MDBContainer>
                    </MDBDropdownMenu>
                  </MDBDropdown>
                </MDBNavbarItem>
                <MDBNavbarItem className="position-static">
                  <MDBDropdown>
                    <MDBDropdownToggle
                      tag="a"
                      className="nav-link"
                      style={{ cursor: "pointer" }}
                    >
                      Procurement
                    </MDBDropdownToggle>

                    <MDBDropdownMenu
                      className="mt-0 justify-content-center"
                      style={{
                        borderTopLeftRadius: "0",
                        borderTopRightRadius: "0",
                        width: "260px",
                      }}
                    >
                      <MDBContainer>
                        <MDBRow className="my-2">
                          <MDBCol md="6" lg="12" className="mb-3 mb-lg-0">
                            <MDBListGroup
                              onMouseLeave={() => {
                                setShowNestedSubMenuPO(false);
                                setShowNestedSubMenuOrderFulfilment(false);
                                setShowNestedSubMenuPI(false);
                                setShowNestedSubMenuDOAcknowledgement(false);
                              }}
                            >
                              <MDBListGroupItem
                                tag="a"
                                href="#"
                                noBorders={true}
                                onMouseEnter={() =>
                                  setShowNestedSubMenuPO(true)
                                }
                              >
                                Purchase Order (PO){" "}
                                <MDBIcon icon="caret-right" className="pe-2" />
                              </MDBListGroupItem>
                              {showNestedSubMenuPO ? (
                                <MDBContainer>
                                  <MDBRow className="">
                                    <MDBCol
                                      md="6"
                                      lg="12"
                                      className="mb-3 mb-lg-0"
                                    >
                                      <MDBListGroup>
                                        <MDBListGroupItem
                                          tag="a"
                                          href="/pendingpo"
                                          action
                                          noBorders={true}
                                        >
                                          &raquo; New PO (From SO)
                                        </MDBListGroupItem>
                                        <MDBListGroupItem
                                          tag="a"
                                          href="/newpoadhoc"
                                          action
                                          noBorders={true}
                                        >
                                          &raquo; New PO (Adhoc)
                                        </MDBListGroupItem>
                                        <MDBListGroupItem
                                          tag="a"
                                          href="/searchpo"
                                          action
                                          noBorders={true}
                                        >
                                          &raquo; Search PO
                                        </MDBListGroupItem>
                                      </MDBListGroup>
                                    </MDBCol>
                                  </MDBRow>
                                </MDBContainer>
                              ) : (
                                ""
                              )}
                              {/* <MDBListGroupItem
                                tag="a"
                                href="#"
                                noBorders={true}
                                onMouseEnter={() =>
                                  setShowNestedSubMenuOrderFulfilment(true)
                                }
                              >
                                Order Fulfilment{" "}
                                <MDBIcon icon="caret-right" className="pe-2" />
                              </MDBListGroupItem>
                              {showNestedSubMenuOrderOrderFulfilment ? (
                                <MDBContainer>
                                  <MDBRow className="">
                                    <MDBCol
                                      md="6"
                                      lg="12"
                                      className="mb-3 mb-lg-0"
                                    >
                                      <MDBListGroup>
                                        <MDBListGroupItem
                                          tag="a"
                                          href="#"
                                          action
                                          noBorders={true}
                                        >
                                          &raquo; New Order Fulfilment
                                        </MDBListGroupItem>
                                        <MDBListGroupItem
                                          tag="a"
                                          href="#"
                                          action
                                          noBorders={true}
                                        >
                                          &raquo; Search Order Fulfilment
                                        </MDBListGroupItem>
                                      </MDBListGroup>
                                    </MDBCol>
                                  </MDBRow>
                                </MDBContainer>
                              ) : (
                                ""
                              )} */}

                              {/* Purchase Invoice */}
                              <MDBListGroupItem
                                tag="a"
                                href="#"
                                noBorders={true}
                                onMouseEnter={() =>
                                  setShowNestedSubMenuPI(true)
                                }
                              >
                                Purchased Invoice (PI){" "}
                                <MDBIcon icon="caret-right" className="pe-2" />
                              </MDBListGroupItem>
                              {showNestedSubMenuPI ? (
                                <MDBContainer>
                                  <MDBRow className="">
                                    <MDBCol
                                      md="6"
                                      lg="12"
                                      className="mb-3 mb-lg-0"
                                    >
                                      <MDBListGroup>
                                        <MDBListGroupItem
                                          tag="a"
                                          href="/newpurchasedinvoice"
                                          action
                                          noBorders={true}
                                        >
                                          &raquo; New PI
                                        </MDBListGroupItem>
                                        <MDBListGroupItem
                                          tag="a"
                                          href="/managepurchasedinvoice"
                                          action
                                          noBorders={true}
                                        >
                                          &raquo; Manage PI
                                        </MDBListGroupItem>
                                      </MDBListGroup>
                                    </MDBCol>
                                  </MDBRow>
                                </MDBContainer>
                              ) : (
                                ""
                              )}

                              {/* DO Acknowledgement */}
                              <MDBListGroupItem
                                tag="a"
                                href="#"
                                noBorders={true}
                                onMouseEnter={() =>
                                  setShowNestedSubMenuDOAcknowledgement(true)
                                }
                              >
                                DO Acknowledgement{" "}
                                <MDBIcon icon="caret-right" className="pe-2" />
                              </MDBListGroupItem>
                              {showNestedSubMenuDOAcknowledgement ? (
                                <MDBContainer>
                                  <MDBRow className="">
                                    <MDBCol
                                      md="6"
                                      lg="12"
                                      className="mb-3 mb-lg-0"
                                    >
                                      <MDBListGroup>
                                        <MDBListGroupItem
                                          tag="a"
                                          href="/newdoacknowledgement"
                                          action
                                          noBorders={true}
                                        >
                                          &raquo; New DO Acknowledgement
                                        </MDBListGroupItem>
                                        <MDBListGroupItem
                                          tag="a"
                                          href="/managedoacknowledgement"
                                          action
                                          noBorders={true}
                                        >
                                          &raquo; Manage DO Acknowledgement
                                        </MDBListGroupItem>
                                      </MDBListGroup>
                                    </MDBCol>
                                  </MDBRow>
                                </MDBContainer>
                              ) : (
                                ""
                              )}

                            </MDBListGroup>
                          </MDBCol>
                        </MDBRow>
                      </MDBContainer>
                    </MDBDropdownMenu>
                  </MDBDropdown>
                </MDBNavbarItem>
                <MDBNavbarItem className="position-static">
                  <MDBDropdown>
                    <MDBDropdownToggle
                      tag="a"
                      className="nav-link"
                      style={{ cursor: "pointer" }}
                    >
                      Delivery Order
                    </MDBDropdownToggle>
                    <MDBDropdownMenu
                      className="mt-0 justify-content-center"
                      style={{
                        borderTopLeftRadius: "0",
                        borderTopRightRadius: "0",
                        width: "260px",
                      }}
                    >
                      <MDBContainer>
                        <MDBRow className="my-2">
                          <MDBCol md="6" lg="12" className="mb-3 mb-lg-0">
                            <MDBListGroup>
                              <MDBListGroupItem
                                tag="a"
                                href="/newdeliveryorder"
                                action
                                noBorders={true}
                              >
                                New Delivery Order
                              </MDBListGroupItem>
                              <MDBListGroupItem
                                tag="a"
                                href="/searchdeliveryorder"
                                action
                                noBorders={true}
                              >
                                Search Delivery Order
                              </MDBListGroupItem>
                            </MDBListGroup>
                          </MDBCol>
                        </MDBRow>
                      </MDBContainer>
                    </MDBDropdownMenu>
                  </MDBDropdown>
                </MDBNavbarItem>
                <MDBNavbarItem className="position-static">
                  <MDBDropdown>
                    <MDBDropdownToggle
                      tag="a"
                      className="nav-link"
                      style={{ cursor: "pointer" }}
                    >
                      Delivery
                    </MDBDropdownToggle>
                    <MDBDropdownMenu
                      className="mt-0 justify-content-center"
                      style={{
                        borderTopLeftRadius: "0",
                        borderTopRightRadius: "0",
                        width: "260px",
                      }}
                    >
                      <MDBContainer>
                        <MDBRow className="my-2">
                          <MDBCol md="6" lg="12" className="mb-3 mb-lg-0">
                            <MDBListGroup>
                              <MDBListGroupItem
                                tag="a"
                                href="/deliverystatus"
                                action
                                noBorders={true}
                              >
                                Delivery Status
                              </MDBListGroupItem>
                            </MDBListGroup>
                          </MDBCol>
                        </MDBRow>
                      </MDBContainer>
                    </MDBDropdownMenu>
                  </MDBDropdown>
                </MDBNavbarItem>
                {/* <MDBNavbarItem className="position-static">
                  <MDBDropdown>
                    <MDBDropdownToggle
                      tag="a"
                      className="nav-link"
                      style={{ cursor: "pointer" }}
                    >
                      Invoice
                    </MDBDropdownToggle>
                    <MDBDropdownMenu
                      className="mt-0 justify-content-center"
                      style={{
                        borderTopLeftRadius: "0",
                        borderTopRightRadius: "0",
                        width: "260px",
                      }}
                    >
                      <MDBContainer>
                        <MDBRow className="my-2">
                          <MDBCol md="6" lg="12" className="mb-3 mb-lg-0">
                            <MDBListGroup>
                              <MDBListGroupItem
                                tag="a"
                                href="/sleinvoice"
                                action
                                noBorders={true}
                              >
                                New Invoice
                              </MDBListGroupItem>
                              <MDBListGroupItem
                                tag="a"
                                href="/slemanageinvoice"
                                action
                                noBorders={true}
                              >
                                Manage Invoice
                              </MDBListGroupItem>
                            </MDBListGroup>
                          </MDBCol>
                        </MDBRow>
                      </MDBContainer>
                    </MDBDropdownMenu>
                  </MDBDropdown>
                </MDBNavbarItem> */}

                {/* Warehouse */}
                <MDBNavbarItem className="position-static">
                  <MDBDropdown>
                    <MDBDropdownToggle
                      tag="a"
                      className="nav-link"
                      style={{ cursor: "pointer" }}
                    >
                      Warehouse
                    </MDBDropdownToggle>
                    <MDBDropdownMenu
                      className="mt-0 justify-content-center"
                      style={{
                        borderTopLeftRadius: "0",
                        borderTopRightRadius: "0",
                        width: "260px",
                      }}
                    >
                      <MDBContainer>
                        <MDBRow className="my-2">
                          <MDBCol md="6" lg="12" className="mb-3 mb-lg-0">
                            <MDBListGroup
                              onMouseLeave={() => {
                                setShowNestedSubMenuGRN(false);
                              }}
                            >
                              <MDBListGroupItem
                                tag="a"
                                href="#"
                                noBorders={true}
                                onMouseEnter={() =>
                                  setShowNestedSubMenuGRN(true)
                                }
                              >
                                Goods Received Note (GRN){" "}
                                <MDBIcon icon="caret-right" className="pe-2" />
                              </MDBListGroupItem>
                              {showNestedSubMenuGRN ? (
                                <MDBContainer>
                                  <MDBRow className="">
                                    <MDBCol
                                      md="6"
                                      lg="12"
                                      className="mb-3 mb-lg-0"
                                    >
                                      <MDBListGroup>
                                        <MDBListGroupItem
                                          tag="a"
                                          href="/newgoodsreceivednote"
                                          action
                                          noBorders={true}
                                        >
                                          &raquo; New GRN
                                        </MDBListGroupItem>
                                        <MDBListGroupItem
                                          tag="a"
                                          href="/managegoodsreceivednote"
                                          action
                                          noBorders={true}
                                        >
                                          &raquo; Search GRN
                                        </MDBListGroupItem>
                                      </MDBListGroup>
                                    </MDBCol>
                                  </MDBRow>
                                </MDBContainer>
                              ) : (
                                ""
                              )}
                            </MDBListGroup>
                          </MDBCol>
                        </MDBRow>
                      </MDBContainer>
                    </MDBDropdownMenu>
                  </MDBDropdown>
                </MDBNavbarItem>

                {/* Finance */}
                <MDBNavbarItem className="position-static">
                  <MDBDropdown>

                    <MDBDropdownToggle
                      tag="a"
                      className="nav-link"
                      style={{ cursor: "pointer" }}
                      >
                      Finance
                    </MDBDropdownToggle>
                    
                    {/* Invoice */}
                    <MDBDropdownMenu
                      className="mt-0 justify-content-center"
                      style={{
                        borderTopLeftRadius: "0",
                        borderTopRightRadius: "0",
                        width: "260px",
                      }}
                    >
                      <MDBContainer>
                        <MDBRow className="my-2">
                          <MDBCol md="6" lg="12" className="mb-3 mb-lg-0">
                            <MDBListGroup
                              onMouseLeave={() => {
                                setShowNestedSubMenuInvoice(false);
                              }}
                            >
                              <MDBListGroupItem
                                tag="a"
                                href="#"
                                noBorders={true}
                                onMouseEnter={() =>
                                  setShowNestedSubMenuInvoice(true)
                                }
                              >
                                Invoice{" "}
                                <MDBIcon icon="caret-right" className="pe-2" />
                              </MDBListGroupItem>
                              {showNestedSubMenuInvoice ? (
                                <MDBContainer>
                                  <MDBRow className="">
                                    <MDBCol
                                      md="6"
                                      lg="12"
                                      className="mb-3 mb-lg-0"
                                    >
                                      <MDBListGroup>
                                      <MDBListGroupItem
                                          tag="a"
                                          href="/pendinginvoice"
                                          action
                                          noBorders={true}
                                        >
                                          &raquo; Pending Invoice
                                        </MDBListGroupItem>
                                        <MDBListGroupItem
                                          tag="a"
                                          href="/newinvoice"
                                          action
                                          noBorders={true}
                                        >
                                          &raquo; New Invoice
                                        </MDBListGroupItem>
                                        <MDBListGroupItem
                                          tag="a"
                                          href="/manageinvoice"
                                          action
                                          noBorders={true}
                                        >
                                          &raquo; Manage Invoice
                                        </MDBListGroupItem>
                                      </MDBListGroup>
                                    </MDBCol>
                                  </MDBRow>
                                </MDBContainer>
                              ) : (
                                ""
                              )}
                            </MDBListGroup>
                          </MDBCol>
                        </MDBRow>
                      </MDBContainer>
                    </MDBDropdownMenu>
                  </MDBDropdown>
                </MDBNavbarItem>

                <MDBNavbarItem className="position-static">
                  <MDBDropdown>
                    <MDBDropdownToggle
                      tag="a"
                      className="nav-link"
                      style={{ cursor: "pointer" }}
                    >
                      User
                    </MDBDropdownToggle>
                    <MDBDropdownMenu
                      className="mt-0 justify-content-center"
                      style={{
                        borderTopLeftRadius: "0",
                        borderTopRightRadius: "0",
                        width: "260px",
                      }}
                    >
                      <MDBContainer>
                        <MDBRow className="my-2">
                          <MDBCol md="6" lg="12" className="mb-3 mb-lg-0">
                            <MDBListGroup>
                              <MDBListGroupItem
                                tag="a"
                                href="/changepassword"
                                action
                                noBorders={true}
                              >
                                Change Password
                              </MDBListGroupItem>
                            </MDBListGroup>
                          </MDBCol>
                        </MDBRow>
                      </MDBContainer>
                    </MDBDropdownMenu>
                  </MDBDropdown>
                </MDBNavbarItem>

                <MDBNavbarItem>
                  <MDBNavbarLink aria-current="page" href="/logout">
                    Logout
                  </MDBNavbarLink>
                </MDBNavbarItem>
              </MDBNavbarNav>
            </MDBCollapse>
          </>
        )}
      </MDBContainer>
    </MDBNavbar>
  );
}

export default NavigationBar;
