import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { clearUserNameAndTokenInLocalStorage, getUserNameFromLocalStorage, getTokenFromLocalStorage } from '../../lib/common';
import BlockUi from '@availity/block-ui';

const config = JSON.parse(process.env.REACT_APP_CONFIG);

function DeleteConfirmationModal(props) {
    const { rowData, onClose, handleShowAlert } = props;
    const [PoNumber] = useState(rowData.PO_NUMBER);
    const [blocking, setBlocking] = useState(false);

    const handleToggleBlocking = () => {
        setBlocking(prevState => !prevState);
    };

    const handledeleteSo = async (event) => {
        event.preventDefault();
        try {
            handleToggleBlocking();
            await deleteSo();
            console.log("Success cancelling ");
            onClose();
            handleShowAlert("ok", "Cancel Purchase Order", "Success deleting Purchase Order");
        } catch (error) {
            handleToggleBlocking();
            console.error("Error while deletting Purchase Order :", error);
            handleShowAlert("error", "Cancel Purchase Order", "Error while deleting Purchase Order: ", error.join("<br>"));
            return;
        }

    };

    const deleteSo = async () => {
        try {
            const response = await fetch(config.po_delete_link, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": "Bearer " + getTokenFromLocalStorage()
                },
                body: JSON.stringify({
                    po_number: PoNumber
                })
            });
            const data = await response.json();
            console.log("submit on hold response : ");
            console.log(data);
            if (
                data.success !== true &&
                data.message === "Access Denied! Invalid token"
            ) {
                clearUserNameAndTokenInLocalStorage();
            }

            if (data.success !== true) {
                var errorMessages = data.error.map((item) => item.msg);
                // handleShowAlert("error", "New Invoice", "Failed Saving New Invoice :", errorMessages.join("<br>"));
                throw errorMessages;
            }
            if (data.success === true) {
                console.log("Success Deletting Purchase Order");
                // handleTrigger(moment().unix());
                return "ok";
            } else {
                throw data;
            }
        } catch (error) {
            console.error("Error while deletting Purchase Order:", error);
            throw error;
        }
    }



    return (
        <Modal show={true} onHide={onClose} size="md" backdrop="static" centered>
            <BlockUi blocking={blocking}>

                <Form onSubmit={handledeleteSo}>
                    <Modal.Header closeButton className="px-4">
                        <Modal.Title className="ms-auto">Delete Purchase Order</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row className="mb-2">
                            <Col md="12">
                                <div className='text-center'>Do you confirm to delete the Purchase Order {PoNumber} ?</div>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button type="submit" variant="primary">
                            Yes
                        </Button>
                        <Button onClick={onClose} variant="secondary">
                            No
                        </Button>
                    </Modal.Footer>
                </Form>
            </BlockUi>
        </Modal>
    );
}

export default DeleteConfirmationModal;