import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import DatePicker from "react-datepicker";
import BlockUi from "@availity/block-ui";
import { useForm, Controller } from "react-hook-form";
import moment from "moment/moment";
import {
  clearUserNameAndTokenInLocalStorage,
  getTokenFromLocalStorage,
} from "../../lib/common";
import ManagePIEditModalTable from "./ManagePI_EditModalTable";
import ManageGRNViewModal from "../ManageGoodsReceivedNote/ManageGRN_ViewModal";
import { useNavigate } from "react-router-dom";

const config = JSON.parse(process.env.REACT_APP_CONFIG);

function ManagePIEditModal(props) {
  const navigate = useNavigate();
  const { rowData, onClose, handleShowAlert, handleCloseAlert } = props;
  const [blocking, setBlocking] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowData, setSelectedRowData] = useState({});
  const [showModal, setShowModal] = useState(false);

  const [ItemList, setItemList] = useState("");
  const [PiNumber] = useState(rowData.PI_NUMBER);
  const [PiDate] = useState(rowData.PI_DATE ? new Date(rowData.PI_DATE) : '');
  const [SupplierInvNumber] = useState(rowData.SUPPLIER_INV_NUMBER);
  const [SupplierInvNumberDate] = useState(rowData.SUPPLIER_INV_DATE ? new Date(rowData.SUPPLIER_INV_DATE) : '');
  const [NewSupplierInvNumber, setNewSupplierInvNumber] = useState("");
  const [NewSupplierDate, setNewSupplierDate] = useState("");
  const [SupplierName] = useState(rowData.SUPPLIER_NAME);

  const {
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
    },
  });

  useEffect(() => {
    getData(config.pi_get_link, rowData);
  }, [rowData]);

  const getData = async (url, params = {}) => {
    await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getTokenFromLocalStorage(),
      },
      body: JSON.stringify({
        pi_number: params.PI_NUMBER,
      }),
    })
      .then((response) => response.json())
      .then((jsonData) => {
        console.log(jsonData);
        setBlocking(false);
        if (
          jsonData.success !== true &&
          jsonData.message === "Access Denied! Invalid token"
        ) {
          clearUserNameAndTokenInLocalStorage();
          const state = { data: "Token expired, please login again" };
          navigate("/login", { state });
          throw new Error(jsonData.message);
        } else if (jsonData.success && jsonData.data.PURCHASE_INVOICE_ITEMS) {
          const newData = jsonData.data.PURCHASE_INVOICE_ITEMS.map((item) => {
            return item;
          });
          return newData;
        } else {
          setItemList([]);
          throw new Error(jsonData.message);
        }
      })
      .then((data) => {
        setItemList(data);
      })
      .catch((error) => console.error(error));
  };

  const handleToggleBlocking = () => {
    setBlocking((prevState) => !prevState);
  };

  const toggleRowSelection = (rowId) => {
    if (selectedRows.includes(rowId)) {
        setSelectedRows(selectedRows.filter((id) => id !== rowId));
    } else {
        setSelectedRows([...selectedRows, rowId]);
    }
};

const submitUpdatePI = async (event) => {

  const supplierInvNumberToUpdate = NewSupplierInvNumber ? NewSupplierInvNumber : SupplierInvNumber;
  const supplierInvDateToUpdate = NewSupplierDate ? moment(NewSupplierDate).format("YYYY-MM-DD") : (SupplierInvNumberDate ? moment(SupplierInvNumberDate).format("YYYY-MM-DD") : null);
  if (selectedRows.length === 0) {
    handleShowAlert(
      "error",
      "Edit PI",
      "Failed Updating PI",
      ["Please select a GRN"]
    );
    return;
  }
  const jsonOb = {
    // supplier_id: SupplierId,
    pi_number: PiNumber,
    new_supplier_inv_number: NewSupplierInvNumber,
    new_supplier_inv_date: NewSupplierDate !== "" ? moment(NewSupplierDate).format("YYYY-MM-DD") : null,
    // supplier_inv_number: supplierInvNumberToUpdate,
    // supplier_inv_date: supplierInvDateToUpdate,
    grn_number: selectedRows,
  };
  console.log(jsonOb);
  try {
    const response = await fetch(config.pi_update_link, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getTokenFromLocalStorage(),
      },
      body: JSON.stringify(jsonOb),
    });
    const data = await response.json();
    console.log(data);
    if (
      data.success !== true &&
      data.message === "Access Denied! Invalid token"
    ) {
      clearUserNameAndTokenInLocalStorage();
      const state = { data: "Token expired, please login again" };
      navigate("/login", { state });
    }
    if (data.success !== true) {
      const errorMessages = data.error.map((item) => item.msg);
      handleShowAlert("error", "Edit Purchase Invoice", "Failed Saving Edit Purchase Invoice :", errorMessages.join("<br>"));
    }
    if (data.success === true) {
      console.log("Success Updating Purchase Invoice");
      handleShowAlert(
        "ok",
        "Edit Purchase Invoice",
        "Success saving Edit Purchase Invoice"
      );
      onClose();
      return "ok";
    } else {
      throw data;
    }
  } catch (error) {
    console.error("Error while saving:", error);
    handleShowAlert(
      "error",
      "Edit Purchase Invoice",
      "Error while saving Edit Purchase Invoice",
      error
    );
    return;
  }
};

  const handleViewClick = (rowData) => {
		console.log(rowData);
		setSelectedRowData(rowData);
		setShowModal("view");
	};

  return (
    <Modal show={true} onHide={onClose} size="xl" backdrop="static" centered>
      <BlockUi blocking={blocking}>
        <Form onSubmit={handleSubmit(submitUpdatePI)}>
          <Modal.Header closeButton className="px-4">
            <Modal.Title className="ms-auto">Edit Purchase Invoice</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="d-flex align-items-center justify-content-center">
              <Col md={10}>
                <Row className="mb-2">
                  <Col md="7">
                    <Form.Group as={Row} controlId="PiNumber">
                      <Form.Label column sm="4" className="fw-bold">
                        PI Number :
                      </Form.Label>
                      <Col sm="6">
                        <Form.Control
                          disabled
                          type="text"
                          placeholder=""
                          value={PiNumber}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col md="5">
                      <Form.Group as={Row} controlId="PiDate">
                      <Form.Label column sm="6" className="fw-bold">
                          PI Date :
                      </Form.Label>
                      <Col sm="6">
                          <DatePicker
                            disabled
                            dateFormat="dd MMM yyyy"
                            className="form-control"
                            showIcon
                            selected={PiDate}
                          />
                      </Col>
                      </Form.Group>
                  </Col>
                </Row>

                <Row className="mb-2">
                  <Col md="7">
                    <Form.Group as={Row} controlId="SupplierInvNumber">
                      <Form.Label column  sm="4" className="fw-bold" >
                        Supplier Inv Number :
                      </Form.Label>
                      <Col sm="6">
                        <Form.Control
                          disabled
                          type="text"
                          placeholder=""
                          value={SupplierInvNumber}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col md="5">
                        <Form.Group as={Row} controlId="SupplierInvNumberDate">
                        <Form.Label column sm="6" className="fw-bold">
                          Supplier Inv Date :
                        </Form.Label>
                        <Col sm="6">
                            <DatePicker
                              disabled
                              dateFormat="dd MMM yyyy"
                              className="form-control"
                              showIcon
                              selected={SupplierInvNumberDate}
                            />
                        </Col>
                        </Form.Group>
                    </Col>
                </Row>

                <Row className="mb-2">
                <Col md="7">
                    <Form.Group as={Row} controlId="SupplierName">
                      <Form.Label column sm="4" className="fw-bold" >
                        Supplier :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          disabled
                          type="text"
                          placeholder=""
                          value={SupplierName}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
            </Row>
            <hr />
            <Row className="d-flex align-items-center justify-content-center">
              <Col md={10}>
                <Row className="mb-2">
                  <Col md="7">
                    <Form.Group as={Row} controlId="NewSupplierInvNumber">
                      <Form.Label column  sm="6" className="fw-bold" >
                        New Supplier Inv Number :
                      </Form.Label>
                      <Col sm="6">
                        <Form.Control
                          type="text"
                          placeholder=""
                          value={NewSupplierInvNumber}
                          onInput={(e) => {
                            setNewSupplierInvNumber(e.target.value);
                          }}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col md="5">
                        <Form.Group as={Row} controlId="NewSupplierDate">
                        <Form.Label column sm="6" className="fw-bold">
                          New Supplier Inv Date :
                        </Form.Label>
                        <Col sm="6">
                            <DatePicker
                              dateFormat="dd MMM yyyy"
                              className="form-control"
                              showIcon
                              selected={NewSupplierDate}
                              onChange={(date) => setNewSupplierDate(date)}
                              minDate={moment().subtract(1, "month").toDate()}
                              maxDate={new Date()}
                            />
                        </Col>
                        </Form.Group>
                    </Col>
                </Row>
              </Col>
            </Row>
            <hr />
            <Row className="mb-4 align-items-center">
								<Col className="text-center">
									<Form.Label>[ GRN ]</Form.Label>
                    <ManagePIEditModalTable
                      ItemList={ItemList}
                      onRowSelect={toggleRowSelection}
                      toggleRowSelection = {toggleRowSelection}
                      handleShowAlert={handleShowAlert}
                      rowData={selectedRowData}
                      selectedRows={selectedRows}
										  setSelectedRows = {setSelectedRows}
                      onViewClick={handleViewClick}
                    />
								</Col>
						</Row>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={onClose} variant="secondary">
              Close
            </Button>
            <Button type="submit" variant="primary">
              Save
            </Button>
          </Modal.Footer>
        </Form>
          {showModal === "view" ? (
						<ManageGRNViewModal
						handleCloseAlert={handleCloseAlert}
						handleShowAlert={handleShowAlert}
						rowData={selectedRowData}
						onClose={() => setShowModal(false)}
						/>
					) : (
						""
					)}
      </BlockUi>
      <style>
          {`
            .form-control:disabled {
              background: #F8F8F8 !important;
            }
          `}
      </style>
    </Modal>
  );
}

export default ManagePIEditModal;
