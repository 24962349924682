import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Pagination from "react-bootstrap/Pagination";
import moment from "moment/moment";
import {
  clearUserNameAndTokenInLocalStorage,
  getTokenFromLocalStorage,
} from "../../lib/common";
import { useNavigate } from "react-router-dom";
const config = JSON.parse(process.env.REACT_APP_CONFIG);

function ManageDoAcknowledgementTable(props) {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [headerData] = useState([
    "DO Ack Number",
    "DO Number",
    "SO Number",
    "Supplier DO Number",
    "Order Type",
    "Customer",
    "Project",
    "Action",
  ]);
  const [currentPage, setCurrentPage] = useState(1);
  const { onCancelClick } = props;
  const { onViewClick } = props;
  const { onEditClick } = props;
  const { searchProps } = props;
  const { showAlert } = props;
  const { handleShowAlert } = props;
  const ROWS_PER_PAGE = 10;
  const totalPages = Math.ceil(data.length / ROWS_PER_PAGE);

  useEffect(() => {
    if (
      searchProps.SleSoNumber ||
      searchProps.SleDoNumber ||
      searchProps.SupplierDoNumber ||
      searchProps.DoAckNumber ||
      searchProps.SleSoDateFrom ||
      searchProps.SleSoDateTo ||
      searchProps.SleDoDateFrom ||
      searchProps.SleDoDateTo ||
      searchProps.SupplierDoDateFrom ||
      searchProps.SupplierDoDateTo ||
      searchProps.CustomerName ||
      searchProps.ProjectName
    ) {
      getData(config.do_ack_list_link, searchProps);
    } else {
      getData(config.do_ack_list_link);
    }
  }, [searchProps]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleToggleBlocking = () => {
    props.onChildToggleChange(false);
  };

  const renderTableData = () => {
    const startIndex = (currentPage - 1) * ROWS_PER_PAGE;
    const endIndex = startIndex + ROWS_PER_PAGE;
    return data.slice(startIndex, endIndex).map((row, index) => {
      const {
        DO_ACK_NUMBER,
        DO_NUMBER,
        SO_NUMBER, 
        SO_TYPE_DESC,
        SUPPLIER_DO_NUMBER,
        CUSTOMER_NAME, 
        PROJECT_NAME,
        CAN_UPDATE
      } = row;
      return (
        <tr key={`${index}-${DO_ACK_NUMBER}`}>
          <td>{DO_ACK_NUMBER}</td>
          <td>{DO_NUMBER}</td>
          <td>{SO_NUMBER}</td>
          <td>{SUPPLIER_DO_NUMBER}</td>
          <td>{SO_TYPE_DESC}</td>
          <td>{CUSTOMER_NAME}</td>
          <td>{PROJECT_NAME}</td>
          <td style={{ width: "250px" }}>
            <Button
              style={{ marginRight: "5px" }}
              size="sm"
              variant="primary"
              onClick={() => onViewClick(row)}
            >
              View
            </Button>
            <Button
              style={{ marginRight: "5px" }}
              size="sm"
              variant="primary"
              onClick={() => onEditClick(row)}
              disabled={CAN_UPDATE === "N" ? true : false}
            >
              Edit
            </Button>
            <Button
              size="sm"
              disabled={CAN_UPDATE === "N" ? true : false}
              variant="danger"
              onClick={() => onCancelClick(row)}
            >
              Delete
            </Button>
          </td>
        </tr>
      );
    });
  };

  const renderPageItems = () => {
    const pageItems = [];
    for (let i = 1; i <= totalPages; i++) {
      pageItems.push(
        <Pagination.Item
          key={i}
          active={i === currentPage}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </Pagination.Item>
      );
    }
    return pageItems;
  };

  const getData = async (url, params = {}) => {
    await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getTokenFromLocalStorage(),
      },
      body: JSON.stringify(
        params.length === 0
          ? params
          : {
            so_number: params.SleSoNumber,
            so_date_from: params.SleSoDateFrom ? moment(params.SleSoDateFrom).format("YYYY-MM-DD") : null,
            so_date_to: params.SleSoDateTo ? moment(params.SleSoDateTo).format("YYYY-MM-DD") : null,
            do_number: params.SleDoNumber,
            do_date_from: params.SleDoDateFrom ? moment(params.SleDoDateFrom).format("YYYY-MM-DD") : null,
            do_date_to: params.SleDoDateTo ? moment(params.SleDoDateTo).format("YYYY-MM-DD") : null,
            supplier_do_number: params.SupplierDoNumber,
            supplier_do_date_from: params.SupplierDoDateFrom ? moment(params.SupplierDoDateFrom).format("YYYY-MM-DD") : null,
            supplier_do_date_to: params.SupplierDoDateTo ? moment(params.SupplierDoDateTo).format("YYYY-MM-DD") : null,
            do_ack_number: params.DoAckNumber,
            customer_id: params.CustomerId,
            project_id: params.ProjectId,
          }
      ),
    })
    .then(response => response.json())
    .then (jsonData => {
        console.log(jsonData)
      handleToggleBlocking();
      if (jsonData.success !== true && jsonData.message === 'Access Denied! Invalid token'){
        clearUserNameAndTokenInLocalStorage();
        const state = { data: "Token expired, please login again" };
        navigate("/login", { state });
        throw new Error (jsonData.message);
      } else if (jsonData.success){
        return jsonData.data;
      } else {
        handleShowAlert(
          "error",
          "Search DO Acknowledgement",
          "Error while searching DO Acknowledgement",
          jsonData.message
        );
        throw jsonData.message;
      }
    })
      .then((data) => setData(data))
      .catch((error) => {
        console.error(error)
        handleShowAlert(
          "error",
          "Search DO Acknowledgement",
          "Error while searching DO Acknowledgement",
          error
        );
      });
  };

  if (data.length < 1) {
    return (
      <div className="text-center">
        <h3>No Data</h3>
      </div>
    );
  } else {
    return (
      <div>
        <Table striped bordered hover className="mb-4">
          <thead>
            <tr>
              {headerData.map((header) => (
                <th key={header}>{header}</th>
              ))}
            </tr>
          </thead>
          <tbody>{renderTableData()}</tbody>
        </Table>
        <Pagination className="d-flex justify-content-center">
          {renderPageItems()}
        </Pagination>
      </div>
    );
  }
}

export default ManageDoAcknowledgementTable;
