import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Pagination from "react-bootstrap/Pagination";
import moment from "moment/moment";
import {
  clearUserNameAndTokenInLocalStorage,
  getTokenFromLocalStorage,
} from "../../lib/common";
import { useNavigate } from "react-router-dom";
const config = JSON.parse(process.env.REACT_APP_CONFIG);

function ManagePITable(props) {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [headerData] = useState([
    "PI Number",
    "PI Date",
    "Supplier Inv Number",
    "Supplier Inv Date",
    "Supplier",
    "Action",
  ]);
  const [currentPage, setCurrentPage] = useState(1);
  const { onOnHoldClick } = props;
  const { onRestockClick } = props;
  const { onCancelClick } = props;
  const { onViewClick } = props;
  const { onEditClick } = props;
  const { searchProps } = props;
  const { showAlert } = props;
  const { handleShowAlert } = props;
  const ROWS_PER_PAGE = 10;
  const totalPages = Math.ceil(data.length / ROWS_PER_PAGE);

  useEffect(() => {
    if (
      searchProps.SlePI ||
      searchProps.SleGRN ||
      searchProps.SupplierInv ||
      searchProps.SlePO ||
      searchProps.PIDateFrom ||
      searchProps.PIDateTo ||
      searchProps.GRNDateFrom ||
      searchProps.GRNDateTo ||
      searchProps.SupplierInvDateFrom ||
      searchProps.SupplierInvDateTo ||
      searchProps.SupplierId
    ) {
      getData(config.pi_list_link, searchProps);
    } else {
      getData(config.pi_list_link);
    }
  }, [searchProps]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleToggleBlocking = () => {
    props.onChildToggleChange(false);
  };

  const renderTableData = () => {
    const startIndex = (currentPage - 1) * ROWS_PER_PAGE;
    const endIndex = startIndex + ROWS_PER_PAGE;
    return data.slice(startIndex, endIndex).map((row) => {
      const {
        GRN_NUMBER,
        PI_NUMBER,
        PI_DATE,
        SUPPLIER_INV_NUMBER,
        SUPPLIER_INV_DATE,
        SUPPLIER_NAME,
        CAN_UPDATE,
      } = row;
      const formattedPIDate = moment(PI_DATE).format("DD MMM yyyy");
      const formattedSupplierInvDate = moment(SUPPLIER_INV_DATE).format("DD MMM yyyy");
      return (
        <tr key={PI_NUMBER}>
          <td>{PI_NUMBER}</td>
          <td>{formattedPIDate}</td>
          <td>{SUPPLIER_INV_NUMBER}</td>
          <td>{formattedSupplierInvDate}</td>
          <td>{SUPPLIER_NAME}</td>
          <td style={{ width: "300px" }}>
            <Button
              style={{ marginRight: "5px" }}
              size="sm"
              variant="primary"
              onClick={() => onViewClick(row)}
            >
              View
            </Button>
            <Button
              style={{ marginRight: "5px" }}
              size="sm"
              variant="primary"
              onClick={() => onEditClick(row)}
              disabled={CAN_UPDATE === "N" ? true : false}
            >
              Edit
            </Button>
            <Button
              size="sm"
              disabled={CAN_UPDATE === "N" ? true : false}
              variant="danger"
              onClick={() => onCancelClick(row)}
            >
              Delete
            </Button>
          </td>
        </tr>
      );
    });
  };

  const renderPageItems = () => {
    const pageItems = [];
    for (let i = 1; i <= totalPages; i++) {
      pageItems.push(
        <Pagination.Item
          key={i}
          active={i === currentPage}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </Pagination.Item>
      );
    }
    return pageItems;
  };

  const getData = async (url, params = {}) => {
    await fetch(url,{
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        "Authorization" : "Bearer " + getTokenFromLocalStorage()
      },
      body : JSON.stringify(params.length === 0 ? params : {
        pi_number: params.SlePI,
        grn_number: params.SleGRN,
        supplier_inv_number: params.SupplierInv,
        po_number: params.SlePO,
        pi_date: params.PIDateFrom ? moment(params.PIDateFrom).format("YYYY-MM-DD") : null,
        grn_date: params.GRNDateFrom ? moment(params.GRNDateFrom).format("YYYY-MM-DD") : null,

        supplier_inv_date: params.SupplierInvDateFrom,

        supplier_id: params.SupplierId,
      })
    })
    .then(response => response.json())
    .then (jsonData => {
        console.log(jsonData)
      handleToggleBlocking();
      if (jsonData.success !== true && jsonData.message === 'Access Denied! Invalid token'){
        clearUserNameAndTokenInLocalStorage();
        const state = { data: "Token expired, please login again" };
        navigate("/login", { state });
        throw new Error (jsonData.message);
      } else if (jsonData.success){
        return jsonData.data;
      } else {
        handleShowAlert(
          "error",
          "Search Purchase Invoice",
          "Error while searching Purchase Invoice",
          jsonData.message
        );
        throw jsonData.message;
      }
    })
    .then(data => setData(data))
    .catch(error => {
      console.error(error)
      handleShowAlert(
        "error",
        "Search Purchase Invoice",
        "Error while searching Purchase Invoice",
        error
      );
    });
  }

  if (data.length < 1) {
    return (
      <div className="text-center">
        <h3>No Data</h3>
      </div>
    );
  } else {
    return (
      <div>
        <Table striped bordered hover className="mb-4">
          <thead>
            <tr>
              {headerData.map((header) => (
                <th key={header}>{header}</th>
              ))}
            </tr>
          </thead>
          <tbody>{renderTableData()}</tbody>
        </Table>
        <Pagination className="d-flex justify-content-center">
          {renderPageItems()}
        </Pagination>
      </div>
    );
  }
}

export default ManagePITable;
