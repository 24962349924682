import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment/moment";
import {
  clearUserNameAndTokenInLocalStorage,
  getTokenFromLocalStorage,
} from "../../lib/common";
import bigDecimal from 'js-big-decimal';
import BlockUi from "@availity/block-ui";
import { v4 as uuidv4 } from "uuid";
import { useForm, Controller } from "react-hook-form";

const config = JSON.parse(process.env.REACT_APP_CONFIG);

function NewSOItemModal(props) {
  const {
    control,
    register,
    handleSubmit,
    watch,
    setValue,
    trigger,
    formState: { errors },
  } = useForm({
    defaultValues: {
      Brand: "",
      ProductId: "",
      OrderQuantity: "0",
      RestockQuantity: "0",
      SellingPrice: "",
      CostPrice: "",
      SupplierId: "",
      TargetDate: "",
    },
  });
  const { onClose, handleAddItemList, itemList, orderType } = props;
  const [BrandList, setBrandList] = useState([]);
  const [Brand, setBrand] = useState("");
  const [ProductList, setProductList] = useState([]);
  const [ProductId, setProductId] = useState("");
  const [ProductName, setProductName] = useState("");
  const [SupplierList, setSupplierList] = useState([]);
  const [SupplierId, setSupplierId] = useState("");
  // const [SupplierId, setSupplierId] = useState("");
  const [OrderQuantity, setOrderQuantity] = useState("0");
  const [isRestockRequired, setIsRestockRequired] = useState(false);
  const [RestockQuantity, setRestockQuantity] = useState(0);
  const [UnitOfMeasurements, setUnitOfMeasurements] = useState("");
  const [UnitPrice, setUnitPrice] = useState("");
  const [MinSellPrice, setMinSellPrice] = useState("");
  const [SellingPrice, setSellingPrice] = useState("");
  const [CostPrice, setCostPrice] = useState("");
  const [MinMargin, setMinMargin] = useState(parseFloat(0));
  // const [objData, setObjData] = useState({})
  const [TargetDate, setTargetDate] = useState("");

  useEffect(() => {
    fetch(config.brand_list_link, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getTokenFromLocalStorage(),
      },
      body: JSON.stringify({}),
    })
      .then((response) => response.json())
      .then((jsonData) => {
        console.log(jsonData);
        if (
          jsonData.success !== true &&
          jsonData.message === "Access Denied! Invalid token"
        ) {
          clearUserNameAndTokenInLocalStorage();
          throw new Error(jsonData.message);
        } else if (jsonData.success) {
          return jsonData.data;
        } else {
          throw new Error(jsonData.message);
        }
      })
      .then((data) => setBrandList(data))
      .catch((error) => console.error(error));
  }, []);

  useEffect(() => {
    if (Brand !== "") {
      fetch(config.product_by_brand_list_link, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + getTokenFromLocalStorage(),
        },
        body: JSON.stringify({
          brand: Brand,
        }),
      })
        .then((response) => response.json())
        .then((jsonData) => {
          console.log(jsonData);
          if (
            jsonData.success !== true &&
            jsonData.message === "Access Denied! Invalid token"
          ) {
            clearUserNameAndTokenInLocalStorage();
            throw new Error(jsonData.message);
          } else if (jsonData.success) {
            return jsonData.data;
          } else {
            throw new Error(jsonData.message);
          }
        })
        .then((data) => {
          setProductList(data);
          setValue("ProductId", "");
        })
        .catch((error) => console.error(error));
    }
  }, [Brand]);

  useEffect(() => {
    if (Brand !== "") {
      fetch(config.consignment_margin_get_link, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + getTokenFromLocalStorage(),
        },
        body: JSON.stringify({
          brand: Brand,
        }),
      })
        .then((response) => response.json())
        .then((jsonData) => {
          console.log(jsonData);
          if (
            jsonData.success !== true &&
            jsonData.message === "Access Denied! Invalid token"
          ) {
            clearUserNameAndTokenInLocalStorage();
            throw new Error(jsonData.message);
          } else if (jsonData.success) {
            return jsonData.data;
          } else {
            throw new Error(jsonData.message);
          }
        })
        .then((data) => {
          setMinMargin(parseFloat(data.MIN_MARGIN).toFixed(2));
          // setValue("ProductId", "");
        })
        .catch((error) => console.error(error));
    }
  }, [Brand]);

  useEffect(() => {
    if (ProductId !== "") {
      fetch(config.supplier_by_product_list_link, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + getTokenFromLocalStorage(),
        },
        body: JSON.stringify({
          product_id: ProductId,
        }),
      })
        .then((response) => response.json())
        //   .then(res => console.log(res))
        .then((jsonData) => {
          if (
            jsonData.success !== true &&
            jsonData.message === "Access Denied! Invalid token"
          ) {
            clearUserNameAndTokenInLocalStorage();
            throw new Error(jsonData.message);
          } else if (jsonData.success) {
            return jsonData.data;
          } else {
            throw new Error(jsonData.message);
          }
        })
        .then((data) => {
          setSupplierList(data);
          // setValue("ProductId", "");
          // trigger("ProductId")
        })
        .catch((error) => console.error(error));
    }
  }, [ProductId]);

  // useEffect(() => {
  //   fetch(config.supplier_list_link, {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: "Bearer " + getTokenFromLocalStorage(),
  //     },
  //     body: JSON.stringify({}),
  //   })
  //     .then((response) => response.json())
  //     .then((jsonData) => {
  //       if (jsonData.message === "Access Denied! Invalid token") {
  //         clearUserNameAndTokenInLocalStorage();
  //         throw new Error(jsonData.message);
  //       }
  //       return jsonData;
  //     })
  //     .then((data) => {
  //       setSupplierList(data.data);
  //       console.log(data);
  //     })
  //     .catch((error) => console.error(error));
  // }, []);

  // useEffect(() => {
  //   fetch(config.product_active_list_link, {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: "Bearer " + getTokenFromLocalStorage(),
  //     },
  //     body: JSON.stringify({
  //       // supplier_name: SupplierId,
  //     }),
  //   })
  //     .then((response) => response.json())
  //     .then((jsonData) => {
  //       console.log(jsonData);
  //       if (
  //         jsonData.success !== true &&
  //         jsonData.message === "Access Denied! Invalid token"
  //       ) {
  //         clearUserNameAndTokenInLocalStorage();
  //         throw new Error(jsonData.message);
  //       } else if (jsonData.success) {
  //         return jsonData.data;
  //       } else {
  //         throw new Error(jsonData.message);
  //       }
  //     })
  //     .then((data) => {
  //       setProductList(data);
  //       setValue("ProductId", "");
  //     })
  //     .catch((error) => console.error(error));
  // }, []);

  const handleCheckboxChange = () => {
    setIsRestockRequired(!isRestockRequired);

    setRestockQuantity(0);
    setValue("RestockQuantity", 0);
    setTargetDate("");
    setValue("TargetDate", "");
    trigger("RestockQuantity");
    trigger("TargetDate");
  };

  const handleAddItem = (event) => {
    // event.preventDefault();
    // const supplierDetails = ProductList.find(product => product.PRODUCT_ID === ProductId);

    var uuid = uuidv4();
    var objData = {
      ITEM_ID: uuid,
      SUPPLIER_ID: SupplierId,
      PRODUCT_ID: ProductId,
      PRODUCT_NAME: ProductName,
      ITEM_QTY: OrderQuantity,
      RESTOCK_REQ: isRestockRequired === true ? "Y" : "N",
      RESTOCK_QTY: RestockQuantity,
      RESTOCK_DEADLINE: isRestockRequired === true ? TargetDate : null,
      UNIT_MEASURE: UnitOfMeasurements !== "" ? UnitOfMeasurements : "",
      UNIT_PRICE: UnitPrice !== "" ? UnitPrice : "",
      BRAND: Brand !== "" ? Brand : "",
      SELLING_PRICE: SellingPrice !== "" ? SellingPrice : "",
      COST_PRICE: CostPrice !== "" ? CostPrice : "",
      MIN_SELL_PRICE: MinSellPrice !== "" ? MinSellPrice : "",
    };
    console.log(objData);
    handleAddItemList(objData);
    onClose();
  };

  return (
    <Modal show={true} onHide={onClose} size="lg" backdrop="static" centered style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}>
      <Form onSubmit={handleSubmit(handleAddItem)}>
        <Modal.Header closeButton className="px-4">
          <Modal.Title className="ms-auto">
          New Sales Order Item{" "}
            {orderType === "C" ? "(Consignment)" : "(Non-consignment)"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="mb-2 justify-content-center">
            <Col md="7">
              <Form.Group as={Row} controlId="Brand">
                <Form.Label column sm="5" className="fw-bold">
                  Brand
                </Form.Label>
                <Col sm="7">
                  <Controller
                    name="Brand"
                    control={control}
                    rules={{ required: "This field is required" }}
                    defaultValue=""
                    className={`form-control ${
                      errors.Brand ? "is-invalid" : ""
                    }`}
                    render={({ field }) => (
                      <Form.Select
                        {...field}
                        onChange={(e) => {
                          // setSupplierId(e.target.value);
                          // setSupplierId(
                          //   e.target.options[e.target.selectedIndex].text
                          // );
                          setValue("Brand", e.target.value);
                          setValue("ProductId", "");
                          setBrand(e.target.value);
                          setUnitOfMeasurements("");
                          setUnitPrice("");
                          trigger("Brand");
                        }}
                        className={`form-select ${
                          errors.Brand ? "is-invalid" : ""
                        }`}
                      >
                        <option key={""} value="">
                          Please select brand name
                        </option>
                        {/* <option key={"HOWA"} value="HOWA">
                          HOWA
                        </option>
                        <option key={"GLINE"} value="GLINE">
                          GLINE
                        </option> */}
                        {BrandList.map((items) => (
                          <option key={items.BRAND} value={items.BRAND}>
                            {items.BRAND}
                          </option>
                        ))}
                      </Form.Select>
                    )}
                  />
                  {errors.Brand && (
                    <Form.Text className="text-danger">
                      {errors.Brand.message}
                    </Form.Text>
                  )}
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-2 justify-content-center">
            <Col md="7">
              <Form.Group as={Row} controlId="ProductId">
                <Form.Label column sm="5" className="fw-bold">
                  Product
                </Form.Label>
                <Col sm="7">
                  <Controller
                    name="ProductId"
                    control={control}
                    rules={{ required: "This field is required" }}
                    render={({ field }) => (
                      <Form.Select
                        {...field}
                        onChange={(e) => {
                          setProductId(e.target.value);
                          setProductName(
                            e.target.options[e.target.selectedIndex].text
                          );
                          setValue("ProductId", e.target.value);
                          setSupplierId("");
                          setValue("SupplierId", "");
                          const supplierDetails = ProductList.find(
                            (product) => product.PRODUCT_ID === e.target.value
                          );
                          setBrand(
                            supplierDetails && supplierDetails.BRAND !== null
                              ? supplierDetails.BRAND
                              : ""
                          );
                          setMinSellPrice(
                            supplierDetails &&
                              supplierDetails.MIN_SELL_PRICE !== null
                              ? supplierDetails.MIN_SELL_PRICE
                              : ""
                          );
                          setUnitOfMeasurements(
                            supplierDetails &&
                              supplierDetails.UNIT_MEASURE !== null
                              ? supplierDetails.UNIT_MEASURE
                              : ""
                          );
                          setUnitPrice(
                            supplierDetails &&
                              supplierDetails.UNIT_PRICE !== null
                              ? supplierDetails.UNIT_PRICE
                              : ""
                          );
                          trigger("ProductId");
                        }}
                        className={`form-select ${
                          errors.ProductId ? "is-invalid" : ""
                        }`}
                      >
                        <option key={""} value="">
                          Please select product name
                        </option>
                        {ProductList.map((items) =>
                          items.PRODUCT_STATUS === "A" ? (
                            <option
                              key={items.PRODUCT_ID}
                              value={items.PRODUCT_ID}
                            >
                              {items.PRODUCT_NAME}
                            </option>
                          ) : (
                            ""
                          )
                        )}
                        {/* {ProductList.map((items) =>
                          items.PRODUCT_STATUS === "A" &&
                          itemList.some(
                            (item) => item.PRODUCT_ID === items.PRODUCT_ID
                          ) === false ? (
                            <option
                              key={items.PRODUCT_ID}
                              value={items.PRODUCT_ID}
                            >
                              {items.PRODUCT_NAME}
                            </option>
                          ) : (
                            ""
                          )
                        )} */}
                      </Form.Select>
                    )}
                  />
                  {errors.ProductId && (
                    <Form.Text className="text-danger">
                      {errors.ProductId.message}
                    </Form.Text>
                  )}
                </Col>
              </Form.Group>
            </Col>
          </Row>
          {orderType === "C" && Brand !== "NOWA" ? (
            <Row className="mb-2 justify-content-center">
              <Col md="7">
                <Form.Group as={Row} controlId="SupplierId">
                  <Form.Label column sm="5" className="fw-bold">
                    Supplier
                  </Form.Label>
                  <Col sm="7">
                    <Controller
                      name="SupplierId"
                      control={control}
                      rules={{ required: "This field is required" }}
                      render={({ field }) => (
                        <Form.Select
                          {...field}
                          onChange={(e) => {
                            setSupplierId(e.target.value);
                            setValue("SupplierId", e.target.value);
                            trigger("SupplierId");
                          }}
                          className={`form-select ${
                            errors.SupplierId ? "is-invalid" : ""
                          }`}
                        >
                          <option key={""} value="">
                            Please select supplier name
                          </option>
                          {SupplierList.map((items) =>
                            itemList.some(
                              (item) =>
                                item.SUPPLIER_ID === items.SUPPLIER_ID &&
                                ProductId === item.PRODUCT_ID
                            ) === false ? (
                              <option
                                key={items.SUPPLIER_ID}
                                value={items.SUPPLIER_ID}
                              >
                                {items.SUPPLIER_NAME}
                              </option>
                            ) : (
                              ""
                            )
                          )}
                        </Form.Select>
                      )}
                    />
                    {errors.SupplierId && (
                      <Form.Text className="text-danger">
                        {errors.SupplierId.message}
                      </Form.Text>
                    )}
                    {/* <Form.Select
                      value={SupplierId}
                      onChange={(e) => setSupplierId(e.target.value)}
                    >
                      <option key={""} value="">
                        Please select supplier name
                      </option>
                      {SupplierList.map((items) => (
                        <option
                          key={items.SUPPLIER_ID}
                          value={items.SUPPLIER_ID}
                        >
                          {items.SUPPLIER_NAME}
                        </option>
                      ))}
                    </Form.Select> */}
                  </Col>
                </Form.Group>
              </Col>
            </Row>
          ) : (
            ""
          )}

          <Row className="mb-2 justify-content-center">
            <Col md="7">
              <Form.Group as={Row} controlId="OrderQuantity">
                <Form.Label column sm="5" className="fw-bold">
                  Order Quantity
                </Form.Label>
                <Col sm="7">
                  <Form.Control
                    type="text"
                    placeholder="quantity"
                    value={OrderQuantity}
                    onInput={(e) => setOrderQuantity(e.target.value)}
                    className={`form-control ${
                      errors.OrderQuantity ? "is-invalid" : ""
                    }`}
                    {...register("OrderQuantity", {
                      required: "This field is required",
                      pattern: {
                        value: /^[1-9]\d*$/,
                        message: "Needs positive integer value",
                      },
                    })}
                  />
                  {errors.OrderQuantity && (
                    <Form.Text className="text-danger">
                      {errors.OrderQuantity.message}
                    </Form.Text>
                  )}
                </Col>
              </Form.Group>
            </Col>
          </Row>
          {/* <Row className="mb-2 justify-content-center">
            <Col md="7">
              <Form.Group as={Row} controlId="Brand">
                <Form.Label column sm="5" className="fw-bold">
                  Brand
                </Form.Label>
                <Col sm="7">
                  <Form.Control readOnly disabled type="text" value={Brand} />
                </Col>
              </Form.Group>
            </Col>
          </Row> */}
          <Row className="mb-2 justify-content-center">
            <Col md="7">
              <Form.Group as={Row} controlId="Brand">
                <Form.Label column sm="5" className="fw-bold">
                  UOM
                </Form.Label>
                <Col sm="7">
                  <Form.Control
                    readOnly
                    disabled
                    type="text"
                    value={UnitOfMeasurements}
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>
          {orderType === "C" ? (
            <Row className="mb-2 justify-content-center">
              <Col md="7">
                <Form.Group as={Row} controlId="Brand">
                  <Form.Label column sm="5" className="fw-bold">
                    Cost Price
                  </Form.Label>
                  <Col sm="7">
                    <Form.Control
                      type="text"
                      placeholder="Cost Price"
                      value={CostPrice}
                      className={`form-control ${
                        errors.CostPrice ? "is-invalid" : ""
                      }`}
                      onInput={(e) => setCostPrice(e.target.value)}
                      {...register("CostPrice", {
                        required: "This field is required",
                        pattern: {
                          value: /^(?:\d+|\d*\.\d{1,2})$/,
                          // value: /^(?!^(\d+|\d*\.\d{0,1})$)\d+(\.\d{1,2})?$/,
                          message: "Needs integer or 2 decimal value",
                        },
                        // validate: (value) => {
                        //   const intValue = parseInt(value, 10);
                        //   return (
                        //     intValue <= OrderQuantity ||
                        //     `Enter a positive integer up to ${OrderQuantity}`
                        //   );
                        // },
                      })}
                    />
                    {errors.CostPrice && (
                      <Form.Text className="text-danger">
                        {errors.CostPrice.message}
                      </Form.Text>
                    )}
                    {/* 
                    <Form.Control
                      
                      onInput={(e) => setCostPrice(e.target.value)}
                      type="text"
                      value={CostPrice}
                    />
                  */}
                  </Col>
                </Form.Group>
              </Col>
            </Row>
          ) : (
            <Row className="mb-2 justify-content-center">
              <Col md="7">
                <Form.Group as={Row} controlId="MinSellPrice">
                  <Form.Label column sm="5" className="fw-bold">
                    Min Selling Price
                  </Form.Label>
                  <Col sm="7">
                    <Form.Control
                      readOnly
                      disabled
                      type="text"
                      value={MinSellPrice}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
          )}
          <Row className="mb-2 justify-content-center">
            <Col md="7">
              <Form.Group as={Row} controlId="SellingPrice">
                <Form.Label column sm="5" className="fw-bold">
                  Selling Price
                </Form.Label>
                <Col sm="7">
                  <Form.Control
                    type="text"
                    placeholder="Selling Price"
                    value={SellingPrice}
                    className={`form-control ${
                      errors.SellingPrice ? "is-invalid" : ""
                    }`}
                    onInput={(e) => setSellingPrice(e.target.value)}
                    {...register("SellingPrice", {
                      required: "This field is required",
                      pattern: {
                        value: /^(?:\d+|\d*\.\d{1,2})$/,
                        // value: /^(?!^(\d+|\d*\.\d{0,1})$)\d+(\.\d{1,2})?$/,
                        message: "Needs integer or 2 decimal value",
                      },
                      validate: (value) => {
                        const floatValue = parseFloat(value);

                        if (orderType === "C") {
                          return (
                            floatValue >=
                              parseFloat(CostPrice) +
                                parseFloat(CostPrice).toFixed(2) * MinMargin ||
                            `Value must have minimum ${MinMargin}% margin`
                          );
                          // return (
                          //   floatValue >=
                          //     parseFloat(CostPrice).toFixed(2) * 1.05 ||
                          //   `Value must have minimum 5% margin`
                          // );
                        } else {
                          return (
                            floatValue >= parseFloat(MinSellPrice).toFixed(2) ||
                            `At least same as Minimum Selling Price`
                          );
                        }
                      },
                    })}
                  />
                  {errors.SellingPrice && (
                    <Form.Text className="text-danger">
                      {errors.SellingPrice.message}
                    </Form.Text>
                  )}
                </Col>
              </Form.Group>
            </Col>
          </Row>
          {/* <Row className="mb-2 justify-content-center">
            <Col md="7">
              <Form.Group as={Row} controlId="Brand">
                <Form.Label column sm="5" className="fw-bold">
                  Unit Price
                </Form.Label>
                <Col sm="7">
                  <Form.Control
                    readOnly
                    disabled
                    type="text"
                    value={UnitPrice}
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row> */}
          <Row className="mb-2 justify-content-center">
            <Col md="7">
              <Form.Group as={Row} controlId="isRestockRequired">
                <Col
                  sm="12"
                  className="d-flex justify-content-center align-items-center"
                >
                  <div key={"isRestockRequired"}>
                    <Form.Check
                      type="checkbox"
                      checked={isRestockRequired}
                      onChange={handleCheckboxChange}
                      id="isRestockRequired"
                      label={"Restock Required?"}
                    />
                    {/* <Form.Label
                      column
                      sm="4"
                      className="fw-bold d-flex align-items-center m-0"
                    >
                      Restock Required?
                    </Form.Label> */}
                  </div>
                </Col>
              </Form.Group>
            </Col>
          </Row>
          {isRestockRequired && (
            <>
              <Row className="mb-2 justify-content-center">
                <Col md="7">
                  <Form.Group as={Row} controlId="RestockQuantity">
                    <Form.Label column sm="5" className="fw-bold">
                      Shortage Quantity
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control
                        type="text"
                        placeholder="quantity"
                        value={RestockQuantity}
                        className={`form-control ${
                          errors.RestockQuantity ? "is-invalid" : ""
                        }`}
                        onInput={(e) => setRestockQuantity(e.target.value)}
                        {...register("RestockQuantity", {
                            required: "This field is required",
                            pattern: {
                              value: /^(?!0+(\.0*)?$)\d+(\.\d{1,4})?$/,
                              message: "Needs integer or 4 decimal value",
                            },
                            validate: (value) => {
                              const intValue = parseFloat(value).toFixed(4);
                              if (bigDecimal.compareTo(value, OrderQuantity) <= 0){
                                console.log(true)
                                return true;
                              } else {
                                console.log(false)
                                return `Enter a value up to ${OrderQuantity}`;
                              }
                            },
                          })}
                      />
                      {errors.RestockQuantity && (
                        <Form.Text className="text-danger">
                          {errors.RestockQuantity.message}
                        </Form.Text>
                      )}
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-2 justify-content-center">
                <Col md="7">
                  <Form.Group as={Row} controlId="TargetDate">
                    <Form.Label column sm="5" className="fw-bold">
                      Target Date
                    </Form.Label>
                    <Col sm="7">
                      <Controller
                        name="TargetDate"
                        control={control}
                        rules={{
                          required: "Target date required",
                          pattern: {
                            value:
                              /^(0[1-9]|1\d|2\d|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/,
                            message: "Invalid date format",
                          },
                        }}
                        render={({ field }) => (
                          <>
                            <DatePicker
                              selected={field.value}
                              onChange={(date) => {
                                field.onChange(date);
                                setTargetDate(date);
                              }}
                              className={`form-control ${
                                errors.TargetDate ? "is-invalid" : ""
                              }`}
                              dateFormat="dd/MM/yyyy"
                            />
                            {errors.TargetDate && (
                              <Form.Text className="text-danger">
                                {errors.TargetDate.message}
                              </Form.Text>
                            )}
                          </>
                        )}
                      />
                      {/* <DatePicker
                        className="form-control"
                        dateFormat="dd/MM/yyyy"
                        showIcon
                        selected={TargetDate}
                        onChange={(date) => setTargetDate(date)}
                      /> */}
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={onClose} variant="secondary">
            Close
          </Button>
          <Button type="submit" variant="primary">
            Save
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default NewSOItemModal;
