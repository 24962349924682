import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";
import {
  clearUserNameAndTokenInLocalStorage,
  getTokenFromLocalStorage,
} from "../lib/common";
import BlockUi from "@availity/block-ui";
import "@availity/block-ui/src/BlockUi.css";
import "@availity/block-ui/src/Loader.css";
import AlertModal from "../page_components/AlertModal";
import { IconContext } from "react-icons";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { useForm, Controller } from "react-hook-form";
import ManagePITable from "../page_components/ManagePurchaseInvoice/ManagePI_Table";
import ManagePIViewModal from "../page_components/ManagePurchaseInvoice/ManagePI_ViewModal";
import ManagePIEditModal from "../page_components/ManagePurchaseInvoice/ManagePI_EditModal";
import ManagePIDeleteModal from "../page_components/ManagePurchaseInvoice/ManagePI_DeleteModal";

const config = JSON.parse(process.env.REACT_APP_CONFIG);

function ManagePurchaseInvoice(){
  const {
		control,
		formState: { errors },
		setValue,
		trigger,
    reset,
	} = useForm({
		defaultValues: {
			ExpectedDeliveryDate: new Date(),
			Currency: "MYR",
			DeliveryTo: "W",
		},
	});

  const [SlePI, setSlePI] = useState("");
  const [SleGRN, setSleGRN] = useState("");
  const [SupplierInv, setSupplierInv] = useState("");
  const [SlePO, setSlePO] = useState("");
  const [PIDateFrom, setPIDateFrom] = useState("");
  const [PIDateTo, setPIDateTo] = useState("");
  const [GRNDateFrom, setGRNDateFrom] = useState("");
  const [GRNDateTo, setGRNDateTo] = useState("");
  const [SupplierInvDateFrom, setSupplierInvDateFrom] = useState("");
  const [SupplierInvDateTo, setSupplierInvDateTo] = useState("");
  const [SupplierList, setSupplierList] = useState([]);
  const [SupplierId, setSupplierId] = useState("");

  const [searchProps, setSearchProps] = useState({});
  const [selectedRowData, setSelectedRowData] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [blocking, setBlocking] = useState(false);
  const [ShowAlert, setShowAlert] = useState(false);
  const [AlertTitle, setAlertTitle] = useState("");
  const [AlertMessage, setAlertMessage] = useState("");
  const [ApiResponse, setApiResponse] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (getTokenFromLocalStorage() === null) {
      const state = { data: "Token expired, please login again" };
      navigate("/login", { state });
    }
  });

  useEffect(() => {
		fetch(config.supplier_active_list_link, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + getTokenFromLocalStorage(),
			},
			body: JSON.stringify({}),
		})
			.then((response) => response.json())
			.then((jsonData) => {
				if (
					jsonData.success !== true &&
					jsonData.message === "Access Denied! Invalid token"
				) {
					clearUserNameAndTokenInLocalStorage();
          const state = { data: "Token expired, please login again" };
          navigate("/login", { state });
					throw new Error(jsonData.message);
				} else if (jsonData.success) {
					return jsonData.data;
				} else {
					throw new Error(jsonData.message);
				}
			})
			.then((data) => {
				setSupplierList(data);
			})
			.catch((error) => console.error(error))
	}, []);
  
    const handleShowAlert = async (status, title, message, apiResponse = "") => {
      setAlertTitle(title);
      if (status === "ok") {
        setAlertMessage(
          <>
            <p className="mb-1">
              <IconContext.Provider
                value={{
                  color: "green",
                  style: { fontSize: "40px", paddingRight: "5px" },
                }}
              >
                <AiOutlineCheckCircle />
                {message}
              </IconContext.Provider>
            </p>
          </>
        );
      } else {
        setAlertMessage(
          <>
            <p className="mb-1">
              <IconContext.Provider
                value={{
                  color: "red",
                  style: { fontSize: "40px", paddingRight: "5px" },
                }}
              >
                <AiOutlineCloseCircle />
                {message}
              </IconContext.Provider>
            </p>
            {apiResponse !== "" ? (
              <p className="text-danger fst-italic ps-3">{apiResponse}</p>
            ) : (
              ""
            )}
          </>
        );
      }
      setShowAlert(true);
    };
  
  
    const handleCloseAlert = () => {
      setShowAlert(false);
    };
  
    const handleToggleBlocking = () => {
      setBlocking((prevState) => !prevState);
    };
  
    const handleToggleFromChild = (newValue) => {
      setBlocking(newValue);
    };
  
    const handleResetForm = () => {
      handleToggleBlocking();
      setSlePI("");
      setSleGRN("");
      setSupplierInv("");
      setSlePO("");
      setPIDateFrom("");
      setPIDateTo("");
      setGRNDateFrom("");
      setGRNDateTo("");
      setSupplierInvDateFrom("");
      setSupplierInvDateTo("");
      setSupplierId("");
      reset();
  
      const searchProperties = {
        SlePI: "",
        SleGRN: "",
        SupplierInv: "",
        SlePO: "",
        PIDateFrom: null,
        PIDateTo: null,
        GRNDateFrom: null,
        GRNDateTo: null,
        SupplierInvDateFrom: null,
        SupplierInvDateTo: null,
        SupplierId: "",

        RestockStatus: false,
      };
      setSearchProps(searchProperties);
    };

    const handleSearch = (event) => {
      event.preventDefault();
      handleToggleBlocking();
      const searchProperties = {
        SlePI: SlePI,
        SleGRN: SleGRN,
        SupplierInv: SupplierInv,
        SlePO: SlePO,
        PIDateFrom: PIDateFrom,
        PIDateTo: PIDateTo,
        GRNDateFrom: GRNDateFrom,
        GRNDateTo: GRNDateTo,
        SupplierInvDateFrom: SupplierInvDateFrom,
        SupplierInvDateTo: SupplierInvDateTo,
        SupplierId: SupplierId,
      };
      setSearchProps(searchProperties);
      console.log(searchProps);
    };
  
    const handleCancelClick = (rowData) => {
      fetch(config.pi_list_link, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + getTokenFromLocalStorage(),
        },
        body: JSON.stringify({
          pi_number: SlePI,
        }),
      })
        .then((response) => response.json())
        .then((jsonData) => {
          if (
            jsonData.success !== true &&
            jsonData.message === "Access Denied! Invalid token"
          ) {
            clearUserNameAndTokenInLocalStorage();
            const state = { data: "Token expired, please login again" };
            navigate("/login", { state });
            throw new Error(jsonData.message);
          } else if (jsonData.success) {
            setSelectedRowData(rowData);
            setShowModal("cancel");
          } else {
            throw new Error(jsonData.message);
          }
        })
        .catch((error) => console.error(error));
    };
  
    const handleViewClick = (rowData) => {
      fetch(config.pi_list_link, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + getTokenFromLocalStorage(),
        },
        body: JSON.stringify({
          pi_number: SlePI,
        }),
      })
        .then((response) => response.json())
        .then((jsonData) => {
          if (
            jsonData.success !== true &&
            jsonData.message === "Access Denied! Invalid token"
          ) {
            clearUserNameAndTokenInLocalStorage();
            const state = { data: "Token expired, please login again" };
            navigate("/login", { state });
            throw new Error(jsonData.message);
          } else if (jsonData.success) {
            setSelectedRowData(rowData);
            setShowModal("view");
          } else {
            throw new Error(jsonData.message);
          }
        })
        .catch((error) => console.error(error));
    };
  
    const handleEditClick = (rowData) => {
      fetch(config.pi_list_link, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + getTokenFromLocalStorage(),
        },
        body: JSON.stringify({
          pi_number: SlePI,
        }),
      })
        .then((response) => response.json())
        .then((jsonData) => {
          if (
            jsonData.success !== true &&
            jsonData.message === "Access Denied! Invalid token"
          ) {
            clearUserNameAndTokenInLocalStorage();
            const state = { data: "Token expired, please login again" };
            navigate("/login", { state });
            throw new Error(jsonData.message);
          } else if (jsonData.success) {
            setSelectedRowData(rowData);
            setShowModal("edit");
          } else {
            throw new Error(jsonData.message);
          }
        })
        .catch((error) => console.error(error));
    };
  
    return (
      <div>
        <Container className="mt-4">
          <BlockUi blocking={blocking}>
            <Row className="justify-content-center mb-4">
              <Col md={7}>
                <Form onSubmit={handleSearch}>
                  <Row className="mb-4">
                    <Col className="text-center">
                      <h2>Search Purchase Invoice</h2>
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col>
                      <Form.Group as={Row} className="mb-3" controlId="SlePI" >
                        <Form.Label column sm="6">
                          SLE PI Number
                        </Form.Label>
                        <Col sm="6">
                          <Form.Control
                            type="text"
                            placeholder="SLE PI"
                            value={SlePI}
                            onInput={(e) => setSlePI(e.target.value)}
                          />
                        </Col>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group as={Row} className="mb-3" controlId="SleGRN">
                        <Form.Label column sm="6" className="text-nowrap">
                          SLE GRN Number
                        </Form.Label>
                        <Col sm="6">
                          <Form.Control
                            type="text"
                            placeholder="SLE GRN"
                            value={SleGRN}
                            onInput={(e) => setSleGRN(e.target.value)}
                          />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col>
                      <Form.Group as={Row} className="mb-3" controlId="SupplierInv" >
                        <Form.Label column sm="6" className='text-nowrap'>
                          Supplier Inv Number
                        </Form.Label>
                        <Col sm="6">
                          <Form.Control
                            type="text"
                            placeholder="Supplier Inv"
                            value={SupplierInv}
                            onInput={(e) => setSupplierInv(e.target.value)}
                          />
                        </Col>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group as={Row} className="mb-3" controlId="SlePO">
                        <Form.Label column sm="6" className='text-nowrap'>
                          SLE PO Number
                        </Form.Label>
                        <Col sm="6">
                          <Form.Control
                            type="text"
                            placeholder="SLE PO"
                            value={SlePO}
                            onInput={(e) => setSlePO(e.target.value)}
                          />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col sm="6">
                      <Form.Group as={Row} className="mb-3" controlId="PIDateFrom" >
                        <Form.Label column sm="6">
                          PI Date From
                        </Form.Label>
                        <Col sm="6" >
                          <DatePicker
                            className="form-control"
                            dateFormat="dd MMM yyyy"
                            showIcon
                            selected={PIDateFrom}
                            onChange={(date) => setPIDateFrom(date)}
                          />
                        </Col>
                      </Form.Group>
                    </Col>
                    <Col sm="5">
                      <Form.Group as={Row} className="mb-3" controlId="PIDateTo">
                        <Form.Label column sm="3" className="text-center">
                          to
                        </Form.Label>
                        <Col sm="7" style={{ paddingRight: "15px" }}>
                          <DatePicker
                            className="form-control"
                            dateFormat="dd MMM yyyy"
                            showIcon
                            selected={PIDateTo}
                            onChange={(date) => setPIDateTo(date)}
                          />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col sm="6">
                      <Form.Group as={Row} className="mb-3" controlId="GRNDateFrom" >
                        <Form.Label column sm="6">
                          GRN Date From
                        </Form.Label>
                        <Col sm="6" style={{ paddingLeft: "12px" }}>
                          <DatePicker
                            className="form-control"
                            dateFormat="dd MMM yyyy"
                            showIcon
                            selected={GRNDateFrom}
                            onChange={(date) => setGRNDateFrom(date)}
                          />
                        </Col>
                      </Form.Group>
                    </Col>
                    <Col sm="5">
                      <Form.Group as={Row} className="mb-3" controlId="GRNDateTo" >
                        <Form.Label column className="text-center" sm="3">
                          to
                        </Form.Label>
                        <Col sm="7" style={{ paddingRight: "15px" }}>
                          <DatePicker
                            className="form-control"
                            dateFormat="dd MMM yyyy"
                            showIcon
                            selected={GRNDateTo}
                            onChange={(date) => setGRNDateTo(date)}
                          />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col sm="6">
                      <Form.Group as={Row} className="mb-3" controlId="SupplierInvDateFrom" >
                        <Form.Label column sm="6">
                          Supplier Inv Date From
                        </Form.Label>
                        <Col sm="6" style={{ paddingLeft: "12px" }}>
                          <DatePicker
                            className="form-control"
                            dateFormat="dd MMM yyyy"
                            showIcon
                            selected={SupplierInvDateFrom}
                            onChange={(date) => setSupplierInvDateFrom(date)}
                          />
                        </Col>
                      </Form.Group>
                    </Col>
                    <Col sm="5">
                      <Form.Group as={Row} className="mb-3" controlId="SupplierInvDateTo">
                        <Form.Label column sm="3" className="text-center">
                          to
                        </Form.Label>
                        <Col sm="7" style={{ paddingRight: "15px" }}>
                          <DatePicker
                            className="form-control"
                            dateFormat="dd MMM yyyy"
                            showIcon
                            selected={SupplierInvDateTo}
                            onChange={(date) => setSupplierInvDateTo(date)}
                          />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="mb-2">
										<Col md="12">
											<Form.Group as={Row} controlId="SupplierId">
												<Form.Label column sm="3"  className="mb-3">
													Supplier Name 
												</Form.Label>
												<Col sm="7" style={{ paddingLeft: "12px" }}>
													<Controller name="SupplierId"
														control={control}
														rules={{
															required:
																"This field is required",
														}}
														defaultValue=""
														className={`form-control ${
															errors.SupplierId
																? "is-invalid"
																: ""
														}`}
														render={({ field }) => (
															<Form.Select {...field}
																onChange={(e) => {
																	setValue("SupplierId",e.target.value);
																	setSupplierId(e.target.value);
																	trigger("SupplierId");
																}}
																className={`form-select ${
																	errors.SupplierId
																		? "is-invalid"
																		: ""
																}`}
															>
																<option key={""} value="" disabled>
																	Please select supplier name
																</option>
																{SupplierList.map(
																	(items) => (
																		<option
																			key={
																				items.SUPPLIER_ID
																			}
																			value={
																				items.SUPPLIER_ID
																			}
																		>
																			{
																				items.SUPPLIER_NAME
																			}
																		</option>
																	)
																)}
															</Form.Select>
														)}
													/>
													{errors.SupplierId && (
														<Form.Text className="text-danger">
															{
																errors
																	.SupplierId
																	.message
															}
														</Form.Text>
													)}
												</Col>
											</Form.Group>
										</Col>
									</Row>
                  <Row>
                    <Col className="text-center">
                      <Button
                        className="me-2"
                        variant="secondary"
                        onClick={handleResetForm}
                      >
                        Reset
                      </Button>
                      <Button type="submit" variant="primary">
                        Search
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
            <hr />
            <br />
            <ManagePITable
              showAlert={ShowAlert}
              searchProps={searchProps}
              onChildToggleChange={handleToggleFromChild}
              onCancelClick={handleCancelClick}
              onViewClick={handleViewClick}
              onEditClick={handleEditClick}
              handleShowAlert={handleShowAlert}
            />
            {showModal === "view" ? (
              <ManagePIViewModal
                // handleCloseAlert={handleCloseAlert}
                handleShowAlert={handleShowAlert}
                rowData={selectedRowData}
                onClose={() => setShowModal(false)}
              />
            ) : (
              ""
            )}
            {showModal === "edit" ? (
              <ManagePIEditModal
                // handleCloseAlert={handleCloseAlert}
                handleShowAlert={handleShowAlert}
                rowData={selectedRowData}
                onClose={() => setShowModal(false)}
              />
            ) : (
              ""
            )}
            {showModal === "cancel" ? (
            <ManagePIDeleteModal
              handleCloseAlert={handleCloseAlert}
              handleShowAlert={handleShowAlert}
              rowData={selectedRowData}
              onClose={() => setShowModal(false)}
            />
          ) : (
            ""
          )}
          </BlockUi>
          {ShowAlert && (
            <AlertModal
              title={AlertTitle}
              message={AlertMessage}
              apiResponse={ApiResponse}
              onClose={handleCloseAlert}
            />
          )}
        </Container>
      </div>
    );
}

export default ManagePurchaseInvoice;