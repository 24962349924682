import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Table from '../page_components/SLEInvoice/SLEInvoice_Table';
import InvoiceModal from '../page_components/SLEInvoice/SLEInvoice_InvoiceModal';
import { useNavigate } from 'react-router-dom';
import {
  clearUserNameAndTokenInLocalStorage,
  getTokenFromLocalStorage,
} from "../lib/common";
import BlockUi from '@availity/block-ui';
import "@availity/block-ui/src/BlockUi.css";
import "@availity/block-ui/src/Loader.css";
import AlertModal from '../page_components/AlertModal';
import { IconContext } from "react-icons";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { AiOutlineCloseCircle } from "react-icons/ai";

const config = JSON.parse(process.env.REACT_APP_CONFIG);


function SleInvoice() {
  const [SleSoDateFrom, setSleSoDateFrom] = useState("");
  const [SleSoDateTo, setSleSoDateTo] = useState("");
  // const [SlePoDateFrom, setSlePoDateFrom] = useState("");
  // const [SlePoDateTo, setSlePoDateTo] = useState("");
  const [SleDoFrom, setSleDoFrom] = useState("");
  const [SleDoTo, setSleDoTo] = useState("");
  const [SleSoNum, setSleSoNum] = useState("");
  // const [SlePoNum, setSlePoNum] = useState("");
  const [SleDoNum, setSleDoNum] = useState("");
  const [CustomerName, setCustomerName] = useState("");
  const [CustomerList, setCustomerList] = useState([]);
  const [ProjectName, setProjectName] = useState("");
  const [ProjectList, setProjectList] = useState([]);
  const [searchProps, setSearchProps] = useState({});
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [blocking, setBlocking] = useState(true);
  const [ShowAlert, setShowAlert] = useState(false);
  const [AlertTitle, setAlertTitle] = useState("");
  const [AlertMessage, setAlertMessage] = useState("");
  const [ApiResponse, setApiResponse] = useState("");
  const [Trigger, setTrigger] = useState("");
  const navigate = useNavigate();


  useEffect(() => {
    if (getTokenFromLocalStorage() === null) {
      const state = { data: 'Token expired, please login again' };
      navigate("/login", { state });
    }
  });

  useEffect(() => {
    fetch(config.customer_list_link, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getTokenFromLocalStorage(),
      },
      body: JSON.stringify({}),
    })
      .then((response) => response.json())
      .then((jsonData) => {
        console.log(jsonData);
        if (
          jsonData.success !== true &&
          jsonData.message === "Access Denied! Invalid token"
        ) {
          clearUserNameAndTokenInLocalStorage();
          throw new Error(jsonData.message);
        } else if (jsonData.success) {
          return jsonData.data;
        } else {
          throw new Error(jsonData.message);
        }
      })
      .then((data) => setCustomerList(data))
      .catch((error) => console.error(error));
  }, []);

  useEffect(() => {
    if (CustomerName !== "") {
      fetch(config.project_list_link, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + getTokenFromLocalStorage(),
        },
        body: JSON.stringify({
          customer_id: CustomerName,
        }),
      })
        .then((response) => response.json())
        .then((jsonData) => {
          console.log(jsonData);
          if (
            jsonData.success !== true &&
            jsonData.message === "Access Denied! Invalid token"
          ) {
            clearUserNameAndTokenInLocalStorage();
            throw new Error(jsonData.message);
          } else if (jsonData.success) {
            return jsonData.data;
          } else {
            throw new Error(jsonData.message);
          }
        })
        .then((data) => setProjectList(data))
        .catch((error) => console.error(error));
    }
  }, [CustomerName]);

  const handleShowAlert = async (status, title, message, apiResponse = "") => {
    setAlertTitle(title);
    if (status === "ok") {
      setAlertMessage(
        <>
          <p className='mb-1'>
            <IconContext.Provider value={{ color: "green", style: { fontSize: "40px", paddingRight: "5px" } }}>
              <AiOutlineCheckCircle />
              {message}
            </IconContext.Provider>
          </p>
        </>
      );
    } else {
      setAlertMessage(
        <>
          <p className='mb-1'>
            <IconContext.Provider value={{ color: "red", style: { fontSize: "40px", paddingRight: "5px" } }}>
              <AiOutlineCloseCircle />
              {message}
            </IconContext.Provider>
          </p>
          {apiResponse !== "" ? <p className='text-danger fst-italic ps-3'>{apiResponse}</p>
            : ""}
        </>
      );
    }
    setShowAlert(true);
  };

  const handleCloseAlert = () => {
    setShowAlert(false);
  };

  const handleToggleBlocking = () => {
    setBlocking(prevState => !prevState);
  };

  const handleToggleFromChild = (newValue) => {
    setBlocking(newValue);
  };

  const handleTrigger = (value) => {
    setTrigger(value);
  }

  const handleResetForm = () => {
    handleToggleBlocking();
    setSleSoDateFrom("");
    setSleSoDateTo("");
    // setSlePoDateFrom("");
    // setSlePoDateTo("");
    setSleDoFrom("");
    setSleDoTo("");
    setSleSoNum("");
    // setSlePoNum("");
    setSleDoNum("");
    setCustomerName("");
    setProjectName("");
    setProjectList([]);

    const searchProperties = {
      // SlePoDateFrom: "",
      // SlePoDateTo: "",
      // SlePoNum: "",
      SleSoDateFrom: "",
      SleSoDateTo: "",
      SleSoNum: "",
      SleDoDateFrom: "",
      SleDoDateTo: "",
      SleDoNum: "",
      CustomerName:"",
      ProjectName : ""
    };
    setSearchProps(searchProperties);
  }

  const handleSearch = (event) => {
    event.preventDefault();
    const searchProperties = {
      // SlePoDateFrom: SlePoDateFrom,
      // SlePoDateTo: SlePoDateTo,
      // SlePoNum: SlePoNum,
      SleSoDateFrom: SleSoDateFrom,
      SleSoDateTo: SleSoDateTo,
      SleSoNum: SleSoNum,
      SleDoDateFrom: SleDoFrom,
      SleDoDateTo: SleDoTo,
      SleDoNum: SleDoNum,
      CustomerName: CustomerName,
      ProjectName : ProjectName
    };
    setSearchProps(searchProperties);
  }

  const handleInvoiceClick = (rowData) => {
    setSelectedRowData(rowData);
    setShowModal("invoice");
  };

  return (
    <div>
      <Container className="mt-4">
        <BlockUi blocking={blocking}>
          <Row className="justify-content-center mb-4">

            <Form onSubmit={handleSearch}>
              <Row className="mb-4">
                <Col className="text-center">
                  <h2>Pending Invoice</h2>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Row className="mb-2">
                    <Col>
                      <Form.Group as={Row} className="mb-3" controlId="SLESONumber" style={{ marginLeft: "40px" }}>
                        <Form.Label column sm="4">
                          SLE SO Number
                        </Form.Label>
                        <Col sm="7">
                          <Form.Control type="text" placeholder="SLE SO Number" value={SleSoNum} onInput={(e) => setSleSoNum(e.target.value)} />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  {/* <Row className="mb-2">
                    <Col>
                      <Form.Group as={Row} className="mb-3" controlId="SLEPONumber" style={{ marginLeft: "40px" }}>
                        <Form.Label column sm="4">
                          SLE PO Number
                        </Form.Label>
                        <Col sm="7">
                          <Form.Control type="text" placeholder="SLE PO Number" value={SlePoNum} onInput={(e) => setSlePoNum(e.target.value)} />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row> */}
                  <Row className="mb-2">
                    <Col>
                      <Form.Group as={Row} className="mb-3" controlId="SLEDONumber" style={{ marginLeft: "40px" }}>
                        <Form.Label column sm="4">
                          SLE DO Number
                        </Form.Label>
                        <Col sm="7">
                          <Form.Control type="text" placeholder="SLE DO Number" value={SleDoNum} onInput={(e) => setSleDoNum(e.target.value)} />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>
                <Col md={6}>
                  <Row className="mb-2">
                    <Col sm="7">
                      <Form.Group as={Row} className="mb-3" controlId="SLESODateFrom">
                        <Form.Label column sm="7">
                          SLE SO Date From
                        </Form.Label>
                        <Col sm="5" style={{ paddingLeft: "8px" }}>
                          <DatePicker dateFormat="dd/MM/yyyy" className="form-control" showIcon selected={SleSoDateFrom} onChange={(date) => setSleSoDateFrom(date)} />
                        </Col>
                      </Form.Group>
                    </Col>
                    <Col sm="5">
                      <Form.Group as={Row} className="mb-3 text-center" controlId="SLESODateTo">
                        <Form.Label column sm="2">
                          to
                        </Form.Label>
                        <Col sm="7" style={{ paddingRight: "0px" }}>
                          <DatePicker dateFormat="dd/MM/yyyy" className="form-control" showIcon selected={SleSoDateTo} onChange={(date) => setSleSoDateTo(date)} />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  {/* <Row className="mb-2">
                    <Col sm="7">
                      <Form.Group as={Row} className="mb-3" controlId="SLEPODateFrom">
                        <Form.Label column sm="7">
                          SLE PO Date From
                        </Form.Label>
                        <Col sm="5" style={{ paddingLeft: "8px" }}>
                          <DatePicker dateFormat="dd/MM/yyyy" className="form-control" showIcon selected={SlePoDateFrom} onChange={(date) => setSlePoDateFrom(date)} />
                        </Col>
                      </Form.Group>
                    </Col>
                    <Col sm="5">
                      <Form.Group as={Row} className="mb-3 text-center" controlId="SLEPODateTo">
                        <Form.Label column sm="2">
                          to
                        </Form.Label>
                        <Col sm="7" style={{ paddingRight: "0px" }}>
                          <DatePicker dateFormat="dd/MM/yyyy" className="form-control" showIcon selected={SlePoDateTo} onChange={(date) => setSlePoDateTo(date)} />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row> */}
                  <Row className="mb-2">
                    <Col sm="7">
                      <Form.Group as={Row} className="mb-3" controlId="SLEPODateFrom">
                        <Form.Label column sm="7">
                          SLE DO Date From
                        </Form.Label>
                        <Col sm="5" style={{ paddingLeft: "8px" }}>
                          <DatePicker dateFormat="dd/MM/yyyy" className="form-control" showIcon selected={SleDoFrom} onChange={(date) => setSleDoFrom(date)} />
                        </Col>
                      </Form.Group>
                    </Col>
                    <Col sm="5">
                      <Form.Group as={Row} className="mb-3 text-center" controlId="SLEPODateTo">
                        <Form.Label column sm="2">
                          to
                        </Form.Label>
                        <Col sm="7" style={{ paddingRight: "0px" }}>
                          <DatePicker dateFormat="dd/MM/yyyy" className="form-control" showIcon selected={SleDoTo} onChange={(date) => setSleDoTo(date)} />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>
                <Row className='mb-2'>
                  <Col sm="8">
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="CustomerName"
                      style={{ marginLeft: "40px" }}
                    >
                      <Form.Label column sm="3">
                        Customer Name
                      </Form.Label>
                      <Col sm="8" style={{ paddingRight: "3px" }}>
                        <Form.Select
                          value={CustomerName}
                          onChange={(e) => setCustomerName(e.target.value)}
                        >
                          <option key={""} value="">
                            Please select customer name
                          </option>
                          {CustomerList.map((items) => (
                            <option
                              key={items.CUSTOMER_ID}
                              value={items.CUSTOMER_ID}
                            >
                              {items.CUSTOMER_NAME}
                            </option>
                          ))}
                        </Form.Select>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col sm="8">
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="ProjectName"
                      style={{ marginLeft: "40px" }}
                    >
                      <Form.Label column sm="3">
                        Project Name
                      </Form.Label>
                      <Col sm="8" style={{ paddingRight: "3px" }}>
                        <Form.Select
                          value={ProjectName}
                          onChange={(e) => setProjectName(e.target.value)}
                        >
                          <option key={""} value="">
                            Please select project name
                          </option>
                          {ProjectList.map((items) => (
                            <option
                              key={items.PROJECT_ID}
                              value={items.PROJECT_ID}
                            >
                              {items.PROJECT_NAME}
                            </option>
                          ))}
                        </Form.Select>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Row>

              <Row>
                <Col className="text-center">
                  <Button className='me-2' variant="secondary" onClick={handleResetForm}>Reset</Button>{' '}
                  <Button type="submit" variant="primary" onClick={handleToggleBlocking}>Search</Button>
                </Col>
              </Row>
            </Form>
          </Row>
          <hr />
          <br />
          <Table handleShowAlert={handleShowAlert} showAlert={ShowAlert} searchProps={searchProps} onChildToggleChange={handleToggleFromChild} onInvoiceClick={handleInvoiceClick} />
          {showModal === "invoice" ? <InvoiceModal handleShowAlert={handleShowAlert} rowData={selectedRowData} onClose={() => setShowModal(false)} /> : ""}
        </BlockUi>
        {ShowAlert && (
          <AlertModal
            title={AlertTitle}
            message={AlertMessage}
            apiResponse={ApiResponse}
            onClose={handleCloseAlert}
          />
        )}
      </Container>
    </div>
  );
}

export default SleInvoice;
