import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Accordion from "react-bootstrap/Accordion";
import DatePicker from "react-datepicker";
import moment from "moment/moment";
import NewDeliveryOrderModalAccordion from "./NewDeliveryOrder_ModalAccordion";
import NewDeliveryOrderCreateConfirmationModal from "./NewDeliveryOrder_CreateConfirmationModal";
import ManageBulkOrderMemoHistoryEditModal from "./NewDeliveryOrder_EditModal";

import { useNavigate } from "react-router-dom";
import {
  clearUserNameAndTokenInLocalStorage,
  getUserNameFromLocalStorage,
  getTokenFromLocalStorage,
} from "../../lib/common";
import BlockUi from "@availity/block-ui";

const config = JSON.parse(process.env.REACT_APP_CONFIG);

function NewDeliveryOrderNewModal(props) {
  const { rowData, onClose, handleShowAlert, handleCloseAlert } = props;
  const [SleSoNum] = useState(rowData.SO_NUMBER);
  const [SlePoNum, setSlePoNum] = useState(rowData.PO_NUMBER);
  const [SleMemoNum, setSleMemoNum] = useState("");
  const [SleDoNum, setSleDoNum] = useState("");
  const [CustomerId] = useState(rowData.CUSTOMER_ID);
  const [CustomerName, setCustomerName] = useState(rowData.CUSTOMER_NAME);
  const [ProjectId] = useState(rowData.PROJECT_ID);
  const [ProjectName, setProjectName] = useState(rowData.PROJECT_NAME);
  const [PaymentTerm] = useState(rowData.PAYMENT_TERM);
  const [SleSoDate] = useState(new Date(rowData.SO_DATE));
  const [SlePoDate, setSlePoDate] = useState(
    rowData.PO_DATE === undefined ? "" : new Date(rowData.PO_DATE)
  );
  const [SleMemoDate, setSleMemoDate] = useState(new Date());
  const [PoDate, setPoDate] = useState(new Date());
  const [isIncludeSleDo, setIsIncludeSleDo] = useState(false);
  const [OrderType] = useState(rowData.SO_TYPE_DESC);
  const [ScheduleDate, setScheduleDate] = useState(new Date());
  const [Remarks, setRemarks] = useState("");
  const [Trigger, setTrigger] = useState("");
  const [blocking, setBlocking] = useState(false);
  const [textInputs, setTextInputs] = useState({});
  const [selectedRows, setSelectedRows] = useState([]);
  const [Data, setData] = useState([]);
  const [ProcessedData, setProcessedData] = useState([]);
  const [showSecondModal, setShowSecondModal] = useState(false);
  const [showCreateConfirmationModal, setShowCreateConfirmationModal] = useState(false);
  const [showNewNonBulkOrder, setShowNewNonBulkOrder] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState({});
  const [selectedMemoNum, setSelectedMemoNum] = useState("");
  const [RefreshHistoryTrigger, setRefreshHistoryTrigger] = useState("");
  const [refreshTrigger, setRefreshTrigger] = useState("");
  const [SelectedTable, setSelectedTable] = useState([]);
  console.log(showSecondModal);
  const navigate = useNavigate();

  const handleIncludeSleDoCheckbox = (event) => {
    setIsIncludeSleDo(event.target.checked);
  };
  const handleToggleBlocking = () => {
    setBlocking((prevState) => !prevState);
  };

  const handleToggleFromChild = (newValue) => {
    setBlocking(newValue);
  };

  const handleToggleClick = (rowData) => {
    setSelectedRowData(rowData);
    setShowSecondModal("toggle");
  };

  const handleDeleteClick = () => {
    setShowCreateConfirmationModal(true);
  };

  useEffect(() => {
    // if (rowData.SO_TYPE === "B") {
    //   getData(config.do_pending_bulk_list_link, rowData);
    // } else {
      getData(config.do_pending_details_link, rowData);
    // }
  }, [rowData, RefreshHistoryTrigger]);

  useEffect(() => {
    // Create an object to store grouped data
    const groupedData = Data.reduce((result, item) => {
      const memoNumber = item.MEMO_NUMBER;

      // If the memoNumber is not in the result, create a new array for it
      if (!result[memoNumber]) {
        result[memoNumber] = [];
      }

      // Push the item into the array under the memoNumber key
      result[memoNumber].push(item);

      return result;
    }, {});

    // Convert the grouped data into an array
    const groupedArray = Object.values(groupedData);
    setProcessedData(groupedArray);
  }, [Data]);

  const getData = async (url, params = {}) => {
    handleToggleBlocking();
    await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getTokenFromLocalStorage(),
      },
      body: JSON.stringify({
        so_number: params.SO_NUMBER,
      }),
    })
      .then((response) => response.json())
      .then((jsonData) => {
        console.log(jsonData);
        handleToggleBlocking();
        if (jsonData.message === "Access Denied! Invalid token") {
          clearUserNameAndTokenInLocalStorage();
          throw new Error(jsonData.message);
        }
        const newData = jsonData.data.map((item) => {
          const date = moment(item.SO_DATE).format("DD MMM yyyy");
          item.SO_DATE = date;
          return item;
        });
        return newData;
      })
      .then((data) => setData(data))
      .catch((error) => console.error(error));
  };

  return (
    <Modal show={true} onHide={onClose} size="lg" backdrop="static" centered>
      <BlockUi blocking={blocking}>
        <Form>
          <Modal.Header closeButton className="px-4">
            <Modal.Title className="ms-auto">
              {rowData.SO_TYPE === "B"
                ? "New Delivery Order (Bulk Order)"
                : "New Delivery Order (Non Bulk Order)"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="mb-2 justify-content-center">
              <Col md="6">
                <Form.Group as={Row} controlId="SLESONumber">
                  <Form.Label column sm="6" className="fw-bold">
                    SLE SO Number :
                  </Form.Label>
                  <Col sm="6">
                    <Form.Control
                      readOnly
                      className="no-border"
                      type="text"
                      placeholder=""
                      value={SleSoNum}
                    />
                  </Col>
                </Form.Group>
              </Col>

              <Col md="6">
                <Form.Group as={Row} controlId="SLESODate">
                  <Form.Label column sm="6" className="fw-bold">
                    SLE SO Date :
                  </Form.Label>
                  <Col sm="6">
                    <DatePicker
                      readOnly
                      dateFormat="dd/MM/yyyy"
                      className="form-control no-border"
                      showIcon
                      selected={SleSoDate}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-2 justify-content-center">
              <Col md="6">
                <Form.Group as={Row} controlId="SLESONumber">
                  <Form.Label column sm="6" className="fw-bold">
                    SLE PO Number :
                  </Form.Label>
                  <Col sm="6">
                    <Form.Control
                      readOnly
                      className="no-border"
                      type="text"
                      placeholder=""
                      value={SlePoNum}
                    />
                  </Col>
                </Form.Group>
              </Col>

              <Col md="6">
                <Form.Group as={Row} controlId="SLESODate">
                  <Form.Label column sm="6" className="fw-bold">
                    SLE PO Date :
                  </Form.Label>
                  <Col sm="6">
                    <DatePicker
                      readOnly
                      dateFormat="dd/MM/yyyy"
                      className="form-control no-border"
                      showIcon
                      selected={SlePoDate}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col md="12">
                <Form.Group as={Row} controlId="SLESONumber">
                  <Form.Label column sm="3" className="fw-bold">
                    Customer Name :
                  </Form.Label>
                  <Col sm="6">
                    <Form.Control
                      readOnly
                      className="no-border"
                      type="text"
                      placeholder=""
                      value={CustomerName}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col md="12">
                <Form.Group as={Row} controlId="SLESODate">
                  <Form.Label column sm="3" className="fw-bold">
                    Project Name:
                  </Form.Label>
                  <Col sm="6">
                    <Form.Control
                      readOnly
                      className="no-border"
                      type="text"
                      placeholder=""
                      value={ProjectName}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col md="12">
                <Form.Group as={Row} controlId="OrderType">
                  <Form.Label column sm="3" className="fw-bold">
                    Order Type :
                  </Form.Label>
                  <Col sm="6">
                    <Form.Control
                      readOnly
                      className="no-border"
                      type="text"
                      placeholder=""
                      value={OrderType}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col md="12">
                <Form.Group as={Row} controlId="OrderType">
                  <Form.Label column sm="3" className="fw-bold">
                    Payment Term :
                  </Form.Label>
                  <Col sm="6">
                    <Form.Control
                      readOnly
                      className="no-border"
                      type="text"
                      placeholder=""
                      value={PaymentTerm}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <hr />
            <Row className="mb-2">
              <Col md="12">
                <Form.Group as={Row} controlId="OrderType">
                  <Form.Label column sm="3" className="fw-bold">
                    Schedule Delivery Date:
                  </Form.Label>
                  <Col sm="3">
                    <DatePicker
                      dateFormat="dd/MM/yyyy"
                      className="form-control"
                      showIcon
                      selected={ScheduleDate}
                      onChange={(date) => setScheduleDate(date)}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <hr />
            <NewDeliveryOrderModalAccordion
              rowData={ProcessedData}
              setSelectedTable={setSelectedTable}
              setSelectedMemoNum={setSelectedMemoNum}
              setShowSecondModal={setShowSecondModal}
              setShowCreateConfirmationModal={setShowCreateConfirmationModal}
              // setSelectedRowData = {setSelectedRowData}
              // onChildToggleChange={handleToggleFromChild}
              // onRowSelect={toggleRowSelection}
              // onInputChange={handleInputChange}
              // selectedRows={selectedRows}
              // setSelectedRows={setSelectedRows}
              // textInputs={textInputs}
              // setTextInputs={setTextInputs}
              // toggleRowSelection={toggleRowSelection}
            />
            {/* {showSecondModal === "edit" ? (
              <ManageBulkOrderMemoHistoryEditModal
                handleCloseAlert={handleCloseAlert}
                handleShowAlert={handleShowAlert}
                rowData={rowData}
                selectedMemoNum={selectedMemoNum}
                onClose={() => setShowSecondModal(false)}
                setTrigger={setTrigger}
                setRefreshHistoryTrigger={setRefreshHistoryTrigger}
              />
            ) : (
              ""
            )} */}
            {showCreateConfirmationModal === true ? (
              <NewDeliveryOrderCreateConfirmationModal
                handleCloseAlert={handleCloseAlert}
                handleShowAlert={handleShowAlert}
                selectedRows={selectedRows}
                rowData={rowData}
                scheduleDate={ScheduleDate}
                selectedTable = {SelectedTable}
                setRefreshTrigger={setRefreshTrigger}
                setShowNewNonBulkOrder={setShowNewNonBulkOrder}
                onClose={() => {
                  setShowCreateConfirmationModal(false);
                  setRefreshHistoryTrigger(moment().unix());
                }}
              />
            ) : (
              ""
            )}
            {/* {showNewNonBulkOrder === true ? (
              <ManageBulkOrderMemoHistoryEditModal
                handleCloseAlert={handleCloseAlert}
                handleShowAlert={handleShowAlert}
                rowData={rowData}
                selectedMemoNum={selectedMemoNum}
                onClose={() => setShowSecondModal(false)}
                setTrigger={setTrigger}
                setRefreshHistoryTrigger={setRefreshHistoryTrigger}
              />
            ) : (
              ""
            )} */}
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={onClose} variant="secondary">
              Close
            </Button>
          </Modal.Footer>
        </Form>
      </BlockUi>
    </Modal>
  );
}

export default NewDeliveryOrderNewModal;
