import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";
import {
  clearUserNameAndTokenInLocalStorage,
  getTokenFromLocalStorage,
} from "../lib/common";
import { useForm } from "react-hook-form";
import BlockUi from "@availity/block-ui";
import "@availity/block-ui/src/BlockUi.css";
import "@availity/block-ui/src/Loader.css";
import AlertModal from "../page_components/AlertModal";
import { IconContext } from "react-icons";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { AiOutlineCloseCircle } from "react-icons/ai";
import ManageDoAcknowledgementTable from "../page_components/ManageDOAcknowledgement/ManageDOAcknowledgement_table";
import ManageDOAcknowledgement_ViewAckModal from "../page_components/ManageDOAcknowledgement/ManageDOAcknowledgement_ViewAckModal";
import ManageDOAcknowledgement_EditAckModal from "../page_components/ManageDOAcknowledgement/ManageDOAcknowledgement_EditAckModal";
import ManageDOAcknowledgementDeleteModal from "../page_components/ManageDOAcknowledgement/ManageDOAcknowledgement_DeleteModal";

const config = JSON.parse(process.env.REACT_APP_CONFIG);

function ManageDOAcknowledgement(){
  const {
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      CustomerName: "",
    },
  });
  
  const [SleSoNumber, setSleSoNumber] = useState("");
  const [SleDoNumber, setSleDoNumber] = useState("");
  const [SupplierDoNumber, setSupplierDoNumber] = useState("");
  const [DoAckNumber, setDoAckNumber] = useState("");
  const [SleSoDateFrom, setSleSoDateFrom] = useState("");
  const [SleSoDateTo, setSleSoDateTo] = useState("");
  const [SleDoDateFrom, setSleDoDateFrom] = useState("");
  const [SleDoDateTo, setSleDoDateTo] = useState("");
  const [SupplierDoDateFrom, setSupplierDoDateFrom] = useState("");
  const [SupplierDoDateTo, setSupplierDoDateTo] = useState("");
  const [CustomerName, setCustomerName] = useState("");
  const [CustomerId, setCustomerId] = useState("");
  const [CustomerList, setCustomerList] = useState([]);
  const [ProjectName, setProjectName] = useState("");
  const [ProjectId, setProjectId] = useState("");
  const [ProjectList, setProjectList] = useState([]);
  
  const [searchProps, setSearchProps] = useState({});
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [blocking, setBlocking] = useState(true);
  const [ShowAlert, setShowAlert] = useState(false);
  const [AlertTitle, setAlertTitle] = useState("");
  const [AlertMessage, setAlertMessage] = useState("");
  const [ApiResponse, setApiResponse] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (getTokenFromLocalStorage() === null) {
      const state = { data: "Token expired, please login again" };
      navigate("/login", { state });
    }
  });

  useEffect(() => {
    fetch(config.customer_list_link, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getTokenFromLocalStorage(),
      },
      body: JSON.stringify({}),
    })
      .then((response) => response.json())
      .then((jsonData) => {
        if (
          jsonData.success !== true &&
          jsonData.message === "Access Denied! Invalid token"
        ) {
          clearUserNameAndTokenInLocalStorage();
          const state = { data: "Token expired, please login again" };
          navigate("/login", { state });
          throw new Error(jsonData.message);
        } else if (jsonData.success) {
          return jsonData.data;
        } else {
          throw new Error(jsonData.message);
        }
      })
      .then((data) => setCustomerList(data))
      .catch((error) => console.error(error));
  }, []);

  useEffect(() => {
    if (CustomerName !== "") {
      fetch(config.project_list_link, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + getTokenFromLocalStorage(),
        },
        body: JSON.stringify({
          customer_id: CustomerName,
        }),
      })
        .then((response) => response.json())
        .then((jsonData) => {
          if (
            jsonData.success !== true &&
            jsonData.message === "Access Denied! Invalid token"
          ) {
            clearUserNameAndTokenInLocalStorage();
            throw new Error(jsonData.message);
          } else if (jsonData.success) {
            return jsonData.data;
          } else {
            throw new Error(jsonData.message);
          }
        })
        .then((data) => setProjectList(data))
        .catch((error) => console.error(error));
    }
  }, [CustomerName]);

  const handleShowAlert = async (status, title, message, apiResponse = "") => {
    setAlertTitle(title);
    if (status === "ok") {
      setAlertMessage(
        <>
          <p className="mb-1">
            <IconContext.Provider
              value={{
                color: "green",
                style: { fontSize: "40px", paddingRight: "5px" },
              }}
            >
              <AiOutlineCheckCircle />
              {message}
            </IconContext.Provider>
          </p>
        </>
      );
    } else {
      setAlertMessage(
        <>
          <p className="mb-1">
            <IconContext.Provider
              value={{
                color: "red",
                style: { fontSize: "40px", paddingRight: "5px" },
              }}
            >
              <AiOutlineCloseCircle />
              {message}
            </IconContext.Provider>
          </p>
          {apiResponse !== "" ? (
            <p className="text-danger fst-italic ps-3">{apiResponse}</p>
          ) : (
            ""
          )}
        </>
      );
    }
    setShowAlert(true);
  };

  const handleCloseAlert = () => {
    setShowAlert(false);
  };

  const handleToggleBlocking = () => {
    setBlocking((prevState) => !prevState);
  };

  const handleToggleFromChild = (newValue) => {
    setBlocking(newValue);
  };

  const handleResetForm = () => {
    handleToggleBlocking();
    setSleSoNumber("");
    setSleDoNumber("");
    setSupplierDoNumber("");
    setDoAckNumber("");
    setSleSoDateFrom("");
    setSleSoDateTo("");
    setSleDoDateFrom("");
    setSleDoDateTo("");
    setSupplierDoDateFrom("");
    setSupplierDoDateTo("");
    setCustomerName("");
    setCustomerId("");
    setProjectName("");
    setProjectId("");
    reset();

    const searchProperties = {
      SleSoNumber: "",
      SleDoNumber: "",
      SupplierDoNumber: "",
      DoAckNumber:"",
      SleSoDateFrom: "",
      SleSoDateTo: "",
      SleDoDateFrom: "",
      SleDoDateTo: "",
      SupplierDoDateFrom: "",
      SupplierDoDateTo: "",
      CustomerName: "",
      CustomerId: "",
      ProjectName: "",
      ProjectId: "",
    };
    setSearchProps(searchProperties);
  };
  const handleSearch = (event) => {
    event.preventDefault();
    const searchProperties = {
      SleSoNumber: SleSoNumber,
      SleDoNumber: SleDoNumber,
      SupplierDoNumber: SupplierDoNumber,
      DoAckNumber: DoAckNumber,
      SleSoDateFrom: SleSoDateFrom,
      SleSoDateTo: SleSoDateTo,
      SleDoDateFrom: SleDoDateFrom,
      SleDoDateTo: SleDoDateTo,
      SupplierDoDateFrom: SupplierDoDateFrom,
      SupplierDoDateTo: SupplierDoDateTo,
      CustomerName: CustomerName,
      ProjectName: ProjectName,
    };
    setSearchProps(searchProperties);
  };

  const handleCancelClick = (rowData) => {
    console.log(rowData);
    setSelectedRowData(rowData);
    setShowModal("cancel");
  };

  const handleViewClick = (rowData) => {
    fetch(config.do_ack_list_link, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getTokenFromLocalStorage(),
      },
      body: JSON.stringify({
        so_number: SleSoNumber,
      }),
    })
      .then((response) => response.json())
      .then((jsonData) => {
        if (
          jsonData.success !== true &&
          jsonData.message === "Access Denied! Invalid token"
        ) {
          clearUserNameAndTokenInLocalStorage();
          const state = { data: "Token expired, please login again" };
          navigate("/login", { state });
          throw new Error(jsonData.message);
        } else if (jsonData.success) {
          setSelectedRowData(rowData);
          setShowModal("view");
        } else {
          throw new Error(jsonData.message);
        }
      })
      .catch((error) => console.error(error));
  };

  const handleEditClick = (rowData) => {
    console.log(rowData);
    fetch(config.do_ack_list_link, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getTokenFromLocalStorage(),
      },
      body: JSON.stringify({
        so_number: SleSoNumber,
      }),
    })
      .then((response) => response.json())
      .then((jsonData) => {
        if (
          jsonData.success !== true &&
          jsonData.message === "Access Denied! Invalid token"
        ) {
          clearUserNameAndTokenInLocalStorage();
          const state = { data: "Token expired, please login again" };
          navigate("/login", { state });
          throw new Error(jsonData.message);
        } else if (jsonData.success) {
          setSelectedRowData(rowData);
          setShowModal("edit");
        } else {
          throw new Error(jsonData.message);
        }
      })
      .catch((error) => console.error(error));
  };

  return (
    <div>
      <Container className="mt-4" style={{ maxWidth: "80%" }}>
        <BlockUi blocking={blocking}>
          <Form onSubmit={handleSearch}>
            <Row className="mb-4">
              <Col className="text-center">
                <h2>Search DO Acknowledgement</h2>
              </Col>
            </Row>
            <Row className="justify-content-center mb-4">
              <Col md={7}>
                <Row className="mb-2">
                  <Col sm="6">
                    <Form.Group as={Row} className="mb-3" controlId="SleDoNumber">
                      <Form.Label column sm="6" className="text-nowrap">
                        SLE DO Number
                      </Form.Label>
                      <Col sm="6" style={{ paddingRight: "3px" }}>
                        <Form.Control
                          type="text"
                          placeholder="SLE DO Number"
                          value={SleDoNumber}
                          onInput={(e) => setSleDoNumber(e.target.value)}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col sm="6">
                    <Form.Group as={Row} className="mb-3" controlId="DoAckNumber">
                      <Form.Label column sm="6" className="text-nowrap">
                        DO Ack Number
                      </Form.Label>
                      <Col sm="6" style={{ paddingRight: "3px" }}>
                        <Form.Control
                          type="text"
                          placeholder="DO Ack Number"
                          value={DoAckNumber}
                          onInput={(e) => setDoAckNumber(e.target.value)}
                        />
                      </Col>
                    </Form.Group>
                  </Col>

                </Row>
                <Row className="mb-2">
                  <Col sm="6">
                    <Form.Group as={Row} className="mb-3" controlId="SupplierDoNumber">
                      <Form.Label column sm="6">
                        Supplier DO Number
                      </Form.Label>
                      <Col sm="6" style={{ paddingRight: "3px" }}>
                        <Form.Control
                          type="text"
                          placeholder="Supplier DO Number"
                          value={SupplierDoNumber}
                          onInput={(e) => setSupplierDoNumber(e.target.value)}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col sm="6">
                    <Form.Group as={Row} className="mb-3" controlId="SleSoNumber">
                      <Form.Label column sm="6" className="text-nowrap">
                        SLE SO Number
                      </Form.Label>
                      <Col sm="6" style={{ paddingRight: "3px" }}>
                        <Form.Control
                          type="text"
                          placeholder="SLE SO Number"
                          value={SleSoNumber}
                          onInput={(e) => setSleSoNumber(e.target.value)}
                        />
                      </Col>
                    </Form.Group>
                  </Col>

                </Row>
                <Row className="mb-2">
                  <Col sm="6">
                    <Form.Group as={Row} className="mb-3" controlId="SleSoDateFrom">
                      <Form.Label column sm="6" className="text-nowrap">
                        SLE SO Date From
                      </Form.Label>
                      <Col sm="6" style={{ paddingLeft: "12px" }}>
                        <DatePicker
                          className="form-control"
                          dateFormat="dd MMM yyyy"
                          showIcon
                          selected={SleSoDateFrom}
                          onChange={(date) => setSleSoDateFrom(date)}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col sm="5">
                    <Form.Group as={Row} className="mb-3" controlId="SleSoDateTo">
                      <Form.Label column sm="3" className="text-center">
                        to
                      </Form.Label>
                      <Col sm="7" style={{ paddingRight: "15px" }}>
                        <DatePicker
                          className="form-control"
                          dateFormat="dd MMM yyyy"
                          showIcon
                          selected={SleSoDateTo}
                          onChange={(date) => setSleSoDateTo(date)}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col sm="6">
                    <Form.Group as={Row} className="mb-3" controlId="SleDoDateFrom">
                      <Form.Label column sm="6" className="text-nowrap">
                        SLE DO Date From
                      </Form.Label>
                      <Col sm="6" style={{ paddingLeft: "12px" }}>
                        <DatePicker
                          className="form-control"
                          dateFormat="dd MMM yyyy"
                          showIcon
                          selected={SleDoDateFrom}
                          onChange={(date) => setSleDoDateFrom(date)}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col sm="5">
                    <Form.Group as={Row} className="mb-3" controlId="SleDoDateTo">
                      <Form.Label column sm="3" className="text-center">
                        to
                      </Form.Label>
                      <Col sm="7" style={{ paddingRight: "15px" }}>
                        <DatePicker
                          className="form-control"
                          dateFormat="dd MMM yyyy"
                          showIcon
                          selected={SleDoDateTo}
                          onChange={(date) => setSleDoDateTo(date)}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col sm="6">
                    <Form.Group as={Row} className="mb-3" controlId="SupplierDoDateFrom">
                      <Form.Label column sm="6" className="text-nowrap">
                        Supplier DO Date From
                      </Form.Label>
                      <Col sm="6" style={{ paddingLeft: "12px" }}>
                        <DatePicker
                          className="form-control"
                          dateFormat="dd MMM yyyy"
                          showIcon
                          selected={SupplierDoDateFrom}
                          onChange={(date) => setSupplierDoDateFrom(date)}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col sm="5">
                    <Form.Group as={Row} className="mb-3" controlId="SupplierDoDateTo" >
                      <Form.Label column sm="3" className="text-center">
                        to
                      </Form.Label>
                      <Col sm="7" style={{ paddingRight: "15px" }}>
                        <DatePicker
                          className="form-control"
                          dateFormat="dd MMM yyyy"
                          showIcon
                          selected={SupplierDoDateTo}
                          onChange={(date) => setSupplierDoDateTo(date)}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col>
                    <Form.Group as={Row} className="mb-3" controlId="CustomerName">
                      <Form.Label column sm="3">
                        Customer Name
                      </Form.Label>
                      <Col sm="7" style={{ paddingRight: "3px" }}>
                        <Form.Select
                          value={CustomerName}
                          onChange={(e) => setCustomerName(e.target.value)}
                        >
                          <option key={""} value="">
                            Please select customer name
                          </option>
                          {CustomerList.map((items) => (
                            <option
                              key={items.CUSTOMER_ID}
                              value={items.CUSTOMER_ID}
                            >
                              {items.CUSTOMER_NAME}
                            </option>
                          ))}
                        </Form.Select>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="ProjectName"
                    >
                      <Form.Label column sm="3">
                        Project Name
                      </Form.Label>
                      <Col sm="7" style={{ paddingRight: "3px" }}>
                        <Form.Select
                          value={ProjectName}
                          onChange={(e) => setProjectName(e.target.value)}
                        >
                          <option key={""} value="" disabled>
                              Please select project name
                            </option>
                            {!CustomerName ? (
                              <option key={"selectcustomer"} value="" className="drop-down-message" disabled>
                                Please select customer name first
                              </option>
                            ) : ProjectList.length <= 0 ? (
                              <option key={"noprojects"} value="" className="drop-down-message" disabled>
                                This customer currently has no projects
                              </option>
                            ) : (
                              <>
                                {ProjectList.map((items) => (
                                  <option
                                    key={items.PROJECT_ID}
                                    value={items.PROJECT_ID}
                                  >
                                    {items.PROJECT_NAME}
                                  </option>
                                ))}
                              </>
                            )}
                        </Form.Select>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
                <Row>
                  <Col className="text-center">
                    <Button
                      className="me-2"
                      variant="secondary"
                      onClick={handleResetForm}
                    >
                      Reset
                    </Button>
                    <Button type="submit" variant="primary">
                      Search
                    </Button>
                  </Col>
                </Row>
            </Row>
          </Form>
          <hr />
          <br />
          <ManageDoAcknowledgementTable
            // showAlert={ShowAlert}
            searchProps={searchProps}
            onChildToggleChange={handleToggleFromChild}
            onCancelClick={handleCancelClick}
            onViewClick={handleViewClick}
            onEditClick={handleEditClick}
            handleShowAlert={handleShowAlert}
          />
          {showModal === "view" ? (
              <ManageDOAcknowledgement_ViewAckModal
                // handleCloseAlert={handleCloseAlert}
                handleShowAlert={handleShowAlert}
                rowData={selectedRowData}
                onClose={() => setShowModal(false)}
              />
            ) : (
              ""
            )}
            {showModal === "edit" ? (
              <ManageDOAcknowledgement_EditAckModal
                // handleCloseAlert={handleCloseAlert}
                handleShowAlert={handleShowAlert}
                rowData={selectedRowData}
                onClose={() => setShowModal(false)}
              />
            ) : (
              ""
            )}
            {showModal === "cancel" ? (
            <ManageDOAcknowledgementDeleteModal
              handleCloseAlert={handleCloseAlert}
              handleShowAlert={handleShowAlert}
              rowData={selectedRowData}
              onClose={() => setShowModal(false)}
            />
          ) : (
            ""
          )}
        </BlockUi>
        <style>
            {`
              .drop-down-message {
                color: #dc4c64;
              }
            `}
          </style>
        {ShowAlert && (
          <AlertModal
            title={AlertTitle}
            message={AlertMessage}
            apiResponse={ApiResponse}
            onClose={handleCloseAlert}
          />
        )}
      </Container>
    </div>
  );
}

export default ManageDOAcknowledgement;