import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Pagination from "react-bootstrap/Pagination";
import Form from 'react-bootstrap/Form';
import moment from "moment/moment";
import {
  clearUserNameAndTokenInLocalStorage,
  getTokenFromLocalStorage,
} from "../../lib/common";
const config = JSON.parse(process.env.REACT_APP_CONFIG);

function ManagePIEditModalTable(props) {
  const [data, setData] = useState([]);
  const { ItemList } = props;
  const [headerData] = useState([
    "GRN Number",
    "GRN Date",
    "PO Number",
    "Supplier DO Number",
    "Supplier DO Date",
    "Action",
  ]);

  const [currentPage, setCurrentPage] = useState(1);
  const { rowData, selectedRows, setSelectedRows, textInputs, setTextInputs, onInputChange, toggleRowSelection } = props;
  const { onViewClick } = props;
  const ROWS_PER_PAGE = 10;
  const totalPages = Math.ceil(data.length / ROWS_PER_PAGE);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleToggleBlocking = () => {
    props.onChildToggleChange(false);
  };

  useEffect(() => {
    if (Array.isArray(ItemList)) {
      setData(ItemList);
      setSelectedRows(ItemList.map((row) => row.GRN_NUMBER));
    } else {
      setData([]);
      setSelectedRows([]);
    }
    console.log(ItemList);
  }, [ItemList]);
  
  const renderTableData = () => {
    const startIndex = (currentPage - 1) * ROWS_PER_PAGE;
    const endIndex = startIndex + ROWS_PER_PAGE;
    return data.slice(startIndex, endIndex).map((row) => {
      const {
        ITEM_ID,
        GRN_NUMBER,
        GRN_DATE,
        PO_NUMBER,
        SUPPLIER_DO_NUMBER,
        SUPPLIER_DO_DATE,
      } = row;
      const formattedGRNDate = moment(GRN_DATE).format("DD MMM yyyy");
      const formattedSupplierDoDate = moment(SUPPLIER_DO_DATE).format("DD MMM yyyy");
      return (
        // <tr key={GRN_NUMBER}>
        <tr key={ITEM_ID}>
          <td>
            <Form.Check
              type="checkbox"
              onChange={() => toggleRowSelection(row.GRN_NUMBER)}
              checked={selectedRows.includes(row.GRN_NUMBER)} 
            />
          </td>
          <td>{GRN_NUMBER}</td>
          <td>{formattedGRNDate}</td>
          <td>{PO_NUMBER}</td>
          <td>{SUPPLIER_DO_NUMBER}</td>
          <td>{formattedSupplierDoDate}</td>
          <td>
            <Button
              size="sm"
              variant="primary"
              onClick={() => onViewClick(row)}
            >
              View GRN
            </Button>
          </td>
        </tr>
      );
    });
  };
  
  const renderPageItems = () => {
    const pageItems = [];
    for (let i = 1; i <= totalPages; i++) {
      pageItems.push(
        <Pagination.Item
          key={i}
          active={i === currentPage}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </Pagination.Item>
      );
    }
    return pageItems;
  };

  if (data.length < 1) {
    return (
      <div className="text-center">
        <h3>No Data</h3>
      </div>
    );
  } else {
    return (
      <div>
          <Table striped bordered hover className="mb-4">
              <thead>
                  <tr>
                      <th>
                          <Form.Check
                              type="checkbox"
                              onChange={() => {
                                  if (selectedRows.length === data.length) {
                                      setTextInputs({})
                                      setSelectedRows([]);
                                  } else {
                                      setSelectedRows(data.map((row) => {return (row.GRN_NUMBER)}));
                                  }
                              }}
                              checked={selectedRows.length === data.length}
                          />
                      </th>
                      {headerData.map((header) => (
                          <th key={header}>{header}</th>
                      ))}
                  </tr>
              </thead>
              <tbody>{renderTableData()}</tbody>
          </Table>
          <Pagination className="d-flex justify-content-center" >{renderPageItems()}</Pagination>
      </div>
    );
  }
}

export default ManagePIEditModalTable;
