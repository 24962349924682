import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Accordion from "react-bootstrap/Accordion";
import DatePicker from "react-datepicker";
import moment from "moment/moment";
// import ManageBulkOrderMemoHistoryModalAccordion from "./ManageBulkOrderMemo_HistoryModalAccordion";
// import ManageBulkOrderMemoHistoryEditModal from "./ManageBulkOrderMemo_EditModal";

import { useNavigate } from "react-router-dom";
import {
  clearUserNameAndTokenInLocalStorage,
  getUserNameFromLocalStorage,
  getTokenFromLocalStorage,
} from "../../lib/common";
import BlockUi from "@availity/block-ui";
import ManageGRNViewModalTable from "./ManageGRN_ViewModalTable";

const config = JSON.parse(process.env.REACT_APP_CONFIG);

function ManageGRNViewModal(props) {
    const { rowData, onClose, handleShowAlert, handleCloseAlert } = props;
    const [grnNumber, setGrnNumber] = useState(rowData.GRN_NUMBER);
    const [grnDate, setGrnDate] = useState(rowData.GRN_DATE ? new Date(rowData.GRN_DATE) : '');
    const [poNumber, setPoNumber] = useState(rowData.PO_NUMBER);
    const [poDate, setPoDate] = useState(rowData.PO_DATE ? new Date(rowData.PO_DATE) : '');
    const [soNumber, setSoNumber] = useState(rowData.SO_NUMBER);
    const [piNumber, setPiNumber] = useState(rowData.PI_NUMBER);
    const [piDate, setPiDate] = useState(rowData.PI_DATE ? new Date(rowData.PI_DATE) : '');
    const [doNumber, setDoNumber] = useState(rowData.SUPPLIER_DO_NUMBER);
    const [supplierDoDate, setSupplierDoDate] = useState(rowData.SUPPLIER_DO_DATE ? new Date(rowData.SUPPLIER_DO_DATE) : '');
    const [supplierId, setSupplierId] = useState(rowData.SUPPLIER_ID);
    const [supplierName, setSupplierName] = useState(rowData.SUPPLIER_NAME);

  const [SleSoNum] = useState(rowData.SO_NUMBER);
  const [SleSoStatus] = useState(rowData.SO_STATUS);
  const [SlePoNum, setSlePoNum] = useState(rowData.PO_NUMBER);
  const [SleMemoNum, setSleMemoNum] = useState("");
  const [SleDoNum, setSleDoNum] = useState("");
  const [CustomerId] = useState(rowData.CUSTOMER_ID);
  const [CustomerName, setCustomerName] = useState(rowData.CUSTOMER_NAME);
  const [CustomerRef, setCustomerRef] = useState(
    rowData.CUSTOMER_REF === null || rowData.CUSTOMER_REF === undefined || rowData.CUSTOMER_REF === ""
      ? "-"
      : rowData.CUSTOMER_REF
  );
  const [ProjectId] = useState(rowData.PROJECT_ID);
  const [ProjectName, setProjectName] = useState(rowData.PROJECT_NAME);
  const [PaymentTerm] = useState(rowData.PAYMENT_TERM);
  const [Remarks, setRemarks] = useState(rowData.REMARKS);
  //   const [SleSoDate] = useState(new Date(rowData.SO_DATE));
  //   const [SlePoDate, setSlePoDate] = useState(new Date(rowData.PO_DATE));
  const [SleSoDate] = useState(new Date());
  const [SlePoDate, setSlePoDate] = useState(new Date());
  const [SleMemoDate, setSleMemoDate] = useState(new Date());
  
  const [isIncludeSleDo, setIsIncludeSleDo] = useState(false);
  const [OrderType] = useState(rowData.SO_TYPE_DESC);
  const [Trigger, setTrigger] = useState("");
  const [blocking, setBlocking] = useState(true);
  const [textInputs, setTextInputs] = useState({});
  const [selectedRows, setSelectedRows] = useState([]);
  const [Data, setData] = useState([]);
  const [ProcessedData, setProcessedData] = useState([]);
  const [showSecondModal, setShowSecondModal] = useState(false);
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] =
    useState(false);
  const [selectedRowData, setSelectedRowData] = useState({});
  const [selectedMemoNum, setSelectedMemoNum] = useState("");
  const [ItemList, setItemList] = useState("");
  const [RefreshHistoryTrigger, setRefreshHistoryTrigger] = useState("");
  console.log(showSecondModal);
  const navigate = useNavigate();

  const handleIncludeSleDoCheckbox = (event) => {
    setIsIncludeSleDo(event.target.checked);
  };
  const handleToggleBlocking = () => {
    setBlocking((prevState) => !prevState);
  };

  const handleToggleFromChild = (newValue) => {
    setBlocking(newValue);
  };

  const handleToggleClick = (rowData) => {
    setSelectedRowData(rowData);
    setShowSecondModal("toggle");
  };

  useEffect(() => {
    getData(config.grn_get_link, rowData);
  }, [rowData]);

  useEffect(() => {
    // Create an object to store grouped data
    const groupedData = Data.reduce((result, item) => {
      const memoNumber = item.MEMO_NUMBER;

      // If the memoNumber is not in the result, create a new array for it
      if (!result[memoNumber]) {
        result[memoNumber] = [];
      }

      // Push the item into the array under the memoNumber key
      result[memoNumber].push(item);

      return result;
    }, {});

    // Convert the grouped data into an array
    const groupedArray = Object.values(groupedData);
    setProcessedData(groupedArray);
  }, [Data]);

  const getData = async (url, params = {}) => {
    await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getTokenFromLocalStorage(),
      },
      body: JSON.stringify({
        grn_number: params.GRN_NUMBER,
      }),
    })
      .then((response) => response.json())
      .then((jsonData) => {
        console.log(jsonData);
        setBlocking(false);
        if (
          jsonData.success !== true &&
          jsonData.message === "Access Denied! Invalid token"
        ) {
          clearUserNameAndTokenInLocalStorage();
          throw new Error(jsonData.message);
        } else if (jsonData.success && jsonData.data.GRN_ITEMS) {
          const newData = jsonData.data.GRN_ITEMS.map((item) => {
            //item.
            return item;
          });
          return newData;
        } else {
          setItemList([]);
          throw new Error(jsonData.message);
        }
      })
      .then((data) => {
        setItemList(data);
      })
      .catch((error) => console.error(error));
  };

  return (
    <Modal show={true} onHide={onClose} size="xl" backdrop="static" centered style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}>
      <BlockUi blocking={blocking}>
        <Form>
          <Modal.Header closeButton className="px-4">
            <Modal.Title className="ms-auto">View GRN</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="d-flex align-items-center justify-content-center">
              <Col md={10}>
                <Row className="mb-2">
                  <Col md="7">
                    <Form.Group
                      as={Row}
                      controlId="SLESONumber"
                    >
                      <Form.Label
                        column
                        sm="4"
                        className="fw-bold"
                        // style={{ paddingLeft: "100px" }}
                      >
                        GRN Number :
                      </Form.Label>
                      <Col sm="6">
                        <Form.Control
                          disabled
                          
                          type="text"
                          placeholder=""
                          value={grnNumber}
                        />
                      </Col>
                    </Form.Group>
                  </Col>

                  <Col md="5">
                      <Form.Group as={Row} controlId="SLESODate">
                      <Form.Label column sm="6" className="fw-bold">
                          GRN Date :
                      </Form.Label>
                      <Col sm="6">
                          <DatePicker
                          disabled
                          dateFormat="dd/MM/yyyy"
                          className="form-control"
                          showIcon
                          selected={grnDate}
                          />
                      </Col>
                      </Form.Group>
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col md="7">
                    <Form.Group
                      as={Row}
                      controlId="SLESONumber"
                    >
                      <Form.Label
                        column
                        sm="4"
                        className="fw-bold"
                        // style={{ paddingLeft: "100px" }}
                      >
                        SLE PO Number :
                      </Form.Label>
                      <Col sm="6">
                        <Form.Control
                          disabled
                          
                          type="text"
                          placeholder=""
                          value={poNumber}
                        />
                      </Col>
                    </Form.Group>
                  </Col>

                  <Col md="5">
                        <Form.Group as={Row} controlId="SLESODate">
                        <Form.Label column sm="6" className="fw-bold">
                            DO Date :
                        </Form.Label>
                        <Col sm="6">
                            <DatePicker
                            disabled
                            dateFormat="dd/MM/yyyy"
                            className="form-control"
                            showIcon
                            selected={supplierDoDate}
                            />
                        </Col>
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="mb-2">
                  <Col md="7">
                    <Form.Group
                      as={Row}
                      controlId="SLESONumber"
                    >
                      <Form.Label
                        column
                        sm="4"
                        className="fw-bold"
                        // style={{ paddingLeft: "100px" }}
                      >
                        Supplier DO Number :
                      </Form.Label>
                      <Col sm="6">
                        <Form.Control
                          disabled
                          
                          type="text"
                          placeholder=""
                          value={doNumber}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col md="5">
                        <Form.Group as={Row} controlId="SLESODate">
                        <Form.Label column sm="6" className="fw-bold">
                            Supplier DO Date :
                        </Form.Label>
                        <Col sm="6">
                            <DatePicker
                            disabled
                            dateFormat="dd/MM/yyyy"
                            className="form-control"
                            showIcon
                            selected={supplierDoDate}
                            />
                        </Col>
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="mb-2">
                <Col md="7">
                    <Form.Group
                      as={Row}
                      controlId="SLESONumber"
                    >
                      <Form.Label
                        column
                        sm="4"
                        className="fw-bold"
                        // style={{ paddingLeft: "100px" }}
                      >
                        Supplier :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          disabled
                          type="text"
                          placeholder=""
                          value={supplierName}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
            </Row>
            {/* <Row className="mb-2 justify-content-center">
              <Col md="6">
                <Form.Group as={Row} controlId="SLESONumber">
                  <Form.Label column sm="6" className="fw-bold">
                    SLE PO Number :
                  </Form.Label>
                  <Col sm="6">
                    <Form.Control
                      disabled
                      
                      type="text"
                      placeholder=""
                      value={SlePoNum}
                    />
                  </Col>
                </Form.Group>
              </Col>

              <Col md="6">
                <Form.Group as={Row} controlId="SLESODate">
                  <Form.Label column sm="6" className="fw-bold">
                    SLE PO Date :
                  </Form.Label>
                  <Col sm="6">
                    <DatePicker
                      disabled
                      dateFormat="dd/MM/yyyy"
                      className="form-control"
                      showIcon
                      selected={SlePoDate}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row> */}
            <hr />
            <ManageGRNViewModalTable
              ItemList={ItemList}
              //   setSelectedMemoNum={setSelectedMemoNum}
              //   setShowSecondModal={setShowSecondModal}
              // setSelectedRowData = {setSelectedRowData}
              // onChildToggleChange={handleToggleFromChild}
              // onRowSelect={toggleRowSelection}
              // onInputChange={handleInputChange}
              // selectedRows={selectedRows}
              // setSelectedRows={setSelectedRows}
              // textInputs={textInputs}
              // setTextInputs={setTextInputs}
              // toggleRowSelection={toggleRowSelection}
            />
            {/* {showSecondModal === "edit" ? (
              <ManageBulkOrderMemoHistoryEditModal
                handleCloseAlert={handleCloseAlert}
                handleShowAlert={handleShowAlert}
                rowData={rowData}
                selectedMemoNum={selectedMemoNum}
                onClose={() => setShowSecondModal(false)}
                setTrigger={setTrigger}
                setRefreshHistoryTrigger={setRefreshHistoryTrigger}
              />
            ) : (
              ""
            )} */}
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={onClose} variant="secondary">
              Close
            </Button>
          </Modal.Footer>
        </Form>
      </BlockUi>
      <style>
          {`
            .form-control:disabled {
              background: #F8F8F8 !important;
            }
          `}
      </style>
    </Modal>
  );
}

export default ManageGRNViewModal;
