import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Pagination from "react-bootstrap/Pagination";
import Form from 'react-bootstrap/Form';
import moment from "moment/moment";
import { useNavigate } from "react-router-dom";
import {
  clearUserNameAndTokenInLocalStorage,
  getTokenFromLocalStorage,
} from "../../lib/common";
const config = JSON.parse(process.env.REACT_APP_CONFIG);

function NewInvoiceTable(props) {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [headerData] = useState([
    "",
    "SLE DO Number", 
    "DO Ack Number", 
    "DO Ack Date",
    "Customer", 
    "Project", 
    "Action"
  ]);   

  const [currentPage, setCurrentPage] = useState(1);
  const { onViewClick } = props;
  const { showAlert, rowData, selectedRows, toggleRowSelection, refreshTrigger } = props;
  const ROWS_PER_PAGE = 10;
  const totalPages = Math.ceil(data.length / ROWS_PER_PAGE);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleToggleBlocking = () => {
    props.onChildToggleChange(false);
  };

  useEffect(() => {
    getData(config.invoice_pending_link, {customer_id : rowData.CUSTOMER_ID});
  }, [rowData.CUSTOMER_ID, showAlert, refreshTrigger]);
  
  const renderTableData = () => {
    const startIndex = (currentPage - 1) * ROWS_PER_PAGE;
    const endIndex = startIndex + ROWS_PER_PAGE;
    return data.slice(startIndex, endIndex).map((row) => {
      const {
        CUSTOMER_ID,
        DO_NUMBER, 
        DO_ACK_NUMBER,
        DO_ACK_DATE,
        CUSTOMER_NAME,
        PROJECT_NAME,
      } = row;
      const formattedDoAckDate = moment(DO_ACK_DATE).format("DD MMM yyyy");
      return (
        // <tr key={`${index}-${CUSTOMER_ID}`}>
        <tr key={DO_ACK_NUMBER}>
          <td>
            <Form.Check
              type="checkbox"
              onChange={() => toggleRowSelection(row.DO_ACK_NUMBER)}
              checked={selectedRows.includes(row.DO_ACK_NUMBER)}
            />
            {/* 
            <Form.Check
              type="checkbox"
              onChange={() => toggleRowSelection(row.DO_ACK_NUMBER)}
              checked={selectedRow[0] === row.DO_ACK_NUMBER}
            /> */}
          </td>
          <td>{DO_NUMBER}</td>
          <td>{DO_ACK_NUMBER}</td>
          <td>{formattedDoAckDate}</td>
          <td>{CUSTOMER_NAME}</td>
          <td>{PROJECT_NAME}</td>
          <td >
            <Button
                variant="primary"
                style={{marginRight:"5px"}}
                onClick={() => onViewClick(row)}
            >
                View
            </Button>
          </td>
        </tr>
      );
    });
  };

  
  const renderPageItems = () => {
    const pageItems = [];
    for (let i = 1; i <= totalPages; i++) {
      pageItems.push(
        <Pagination.Item
          key={i}
          active={i === currentPage}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </Pagination.Item>
      );
    }
    return pageItems;
  };

  const getData = async (url, params = {}) => {
    await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getTokenFromLocalStorage(),
      },
      body: JSON.stringify(
        params.length === 0
          ? params
          : {
            customer_id: params.customer_id,
          }
      ),
    })
    .then(response => response.json())
    .then (jsonData => {
      handleToggleBlocking();
      if (jsonData.success !== true && jsonData.message === 'Access Denied! Invalid token'){
        clearUserNameAndTokenInLocalStorage();
        const state = { data: "Token expired, please login again" };
        navigate("/login", { state });
        throw new Error (jsonData.message);
      } else if (jsonData.success){
        return jsonData.data;
      } else {
        throw new Error (jsonData.message);
      }
    })
    .then((data) => {
      setData(data);
    })
      .catch((error) => console.error(error));
  };

  // if (data.length < 1) {
  //   return (
  //     <div className="text-center">
  //       <h3>No Data</h3>
  //     </div>
  //   );
  // } else {
  //   return (
  //     <div>
  //       <Table striped bordered hover className="mb-4">
  //         <thead>
  //           <tr>
  //             {headerData.map((header) => (
  //               <th key={header}>{header}</th>
  //             ))}
  //           </tr>
  //         </thead>
  //         <tbody>{renderTableData()}</tbody>
  //       </Table>
  //       <Pagination className="d-flex justify-content-center">
  //         {renderPageItems()}
  //       </Pagination>
  //     </div>
  //   );
  // }

  if (data.length < 1) {
    return (
      <div className="text-center">
        <h3>No Data</h3>
      </div>
    );
  } else {
    return (
      <div>
        <Table striped bordered hover className="mb-4">
          <thead>
            <tr>
              {headerData.map((header) => (
                <th key={header}>{header}</th>
              ))}
            </tr>
          </thead>
          <tbody>{renderTableData()}</tbody>
        </Table>
        <Pagination className="d-flex justify-content-center">
          {renderPageItems()}
        </Pagination>
      </div>
    );
  }
}

export default NewInvoiceTable;
