import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Pagination from "react-bootstrap/Pagination";
import moment from "moment/moment";
import {
  clearUserNameAndTokenInLocalStorage,
  getTokenFromLocalStorage,
} from "../../lib/common";
const config = JSON.parse(process.env.REACT_APP_CONFIG);

function NewGoodsReceivedNoteTable(props) {
  const [data, setData] = useState([]);
  const [headerData] = useState([
    "PO Number",
    "PO Date",
    "PO Type",
    "Supplier Name"
  ]);
  const [currentPage, setCurrentPage] = useState(1);
  const { onNewClick } = props;
  const { searchProps } = props;
  const { showAlert } = props;
  const ROWS_PER_PAGE = 10;
  const totalPages = Math.ceil(data.length / ROWS_PER_PAGE);

  useEffect(() => {
    if (
      searchProps.SlePoNum ||
      searchProps.SlePoDateFrom ||
      searchProps.SlePoDateTo ||
      searchProps.SupplierName 
    ) {
      getData(config.seng_liy_backend_url +'/grn/pending', searchProps);
    } else {
      getData(config.seng_liy_backend_url +'/grn/pending');
    }
  }, [searchProps, showAlert]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleToggleBlocking = () => {
    props.onChildToggleChange(false);
  };

  const renderTableData = () => {
    const startIndex = (currentPage - 1) * ROWS_PER_PAGE;
    const endIndex = startIndex + ROWS_PER_PAGE;
    return data.slice(startIndex, endIndex).map((row) => {
      const {
        ROW_KEY,
        PO_NUMBER,
        PO_TYPE,
        PO_DATE,
        SUPPLIER_NAME
      } = row;
      return (
        <tr key={ROW_KEY}>
          <td>{PO_NUMBER}</td>
          <td>{PO_DATE}</td>
          <td>{PO_TYPE == "S" ? "Standard" : "Adhoc"}</td>
          <td>{SUPPLIER_NAME}</td>
          <td style={{ width: "175px" }}>
            <Button
              style={{ marginRight: "5px" }}
              variant="primary"
              onClick={() => onNewClick(row)}
            >
              Issue GRN
            </Button>
          </td>
        </tr>
      );
    });
  };

  const renderPageItems = () => {
    const pageItems = [];
    for (let i = 1; i <= totalPages; i++) {
      pageItems.push(
        <Pagination.Item
          key={i}
          active={i === currentPage}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </Pagination.Item>
      );
    }
    return pageItems;
  };

  const getData = async (url, params = {}) => {
    await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getTokenFromLocalStorage(),
      },
      body: JSON.stringify(
        params
      ),
    })
      .then((response) => response.json())
      .then((jsonData) => {
        handleToggleBlocking();
        if (jsonData.message === "Access Denied! Invalid token") {
          clearUserNameAndTokenInLocalStorage();
          throw new Error(jsonData.message);
        }
        const newData = jsonData.data.map((item) => {
          const date = moment(item.PO_DATE).utcOffset(480).format("DD MMM yyyy");
          item.PO_DATE = date;
          return item;
        });
        return newData;
      })
      .then((data) => setData(data))
      .catch((error) => console.error(error));
  };

  if (data.length < 1) {
    return (
      <div className="text-center">
        <h3>No Data</h3>
      </div>
    );
  } else {
    return (
      <div>
        <Table striped bordered hover className="mb-4">
          <thead>
            <tr>
              {headerData.map((header) => (
                <th key={header}>{header}</th>
              ))}
            </tr>
          </thead>
          <tbody>{renderTableData()}</tbody>
        </Table>
        <Pagination className="d-flex justify-content-center">
          {renderPageItems()}
        </Pagination>
      </div>
    );
  }
}

export default NewGoodsReceivedNoteTable;
