import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import "react-datepicker/dist/react-datepicker.css";
import {
  clearUserNameAndTokenInLocalStorage,
  getTokenFromLocalStorage,
} from "../../lib/common";
import BlockUi from "@availity/block-ui";
import { useForm } from "react-hook-form";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import SuppliersAddressTable from "./Suppliers_AddressTable";
import SuppliersNewAddressModal from "./Suppliers_NewSupplierAddAddressModal";
import SuppliersEditAddressModal from "./Suppliers_NewSupplierEditAddressModal";
import DeleteAddressModal from "./Suppliers_DeleteAddressModal";
import { useNavigate } from "react-router-dom";

const config = JSON.parse(process.env.REACT_APP_CONFIG);

function SuppliersNewSupplierModal(props) {
  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {

    },
  });
  const navigate = useNavigate();
  const {onClose, handleShowAlert, handleCloseAlert } = props;
  const [SupplierName, setSupplierName] = useState("");
  const [SupplierEmail, setSupplierEmail] = useState("");
  const [CreditorCode, setCreditorCode] = useState("");
  const [SupplierStatus, setSupplierStatus ] = useState("A");
  const [PaymentTermList, setPaymentTermList ] = useState([]);
  const [PaymentTerm, setPaymentTerm] = useState([]);
  const [blocking, setBlocking] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState({});
  const [ItemList, setItemList] = useState([]);
  

  useEffect(() => {
    fetch(config.payment_term_get_link, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getTokenFromLocalStorage(),
      },
      body: JSON.stringify({}),
    })
      .then((response) => response.json())
      .then((jsonData) => {
        console.log(jsonData);
        if (
          jsonData.success !== true &&
          jsonData.message === "Access Denied! Invalid token"
        ) {
          clearUserNameAndTokenInLocalStorage();
          const state = { data: "Token expired, please login again" };
          navigate("/login", { state });
          throw new Error(jsonData.message);
        } else if (jsonData.success) {
          return jsonData.data;
        } else {
          throw new Error(jsonData.message);
        }
      })
      .then((data) => setPaymentTermList(data))
      .catch((error) => console.error(error));
  }, [PaymentTerm]);

  const submitNewSupplier = async () => {
    if (ItemList.length === 0) {
      handleShowAlert(
        "error",
        "New Supplier",
        "Failed Saving New Supplier :",
        ["Address list is empty!"]
      );
      return;
    }
    if (PaymentTerm.length === 0) {
      handleShowAlert(
        "error",
        "New Supplier",
        "Failed Saving New Supplier :",
        ["Please select Payment Term!"]
      );
      return;
    }
    try {
      var arrSupplierAddrID = [];
      var arrAddrType = [];
      var arrAddr1Name = [];
      var arrAddr2Name = [];
      var arrAddr3Name = [];
      var arrAddr4Name = [];
      var arrPostCode = [];
      var arrCity = [];
      var arrState = [];
      var arrCountryName = [];
      var arrCountryCode = [];
      var arrContactName = [];
      var arrContactPhone = [];

      for (var i = 0; i < ItemList.length; i++) {
        arrSupplierAddrID.push(ItemList[i].SUPPLIER_ADDR_ID);
        arrAddrType.push(ItemList[i].ADDR_TYPE);
        arrAddr1Name.push(ItemList[i].ADDR1_NAME);
        arrAddr2Name.push(ItemList[i].ADDR2_NAME);
        arrAddr3Name.push(ItemList[i].ADDR3_NAME);
        arrAddr4Name.push(ItemList[i].ADDR4_NAME);
        arrPostCode.push(ItemList[i].POST_CODE);
        arrCity.push(ItemList[i].CITY);
        arrState.push(ItemList[i].STATE);
        arrCountryName.push(ItemList[i].COUNTRY_NAME);
        arrCountryCode.push(ItemList[i].COUNTRY_CODE);
        arrContactName.push(ItemList[i].CONTACT_NAME);
        arrContactPhone.push(ItemList[i].CONTACT_PHONE);
      }

      var jsonOb = {
        supplier_name: SupplierName,
        creditor_code: CreditorCode,
        supplier_email: SupplierEmail,
        supplier_status: SupplierStatus,
        payment_term: PaymentTerm,
        supplier_addr_id: arrSupplierAddrID,
        addr_type: arrAddrType,
        addr1_name: arrAddr1Name,
        addr2_name: arrAddr2Name,
        addr3_name: arrAddr3Name,
        addr4_name: arrAddr4Name,
        post_code: arrPostCode,
        city: arrCity,
        state: arrState,
        COUNTRY_NAME: arrCountryName,
        country_code: arrCountryCode,
        contact_name: arrContactName,
        contact_phone: arrContactPhone,
      };
      console.log(jsonOb);
      const response = await fetch(config.supplier_create_link, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + getTokenFromLocalStorage(),
        },
        body: JSON.stringify(jsonOb),
      });

      const contentType = response.headers.get("content-type");
      const data =
        contentType && contentType.includes("application/json")
          ? await response.json()
          : await response.text();
      console.log("result :");
      console.log(data);
      if (data.message === "Access Denied! Invalid token") {
        clearUserNameAndTokenInLocalStorage();
        const state = { data: "Token expired, please login again" };
        navigate("/login", { state });
        throw data.message;
      } else if (data.message) {
        throw data.message;
      }
      if (response.status !== 200) {
        var errorMessages = data.error.map((item) => item.msg);
        handleShowAlert(
          "error",
          "New Supplier",
          "Failed Saving New Supplier :",
          errorMessages
        );
        // throw errorMessages.join("<br/>");
      } else {
        handleShowAlert(
          "ok",
          "New Supplier",
          "Success Saving New Supplier"
        );
        handleResetForm();
        onClose();
        return "ok";
      }
    } catch (error) {
      console.error("Error while submitting New Supplier:", error);
      handleShowAlert(
        "error",
        "New Supplier",
        "Failed Saving New Supplier :",
        error
      );
    } finally {
      handleToggleBlocking(false);
    }
  };

  const handleToggleBlocking = (value) => {
    setBlocking(value);
  };

  const handleNewAddress = () => {
    setShowModal("newaddress");
  };
  const handleAddItemList = (newValue) => {
    setItemList([...ItemList, newValue]);
  };

  const handleEditClick = (rowData) => {
    setSelectedRowData(rowData);
    setShowModal("edit");
  };
  const handleDeleteClick = (rowData) => {
    setSelectedRowData(rowData);
    setShowModal("delete");
  };

  const handleResetForm = () => {
    reset();
    setSupplierName("");
    setCreditorCode("");
    setSupplierEmail("");
    setSupplierStatus("A");
    setItemList([]);
    setPaymentTerm([]);
  };

  const handleEditItemList = (newValue) => {
    console.log("newValue");
    console.log(newValue);
    const updatedItems = ItemList.map((item) => {
      if (item.ITEM_ID === newValue.ITEM_ID) {
        return newValue;
      }
      return item; 
    });
    setItemList(updatedItems);
  };

  const handlePaymentTermChange = (selectedTerm, isChecked) => {
    if (isChecked) {
      setPaymentTerm([...PaymentTerm, selectedTerm]);
    } else {
      setPaymentTerm(PaymentTerm.filter(term => term !== selectedTerm));
    }
    console.log("Selected Payment Term:", PaymentTerm);
  };
  const handleDeleteItemList = (newValue) => {
    const updatedItems = ItemList.filter(
      (item) => item.ITEM_ID !== newValue.ITEM_ID
    );

    setItemList(updatedItems);
  };
  

  return (
    <Modal show={true} onHide={onClose} size="xl" backdrop="static" centered>
      <BlockUi blocking={blocking}>
        <Form onSubmit={handleSubmit(submitNewSupplier)}>
          <Modal.Header closeButton className="px-4">
            <Modal.Title className="ms-auto">New Supplier</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="mb-2 justify-content-center">
              <Col md="8">
                <Form.Group as={Row} controlId="SupplierName">
                  <Form.Label column sm="4" className="fw-bold">
                    Supplier Name :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      type="text"
                      placeholder="Supplier Name"
                      value={SupplierName}
                      maxLength={250}
                      onInput={(e) => setSupplierName(e.target.value)}
                      className={`form-control ${
                        errors.SupplierName ? "is-invalid" : ""
                      }`}
                      {...register("SupplierName", {
                        pattern: {
                          value: /^.{3,}$/,
                          message: "Minimum 3 Character",
                        },
                        validate: (value) => {
                          return value !== "" || "This field is required";
                        },
                      })}
                    />
                    {errors.SupplierName && (
                      <p className="text-danger">
                        {errors.SupplierName.message}
                      </p>
                    )}
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-2 justify-content-center">
              <Col md="8">
                <Form.Group as={Row} controlId="SupplierEmail">
                  <Form.Label column sm="4" className="fw-bold">
                    Supplier Email :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      type="email"
                      placeholder="Supplier Email"
                      value={SupplierEmail}
                      maxLength={100}
                      onInput={(e) => setSupplierEmail(e.target.value)}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-2 justify-content-center">
              <Col md="8">
                <Form.Group as={Row} controlId="CreditorCode">
                  <Form.Label column sm="4" className="fw-bold">
                    Creditor Code :
                  </Form.Label>
                  <Col sm="5">
                    <Form.Control
                      type="text"
                      placeholder="Creditor Code"
                      value={CreditorCode}
                      maxLength={20}
                      onInput={(e) => setCreditorCode(e.target.value)}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-2 justify-content-center">
              <Col md="8">
                <Form.Group as={Row} controlId="SupplierStatus">
                  <Form.Label column sm="4" className="fw-bold">
                    Status :
                  </Form.Label>
                  <Col sm="8">
                    {/* <Form.Control type="text" placeholder="" value={Status} onInput={(e) => setStatus(e.target.value)} /> */}
                    {/* <Form.Control as="select" value={Status} onChange={(e) => setStatus(e.target.value)}>
                            <option value="">Please Select..</option>
                            <option value="A">Active</option>
                            <option value="I">Inactive</option>
                        </Form.Control> */}
                    <Form.Check
                      inline
                      type="radio"
                      label="Active"
                      name="statusRadios"
                      id="statusRadios1"
                      checked={SupplierStatus === "A"}
                      onChange={() => setSupplierStatus("A")}
                    />
                    <Form.Check
                      inline
                      type="radio"
                      label="Inactive"
                      name="statusRadios"
                      id="statusRadios2"
                      checked={SupplierStatus === "I"} 
                      onChange={() => setSupplierStatus("I")} 
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <hr />
            <Row className="mb-4 align-items-center">
              <Tabs defaultActiveKey="address" id="uncontrolled-tab-example" className="justify-content-center">
                <Tab eventKey="address" title="Addresses">
                  <Row className="mb-4 justify-content-end">
                    <Col sm="3">
                      <Button className="me-2"  variant="secondary" onClick={handleNewAddress}>
                        Add Address
                      </Button>
                    </Col>
                  </Row>
                  <SuppliersAddressTable
                    itemList={ItemList}
                    onEditClick={handleEditClick}
                    onDeleteClick={handleDeleteClick}
                    fromEditModal={false} />
                </Tab>
                {PaymentTermList.length > 0 && (
                  <Tab eventKey="paymentTerms" title="Payment Terms">
                  <Row className="mt-3 justify-content-center">
                    <Col md="8">
                      <Form as={Row}>
                        {PaymentTermList.map((items) =>
                            <Col sm="6" className="mb-2" key={items.PAYMENT_TERM}>
                              <Form.Check
                                inline
                                label={items.PAYMENT_TERM_DESC}
                                name="paymentTerms"
                                checked={PaymentTerm.includes(items.PAYMENT_TERM)}
                                // checked={ PaymentTerm === items.PAYMENT_TERM}
                                onChange={(e) => {
                                  // handlePaymentTermChange(items.PAYMENT_TERM)
                                  // setPaymentTerm(items.PAYMENT_TERM);
                                  handlePaymentTermChange(items.PAYMENT_TERM, e.target.checked);
                                }}
                                id={items.PAYMENT_TERM}
                              />
                            </Col>
                        )}
                      </Form> 
                    </Col>
                  </Row>
                </Tab>
                )}
              </Tabs>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={onClose} variant="secondary">
              Close
            </Button>
            <Button
              type="submit"
              variant="primary"
            >
              Save
            </Button>
          </Modal.Footer>
        </Form>
        {showModal === "newaddress" ? (
          <SuppliersNewAddressModal
            handleAddItemList={handleAddItemList}
            handleCloseAlert={handleCloseAlert}
            handleShowAlert={handleShowAlert}
            itemList={ItemList}
            rowData={selectedRowData}
            onClose={() => setShowModal(false)}
          />
        ) : ("")}
        {showModal === "edit" ? (
          <SuppliersEditAddressModal
            handleEditItemList={handleEditItemList}
            handleShowAlert={handleShowAlert}
            itemList={ItemList}
            rowData={selectedRowData}
            onClose={() => setShowModal(false)}
          />
        ) : ("")}
        {showModal === "delete" ? (
          <DeleteAddressModal
            handleDeleteItemList={handleDeleteItemList}
            handleShowAlert={handleShowAlert}
            rowData={selectedRowData}
            onClose={() => setShowModal(false)}
          />
        ) : ("")}
      </BlockUi>
    </Modal>
  );
}

export default SuppliersNewSupplierModal;
