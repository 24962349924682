import React, { useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Pagination from 'react-bootstrap/Pagination';
import { clearUserNameAndTokenInLocalStorage, getTokenFromLocalStorage } from '../../lib/common';
import { useNavigate } from "react-router-dom";
const config = JSON.parse(process.env.REACT_APP_CONFIG);

function SuppliersTable(props) {
  const [data, setData] = useState([]);
  const [headerData] = useState([
    "Supplier Name", 
    "Email",
    "Creditor Code",
    "Status", 
    "Action"
  ]);
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const { onEditClick } = props;
  const { searchProps } = props;
  const { showAlert} = props;
  const ROWS_PER_PAGE = 10;
  const totalPages = Math.ceil(data.length / ROWS_PER_PAGE);

  useEffect(() => {
    if (searchProps.SupplierName){
      getData(config.supplier_list_link, searchProps);
    } 
    else if(searchProps.SupplierEmail){
      getData(config.supplier_list_link, searchProps);
    }
    else if(searchProps.CreditorCode){
      getData(config.supplier_list_link, searchProps);
    }
    else {
      getData(config.supplier_list_link);
    }
  }, [searchProps, showAlert]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleToggleBlocking = () => {
    props.onChildToggleChange(false);
  };

  const renderTableData = () => {
    const startIndex = (currentPage - 1) * ROWS_PER_PAGE;
    const endIndex = startIndex + ROWS_PER_PAGE;
    return data.slice(startIndex, endIndex).map((row, index) => {
      const { 
        SUPPLIER_ID, 
        SUPPLIER_NAME, 
        SUPPLIER_EMAIL,
        CREDITOR_CODE,
        SUPPLIER_STATUS
      } = row;
      return (
        <tr key={`${index}-${SUPPLIER_ID}`}>
          <td>{SUPPLIER_NAME}</td>
          <td>{SUPPLIER_EMAIL}</td>
          <td>{CREDITOR_CODE}</td>
          <td>{SUPPLIER_STATUS === "A" ? "Active" : "Inactive"}</td>
          <td style={{width:"100px"}}>
              <Button variant="primary" 
                onClick={() => onEditClick(row)}>
                  Edit
              </Button>
            </td>
        </tr>
      );
    });
  };

  const renderPageItems = () => {
    const pageItems = [];
    for (let i = 1; i <= totalPages; i++) {
      pageItems.push(
        <Pagination.Item key={i} active={i === currentPage} onClick={() => handlePageChange(i)}>
          {i}
        </Pagination.Item>
      );
    }
    return pageItems;
  };

  const getData = async (url, params = {}) => {
    await fetch(url,{
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        "Authorization" : "Bearer " + getTokenFromLocalStorage()
      },
      body : JSON.stringify(params.length === 0 ? params : {
        supplier_name : params.SupplierName,
        supplier_email : params.SupplierEmail,
        creditor_code : params.CreditorCode,
      })
    })
    .then(response => response.json())
    .then (jsonData => {
      handleToggleBlocking();
      if (jsonData.success !== true && jsonData.message === 'Access Denied! Invalid token'){
        clearUserNameAndTokenInLocalStorage();
        const state = { data: "Token expired, please login again" };
        navigate("/login", { state });
        throw new Error (jsonData.message);
      } else if (jsonData.success){
        return jsonData.data;
      } else {
        throw new Error (jsonData.message);
      }
    })
    .then(data => setData(data))
    .catch(error => console.error(error));
  }

  if (data.length<1){
    return(
      <div className="text-center"><h3>No Data</h3></div>
    )
  } else {
    return (
      <div>
        <Table striped bordered hover className="mb-4">
          <thead>
            <tr>{headerData.map((header) => <th key={header}>{header}</th>)}</tr>
          </thead>
          <tbody>{renderTableData()}</tbody>
        </Table>
        <Pagination className="d-flex justify-content-center" >{renderPageItems()}</Pagination>
      </div>
    );
  }

}

export default SuppliersTable;