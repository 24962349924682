import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Pagination from "react-bootstrap/Pagination";
import moment from "moment/moment";
import {
  clearUserNameAndTokenInLocalStorage,
  getTokenFromLocalStorage,
} from "../../lib/common";

const config = JSON.parse(process.env.REACT_APP_CONFIG);

function SLEManageInvoiceTable(props) {
  const [data, setData] = useState([]);
  const [headerData] = useState([
    "Inv Number",
    "Inv Date",
    "SO Number",
    "DO Number",
    "Order Type",
    "Customer",
    "Project",
    "Action",
  ]);
  const [currentPage, setCurrentPage] = useState(1);
  const { onViewClick } = props;
  const { onNewClick } = props;
//   const { onCancelClick } = props;
//   const { onDeleteClick } = props;
//   const { onViewClick } = props;
  const { onEditClick } = props;
  const { searchProps } = props;
  const { showAlert } = props;
  const ROWS_PER_PAGE = 10;
  const totalPages = Math.ceil(data.length / ROWS_PER_PAGE);

  useEffect(() => {
    if (
      searchProps.SleInvoiceNum ||
      searchProps.SleSoNum ||
      searchProps.SleDoNum ||
      searchProps.SleInvoiceDateFrom ||
      searchProps.SleInvoiceDateTo ||
      searchProps.SleSoDateFrom ||
      searchProps.SleSoDateTo ||
      searchProps.SleDoDateFrom ||
      searchProps.SleDoDateTo ||
      searchProps.CustomerName ||
      searchProps.ProjectName
    ) {
      getData(config.invoice_list_link, searchProps);
    } else {
      getData(config.invoice_list_link);
    }
  }, [searchProps, showAlert]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleToggleBlocking = () => {
    props.onChildToggleChange(false);
  };

  const renderTableData = () => {
    const startIndex = (currentPage - 1) * ROWS_PER_PAGE;
    const endIndex = startIndex + ROWS_PER_PAGE;
    return data.slice(startIndex, endIndex).map((row) => {
      const {
        INVOICE_NUMBER,
        INVOICE_DATE,
        DO_NUMBER,
        SO_TYPE_DESC,
        SO_NUMBER,
        CUSTOMER_NAME,
        PROJECT_NAME
      } = row;
      return (
        <tr key={INVOICE_NUMBER}>
          <td>{INVOICE_NUMBER}</td>
          <td>{moment(INVOICE_DATE).format("DD MMM yyyy")}</td>
          <td>{SO_NUMBER}</td>
          <td>{DO_NUMBER}</td>
          <td>{SO_TYPE_DESC}</td>
          <td>{CUSTOMER_NAME}</td>
          <td>{PROJECT_NAME}</td>
          <td style={{ width: "210px" }}>
            <Button
              variant="primary"
              // onClick={() => onViewClick(row)}
            >
              Cancel
            </Button>
          </td>
        </tr>
      );
    });
  };

  const renderPageItems = () => {
    const pageItems = [];
    for (let i = 1; i <= totalPages; i++) {
      pageItems.push(
        <Pagination.Item
          key={i}
          active={i === currentPage}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </Pagination.Item>
      );
    }
    return pageItems;
  };

  const getData = async (url, params = {}) => {
    await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getTokenFromLocalStorage(),
      },
      body: JSON.stringify(
        params.length === 0
          ? params
          : {
              invoice_number: params.SleInvoiceNum,
              so_number: params.SleSoNum,
              // po_number: params.SlePoNum,
              inv_date_from: params.SleInvoiceDateFrom,
              inv_date_to: params.SleInvoiceDateTo,
              so_date_from: params.SleSoDateFrom,
              so_date_to: params.SleSoDateTo,
              do_date_from: params.SleDoDateFrom,
              do_date_to: params.SleDoDateTo,
              customer_id: params.CustomerName,
              project_id: params.ProjectName,
            }
      ),
    })
      .then((response) => response.json())
      .then((jsonData) => {
        handleToggleBlocking();
        console.log(jsonData);
        if (jsonData.message === "Access Denied! Invalid token") {
          clearUserNameAndTokenInLocalStorage();
          throw new Error(jsonData.message);
        }
        const newData = jsonData.data.map((item) => {
          const date = moment(item.SO_DATE).format("DD MMM yyyy");
          item.SO_DATE = date;
          return item;
        });
        return newData;
      })
      .then((data) => setData(data))
      .catch((error) => console.error(error));
  };

  if (data.length < 1) {
    return (
      <div className="text-center">
        <h3>No Data</h3>
      </div>
    );
  } else {
    return (
      <div>
        <Table striped bordered hover className="mb-4">
          <thead>
            <tr>
              {headerData.map((header) => (
                <th key={header}>{header}</th>
              ))}
            </tr>
          </thead>
          <tbody>{renderTableData()}</tbody>
        </Table>
        <Pagination className="d-flex justify-content-center">
          {renderPageItems()}
        </Pagination>
      </div>
    );
  }
}

export default SLEManageInvoiceTable;
