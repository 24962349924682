import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Accordion from "react-bootstrap/Accordion";
import SearchDeliveryOrderViewModalTable from "./SearchDeliveryOrder_ViewModalTable";
// import ManageBulkOrderMemoHistoryDeleteConfirmationModal from "./ManageBulkOrderMemo_DeleteConfirmationModal";
import DatePicker from "react-datepicker";
import moment from "moment/moment";
import { useNavigate } from "react-router-dom";
import {
  clearUserNameAndTokenInLocalStorage,
  getUserNameFromLocalStorage,
  getTokenFromLocalStorage,
} from "../../lib/common";
import BlockUi from "@availity/block-ui";

const config = JSON.parse(process.env.REACT_APP_CONFIG);

function SearchDeliveryOrderViewModal(props) {
  const {
    rowData,
    selectedMemoNum,
    onClose,
    handleShowFollowUp,
    handleShowDeleteConfirmation,
    handleShowAlert,
    handleCloseAlert,
    setTrigger,
    setRefreshHistoryTrigger,
  } = props;
  const [SleSoNum] = useState(rowData.SO_NUMBER);
  // const [SlePoNum, setSlePoNum] = useState(rowData.PO_NUMBER !== null ? rowData.PO_NUMBER : "");
  const [SleMemoNum, setSleMemoNum] = useState(selectedMemoNum);
  const [SleDoNum, setSleDoNum] = useState(rowData.DO_NUMBER);
  const [CustomerId] = useState(rowData.CUSTOMER_ID);
  const [CustomerName, setCustomerName] = useState(rowData.CUSTOMER_NAME);
  const [ProjectId] = useState(rowData.PROJECT_ID);
  const [ProjectName, setProjectName] = useState(rowData.PROJECT_NAME);
  const [PaymentTerm] = useState(rowData.PAYMENT_TERM);
  const [SleSoDate] = useState(rowData.SO_DATE ? new Date(rowData.SO_DATE) : "");
  const [SleDoDate] = useState(rowData.DO_DATE ? new Date(rowData.DO_DATE) : "");
  // const [SlePoDate, setSlePoDate] = useState("");
  // const [SlePoDate, setSlePoDate] = useState(rowData.PO_DATE ? new Date(rowData.PO_DATE) : "");
  const [ScheduleDate, setScheduleDate] = useState(rowData.SCHEDULE_DELIVERY_DATE ? new Date(rowData.SCHEDULE_DELIVERY_DATE) : "");
//   const [SlePoDate, setSlePoDate] = useState(new Date(rowData.PO_DATE));
  // const [PoDate, setPoDate] = useState("");
  // const [PoDate, setPoDate] = useState(new Date());
  const [isIncludeSleDo, setIsIncludeSleDo] = useState(false);
  const [OrderType] = useState(rowData.SO_TYPE_DESC);
  const [Remarks, setRemarks] = useState("");
  const [blocking, setBlocking] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [textInputs, setTextInputs] = useState({});
  const [selectedData, setSelectedData] = useState([]);
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);
  const [refreshTrigger, setRefreshTrigger] = useState("");
  const navigate = useNavigate();
  const handleIncludeSleDoCheckbox = (event) => {
    setIsIncludeSleDo(event.target.checked);
  };
  const handleToggleBlocking = () => {
    setBlocking((prevState) => !prevState);
  };

  const handleToggleFromChild = (newValue) => {
    setBlocking(newValue);
  };

  const handleDeleteClick = () => {
    setShowDeleteConfirmationModal(true);
  };

  const handleUpdateMemo = async (event) => {
    // event.preventDefault();
    try {
      handleToggleBlocking();
      await updateMemo();
      console.log("Success updating Memo");
      onClose();
      handleShowAlert("ok", "Update Memo", "Success updating Memo");
    } catch (error) {
      handleToggleBlocking();
      console.error("Error while updating Memo:", error);
      handleShowAlert(
        "error",
        "Update Memo",
        "Error while updating Memo: ",
        error.join("<br>")
      );
      return;
    }
  };

  const updateMemo = async () => {
    try {
      var item_qtis = Object.values(textInputs);
      console.log({
        item_id: selectedRows,
        new_item_qty: item_qtis.map((item_qty) => parseInt(item_qty)),
      });
      const response = await fetch(config.memo_item_update_link, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + getTokenFromLocalStorage(),
        },
        body: JSON.stringify({
          item_id: selectedRows,
          new_item_qty: item_qtis.map((item_qty) => parseInt(item_qty)),
        }),
      });

      const data = await response.json();
      console.log("update memo response : ");
      console.log(data);
      if (
        data.success !== true &&
        data.message === "Access Denied! Invalid token"
      ) {
        clearUserNameAndTokenInLocalStorage();
      }

      if (data.success !== true) {
        var errorMessages = data.error.map((item) => item.msg);
        // handleShowAlert("error", "New Invoice", "Failed Saving New Invoice :", errorMessages.join("<br>"));
        throw errorMessages;
      }
      setTrigger(moment().unix());
      if (data.success === true) {
        console.log("Success Updating Memo");
        // handleTrigger(moment().unix());
        return "ok";
      } else {
        throw data;
      }
    } catch (error) {
      console.error("Error while Updating Memo:", error);
      throw error;
    }
  };

  const toggleRowSelection = (rowId) => {
    if (selectedRows.includes(rowId)) {
      setSelectedRows(selectedRows.filter((id) => id !== rowId));
    } else {
      setSelectedRows([...selectedRows, rowId]);
    }
  };

  const handleInputChange = (rowId, value) => {
    setTextInputs({ ...textInputs, [rowId]: value });
  };

  const handleSaveSelectedData = (event) => {
    // event.preventDefault();
    var item_qtis = Object.values(textInputs);
    var dataSend = {
      product_id: selectedRows,
      item_qty: item_qtis.map((item_qty) => parseInt(item_qty)),
    };

    // You can perform additional actions with the selected data here
  };

  return (
    <Modal
      show={true}
      onHide={onClose}
      size="xl"
      backdrop="static"
      style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
      centered
    >
      <BlockUi blocking={blocking}>
        {/* <Form onSubmit={handleUpdateMemo}> */}
        <Modal.Header closeButton className="px-4">
          <Modal.Title className="ms-auto">View Delivery Order</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Row className="mb-2 justify-content-center" style={{marginLeft : "120px"}}>
            <Col md="6">
              <Form.Group as={Row} controlId="SLEDONumber">
                <Form.Label column sm="4" className="fw-bold">
                  SLE DO Number :
                </Form.Label>
                <Col sm="6">
                  <Form.Control
                    readOnly
                    className="no-border"
                    type="text"
                    placeholder=""
                    value={SleDoNum}
                  />
                </Col>
              </Form.Group>
            </Col>

            <Col md="6">
              <Form.Group as={Row} controlId="SLEDODate">
                <Form.Label column sm="4" className="fw-bold">
                  SLE DO Date :
                </Form.Label>
                <Col sm="6">
                  <DatePicker
                    readOnly
                    dateFormat="dd/MM/yyyy"
                    className="form-control no-border"
                    showIcon
                    selected={SleDoDate}
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-2 justify-content-center" style={{marginLeft : "120px"}}>
            <Col md="6">
              <Form.Group as={Row} controlId="SLESONumber">
                <Form.Label column sm="4" className="fw-bold">
                  SLE SO Number :
                </Form.Label>
                <Col sm="6">
                  <Form.Control
                    readOnly
                    className="no-border"
                    type="text"
                    placeholder=""
                    value={SleSoNum}
                  />
                </Col>
              </Form.Group>
            </Col>

            <Col md="6">
              <Form.Group as={Row} controlId="SLESODate">
                <Form.Label column sm="4" className="fw-bold">
                  SLE SO Date :
                </Form.Label>
                <Col sm="6">
                  <DatePicker
                    readOnly
                    dateFormat="dd/MM/yyyy"
                    className="form-control no-border"
                    showIcon
                    selected={SleSoDate}
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>
          {/* <Row className="mb-2 justify-content-center">
            <Col md="6">
              <Form.Group as={Row} controlId="SLESONumber">
                <Form.Label column sm="6" className="fw-bold">
                  SLE PO Number :
                </Form.Label>
                <Col sm="6">
                  <Form.Control
                    readOnly
                    className="no-border"
                    type="text"
                    placeholder=""
                    value={SlePoNum}
                  />
                </Col>
              </Form.Group>
            </Col>

            <Col md="6">
              <Form.Group as={Row} controlId="SLESODate">
                <Form.Label column sm="6" className="fw-bold">
                  SLE PO Date :
                </Form.Label>
                <Col sm="6">
                  <DatePicker
                    readOnly
                    dateFormat="dd/MM/yyyy"
                    className="form-control no-border"
                    showIcon
                    selected={SlePoDate}
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row> */}
          <Row className="mb-2" style={{marginLeft : "120px"}}>
            <Col md="6">
              <Form.Group as={Row} controlId="OrderType">
                <Form.Label column sm="4" className="fw-bold">
                  Order Type :
                </Form.Label>
                <Col sm="6">
                  <Form.Control
                    readOnly
                    className="no-border"
                    type="text"
                    placeholder=""
                    value={OrderType}
                  />
                </Col>
              </Form.Group>
            </Col>

            <Col md="6">
              <Form.Group as={Row} controlId="OrderType">
                <Form.Label column sm="4" className="fw-bold">
                  Payment Term :
                </Form.Label>
                <Col sm="6">
                  <Form.Control
                    readOnly
                    className="no-border"
                    type="text"
                    placeholder=""
                    value={PaymentTerm}
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-2" style={{marginLeft : "120px"}}>
            <Col md="6">
              <Form.Group as={Row} controlId="SLESONumber">
                <Form.Label column sm="4" className="fw-bold">
                  Customer Name :
                </Form.Label>
                <Col sm="6">
                  <Form.Control
                    readOnly
                    className="no-border"
                    type="text"
                    placeholder=""
                    value={CustomerName}
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-2" style={{marginLeft : "120px"}}>
            <Col md="6">
              <Form.Group as={Row} controlId="SLESODate">
                <Form.Label column sm="4" className="fw-bold">
                  Project Name :
                </Form.Label>
                <Col sm="6">
                  <Form.Control
                    readOnly
                    className="no-border"
                    type="text"
                    placeholder=""
                    value={ProjectName}
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <hr />
          <Row className="mb-2" style={{marginLeft : "120px"}}>
            <Col md="6">
              <Form.Group as={Row} controlId="OrderType">
                <Form.Label column sm="5" className="fw-bold">
                  Schedule Delivery Date :
                </Form.Label>
                <Col sm="6">
                <DatePicker
                    readOnly
                    dateFormat="dd/MM/yyyy"
                    className="form-control no-border"
                    showIcon
                    selected={ScheduleDate}
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <hr />
          <Row className="mb-1 align-items-center">
            <Col sm="12" className="text-center">
              <Form.Label>[ Delivery Item ]</Form.Label>
            </Col>
          </Row>
          <SearchDeliveryOrderViewModalTable
            rowData={rowData}
            selectedMemoNum={selectedMemoNum}
            onChildToggleChange={handleToggleFromChild}
            onRowSelect={toggleRowSelection}
            onInputChange={handleInputChange}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            textInputs={textInputs}
            setTextInputs={setTextInputs}
            toggleRowSelection={toggleRowSelection}
            refreshTrigger={refreshTrigger}
          />
        </Modal.Body>
        <Modal.Footer>
          {/* <Button onClick={() => handleShowDeleteConfirmation()} variant="primary">
            Cancel Delivery Order
          </Button> */}
          <Button
            type="submit"
            variant="primary"
            onClick={() => handleShowFollowUp()}
          >
            Follow Up
          </Button>
          <Button onClick={onClose} variant="secondary">
            Cancel
          </Button>
        </Modal.Footer>
        {/* {showDeleteConfirmationModal === true ? (
          <ManageBulkOrderMemoHistoryDeleteConfirmationModal
            handleCloseAlert={handleCloseAlert}
            handleShowAlert={handleShowAlert}
            selectedRows={selectedRows}
            setRefreshTrigger={setRefreshTrigger}
            onClose={() => {
              setShowDeleteConfirmationModal(false);
              setRefreshHistoryTrigger(moment().unix());
            }}
          />
        ) : (
          ""
        )} */}
      </BlockUi>
    </Modal>
  );
}

export default SearchDeliveryOrderViewModal;
