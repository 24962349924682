import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import DatePicker from "react-datepicker";
import BlockUi from "@availity/block-ui";
import ManagePIViewModalTable from "./ManagePI_ViewModalTable";
import {
  clearUserNameAndTokenInLocalStorage,
  getTokenFromLocalStorage,
} from "../../lib/common";
import { useNavigate } from "react-router-dom";

const config = JSON.parse(process.env.REACT_APP_CONFIG);

function ManagePIViewModal(props) {
  const navigate = useNavigate();
  const { rowData, onClose, handleShowAlert } = props;
  const [blocking, setBlocking] = useState(false);
  const [selectedRowData] = useState({});
  const [ItemList, setItemList] = useState("");

  const [PiNumber] = useState(rowData.PI_NUMBER);
  const [PiDate] = useState(rowData.PI_DATE ? new Date(rowData.PI_DATE) : '');
  const [SelInvNumber] = useState(rowData.SUPPLIER_INV_NUMBER);
  const [SelInvNumberDate] = useState(rowData.SUPPLIER_INV_DATE ? new Date(rowData.SUPPLIER_INV_DATE) : '');
  const [SupplierName] = useState(rowData.SUPPLIER_NAME);

  useEffect(() => {
    getData(config.pi_get_link, rowData);
  }, [rowData]);

  const getData = async (url, params = {}) => {
    await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getTokenFromLocalStorage(),
      },
      body: JSON.stringify({
        pi_number: params.PI_NUMBER,
      }),
    })
      .then((response) => response.json())
      .then((jsonData) => {
        console.log(jsonData);
        setBlocking(false);
        if (
          jsonData.success !== true &&
          jsonData.message === "Access Denied! Invalid token"
        ) {
          clearUserNameAndTokenInLocalStorage();
          const state = { data: "Token expired, please login again" };
          navigate("/login", { state });
          throw new Error(jsonData.message);
        } else if (jsonData.success && jsonData.data.PURCHASE_INVOICE_ITEMS) {
          const newData = jsonData.data.PURCHASE_INVOICE_ITEMS.map((item) => {
            return item;
          });
          return newData;
        } else {
          setItemList([]);
          throw new Error(jsonData.message);
        }
      })
      .then((data) => {
        setItemList(data);
      })
      .catch((error) => console.error(error));
  };


  return (
    <Modal show={true} onHide={onClose} size="xl" backdrop="static" centered>
      <BlockUi blocking={blocking}>
        <Form>
          <Modal.Header closeButton className="px-4">
            <Modal.Title className="ms-auto">View Purchase Invoice</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="d-flex align-items-center justify-content-center">
              <Col md={10}>
                <Row className="mb-2">
                  <Col md="7">
                    <Form.Group as={Row} controlId="PiNumber">
                      <Form.Label column sm="4" className="fw-bold">
                        PI Number :
                      </Form.Label>
                      <Col sm="6">
                        <Form.Control
                          disabled
                          type="text"
                          placeholder=""
                          value={PiNumber}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col md="5">
                      <Form.Group as={Row} controlId="PiDate">
                      <Form.Label column sm="6" className="fw-bold">
                          PI Date :
                      </Form.Label>
                      <Col sm="6">
                          <DatePicker
                            disabled
                            dateFormat="dd MMM yyyy"
                            className="form-control"
                            showIcon
                            selected={PiDate}
                          />
                      </Col>
                      </Form.Group>
                  </Col>
                </Row>

                <Row className="mb-2">
                  <Col md="7">
                    <Form.Group as={Row} controlId="SelInvNumber">
                      <Form.Label column  sm="4" className="fw-bold" >
                        SLE Inv Number :
                      </Form.Label>
                      <Col sm="6">
                        <Form.Control
                          disabled
                          type="text"
                          placeholder=""
                          value={SelInvNumber}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col md="5">
                        <Form.Group as={Row} controlId="SelInvNumberDate">
                        <Form.Label column sm="6" className="fw-bold">
                          SLE Inv Date :
                        </Form.Label>
                        <Col sm="6">
                            <DatePicker
                              disabled
                              dateFormat="dd MMM yyyy"
                              className="form-control"
                              showIcon
                              selected={SelInvNumberDate}
                            />
                        </Col>
                        </Form.Group>
                    </Col>
                </Row>

                <Row className="mb-2">
                <Col md="7">
                    <Form.Group as={Row} controlId="SupplierName">
                      <Form.Label column sm="4" className="fw-bold" >
                        Supplier :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          disabled
                          type="text"
                          placeholder=""
                          value={SupplierName}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
            </Row>
            <hr />
            <Row className="mb-4 align-items-center">
								<Col className="text-center">
									<Form.Label>[ GRN ]</Form.Label>
                    <ManagePIViewModalTable
                      ItemList={ItemList}
                      handleShowAlert={handleShowAlert}
                      rowData={selectedRowData}
                    />
								</Col>
						</Row>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={onClose} variant="secondary">
              Close
            </Button>
          </Modal.Footer>
        </Form>
      </BlockUi>
      <style>
          {`
            .form-control:disabled {
              background: #F8F8F8 !important;
            }
          `}
      </style>
    </Modal>
  );
}

export default ManagePIViewModal;
