import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import DatePicker from "react-datepicker";
import moment from "moment/moment";
import { useNavigate } from "react-router-dom";
import RestockTable from "./ManageSO_RestockTable";
import {
  clearUserNameAndTokenInLocalStorage,
  getUserNameFromLocalStorage,
  getTokenFromLocalStorage,
} from "../../lib/common";
import BlockUi from "@availity/block-ui";

const config = JSON.parse(process.env.REACT_APP_CONFIG);

function ManageSoRestockModal(props) {
  const { rowData, onClose, handleShowAlert, handleCloseAlert, trigger } =
    props;
  // const [SlePoNum, setSlePoNum] = useState("");
  // const [SleMemoNum, setSleMemoNum] = useState("");
  // const [SleDoNum, setSleDoNum] = useState("");
  const [SleSoNum] = useState(rowData.SO_NUMBER);
  const [RestockList, setRestockList] = useState([]);
  const [CustomerId] = useState(rowData.CUSTOMER_ID);
  const [CustomerName, setCustomerName] = useState("");
  const [ProjectId] = useState(rowData.PROJECT_ID);
  const [ProjectName, setProjectName] = useState("");
  const [OrderStatus] = useState(rowData.SO_STATUS);
  const [SleSoDate] = useState(new Date(rowData.SO_DATE));
  // const [SlePoDate, setSlePoDate] = useState(new Date());
  // const [SleMemoDate, setSleMemoDate] = useState(new Date());
  // const [SleDoDate, setSleDoDate] = useState(new Date());
  // const [isIncludeSleDo, setIsIncludeSleDo] = useState(false);
  const [OrderType] = useState(rowData.SO_TYPE_DESC);
  const [Trigger, setTrigger] = useState(trigger);
  const [blocking, setBlocking] = useState(false);
  const navigate = useNavigate();

  // const handleIncludeSleDoCheckbox = (event) => {
  //   setIsIncludeSleDo(event.target.checked);
  // };
  const handleToggleBlocking = () => {
    setBlocking((prevState) => !prevState);
  };

  const handleToggleFromChild = (newValue) => {
    setBlocking(newValue);
  };



  // useEffect(() => {
  //   if (CustomerId !== null) {
  //     fetch(config.customer_get_link, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: "Bearer " + getTokenFromLocalStorage(),
  //       },
  //       body: JSON.stringify({
  //         customer_id: CustomerId
  //       }),
  //     })
  //       .then(response => response.json())
  //       .then(jsonData => {
  //         console.log(jsonData)
  //         if (jsonData.success !== true && jsonData.message === 'Access Denied! Invalid token') {
  //           clearUserNameAndTokenInLocalStorage();
  //           throw new Error(jsonData.message);
  //         } else if (jsonData.success) {
  //           return jsonData.data;
  //         } else {
  //           throw new Error(jsonData.message);
  //         }
  //       })
  //       .then(data => setCustomerName(data.CUSTOMER_NAME))
  //       .catch(error => console.error(error));

  //   }
  // }, [CustomerId]);

  // useEffect(() => {
  //   if (ProjectId !== null) {
  //     console.log("projectid : " + ProjectId)
  //     fetch(config.project_get_link, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: "Bearer " + getTokenFromLocalStorage(),
  //       },
  //       body: JSON.stringify({
  //         project_id: ProjectId
  //       }),
  //     })
  //       .then(response => response.json())
  //       .then(jsonData => {
  //         console.log(jsonData)
  //         if (jsonData.success !== true && jsonData.message === 'Access Denied! Invalid token') {
  //           clearUserNameAndTokenInLocalStorage();
  //           throw new Error(jsonData.message);
  //         } else if (jsonData.success) {
  //           return jsonData.data;
  //         } else {
  //           throw new Error(jsonData.message);
  //         }
  //       })
  //       .then(data => setProjectName(data.PROJECT_NAME))
  //       .catch(error => console.error(error));
  //   }
  // }, [ProjectId]);

  useEffect(() => {
    if (SleSoNum !== null) {
      handleToggleBlocking();
      fetch(config.restock_get_link, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + getTokenFromLocalStorage(),
        },
        body: JSON.stringify({
          so_number: SleSoNum,
        }),
      })
        .then((response) => response.json())
        .then((jsonData) => {
          console.log(jsonData);
          if (
            jsonData.success !== true &&
            jsonData.message === "Access Denied! Invalid token"
          ) {
            clearUserNameAndTokenInLocalStorage();
            throw new Error(jsonData.message);
          } else if (jsonData.success) {
            return jsonData.data;
          } else {
            throw new Error(jsonData.message);
          }
        })
        .then((data) => {
          setRestockList(data[0].ORDER_ITEMS);
          setCustomerName(data[0].CUSTOMER_NAME);
          setProjectName(data[0].PROJECT_NAME);
          handleToggleBlocking();
        })
        .catch((error) => console.error(error));
    }
  }, [SleSoNum, Trigger]);

  // const handleSavePO = async (event) => {
  //   event.preventDefault();

  //   try {
  //     if (isIncludeSleDo === true && SleDoNum === "") {
  //       handleToggleBlocking();
  //       handleShowAlert("error", "New Purchase Order", "Error while saving PO: ", "DO Number must not be empty when 'Deliver with SLE DO?' is checked");
  //       return;
  //     }

  //     if (rowData.SO_TYPE === "B") {
  //       await submitMemo();
  //     }

  //     await submitPO();

  //     if (isIncludeSleDo === true && SleDoNum !== "") {
  //       await submitDO();
  //     }
  //     handleToggleBlocking();
  //     console.log("Success saving PO");
  //     onClose();
  //     handleShowAlert("ok", "New Purchase Order", "Success saving PO");
  //   } catch (error) {
  //     handleToggleBlocking();
  //     console.error("Error while saving PO:", error);
  //     handleShowAlert("error", "New Purchase Order", "Error while saving PO: ", error.join("<br>"));
  //     return;
  //   }
  // };

  // const submitMemo = async () => {
  //   try {
  //     const response = await fetch(config.memo_create_link, {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //         "Authorization": "Bearer " + getTokenFromLocalStorage()
  //       },
  //       body: JSON.stringify({
  //         memo_number: SleMemoNum,
  //         memo_date: moment(SleMemoDate).format("YYYY-MM-DD"),
  //         user_modify: getUserNameFromLocalStorage()
  //       })
  //     });

  //     const contentType = response.headers.get('content-type');
  //     const data = contentType && contentType.includes('application/json')
  //       ? await response.json()
  //       : await response.text();

  //     if (data.message === 'Access Denied! Invalid token') {
  //       clearUserNameAndTokenInLocalStorage();
  //       throw ([data.message]);
  //     } else if (data.message) {
  //       throw (errorMessages.join("<br>"));
  //     }
  //     if (data.errors) {
  //       var errorMessages = data.errors.map(item => item.msg);
  //       throw (errorMessages);
  //     }
  //     if (data.affectedRows > 0) {
  //       console.log("Success Submitting Memo");
  //       return "ok";
  //     }
  //   } catch (error) {
  //     console.error("Error while submitting memo:", error);
  //     throw error;
  //   }
  // };

  // const submitPO = async () => {
  //   try {
  //     const response = await fetch(config.po_create_link, {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //         "Authorization": "Bearer " + getTokenFromLocalStorage()
  //       },
  //       body: JSON.stringify({
  //         po_number: SlePoNum,
  //         po_date: moment(SlePoDate).format("YYYY-MM-DD"),
  //         so_number: SleSoNum,
  //         so_date: moment(SleSoDate).format("YYYY-MM-DD"),
  //         memo_number: SleMemoNum !== "" ? SleMemoNum : "",
  //         user_modify: getUserNameFromLocalStorage()
  //       })
  //     });

  //     const contentType = response.headers.get('content-type');
  //     const data = contentType && contentType.includes('application/json')
  //       ? await response.json()
  //       : await response.text();

  //     if (data.message === 'Access Denied! Invalid token') {
  //       clearUserNameAndTokenInLocalStorage();
  //       throw (data.message);
  //     } else if (data.message) {
  //       throw (data.message);
  //     }
  //     if (data.errors) {
  //       var errorMessages = data.errors.map(item => item.msg);
  //       throw (errorMessages.join("<br>"));
  //     }
  //     if (data.affectedRows > 0) {
  //       console.log("Success Submitting PO");
  //       return "ok";
  //     }
  //   } catch (error) {
  //     console.error("Error while submitting PO:", error);
  //     throw error;
  //   }
  // };

  // const submitDO = async () => {
  //   try {
  //     const response = await fetch(config.do_create_link, {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //         "Authorization": "Bearer " + getTokenFromLocalStorage()
  //       },
  //       body: JSON.stringify({
  //         po_number: SlePoNum,
  //         do_number: SleDoNum,
  //         do_date: moment(SleDoDate).format("YYYY-MM-DD"),
  //         user_modify: getUserNameFromLocalStorage()
  //       })
  //     });

  //     const contentType = response.headers.get('content-type');
  //     const data = contentType && contentType.includes('application/json')
  //       ? await response.json()
  //       : await response.text();

  //     if (data.message === 'Access Denied! Invalid token') {
  //       clearUserNameAndTokenInLocalStorage();
  //       throw (data.message);
  //     } else if (data.message) {
  //       throw (data.message);
  //     }
  //     if (data.errors) {
  //       var errorMessages = data.errors.map(item => item.msg);
  //       throw (errorMessages.join("<br>"));
  //     }
  //     if (data.affectedRows > 0) {
  //       console.log("Success Submitting DO");
  //       return "ok";
  //     }
  //   } catch (error) {
  //     console.error("Error while submitting DO:", error);
  //     throw error;
  //   }
  // }

  return (
    <Modal show={true} onHide={onClose} size="lg" backdrop="static" centered>
      <BlockUi blocking={blocking}>
        <Form>
          <Modal.Header closeButton className="px-4">
            <Modal.Title className="ms-auto">Restock Request</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="mb-2 justify-content-center">
              <Col md="6">
                <Form.Group as={Row} controlId="SLESONumber">
                  <Form.Label column sm="6" className="fw-bold">
                    SLE SO Number :
                  </Form.Label>
                  <Col sm="6">
                    <Form.Control
                      readOnly
                      className="no-border"
                      type="text"
                      placeholder=""
                      value={SleSoNum}
                    />
                  </Col>
                </Form.Group>
              </Col>

              <Col md="6">
                <Form.Group as={Row} controlId="SLESODate">
                  <Form.Label column sm="6" className="fw-bold">
                    SLE SO Date :
                  </Form.Label>
                  <Col sm="6">
                    <DatePicker
                      readOnly
                      dateFormat="dd/MM/yyyy"
                      className="form-control no-border"
                      showIcon
                      selected={SleSoDate}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-2 justify-content-center">
              <Col md="6">
                <Form.Group as={Row} controlId="SLESONumber">
                  <Form.Label column sm="6" className="fw-bold">
                    Customer Name :
                  </Form.Label>
                  <Col sm="6">
                    <Form.Control
                      readOnly
                      className="no-border"
                      type="text"
                      placeholder=""
                      value={CustomerName}
                    />
                  </Col>
                </Form.Group>
              </Col>

              <Col md="6">
                <Form.Group as={Row} controlId="SLESODate">
                  <Form.Label column sm="6" className="fw-bold">
                    Project Name:
                  </Form.Label>
                  <Col sm="6">
                    <Form.Control
                      readOnly
                      className="no-border"
                      type="text"
                      placeholder=""
                      value={ProjectName}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <Form.Group as={Row} controlId="OrderType">
                  <Form.Label column sm="6" className="fw-bold">
                    Order Type :
                  </Form.Label>
                  <Col sm="6">
                    <Form.Control
                      readOnly
                      className="no-border"
                      type="text"
                      placeholder=""
                      value={OrderType}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <hr />
            <RestockTable
              rowData={RestockList}
              setTrigger={setTrigger}
              onChildToggleChange={handleToggleFromChild}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={onClose} variant="secondary">
              Close
            </Button>
          </Modal.Footer>
        </Form>

      </BlockUi>
    </Modal>
  );
}

export default ManageSoRestockModal;
