import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import DatePicker from "react-datepicker";
import moment from 'moment/moment';
import { clearUserNameAndTokenInLocalStorage, getTokenFromLocalStorage } from '../../lib/common';
import BlockUi from '@availity/block-ui';

const config = JSON.parse(process.env.REACT_APP_CONFIG);

function DeliveryDateDateModal(props) {
  const { rowData, onClose, handleShowAlert } = props;
  const [SleSoNum] = useState(rowData.SO_NUMBER);
  const [SlePoNum] = useState(rowData.PO_NUMBER);
  const [SleSoDate] = useState(new Date(rowData.SO_DATE));
  const [SlePoDate] = useState(new Date(rowData.PO_DATE));
  const [DeliveryDate, setDeliveryDate] = useState(new Date());
  const [OrderType] = useState(rowData.SO_TYPE_DESC);
  const [blocking, setBlocking] = useState(false);

  const handleUpdateDeliveryDate = async (event) => {
    event.preventDefault();
    try {
      await submitDeliveryDate();
      onClose();
      handleShowAlert("ok", "Update Delivery Date", "Success updating Delivery Date");
    } catch (error){
      console.error("Error while updating Delivery Date:", error);
      handleShowAlert("error", "Update Delivery Date", "Error while updating Delivery Date:", error);
    }
  }

  const handleToggleBlocking = () => {
    setBlocking(prevState => !prevState);
  };

  const submitDeliveryDate = async () => {
    try {
      const response = await fetch(config.deliverydate_update_link, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          "Authorization" : "Bearer " + getTokenFromLocalStorage()
        },
        body: JSON.stringify({
          po_number: rowData.PO_NUMBER,
          delivery_date : moment(DeliveryDate).format("YYYY-MM-DD"),
        })
      });
  
      const contentType = response.headers.get('content-type');
      const data = contentType && contentType.includes('application/json')
        ? await response.json()
        : await response.text();
      handleToggleBlocking();
      if (data.message === 'Access Denied! Invalid token'){
        clearUserNameAndTokenInLocalStorage();
        throw (data.message);
      } else if (data.message){
        throw (data.message);
      }
      if (data.errors){
        var errorMessages = data.errors.map(item => item.msg);
        // handleShowAlert("error", "New Invoice", "Failed Saving New Invoice :", errorMessages.join("<br>"));
        throw errorMessages.join("<br>");
      }
      if (data.affectedRows > 0) {
        console.log("Success Updating Delivery Date");
        return "ok";
      } else {
        throw (data.message);
      }
    } catch (error) {
      console.error("Error while Updating Delivery Date:", error);
      throw error;
    }
  };

  return (
    <Modal show={true} onHide={onClose} size="lg" backdrop="static" centered>
      <BlockUi blocking={blocking}>
        <Form onSubmit = {handleUpdateDeliveryDate}>
          <Modal.Header closeButton className="px-4">
            <Modal.Title className="ms-auto">Update Delivery Date</Modal.Title>
          </Modal.Header>
          <Modal.Body>
              <Row className="mb-2 justify-content-center">
                <Col md="6">
                  <Form.Group as={Row} controlId="SLESONumber">
                    <Form.Label column sm="6" className="fw-bold">
                      SLE SO Number :
                    </Form.Label>
                    <Col sm="6">
                      <Form.Control readOnly className="no-border" type="text" placeholder="" value={SleSoNum}/>
                    </Col>
                  </Form.Group>
                </Col>

                <Col md="6">
                  <Form.Group as={Row} controlId="SLESODate">
                      <Form.Label column sm="6" className="fw-bold">
                        SLE SO Date :
                      </Form.Label>
                      <Col sm="6">
                        <DatePicker readOnly dateFormat="dd/MM/yyyy" className="form-control no-border" showIcon selected={SleSoDate}/>
                      </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-2 justify-content-center">
                <Col md="6">
                  <Form.Group as={Row} controlId="SLEPONumber">
                    <Form.Label column sm="6" className="fw-bold">
                      SLE PO Number :
                    </Form.Label>
                    <Col sm="6">
                      <Form.Control readOnly className="no-border" type="text" placeholder="" value={SlePoNum}/>
                    </Col>
                  </Form.Group>
                </Col>

                <Col md="6">
                  <Form.Group as={Row} controlId="SLEPODate">
                      <Form.Label column sm="6" className="fw-bold">
                        SLE PO Date :
                      </Form.Label>
                      <Col sm="6">
                        <DatePicker readOnly dateFormat="dd/MM/yyyy" className="form-control no-border" showIcon selected={SlePoDate} />
                      </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <Form.Group as={Row} controlId="OrderType">
                    <Form.Label column sm="6" className="fw-bold">
                      Order Type :
                    </Form.Label>
                    <Col sm="6">
                      <Form.Control readOnly className="no-border" type="text" placeholder="" value={OrderType}/>
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <hr/>
              <Row>
                <Col md="6">
                  <Form.Group as={Row} controlId="OrderType">
                    <Form.Label column sm="6" className="fw-bold">
                      Delivery Date :
                    </Form.Label>
                    <Col sm="6">
                      <DatePicker dateFormat="dd/MM/yyyy" className="form-control" showIcon selected={DeliveryDate} onChange={(date) => setDeliveryDate(date)} />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={onClose} variant="secondary">Cancel</Button>
            <Button type="submit" variant="primary" onClick={handleToggleBlocking}>Save</Button>
          </Modal.Footer>
        </Form>
      </BlockUi>
    </Modal>
  );
}

export default DeliveryDateDateModal;