import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import moment from "moment/moment";
import {
  clearUserNameAndTokenInLocalStorage,
  getTokenFromLocalStorage,
} from "../lib/common";
import BlockUi from "@availity/block-ui";
import "@availity/block-ui/src/BlockUi.css";
import "@availity/block-ui/src/Loader.css";
import AlertModal from "../page_components/AlertModal";
import { IconContext } from "react-icons";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { useForm } from "react-hook-form";
import NewInvoiceTable from "../page_components/NewInvoice/NewInvoice_table";
import ManageDOAcknowledgement_ViewAckModal from "../page_components/ManageDOAcknowledgement/ManageDOAcknowledgement_ViewAckModal";

const config = JSON.parse(process.env.REACT_APP_CONFIG);

function NewInvoice() {
  const { customerId } = useParams(); 
  const [CustomerName, setCustomerName] = useState("");
  const [CustomerId, setCustomerId] = useState("noselectedCustomer");
  const [LocationCustomerId, setLocationCustomerId] = useState("");
  const [CustomerList, setCustomerList] = useState([]);
  const [TaxRate, setTaxRate] = useState("");
  const [SleInvoiceDate, setSleInvoiceDate] = useState(new Date());
  const [TaxAmount, setTaxAmount] = useState("");
  const [CurrencyList, setCurrencyList] = useState([]);
  const [Currency, setCurrency] = useState("");
  const [CurrencyName, setCurrencyName] = useState("");
  const [InvAmount, setInvAmount] = useState("");
  const [InvoiceList, setInvoiceList] = useState([]);

  const [searchProps, setSearchProps] = useState({});
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowsData, setSelectedRowsData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [blocking, setBlocking] = useState(false);
  const [ShowAlert, setShowAlert] = useState(false);
  const [AlertTitle, setAlertTitle] = useState("");
  const [AlertMessage, setAlertMessage] = useState("");
  const [ApiResponse, setApiResponse] = useState("");
  const [tableKey, setTableKey] = useState(Date.now());
  const navigate = useNavigate();

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
} = useForm({
    defaultValues: {
      CustomerId: "noselectedCustomer"
    },
});

  useEffect(() => {
    if (getTokenFromLocalStorage() === null) {
      const state = { data: "Token expired, please login again" };
      navigate("/login", { state });
    }
  });

  useEffect(() => {
    fetch(config.customer_active_list_link, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getTokenFromLocalStorage(),
      },
      body: JSON.stringify({}),
    })
      .then((response) => response.json())
      .then((jsonData) => {
        if (
          jsonData.success !== true &&
          jsonData.message === "Access Denied! Invalid token"
        ) {
          clearUserNameAndTokenInLocalStorage();
          throw new Error(jsonData.message);
        } else if (jsonData.success) {
          return jsonData.data;
        } else {
          throw new Error(jsonData.message);
        }
      })
      .then((data) => setCustomerList(data))
      .catch((error) => console.error(error));
  }, []);

  useEffect(() => {
    fetch(config.currency_list_link, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getTokenFromLocalStorage(),
      },
      body: JSON.stringify({}),
    })
      .then((response) => response.json())
      .then((jsonData) => {
        if (
          jsonData.success !== true &&
          jsonData.message === "Access Denied! Invalid token"
        ) {
          clearUserNameAndTokenInLocalStorage();
          throw new Error(jsonData.message);
        } else if (jsonData.success) {
          return jsonData.data;
        } else {
          throw new Error(jsonData.message);
        }
      })
      .then((data) => {
        setCurrencyList(data);
        // setCurrency(data[0].CURRENCY_CODE);
      })
      .catch((error) => console.error(error));
  }, []);

  // useEffect(() => {
  //   if (location.state?.customerId) {
  //     setLocationCustomerId(location.state.customerId);
  //     setCustomerId(location.state.customerId);
  //     console.log("Customer Id");
  //     console.log(location.state.customerId);
  //   }
  // }, [location.state]);
  useEffect(() => {
    if (customerId) {
      setLocationCustomerId(customerId);
      setCustomerId(customerId);
    }
  }, [customerId]);

  useEffect(() => {
    if (CustomerId && !Currency) {
      setCurrency("MYR");
    }
  }, [CustomerId, Currency]);
  
  useEffect(() => {
    fetch(config.invoice_pending_link, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getTokenFromLocalStorage(),
      },
      body: JSON.stringify({customer_id: CustomerId}),
    })
      .then((response) => response.json())
      .then((jsonData) => {
        if (jsonData.message === "Access Denied! Invalid token") {
          clearUserNameAndTokenInLocalStorage();
          throw new Error(jsonData.message);
        }
        return jsonData;
      })
      .then((data) => {
        setInvoiceList(data.data);
      })
      .catch((error) => console.error(error));
  }, [CustomerId]);

  useEffect(() => {
    fetch(config.tax_rate_list_link, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getTokenFromLocalStorage(),
      },
      body: JSON.stringify({}),
    })
      .then((response) => response.json())
      .then((jsonData) => {
        if (
          jsonData.success !== true &&
          jsonData.message === "Access Denied! Invalid token"
        ) {
          clearUserNameAndTokenInLocalStorage();
          throw new Error(jsonData.message);
        } else if (jsonData.success) {
          return jsonData.data;
        } else {
          throw new Error(jsonData.message);
        }
      })
      .then((data) => {
        setTaxRate(data[0].TAX_RATE);
        // setSleInvoiceDate(data[0].DATE_EFFECTIVE ? new Date(data[0].DATE_EFFECTIVE) : '')
      })
      .catch((error) => console.error(error));
  }, []);
  
  const handleShowAlert = async (status, title, message, apiResponse = []) => {
		setAlertTitle(title);
		if (status === "ok") {
		  setAlertMessage(
			<>
			  <p className="mb-1">
				<IconContext.Provider
				  value={{
					color: "green",
					style: { fontSize: "40px", paddingRight: "5px" },
				  }}
				>
				  <AiOutlineCheckCircle />
				  {message}
				</IconContext.Provider>
			  </p>
			  <p>{apiResponse}</p>
			</>
		  );
		} else {
		  setAlertMessage(
			<>
			  <p className="mb-1">
				<IconContext.Provider
				  value={{
					color: "red",
					style: { fontSize: "40px", paddingRight: "5px" },
				  }}
				>
				  <AiOutlineCloseCircle />
				  {message}
				</IconContext.Provider>
			  </p>
			  {apiResponse.length > 0
				? apiResponse.map((response) => (
					<p className="text-danger fst-italic ps-3">{response}</p>
				  ))
				: ""}
			</>
		  );
		}
		setShowAlert(true);
	};

  const handleCloseAlert = () => {
    setShowAlert(false);
  };

  const handleToggleBlocking = () => {
    setBlocking((prevState) => !prevState);
  };

  const handleToggleFromChild = (newValue) => {
    setBlocking(newValue);
  };

  const handleCustomerChange = (e) => {
    setCustomerId(e.target.value);
    setCustomerName(e.target.options[e.target.selectedIndex].text);
    setSelectedRows([]);
    setTaxAmount("0"); 
    setInvAmount("0");
  };
  
  const handleResetForm = () => {
    handleToggleBlocking();
    setTaxAmount(0);
    setCurrency("");
    setInvAmount(0);
    let searchProperties = { };
    if (customerId) {
      setCustomerId(LocationCustomerId);
      searchProperties = {
        TaxAmount: 0,
        Currency: "",
        InvAmount: 0,
        CustomerId: LocationCustomerId,
      };
      handleToggleBlocking();
    }
    else{
      setCustomerId("noselectedCustomer");
      searchProperties = {
        TaxAmount: 0,
        Currency: "",
        InvAmount: 0,
        CustomerId: "",
      };
      handleToggleBlocking();
    }
    setSearchProps(searchProperties);
  };

  const handleNewInvClick = (rowData) => {
    fetch(config.invoice_pending_link, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getTokenFromLocalStorage(),
      },
      body: JSON.stringify({
        customer_id: CustomerId,
      }),
    })
      .then((response) => response.json())
      .then((jsonData) => {
        if (
          jsonData.success !== true &&
          jsonData.message === "Access Denied! Invalid token"
        ) {
          clearUserNameAndTokenInLocalStorage();
          const state = { data: "Token expired, please login again" };
          navigate("/login", { state });
          throw new Error(jsonData.message);
        } else if (jsonData.success) {
          setSelectedRowsData(rowData);
          setShowModal("newInv");
        } else {
          throw new Error(jsonData.message);
        }
      })
      .catch((error) => console.error(error));
  };

  const toggleRowSelection = (rowId) => {
    let updatedRows;
    if (selectedRows.includes(rowId)) {
      updatedRows = selectedRows.filter((id) => id !== rowId);
    } else {
      updatedRows = [...selectedRows, rowId];
    }
    setSelectedRows(updatedRows);
    updateTaxAndInvAmount(updatedRows);
  };
  // const toggleRowSelection = (rowId) => {
  //   setSelectedRow([rowId]);
  // };

  const updateTaxAndInvAmount = (updatedRows) => {
    if (updatedRows.length > 0) {
      const token = getTokenFromLocalStorage();
      const fetchRowData = (row) => {
        return fetch(config.invoice_pending_get_do_ack_link, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          body: JSON.stringify({ do_ack_number: row }),
        })
        .then(response => response.json())
        .then(jsonData => {
          if (jsonData.message === "Access Denied! Invalid token") {
            clearUserNameAndTokenInLocalStorage();
            throw new Error(jsonData.message);
          }
          if (jsonData.success && jsonData.data) {
            return parseFloat(jsonData.data[0].TOTAL_DO_ACK_AMOUNT) || 0;
          } else {
            return 0;
          }
        });
      };
      Promise.all(updatedRows.map(row => fetchRowData(row)))
        .then(amounts => {
          const totalAmount = amounts.reduce((acc, amount) => acc + amount, 0);
          const taxRate = parseFloat(TaxRate);
          const calculatedTaxAmount = (taxRate * totalAmount).toFixed(2);
          const truncateToTwoDecimals = (num) => {
          const numStr = num.toString();
          const decimalIndex = numStr.indexOf('.');
            if (decimalIndex === -1) {
              return numStr;
            }
            return numStr.substring(0, decimalIndex + 3);
          };
          const formatNumber = (num) => {
            return new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(num);
          };
          const calculatedInvAmount = truncateToTwoDecimals(totalAmount + parseFloat(calculatedTaxAmount));
          
          setTaxAmount(formatNumber(calculatedTaxAmount));
          setInvAmount(formatNumber(calculatedInvAmount));
        })
        .catch(error => console.error(error));
    } else {
      setTaxAmount("0");
      setInvAmount("0");
    }
  };
  

  const handleSaveClick = async () => {
    handleToggleBlocking();
    var jsonOb = {
      customer_id: CustomerId,
      invoice_date: SleInvoiceDate !== "" ? moment(SleInvoiceDate).format("YYYY-MM-DD") : null,
      tax_rate: TaxRate,
      tax_amount: TaxAmount,
      invoice_amount_w_tax: InvAmount,
      currency_code: Currency,
      do_ack_number: selectedRows,
    }
    console.log(jsonOb);
    try {
      if (selectedRows.length <= 0) {
        handleShowAlert(
          "error",
          "New Invoice",
          "Failed Saving New Invoice",
          ["Please select a DO Ack"]
        );
        return;
      }

      const response = await fetch(config.invoice_create_link, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getTokenFromLocalStorage(),
      },
      body: JSON.stringify(jsonOb),
    });
    const data = await response.json();
    console.log(data);
    if (
      data.success !== true &&
      data.message === "Access Denied! Invalid token"
    ) {
      clearUserNameAndTokenInLocalStorage();
      const state = { data: "Token expired, please login again" };
      navigate("/login", { state });
    }
    if (data.success !== true) {
      var errorMessages = data.error.map((item) => item.msg);
      handleShowAlert("error", "New Invoice", "Failed Saving New Invoice :", errorMessages.join("<br>"));
    }
    if (data.success === true) {
      handleShowAlert(
        "ok",
        "New Invoice",
        "Success add New Invoice",
        "The Invoice Number is: " + data.data.invoice_number
      ); 
      setTableKey(Date.now());
      return "ok";
    } else {
      throw data;
    }
    } catch (error) {
      console.error("Error while saving:", error);
      handleShowAlert(
        "error",
        "New Invoice",
        "Error while saving New Invoice",
        error
      );
      return;
    } finally {
      handleToggleBlocking(false);
    }
  };

  return (
    <div>
      <Container className="mt-4" style={{ maxWidth: "70%" }}>
        <BlockUi blocking={blocking}>
          <Form  onSubmit={handleSubmit(handleSaveClick)}>
            <Row className="justify-content-center mb-4">
              <Col md={8}>
                <Row className="mb-4">
                  <Col className="text-center">
                    <h2>New Invoice</h2>
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col>
                    <Form.Group as={Row} className="mb-3" controlId="CustomerId">
                      <Form.Label column sm="3" className="fw-bold">
                        Customer Name
                      </Form.Label>
                      <Col sm="6" style={{ paddingRight: "3px" }}>
                        <Form.Select
                          placeholder="Please select customer name"
                          {...register("CustomerId", {
                            required: CustomerId === "" ? "This field is required" : false,
                          })}
                          className={`form-control ${
                            errors.CustomerId ? "is-invalid": ""
                          }`}
                          value={CustomerId}
                          // onChange={(e) => {
                          //   setCustomerId(e.target.value);
                          //   setCustomerName(e.target.options[e.target.selectedIndex].text);
                          // }}
                          onChange={handleCustomerChange} 
                        >
                          <option key={""} value="noselectedCustomer" disabled>
                            Please select customer name
                          </option>
                          {CustomerList.map((items) => (
                            <option key={items.CUSTOMER_ID} value={items.CUSTOMER_ID}>
                              {items.CUSTOMER_NAME}
                            </option>
                          ))}
                        </Form.Select>
                        {errors.CustomerId && (
                          <Form.Text className="text-danger">
                            {errors.CustomerId.message}
                          </Form.Text>
                        )}
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <hr />
                <Row className="mb-2">
                  <Col md="6">
                      <Form.Group as={Row} controlId="TaxRate">
                      <Form.Label column sm="5" className="fw-bold">
                          Tax Rate :
                      </Form.Label>
                      <Col sm="6">
                        <Form.Control 
                          disabled
                          type="text" 
                          placeholder="Tax Rate" 
                          value={TaxRate}
                        />
                      </Col>
                      </Form.Group>
                  </Col>
                  <Col md="6">
                      <Form.Group as={Row} controlId="SleInvoiceDate">
                      <Form.Label column sm="6" className="fw-bold">
                          SLE Invoice Date :
                      </Form.Label>
                      <Col sm="6">
                        <DatePicker
                          showIcon
                          disabled
                          dateFormat="dd MMM yyyy"
                          selected={SleInvoiceDate}
                          minDate={moment().subtract(3, 'days').toDate()}
                          maxDate={new Date()}
                        />
                      </Col>
                      </Form.Group>
                  </Col>
                </Row>
                <Row className="mb-2">
                    <Col md="6">
                        <Form.Group as={Row} controlId="TaxAmount">
                        <Form.Label column sm="5" className="fw-bold">
                            Tax Amount :
                        </Form.Label>
                        <Col sm="6">
                          <Form.Control 
                            disabled
                            type="text" 
                            placeholder="Tax Amount" 
                            value={TaxAmount}
                          />
                        </Col>
                        </Form.Group>
                    </Col>
                    <Col md="6" style={{ paddingRight: "0px" }}>
                    <Form.Group as={Row} controlId="Currency">
                      <Form.Label column sm="6" className="fw-bold">
                        Currency :
                      </Form.Label>
                      <Col sm="6" style={{ paddingLeft: "5px" }}>
                      <Form.Select
                          {...register("Currency", {
                            required: Currency === "" ? "This field is required" : false,
                          })}
                          className={`form-control ${
                            errors.Currency ? "is-invalid": ""
                          }`}
                          value={Currency}
                          onChange={(e) => {
                            setCurrency(e.target.value);
                            setCurrencyName(e.target.options[e.target.selectedIndex].text);
                          }}
                        >
                          <option key={""} value="" disabled>
                            Please select currency
                          </option>
                          {!CustomerId ? (
                              <option key={"selectcustomer"} value="" className="drop-down-message" disabled>
                                Please select customer name first
                              </option>
                            ) : (
                          <>
                          {CurrencyList.map((items) => (
                            <option key={items.CURRENCY_CODE} value={items.CURRENCY_CODE}>
                              {items.CURRENCY_NAME}
                            </option>
                          ))}
                          </>
                        )}
                        </Form.Select>
                        {errors.Currency && (
                          <Form.Text className="text-danger">
                            {errors.Currency.message}
                          </Form.Text>
                        )}
                      </Col>
                    </Form.Group>
                    </Col>
                </Row>
                <Row className="mb-2">
                <Col md="6">
                    <Form.Group as={Row} controlId="InvAmount">
                    <Form.Label column sm="5" className="fw-bold">
                      Inv Amt w/ Tax :
                    </Form.Label>
                    <Col sm="6">
                        <Form.Control 
                          disabled
                          // {...register("TaxRate", {
                          //   required: InvAmount === "" ? "This field is required" : false,
                          // })}
                          // className={`form-control ${
                          //   errors.InvAmount ? "is-invalid": ""
                          // }`}
                          type="text" 
                          placeholder="Inv Amt w/ Tax" 
                          value={InvAmount}
                        />
                        {/* {errors.InvAmount && (
                          <Form.Text className="text-danger">
                            {errors.InvAmount.message}
                          </Form.Text>
                        )} */}
                    </Col>
                    </Form.Group>
                </Col>
                </Row>
              </Col>
              <Col md={12}>
                <hr />
                <Row className="mb-4 align-items-center">
                    <Col className="text-center">
                        <Form.Label>[ DO Ack ]</Form.Label>
                        {/* <NewInvoiceTable
                          showAlert={ShowAlert}
                          searchProps={searchProps}
                          selectedRows={selectedRows}
                          setSelectedRows={setSelectedRows}
                          onViewClick ={handleNewInvClick}
                          onChildToggleChange={handleToggleFromChild}
                          toggleRowSelection={toggleRowSelection}
                        /> */}
                        <NewInvoiceTable
                          key={tableKey}
                          rowData={{ CUSTOMER_ID: CustomerId }} 
                          // searchProps={searchProps}
                          onChildToggleChange={handleToggleFromChild}
                          onRowSelect={toggleRowSelection}
                          toggleRowSelection={toggleRowSelection}
                          selectedRows={selectedRows}
                          setSelectedRows={setSelectedRows}
                          onViewClick ={handleNewInvClick}
                        />
                    </Col>
                </Row>
              </Col>
                <Row>
                  <Col className="text-center">
                    <Button
                      className="me-2"
                      variant="secondary"
                      onClick={handleResetForm}
                    >
                      Reset
                    </Button>
                    <Button
                      type="submit"
                      variant="primary"
                      // onClick={handleToggleBlocking}
                      disabled={InvoiceList.length === 0}
                    >
                      Save
                    </Button>
                  </Col>
                </Row>
            </Row>
          </Form>
          {showModal === "newInv" ? (
            <ManageDOAcknowledgement_ViewAckModal
              handleCloseAlert={handleCloseAlert}
              handleShowAlert={handleShowAlert}
              rowData={selectedRowsData}
              onClose={() => setShowModal(false)}
            />
          ) : (
            ""
          )}

        </BlockUi>
        <style>
          {`
            .drop-down-message {
              color: #dc4c64;
            }
            .react-datepicker__view-calendar-icon input {
              padding: 6px 0px 5px 25px !important;
            }
          `}
        </style>
        {ShowAlert && (
          <AlertModal
            title={AlertTitle}
            message={AlertMessage}
            apiResponse={ApiResponse}
            onClose={handleCloseAlert}
          />
        )}
      </Container>
    </div>
  );
}

export default NewInvoice;
