import React, { useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Pagination from 'react-bootstrap/Pagination';
import moment from 'moment/moment';
import { useNavigate } from "react-router-dom";
import { clearUserNameAndTokenInLocalStorage, getTokenFromLocalStorage } from '../../lib/common';
const config = JSON.parse(process.env.REACT_APP_CONFIG);

function PendingInvoiceTable(props) {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [headerData] = useState([
    "SLE DO Number", 
    "SLE DO Date", 
    "DO Ack Number", 
    "DO Ack Date",
    "Customer", 
    "Project", 
    "Action"
  ]);
  const [currentPage, setCurrentPage] = useState(1);
  const { searchProps } = props;
  const { showAlert} = props;
  const ROWS_PER_PAGE = 10;
  const totalPages = Math.ceil(data.length / ROWS_PER_PAGE);

  useEffect(() => {
    if (searchProps.SleSoNumber || searchProps.SleDoNumber || searchProps.SleSoDateFrom || searchProps.SleSoDateTo 
        || searchProps.SleDoDateFrom || searchProps.SleDoDateTo || searchProps.CustomerName || searchProps.ProjectName){
      getData(config.invoice_pending_link, searchProps);
    } else {
      getData(config.invoice_pending_link);
    }
  }, [searchProps, showAlert]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleToggleBlocking = () => {
    props.onChildToggleChange(false);
  };

  // const handleNewInvClick = (row) => {
  //   console.log(row.CUSTOMER_ID);
  //   navigate('/newinvoice', { state: { customerId: row.CUSTOMER_ID } });
  // };
  const handleNewInvClick = (rowData) => {
    props.onNewInvClick(rowData.CUSTOMER_ID);
    navigate('/newinvoice/' + rowData.CUSTOMER_ID);
  };

  const renderTableData = () => {
    const startIndex = (currentPage - 1) * ROWS_PER_PAGE;
    const endIndex = startIndex + ROWS_PER_PAGE;
    return data.slice(startIndex, endIndex).map((row, index) => {
      const { 
        DO_NUMBER, 
        DO_DATE, 
        DO_ACK_NUMBER,
        DO_ACK_DATE,
        CUSTOMER_ID,
        CUSTOMER_NAME,
        PROJECT_NAME,
      } = row;
      const formattedDoDate = moment(DO_DATE).format("DD MMM yyyy");
      const formattedDoAckDate = moment(DO_ACK_DATE).format("DD MMM yyyy");
      return (
        <tr key={`${CUSTOMER_ID}-${index}`}>
          <td>{DO_NUMBER}</td>
          <td>{formattedDoDate}</td>
          <td>{DO_ACK_NUMBER}</td>
          <td>{formattedDoAckDate}</td>
          <td>{CUSTOMER_NAME}</td>
          <td>{PROJECT_NAME}</td>
          <td style={{width:"150px"}}>
            <Button variant="primary" onClick={() => handleNewInvClick(row)}>New Inv</Button>
            </td>
        </tr>
      );
    });
  };

  const renderPageItems = () => {
    const pageItems = [];
    for (let i = 1; i <= totalPages; i++) {
      pageItems.push(
        <Pagination.Item key={i} active={i === currentPage} onClick={() => handlePageChange(i)}>
          {i}
        </Pagination.Item>
      );
    }
    return pageItems;
  };

  const getData = async (url, params = {}) => {
    await fetch(url,{
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        "Authorization" : "Bearer " + getTokenFromLocalStorage()
      },
      body : JSON.stringify(params.length === 0 ? params : {
        do_number : params.SleDoNumber,
        so_number : params.SleSoNumber,
        do_date_from : params.SleDoDateFrom,
        do_date_to : params.SleDoDateTo,
        so_date_from : params.SleSoDateFrom,
        so_date_to : params.SleSoDateTo,
        customer_name : params.CustomerName,
        project_name : params.ProjectName
      })
    })
    .then(response => response.json())
    .then (jsonData => {
      handleToggleBlocking();
      console.log(jsonData)
      if (jsonData.message === 'Access Denied! Invalid token'){
        clearUserNameAndTokenInLocalStorage();
        const state = { data: "Token expired, please login again" };
        navigate("/login", { state });
        throw new Error (jsonData.message);
      }
      const newData = jsonData.data.map(item => {
        const date = moment(item.DO_DATE).format("DD MMM yyyy");
        item.DO_DATE = date;
        return item;
      });
      return newData;
    })
    .then(data => setData(data))
    .catch(error => console.error(error));
  }

  if (data.length<1){
    return(
      <div className="text-center"><h3>No Data</h3></div>
    )
  } else {
    return (
      <div>
        <Table striped bordered hover className="mb-4">
          <thead>
            <tr>{headerData.map((header) => <th key={header}>{header}</th>)}</tr>
          </thead>
          <tbody>{renderTableData()}</tbody>
        </Table>
        <Pagination className="d-flex justify-content-center" >{renderPageItems()}</Pagination>
      </div>
    );
  }

}

export default PendingInvoiceTable;