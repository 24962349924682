import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";
import {
  clearUserNameAndTokenInLocalStorage,
  getTokenFromLocalStorage,
} from "../lib/common";
import moment from "moment/moment";
import BlockUi from "@availity/block-ui";
import "@availity/block-ui/src/BlockUi.css";
import "@availity/block-ui/src/Loader.css";
import AlertModal from "../page_components/AlertModal";
import { IconContext } from "react-icons";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { AiOutlineCloseCircle } from "react-icons/ai";
import ManageGrnTable from "../page_components/ManageGoodsReceivedNote/ManageGRN_Table";
import ManageGRNViewModal from "../page_components/ManageGoodsReceivedNote/ManageGRN_ViewModal";
import ManageGrnCancelModal from "../page_components/ManageGoodsReceivedNote/ManageGRN_CancelModal";
import ManageGoodsReceivedNoteEditModal from "../page_components/ManageGoodsReceivedNote/ManageGRN_EditModal";

const config = JSON.parse(process.env.REACT_APP_CONFIG);

function ManageGrn() {
    const [SleGrn, setSleGrn] = useState("");
    const [SlePO, setSlePO] = useState("");
    const [SupplierDo, setSupplierDo] = useState("");
    const [SleGrnDateFrom, setSleGRNDateFrom] = useState("");
    const [SleGrnDateTo, setSleGrnDateTo] = useState("");
    const [SupplierDoDateFrom, setSupplierDoDateFrom] = useState("");
    const [SupplierDoDateTo, setSupplierDoDateTo] = useState("");
    const [PODateFrom, setPODateFrom] = useState("");
    const [PODateTo, setPODateTo] = useState("");
    const [SupplierId, setSupplierId] = useState("");
    const [SupplierList, setSupplierList] = useState([]);

  const [isPendingRestock, setIsPendingRestock] = useState(false);
  const [searchProps, setSearchProps] = useState({});
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [blocking, setBlocking] = useState(true);
  const [ShowAlert, setShowAlert] = useState(false);
  const [AlertTitle, setAlertTitle] = useState("");
  const [AlertMessage, setAlertMessage] = useState("");
  const [ApiResponse, setApiResponse] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (getTokenFromLocalStorage() === null) {
      const state = { data: "Token expired, please login again" };
      navigate("/login", { state });
    }
  });

  useEffect(() => {
    fetch(config.supplier_list_link, {
    method: "POST",
    headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getTokenFromLocalStorage(),
    },
    body: JSON.stringify({
        customer_id: SupplierId,
    }),
    })
    .then((response) => response.json())
    .then((jsonData) => {
        console.log(jsonData);
        if (
        jsonData.success !== true &&
        jsonData.message === "Access Denied! Invalid token"
        ) {
        clearUserNameAndTokenInLocalStorage();
        const state = { data: "Token expired, please login again" };
        navigate("/login", { state });
        throw new Error(jsonData.message);
        } else if (jsonData.success) {
        return jsonData.data;
        } else {
        throw new Error(jsonData.message);
        }
    })
    .then((data) => setSupplierList(data))
    .catch((error) => console.error(error));
  }, []);

  const handleShowAlert = async (status, title, message, apiResponse = "") => {
    setAlertTitle(title);
    if (status === "ok") {
      setAlertMessage(
        <>
          <p className="mb-1">
            <IconContext.Provider
              value={{
                color: "green",
                style: { fontSize: "40px", paddingRight: "5px" },
              }}
            >
              <AiOutlineCheckCircle />
              {message}
            </IconContext.Provider>
          </p>
        </>
      );
    } else {
      setAlertMessage(
        <>
          <p className="mb-1">
            <IconContext.Provider
              value={{
                color: "red",
                style: { fontSize: "40px", paddingRight: "5px" },
              }}
            >
              <AiOutlineCloseCircle />
              {message}
            </IconContext.Provider>
          </p>
          {apiResponse !== "" ? (
            <p className="text-danger fst-italic ps-3">{apiResponse}</p>
          ) : (
            ""
          )}
        </>
      );
    }
    setShowAlert(true);
  };

  const handleCloseAlert = () => {
    setShowAlert(false);
  };

  const handleToggleBlocking = () => {
    setBlocking((prevState) => !prevState);
  };

  const handleToggleFromChild = (newValue) => {
    setBlocking(newValue);
  };

  const handleResetForm = () => {
    handleToggleBlocking();

    setSleGrn('');
    setSlePO('');
    setSupplierDo('');
    setSleGRNDateFrom('');
    setSleGrnDateTo('');
    setSupplierDoDateFrom('');
    setSupplierDoDateTo('');
    setPODateFrom('');
    setPODateTo('');
    setSupplierId('');

    const searchProperties = {
        po_number:'',
        po_date_from:'',
        po_date_to:'',
        supplier_id:'',
        supplier_do_number:'',
        supplier_do_date_from:'',
        supplier_do_date_to:''
    };
    setSearchProps(searchProperties);
  };
  const handleSearch = (event) => {
    event.preventDefault();
    handleToggleBlocking();
    const searchProperties = {
        grn_number: SleGrn,
        grn_date_from: SleGrnDateFrom !== "" 
        ? moment(SleGrnDateFrom).format("YYYY-MM-DD")
        : null,
        grn_date_to : SleGrnDateTo !== ""
        ? moment(SleGrnDateTo).format("YYYY-MM-DD")
        : null,
        po_number:SlePO,
        po_date_from: PODateFrom !== ""
        ? moment(PODateFrom).format("YYYY-MM-DD")
        : null,
        po_date_to: PODateTo !== ""
        ? moment(PODateTo).format("YYYY-MM-DD")
        : null,
        supplier_id:SupplierId,
        supplier_do_number:SupplierDo,
        supplier_do_date_from:SupplierDoDateFrom !== ""
        ? moment(SupplierDoDateFrom).format("YYYY-MM-DD")
        : null,
        supplier_do_date_to:SupplierDoDateTo !== ""
        ? moment(SupplierDoDateTo).format("YYYY-MM-DD")
        : null
    };
    console.log(searchProperties);
    setSearchProps(searchProperties);
  };

  const handleCancelClick = (rowData) => {
    fetch(config.grn_get_list_link, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getTokenFromLocalStorage(),
      },
      body: JSON.stringify({
        po_number: SlePO,
      }),
    })
      .then((response) => response.json())
      .then((jsonData) => {
        if (
          jsonData.success !== true &&
          jsonData.message === "Access Denied! Invalid token"
        ) {
          clearUserNameAndTokenInLocalStorage();
          const state = { data: "Token expired, please login again" };
          navigate("/login", { state });
          throw new Error(jsonData.message);
        } else if (jsonData.success) {
          setSelectedRowData(rowData);
          setShowModal("cancel");
        } else {
          throw new Error(jsonData.message);
        }
      })
      .catch((error) => console.error(error));
  };

  const handleViewClick = (rowData) => {
    fetch(config.grn_get_list_link, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getTokenFromLocalStorage(),
      },
      body: JSON.stringify({
        po_number: SlePO,
      }),
    })
      .then((response) => response.json())
      .then((jsonData) => {
        if (
          jsonData.success !== true &&
          jsonData.message === "Access Denied! Invalid token"
        ) {
          clearUserNameAndTokenInLocalStorage();
          const state = { data: "Token expired, please login again" };
          navigate("/login", { state });
          throw new Error(jsonData.message);
        } else if (jsonData.success) {
          setSelectedRowData(rowData);
          setShowModal("view");
        } else {
          throw new Error(jsonData.message);
        }
      })
      .catch((error) => console.error(error));
  };

  const handleEditClick = (rowData) => {
    fetch(config.grn_get_list_link, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getTokenFromLocalStorage(),
      },
      body: JSON.stringify({
        po_number: SlePO,
      }),
    })
      .then((response) => response.json())
      .then((jsonData) => {
        if (
          jsonData.success !== true &&
          jsonData.message === "Access Denied! Invalid token"
        ) {
          clearUserNameAndTokenInLocalStorage();
          const state = { data: "Token expired, please login again" };
          navigate("/login", { state });
          throw new Error(jsonData.message);
        } else if (jsonData.success) {
          setSelectedRowData(rowData);
          setShowModal("edit");
        } else {
          throw new Error(jsonData.message);
        }
      })
      .catch((error) => console.error(error));
  };

  return (
    <div>
      <Container className="mt-4" style={{ maxWidth: "80%" }}>
        <BlockUi blocking={blocking}>
          <Row className="justify-content-center mb-4">
            <Col md={7}>
              <Form onSubmit={handleSearch}>
                <Row className="mb-4">
                  <Col className="text-center">
                    <h2>Search GRN</h2>
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="SLEGRN"
                    >
                      <Form.Label column sm="3">
                        SLE GRN
                      </Form.Label>
                      <Col sm="6">
                        <Form.Control
                          type="text"
                          placeholder="SLE GRN"
                          value={SleGrn}
                          onInput={(e) => setSleGrn(e.target.value)}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="SLEPO"
                    >
                      <Form.Label column sm="3">
                        SLE PO
                      </Form.Label>
                      <Col sm="6">
                        <Form.Control
                          type="text"
                          placeholder="SLE PO"
                          value={SlePO}
                          onInput={(e) => setSlePO(e.target.value)}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="SupplierDO"
                    >
                      <Form.Label column sm="3">
                        Supplier DO
                      </Form.Label>
                      <Col sm="6">
                        <Form.Control
                          type="text"
                          placeholder="Supplier DO"
                          value={SupplierDo}
                          onInput={(e) => setSupplierDo(e.target.value)}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>

                {/* <Row className="mb-2">
                  <Col sm="6">
                    <Form.Label column sm="3">
                        GRN Date From
                    </Form.Label>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="GRNDATEFROM"
                    >
                      <Form.Label column sm="6">
                        GRN Date From
                      </Form.Label>
                      <Col sm="6" style={{ paddingLeft: "12px" }}>
                        <DatePicker
                          className="form-control"
                          dateFormat="dd/MM/yyyy"
                          showIcon
                          selected={SleGrnDateFrom}
                          onChange={(date) => setSleGRNDateFrom(date)}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col sm="5">
                    <Form.Label column sm="1">
                        to
                    </Form.Label>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="GRNDATETO"
                    >
                      <Form.Label column sm="3">
                        to
                      </Form.Label>
                      <Col sm="7" style={{ paddingRight: "15px" }}>
                        <DatePicker
                          className="form-control"
                          dateFormat="dd/MM/yyyy"
                          showIcon
                          selected={SleGrnDateTo}
                          onChange={(date) => setSleGrnDateTo(date)}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row> */}

                <Row className="mb-2">
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="GRNDATEFROM"
                    >
                      <Form.Label column sm="3">
                      GRN Date From
                      </Form.Label>
                      <Col sm="6" as={Row} className="m-0 p-0">
                        <Col sm="5">
                          <DatePicker
                            className="form-control"
                            dateFormat="dd/MM/yyyy"
                            showIcon
                            selected={SleGrnDateFrom}
                            onChange={(date) => setSleGRNDateFrom(date)}
                          />
                        </Col>
                        <Form.Label column sm="2" className="text-center">
                          to
                        </Form.Label>
                        <Col sm="5">
                            <DatePicker
                              className="form-control"
                              dateFormat="dd/MM/yyyy"
                              showIcon
                              selected={SleGrnDateTo}
                              onChange={(date) => setSleGrnDateTo(date)}
                            />
                        </Col>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="SUPPLIERDODATEFROM"
                    >
                      <Form.Label column sm="3">
                        Supplier DO Date From
                      </Form.Label>
                      <Col sm="6" as={Row} className="m-0 p-0">
                        <Col sm="5">
                          <DatePicker
                            className="form-control"
                            dateFormat="dd/MM/yyyy"
                            showIcon
                            selected={SupplierDoDateFrom}
                            onChange={(date) => setSupplierDoDateFrom(date)}
                          />
                        </Col>
                        <Form.Label column sm="2" className="text-center">
                          to
                        </Form.Label>
                        <Col sm="5">
                          <DatePicker
                            className="form-control"
                            dateFormat="dd/MM/yyyy"
                            showIcon
                            selected={SupplierDoDateTo}
                            onChange={(date) => setSupplierDoDateTo(date)}
                          />
                        </Col>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="PODateFrom"
                    >
                      <Form.Label column sm="3">
                        PO Date From
                      </Form.Label>
                      <Col sm="6" as={Row} className="m-0 p-0">
                        <Col sm="5">
                          <DatePicker
                            className="form-control"
                            dateFormat="dd/MM/yyyy"
                            showIcon
                            selected={PODateFrom}
                            onChange={(date) => setPODateFrom(date)}
                          />
                        </Col>
                        <Form.Label column sm="2" className="text-center">
                          to
                        </Form.Label>
                        <Col sm="5">
                          <DatePicker
                            className="form-control"
                            dateFormat="dd/MM/yyyy"
                            showIcon
                            selected={PODateTo}
                            onChange={(date) => setPODateTo(date)}
                          />
                        </Col>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="SupplierName"
                    >
                      <Form.Label column sm="3">
                        Supplier Name
                      </Form.Label>
                      <Col sm="6">
                        <Form.Select
                          value={SupplierId}
                          onChange={(e) => setSupplierId(e.target.value)}
                        >
                          <option key={""} value="">
                            Please select supplier name
                          </option>
                          {SupplierList.map((items) => (
                            <option
                              key={items.SUPPLIER_ID}
                              value={items.SUPPLIER_ID}
                            >
                              {items.SUPPLIER_NAME}
                            </option>
                          ))}
                        </Form.Select>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                
                <Row>
                  <Col className="text-center">
                    <Button
                      className="me-2"
                      variant="secondary"
                      onClick={handleResetForm}
                    >
                      Reset
                    </Button>
                    <Button type="submit" variant="primary">
                      Search
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
          <hr />
          <br />
          <ManageGrnTable
            showAlert={ShowAlert}
            searchProps={searchProps}
            onChildToggleChange={handleToggleFromChild}
            onCancelClick={handleCancelClick}
            onViewClick={handleViewClick}
            onEditClick={handleEditClick}
          />
          {showModal === "cancel" ? (
            <ManageGrnCancelModal
              handleCloseAlert={handleCloseAlert}
              handleShowAlert={handleShowAlert}
              rowData={selectedRowData}
              onClose={() => setShowModal(false)}
            />
          ) : (
            ""
          )}

          {showModal === "view" ? (
            <ManageGRNViewModal
              handleCloseAlert={handleCloseAlert}
              handleShowAlert={handleShowAlert}
              rowData={selectedRowData}
              onClose={() => setShowModal(false)}
            />
          ) : (
            ""
          )}
          {showModal === "edit" ? (
            <ManageGoodsReceivedNoteEditModal
              handleCloseAlert={handleCloseAlert}
              handleShowAlert={handleShowAlert}
              rowData={selectedRowData}
              onClose={() => setShowModal(false)}
            />
          ) : (
            ""
          )}
        </BlockUi>
        {ShowAlert && (
          <AlertModal
            title={AlertTitle}
            message={AlertMessage}
            apiResponse={ApiResponse}
            onClose={handleCloseAlert}
          />
        )}
      </Container>
    </div>
  );
}

export default ManageGrn;
