import React, { useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Pagination from 'react-bootstrap/Pagination';
import { clearUserNameAndTokenInLocalStorage, getTokenFromLocalStorage } from '../../lib/common';
const config = JSON.parse(process.env.REACT_APP_CONFIG);

function ProjectsTable(props) {
  const [data, setData] = useState([]);
  const [headerData] = useState(["Customer Name", "Project Name", "Status", "Action"]);
  const [currentPage, setCurrentPage] = useState(1);
  const { onEditClick } = props;
  const { searchProps } = props;
  const { showAlert} = props;
  const ROWS_PER_PAGE = 10;
  const totalPages = Math.ceil(data.length / ROWS_PER_PAGE);

  useEffect(() => {
    console.log(searchProps);
    if (searchProps.CustomerName || searchProps.ProjectName){
      getData(config.project_list_link, searchProps);
    } else {
      getData(config.project_list_link);
    }
  }, [searchProps, showAlert]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleToggleBlocking = () => {
    props.onChildToggleChange(false);
  };

  const renderTableData = () => {
    const startIndex = (currentPage - 1) * ROWS_PER_PAGE;
    const endIndex = startIndex + ROWS_PER_PAGE;
    return data.slice(startIndex, endIndex).map((row) => {
      const { PROJECT_ID, CUSTOMER_ID, CUSTOMER_NAME, PROJECT_NAME, PROJECT_STATUS } = row;
      return (
        <tr key={PROJECT_ID}>
          <td>{CUSTOMER_NAME}</td>
          <td>{PROJECT_NAME}</td>
          <td>{PROJECT_STATUS === "A" ? "Active" : "Inactive"}</td>
          <td style={{width:"100px"}}><Button variant="primary" onClick={() => onEditClick(row)}>Edit</Button></td>
        </tr>
      );
    });
  };

  const renderPageItems = () => {
    const pageItems = [];
    for (let i = 1; i <= totalPages; i++) {
      pageItems.push(
        <Pagination.Item key={i} active={i === currentPage} onClick={() => handlePageChange(i)}>
          {i}
        </Pagination.Item>
      );
    }
    return pageItems;
  };

  const getData = async (url, params = {}) => {
    await fetch(url,{
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        "Authorization" : "Bearer " + getTokenFromLocalStorage()
      },
      body : JSON.stringify(params.length === 0 ? params : {
        customer_id : params.CustomerName,
        project_name : params.ProjectName
      })
    })
    .then(response => response.json())
    .then (jsonData => {
        console.log(jsonData)
      handleToggleBlocking();
      if (jsonData.success !== true && jsonData.message === 'Access Denied! Invalid token'){
        clearUserNameAndTokenInLocalStorage();
        throw new Error (jsonData.message);
      } else if (jsonData.success){
        return jsonData.data;
      } else {
        throw new Error (jsonData.message);
      }
    })
    .then(data => setData(data))
    .catch(error => console.error(error));
  }

  if (data.length<1){
    return(
      <div className="text-center"><h3>No Data</h3></div>
    )
  } else {
    return (
      <div>
        <Table striped bordered hover className="mb-4">
          <thead>
            <tr>{headerData.map((header) => <th key={header}>{header}</th>)}</tr>
          </thead>
          <tbody>{renderTableData()}</tbody>
        </Table>
        <Pagination className="d-flex justify-content-center" >{renderPageItems()}</Pagination>
      </div>
    );
  }

}

export default ProjectsTable;