import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Pagination from "react-bootstrap/Pagination";
import moment from "moment/moment";
import {
  clearUserNameAndTokenInLocalStorage,
  getTokenFromLocalStorage,
} from "../../lib/common";
const config = JSON.parse(process.env.REACT_APP_CONFIG);

function SearchDeliveryOrderViewModalTable(props) {
  const [data, setData] = useState([]);
  const [headerData] = useState([
    "Brand",
    "Product",
    "UOM",
    "This DO Qty",
    "SO Qty",
    "Total DO Qty",
    "Balance Qty",
  ]);
  const [currentPage, setCurrentPage] = useState(1);
  //   const { onOnHoldClick } = props;
  //   const { onRestockClick } = props;
  //   const { onCancelClick} = props;
  //   const { searchProps } = props;

  const {
    showAlert,
    rowData,
    selectedMemoNum,
    onRowSelect,
    selectedRows,
    setSelectedRows,
    textInputs,
    setTextInputs,
    onInputChange,
    toggleRowSelection,
    refreshTrigger,
  } = props;
  const ROWS_PER_PAGE = 10;
  const totalPages = Math.ceil(data.length / ROWS_PER_PAGE);

  useEffect(() => {
    getData(config.do_get_link, rowData);
  }, [showAlert, refreshTrigger]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleToggleBlocking = () => {
    props.onChildToggleChange(false);
  };

  const handleInputChange = (event, rowId) => {
    const { name, value } = event.target;
    setTextInputs({ ...textInputs, [rowId]: value });
    onInputChange(rowId, value); // Call the callback function in the parent component
  };
  const renderTableData = () => {
    const startIndex = (currentPage - 1) * ROWS_PER_PAGE;
    const endIndex = startIndex + ROWS_PER_PAGE;
    return data.slice(startIndex, endIndex).map((row) => {
      const {
        ITEM_ID,
        PRODUCT_ID,
        BRAND,
        UNIT_MEASURE,
        SUPPLIER_NAME,
        PRODUCT_NAME,
        ITEM_QTY,
        TOTAL_ORDER_QTY,
        TOTAL_DO_QTY,
        THIS_DO_QTY,
        SO_QTY,
      } = row;
      return (
        <tr key={ITEM_ID}>
          <td>{BRAND}</td>
          <td>{PRODUCT_NAME}</td>
          <td>{UNIT_MEASURE}</td>
          <td className="text-end">{THIS_DO_QTY}</td>
          <td className="text-end">{SO_QTY}</td>
          <td className="text-end">{TOTAL_DO_QTY}</td>
          <td className="text-end">{parseFloat(parseFloat(SO_QTY).toFixed(4) - parseFloat(TOTAL_DO_QTY).toFixed(4)).toFixed(4)}</td>
        </tr>
      );
    });
  };

  const renderPageItems = () => {
    const pageItems = [];
    for (let i = 1; i <= totalPages; i++) {
      pageItems.push(
        <Pagination.Item
          key={i}
          active={i === currentPage}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </Pagination.Item>
      );
    }
    return pageItems;
  };

  const getData = async (url, params = {}) => {
    console.log(params);
    await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getTokenFromLocalStorage(),
      },
      body: JSON.stringify({
        do_number: params.DO_NUMBER,
      }),
    })
      .then((response) => response.json())
      .then((jsonData) => {
        console.log(jsonData);
        handleToggleBlocking();
        if (jsonData.message === "Access Denied! Invalid token") {
          clearUserNameAndTokenInLocalStorage();
          throw new Error(jsonData.message);
        }
        const newData = jsonData.data.DELIVERY_ORDER_ITEMS.map((item) => {
          const date = moment(item.SO_DATE).format("DD MMM yyyy");
          item.SO_DATE = date;
          return item;
        });
        return newData;
      })
      .then((data) => setData(data))
      .catch((error) => console.error(error));
  };

  if (data.length < 1) {
    return (
      <div className="text-center">
        <h3>No Data</h3>
      </div>
    );
  } else {
    return (
      <div>
        <Table striped bordered hover className="mb-4">
          <thead>
            <tr>
              {headerData.map((header) => (
                <th key={header}>{header}</th>
              ))}
            </tr>
          </thead>
          <tbody>{renderTableData()}</tbody>
        </Table>
        <Pagination className="d-flex justify-content-center">
          {renderPageItems()}
        </Pagination>
      </div>
    );
  }
}

export default SearchDeliveryOrderViewModalTable;
