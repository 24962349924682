import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import DatePicker from "react-datepicker";
import moment from "moment/moment";
import { useNavigate } from "react-router-dom";
import OnholdTable from "./ManageSO_OnHoldTable";
import {
  clearUserNameAndTokenInLocalStorage,
  getUserNameFromLocalStorage,
  getTokenFromLocalStorage,
} from "../../lib/common";
import BlockUi from "@availity/block-ui";
import { useForm, Controller } from "react-hook-form";

const config = JSON.parse(process.env.REACT_APP_CONFIG);

function ManageSoOnHoldModal(props) {
  const {
    control,
    register,
    handleSubmit,
    watch,
    setValue,
    trigger,
    formState: { errors },
  } = useForm({
    defaultValues: {
      OnHoldExpiryDate: new Date(),
      Remarks: "",
    },
  });

  const { rowData, onClose, handleShowAlert, handleCloseAlert, handleUnholdClick } = props;
  const [SleSoNum] = useState(rowData.SO_NUMBER);
  const [SlePoNum, setSlePoNum] = useState("");
  const [SleMemoNum, setSleMemoNum] = useState("");
  const [SleDoNum, setSleDoNum] = useState("");
  const [CustomerId] = useState(rowData.CUSTOMER_ID);
  const [CustomerName, setCustomerName] = useState("");
  const [ProjectId] = useState(rowData.PROJECT_ID);
  const [ProjectName, setProjectName] = useState("");
  const [OrderStatus] = useState(rowData.SO_STATUS);
  const [SleSoDate] = useState(new Date(rowData.SO_DATE));
  const [SlePoDate, setSlePoDate] = useState(new Date());
  const [SleMemoDate, setSleMemoDate] = useState(new Date());
  const [OnHoldExpiryDate, setOnHoldExpiryDate] = useState(new Date());
  const [isIncludeSleDo, setIsIncludeSleDo] = useState(false);
  const [OrderType] = useState(rowData.SO_TYPE_DESC);
  const [Remarks, setRemarks] = useState("");
  const [Trigger, setTrigger] = useState("");
  const [blocking, setBlocking] = useState(false);
  const [minDate] = useState(new Date());
  const [maxDate] = useState(() => {
    const maxDate = new Date();
    maxDate.setFullYear(new Date().getFullYear() + 1);
    const formattedMaxDate = maxDate;
    return formattedMaxDate;
  });
  const navigate = useNavigate();

  const handleIncludeSleDoCheckbox = (event) => {
    setIsIncludeSleDo(event.target.checked);
  };
  const handleToggleBlocking = () => {
    setBlocking((prevState) => !prevState);
  };

  const handleToggleFromChild = (newValue) => {
    setBlocking(newValue);
  };

  useEffect(() => {
    if (CustomerId !== null) {
      fetch(config.customer_get_link, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + getTokenFromLocalStorage(),
        },
        body: JSON.stringify({
          customer_id: CustomerId,
        }),
      })
        .then((response) => response.json())
        .then((jsonData) => {
          console.log(jsonData);
          if (
            jsonData.success !== true &&
            jsonData.message === "Access Denied! Invalid token"
          ) {
            clearUserNameAndTokenInLocalStorage();
            throw new Error(jsonData.message);
          } else if (jsonData.success) {
            return jsonData.data;
          } else {
            throw new Error(jsonData.message);
          }
        })
        .then((data) => setCustomerName(data.CUSTOMER_NAME))
        .catch((error) => console.error(error));
    }
  }, [CustomerId]);

  useEffect(() => {
    if (ProjectId !== null) {
      console.log("projectid : " + ProjectId);
      fetch(config.project_get_link, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + getTokenFromLocalStorage(),
        },
        body: JSON.stringify({
          project_id: ProjectId,
        }),
      })
        .then((response) => response.json())
        .then((jsonData) => {
          console.log(jsonData);
          if (
            jsonData.success !== true &&
            jsonData.message === "Access Denied! Invalid token"
          ) {
            clearUserNameAndTokenInLocalStorage();
            throw new Error(jsonData.message);
          } else if (jsonData.success) {
            return jsonData.data;
          } else {
            throw new Error(jsonData.message);
          }
        })
        .then((data) => setProjectName(data.PROJECT_NAME))
        .catch((error) => console.error(error));
    }
  }, [ProjectId]);

  const handleSaveOnHold = async () => {
    try {
      handleToggleBlocking();
      await submitOnHold();
      handleToggleBlocking();
      console.log("Success saving On-hold");
      onClose();
      handleShowAlert("ok", "New On-hold", "Success saving On-hold");
    } catch (error) {
      handleToggleBlocking();
      console.error("Error while saving On-hold:", error);
      handleShowAlert(
        "error",
        "New On-hold",
        "Error while saving On-hold: ",
        error.join("<br>")
      );
      return;
    }
  };

  const submitOnHold = async () => {
    try {
      const response = await fetch(config.so_hold_link, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + getTokenFromLocalStorage(),
        },
        body: JSON.stringify({
          so_number: SleSoNum,
          hold_expiry: moment(OnHoldExpiryDate).format("YYYY-MM-DD"),
          remarks: Remarks,
        }),
      });

      const data = await response.json();
      console.log("submit on hold response : ");
      console.log(data);
      if (
        data.success !== true &&
        data.message === "Access Denied! Invalid token"
      ) {
        clearUserNameAndTokenInLocalStorage();
      }

      if (data.success !== true) {
        var errorMessages = data.error.map((item) => item.msg);
        // handleShowAlert("error", "New Invoice", "Failed Saving New Invoice :", errorMessages.join("<br>"));
        throw errorMessages;
      }
      if (data.success === true) {
        console.log("Success Submitting Customer");
        // handleTrigger(moment().unix());
        return "ok";
      } else {
        throw data;
      }
    } catch (error) {
      console.error("Error while submitting PO:", error);
      throw error;
    }
  };

  return (
    <Modal show={true} onHide={onClose} size="xl" backdrop="static" centered>
      <BlockUi blocking={blocking}>
        <Form onSubmit={handleSubmit(handleSaveOnHold)}>
          <Modal.Header closeButton className="px-4">
            <Modal.Title className="ms-auto">On-hold Sales Order</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row
              className="mb-2 justify-content-center"
              style={{ paddingLeft: "160px" }}
            >
              <Col md="6">
                <Form.Group as={Row} controlId="SLESONumber">
                  <Form.Label column sm="4" className="fw-bold">
                    SLE SO Number :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      readOnly
                      className="no-border"
                      type="text"
                      placeholder=""
                      value={SleSoNum}
                    />
                  </Col>
                </Form.Group>
              </Col>

              <Col md="6">
                <Form.Group as={Row} controlId="SLESODate">
                  <Form.Label column sm="4" className="fw-bold">
                    SLE SO Date :
                  </Form.Label>
                  <Col sm="8">
                    <DatePicker
                      readOnly
                      dateFormat="dd/MM/yyyy"
                      className="form-control no-border"
                      showIcon
                      selected={SleSoDate}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row
              className="mb-2 justify-content-center"
              style={{ paddingLeft: "160px" }}
            >
              <Col md="6">
                <Form.Group as={Row} controlId="SLESONumber">
                  <Form.Label column sm="4" className="fw-bold">
                    Customer Name :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      readOnly
                      className="no-border"
                      type="text"
                      placeholder=""
                      value={CustomerName}
                    />
                  </Col>
                </Form.Group>
              </Col>

              <Col md="6">
                <Form.Group as={Row} controlId="SLESODate">
                  <Form.Label column sm="4" className="fw-bold">
                    Project Name:
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      readOnly
                      className="no-border"
                      type="text"
                      placeholder=""
                      value={ProjectName}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row style={{ paddingLeft: "160px" }}>
              <Col md="6">
                <Form.Group as={Row} controlId="OrderType">
                  <Form.Label column sm="4" className="fw-bold">
                    Order Type :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      readOnly
                      className="no-border"
                      type="text"
                      placeholder=""
                      value={OrderType}
                    />
                  </Col>
                </Form.Group>
              </Col>

              <Col md="6">
                <Form.Group as={Row} controlId="OrderType">
                  <Form.Label column sm="4" className="fw-bold">
                    Order Status :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      readOnly
                      className="no-border"
                      type="text"
                      placeholder=""
                      value={
                        OrderStatus === "V"
                          ? "Valid"
                          : OrderStatus === "H"
                            ? "Hold"
                            : OrderStatus === "C"
                              ? "Cancel"
                              : ""
                      }
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <hr />
            <OnholdTable
              rowData={rowData}
              trigger={Trigger}
              onChildToggleChange={handleToggleFromChild}
            />
            <hr />
            <Row className="mb-2 justify-content-center">
              <Col md="12">
                <Form.Group as={Row} controlId="OnHoldExpiryDate">
                  <Form.Label column sm="9" className="fw-bold text-end">
                    New on-hold expiry date :
                  </Form.Label>
                  <Col sm="3">
                    {/* <DatePicker
                      dateFormat="dd/MM/yyyy"
                      className="form-control"
                      showIcon
                      selected={OnHoldExpiryDate}
                      onChange={(date) => setOnHoldExpiryDate(date)}
                      minDate={minDate}
                      maxDate={maxDate}
                    /> */}
                    <Controller
                      name="OnHoldExpiryDate"
                      control={control}
                      rules={{
                        required: "This field is required",
                        pattern: {
                          value:
                            /^(0[1-9]|1\d|2\d|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/,
                          message: "Invalid date format",
                        },
                      }}
                      render={({ field }) => (
                        <>
                          <DatePicker
                            selected={field.value}
                            onChange={(date) => field.onChange(date)}
                            minDate={minDate}
                            maxDate={maxDate}
                            className={`form-control ${errors.OnHoldExpiryDate ? "is-invalid" : ""
                              }`}
                          />
                          {errors.OnHoldExpiryDate && (
                            <Form.Text className="text-danger">
                              {errors.OnHoldExpiryDate.message}
                            </Form.Text>
                          )}
                        </>
                      )}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            {/* <Form.Control
              as="textarea"
              rows={3}
              placeholder="New On-hold Remarks"
              value={Remarks}
              onInput={(e) => setRemarks(e.target.value)}
            /> */}
            <Form.Control
              as="textarea"
              rows={3}
              placeholder="New On-hold Remarks"
              value={Remarks}
              className={`form-control ${errors.Remarks ? "is-invalid" : ""}`}
              onInput={(e) => setRemarks(e.target.value)}
              {...register("Remarks", {
                required: "This field is required",
                // pattern: {
                //   value: /^[1-9]\d*$/,
                //   message: "Needs positive integer value",
                // },
                validate: (value) => {
                  return value.length >= 3 || `Minimum 3 character`;
                },
              })}
            />
            {errors.Remarks && (
              <Form.Text className="text-danger">
                {errors.Remarks.message}
              </Form.Text>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={onClose} variant="secondary">
              Cancel
            </Button>
            <Button disabled={rowData.SO_STATUS === "H" ? false : true} onClick={() => handleUnholdClick(rowData)} variant="secondary">
              Un-hold
            </Button>
            <Button
              disabled={rowData.CAN_UPDATE_SO === "N" ? true : false}
              type="submit"
              variant="primary"
            >
              Save
            </Button>
          </Modal.Footer>
        </Form>
      </BlockUi>
    </Modal >
  );
}

export default ManageSoOnHoldModal;
