import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Pagination from "react-bootstrap/Pagination";
import moment from "moment/moment";
import {
  clearUserNameAndTokenInLocalStorage,
  getTokenFromLocalStorage,
} from "../../lib/common";
const config = JSON.parse(process.env.REACT_APP_CONFIG);

function ManageGrnTable(props) {
  const [data, setData] = useState([]);
  const [headerData] = useState([
    "GRN",
    "GRN Date",
    "Actual Delivery Date",
    "PO",
    "Supplier"
  ]);
  const [currentPage, setCurrentPage] = useState(1);
  const { onOnHoldClick } = props;
  const { onRestockClick } = props;
  const { onCancelClick } = props;
  const { onViewClick } = props;
  const { onEditClick } = props;
  const { searchProps } = props;
  const { showAlert } = props;
  const ROWS_PER_PAGE = 10;
  const totalPages = Math.ceil(data.length / ROWS_PER_PAGE);

  useEffect(() => {
    console.log(searchProps);
    if (
      searchProps.po_number ||
      searchProps.po_date_from ||
      searchProps.po_date_to ||
      searchProps.supplier_id ||
      searchProps.supplier_do_number ||
      searchProps.supplier_do_date_from ||
      searchProps.supplier_do_date_to ||
      searchProps.grn_number ||
      searchProps.grn_date_from ||
      searchProps.grn_date_to
    ) {
      getData(config.grn_get_list_link, searchProps);
    } else {
      getData(config.grn_get_list_link);
    }
  }, [searchProps, showAlert]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleToggleBlocking = () => {
    props.onChildToggleChange(false);
  };

  const renderTableData = () => {
    const startIndex = (currentPage - 1) * ROWS_PER_PAGE;
    const endIndex = startIndex + ROWS_PER_PAGE;
    return data.slice(startIndex, endIndex).map((row) => {
      const {
        GRN_DATE,
        GRN_NUMBER,        
        PI_NUMBER,        
        PO_NUMBER,        
        SO_DATE,        
        SO_NUMBER,        
        SUPPLIER_DO_DATE,        
        SUPPLIER_DO_NUMBER,        
        SUPPLIER_ID,        
        SUPPLIER_NAME,        
        CAN_UPDATE
      } = row;
      return (
        <tr key={GRN_NUMBER}>
          <td>{GRN_NUMBER}</td>
          <td>{moment(GRN_DATE).utcOffset(480).format("DD MMM yyyy")}</td>
          <td>{moment(SUPPLIER_DO_DATE).utcOffset(480).format("DD MMM yyyy")}</td>
          <td>{PO_NUMBER}</td>
          <td>{SUPPLIER_NAME}</td>
          <td style={{ width: "248px" }}>
            <Button
              style={{ marginRight: "5px" }}
              size="sm"
              variant="primary"
              onClick={() => onViewClick(row)}
            >
              View
            </Button>
            <Button
              style={{ marginRight: "5px" }}
              size="sm"
              variant="primary"
              onClick={() => onEditClick(row)}
              disabled={CAN_UPDATE === "N" ? true : false}
            >
              Edit
            </Button>
            <Button
              size="sm"
              variant="danger"
              onClick={() => onCancelClick(row)}
              disabled={CAN_UPDATE === "N" ? true : false}
            >
              Delete
            </Button>
          </td>
        </tr>
      );
    });
  };

  const renderPageItems = () => {
    const pageItems = [];
    for (let i = 1; i <= totalPages; i++) {
      pageItems.push(
        <Pagination.Item
          key={i}
          active={i === currentPage}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </Pagination.Item>
      );
    }
    return pageItems;
  };

  const getData = async (url, params = {}) => {
    await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getTokenFromLocalStorage(),
      },
      body: JSON.stringify(params),
    })
      .then((response) => response.json())
      .then((jsonData) => {
        handleToggleBlocking();
        if (jsonData.message === "Access Denied! Invalid token") {
          clearUserNameAndTokenInLocalStorage();
          throw new Error(jsonData.message);
        }
        const newData = jsonData.data.map((item) => {
          const date = moment(item.SO_DATE).utcOffset(480).format("DD MMM yyyy");
          item.SO_DATE = date;
          return item;
        });
        return newData;
      })
      .then((data) => setData(data))
      .catch((error) => console.error(error));
  };

  if (data.length < 1) {
    return (
      <div className="text-center">
        <h3>No Data</h3>
      </div>
    );
  } else {
    return (
      <div>
        <Table striped bordered hover className="mb-4">
          <thead>
            <tr>
              {headerData.map((header) => (
                <th key={header}>{header}</th>
              ))}
            </tr>
          </thead>
          <tbody>{renderTableData()}</tbody>
        </Table>
        <Pagination className="d-flex justify-content-center">
          {renderPageItems()}
        </Pagination>
      </div>
    );
  }
}

export default ManageGrnTable;
