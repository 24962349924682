import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// import ManageBulkOrderMemoTable from "../page_components/ManageBulkOrderMemo/ManageBulkOrderMemo_Table"
// import ManageBulkOrderMemoHistoryModal from "../page_components/ManageBulkOrderMemo/ManageBulkOrderMemo_HistoryModal";
import { useNavigate } from "react-router-dom";
import {
  clearUserNameAndTokenInLocalStorage,
  getTokenFromLocalStorage,
} from "../lib/common";
import BlockUi from "@availity/block-ui";
import "@availity/block-ui/src/BlockUi.css";
import "@availity/block-ui/src/Loader.css";
import AlertModal from "../page_components/AlertModal";
import { IconContext } from "react-icons";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { AiOutlineCloseCircle } from "react-icons/ai";
import NewGoodsReceivedNoteTable from "../page_components/NewGoodsReceivedNote/NewGoodsReceivedNote_Table";
import NewGoodsReceivedNoteEditModal from "../page_components/NewGoodsReceivedNote/NewGoodsReceivedNote_EditModal";

const config = JSON.parse(process.env.REACT_APP_CONFIG);

function NewGoodsReceivedNote() {
  
  const [SlePoDateFrom, setSlePoDateFrom] = useState("");
  const [SlePoDateTo, setSlePoDateTo] = useState("");
  const [SlePoNum, setSlePoNum] = useState("");
  
  const [isPendingRestock, setIsPendingRestock] = useState(false);
  const [searchProps, setSearchProps] = useState({});
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [blocking, setBlocking] = useState(true);
  const [ShowAlert, setShowAlert] = useState(false);
  const [AlertTitle, setAlertTitle] = useState("");
  const [AlertMessage, setAlertMessage] = useState("");
  const [ApiResponse, setApiResponse] = useState("");
  const [Trigger, setTrigger] = useState(0);
  const navigate = useNavigate();
  const [SuppliersList, setSuppliersList] = useState([]);
  const [SupplierName, setSupplierName] = useState("");

  useEffect(() => {
    fetch(config.supplier_list_link,{
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
          "Authorization" : "Bearer " + getTokenFromLocalStorage()
        },
        body : JSON.stringify({})
      })
      .then(response => response.json())
      .then (jsonData => {
        if (jsonData.success !== true && jsonData.message === 'Access Denied! Invalid token'){
          clearUserNameAndTokenInLocalStorage();
          throw new Error (jsonData.message);
        } else if (jsonData.success){
          return jsonData.data;
        } else {
          throw new Error (jsonData.message);
        }
      })
      .then(data => {
        setSuppliersList(data);
      })
      .catch(error => console.error(error));
  }, []);

  useEffect(() => {
    if (getTokenFromLocalStorage() === null) {
      const state = { data: "Token expired, please login again" };
      navigate("/login", { state });
    }
  });

  const handleShowAlert = async (status, title, message, apiResponse = "") => {
    setAlertTitle(title);
    if (status === "ok") {
      setAlertMessage(
        <>
          <p className="mb-1">
            <IconContext.Provider
              value={{
                color: "green",
                style: { fontSize: "40px", paddingRight: "5px" },
              }}
            >
              <AiOutlineCheckCircle />
              {message}
            </IconContext.Provider>
          </p>
        </>
      );
    } else {
      setAlertMessage(
        <>
          <p className="mb-1">
            <IconContext.Provider
              value={{
                color: "red",
                style: { fontSize: "40px", paddingRight: "5px" },
              }}
            >
              <AiOutlineCloseCircle />
              {message}
            </IconContext.Provider>
          </p>
          {apiResponse !== "" ? (
            <p className="text-danger fst-italic ps-3">{apiResponse}</p>
          ) : (
            ""
          )}
        </>
      );
    }
    setShowAlert(true);
  };

  const handleCloseAlert = () => {
    setShowAlert(false);
  };

  const handleToggleBlocking = () => {
    setBlocking((prevState) => !prevState);
  };

  const handleToggleFromChild = (newValue) => {
    setBlocking(newValue);
  };

  const handleResetForm = () => {
    handleToggleBlocking();

    setSlePoDateFrom("");
    setSlePoNum("");
    setSupplierName("");

    const searchProperties = {
      SlePoDateFrom: "",
      SlePoDateTo: "",
      SlePoNum: "",
      SupplierName: "",
    };
    setSearchProps(searchProperties);
  };
  const handleSearch = (event) => {
    event.preventDefault();
    const searchProperties = {
      SlePoDateFrom,
      SlePoDateTo,
      SlePoNum,
      SupplierName,
    };
    setSearchProps(searchProperties);
  };

  const handleNewClick = (rowData) => {
    fetch(config.seng_liy_backend_url +'/grn/pending', {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getTokenFromLocalStorage(),
      },
      body: JSON.stringify({
        po_number: SlePoNum,
      }),
    })
      .then((response) => response.json())
      .then((jsonData) => {
        if (
          jsonData.success !== true &&
          jsonData.message === "Access Denied! Invalid token"
        ) {
          clearUserNameAndTokenInLocalStorage();
          const state = { data: "Token expired, please login again" };
          navigate("/login", { state });
          throw new Error(jsonData.message);
        } else if (jsonData.success) {
          setSelectedRowData(rowData);
          setShowModal("new");
        } else {
          throw new Error(jsonData.message);
        }
      })
      .catch((error) => console.error(error));
  };

  return (
    <div>
      <Container className="mt-4">
        <BlockUi blocking={blocking}>
          <Row className="justify-content-center mb-4">
            <Col md={6}>
              <Form onSubmit={handleSearch}>
                <Row className="mb-4">
                  <Col className="text-center">
                    <h2>Pending Goods Received Note</h2>
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col>
                    <Form.Group as={Row}  className="mb-3"  controlId="SLESONumber">
                      <Form.Label column sm="4">
                        SLE PO Number
                      </Form.Label>
                      <Col sm="7" style={{ paddingRight: "3px" }}>
                        <Form.Control
                          type="text"
                          placeholder="SLE PO Number"
                          value={SlePoNum}
                          onInput={(e) => setSlePoNum(e.target.value)}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col>
                    <Form.Group as={Row}  className="mb-3"  controlId="SLESODateFrom">
                      <Form.Label column sm="4">
                        SLE PO Date From
                      </Form.Label>
                      <Col sm="7" as={Row} className="m-0" style={{ paddingRight: "3px" }}>
                        <Col sm="5" className="p-0">
                          <DatePicker
                            className="form-control"
                            dateFormat="dd/MM/yyyy"
                            showIcon
                            selected={SlePoDateFrom}
                            onChange={(date) => setSlePoDateFrom(date)}
                          />
                        </Col>
                        <Col sm="2" className="p-0 d-flex align-items-center justify-content-center">
                          <Form.Label>
                            to
                          </Form.Label>
                        </Col>
                        <Col sm="5" className="p-0">
                          <DatePicker
                            className="form-control"
                            dateFormat="dd/MM/yyyy"
                            showIcon
                            selected={SlePoDateTo}
                            onChange={(date) => setSlePoDateTo(date)}
                          />
                        </Col>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="SupplierName"
                    >
                      <Form.Label column sm="4">
                        Supplier Name
                      </Form.Label>
                      <Col sm="7" style={{ paddingRight: "3px" }}>
                        <Form.Select
                          value={SupplierName}
                          onChange={(e) => setSupplierName(e.target.value)}
                        >
                          <option key={""} value="">
                            Please select supplier name
                          </option>
                          {SuppliersList.map((items) => (
                            <option
                              key={items.SUPPLIER_ID}
                              value={items.SUPPLIER_ID}
                            >
                              {items.SUPPLIER_NAME}
                            </option>
                          ))}
                        </Form.Select>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col className="text-center">
                    <Button
                      className="me-2"
                      variant="secondary"
                      onClick={handleResetForm}
                    >
                      Reset
                    </Button>
                    <Button
                      type="submit"
                      variant="primary"
                      onClick={handleToggleBlocking}
                    >
                      Search
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
          <hr />
          <br />
          <NewGoodsReceivedNoteTable
            showAlert={ShowAlert}
            searchProps={searchProps}
            onChildToggleChange={handleToggleFromChild}
            onNewClick={handleNewClick}
          />
          {showModal === "new" ? (
            <NewGoodsReceivedNoteEditModal
              handleCloseAlert={handleCloseAlert}
              handleShowAlert={handleShowAlert}
              rowData={selectedRowData}
              onClose={() => setShowModal(false)}
            />
          ) : (
            ""
          )}
          
        </BlockUi>
        {ShowAlert && (
          <AlertModal
            title={AlertTitle}
            message={AlertMessage}
            apiResponse={ApiResponse}
            onClose={handleCloseAlert}
          />
        )}
      </Container>
    </div>
  );
}

export default NewGoodsReceivedNote;
