import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import CustomersNewAddressModal from "./Customers_NewCustomerAddAddressModal";
import CustomersAddressTable from "./Customers_AddressTable";
import CustomersEditAddressModal from "./Customers_NewCustomerEditAddressModal";
import DeleteAddressModal from "./Customers_DeleteAddressModal";
import {
  clearUserNameAndTokenInLocalStorage,
  getTokenFromLocalStorage,
} from "../../lib/common";
import BlockUi from "@availity/block-ui";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";

const config = JSON.parse(process.env.REACT_APP_CONFIG);

function CustomersNewProductModal(props) {
  const {
    handleSubmit,
    reset,
    register,
    formState: { errors },
  } = useForm({
    defaultValues: {
      CustomerName: "",
      CustomerStatus: "",
      DebtorCode: "",
      CustomerEmail: "",
    },
  });
  const {
    onClose,
    handleShowAlert,
    handleCloseAlert,
  } = props;
  const [CustomerName, setCustomerName] = useState("");
  const [CustomerEmail, setCustomerEmail] = useState("");
  const [DebtorCode, setDebtorCode] = useState("");
  const [CustomerStatus, setCustomerStatus ] = useState("A");

  const [blocking, setBlocking] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState({});
  const [ItemList, setItemList] = useState([]);
  const navigate = useNavigate();

  const handleToggleBlocking = (value) => {
    setBlocking(value);
  };

  const handleAddItemList = (newValue) => {
    setItemList([...ItemList, newValue]);
  };

  const handleEditItemList = (newValue) => {
    console.log("newValue");
    console.log(newValue);
    const updatedItems = ItemList.map((item) => {
      if (item.ITEM_ID === newValue.ITEM_ID) {
        return newValue;
      }
      return item; 
    });

    setItemList(updatedItems);
  };

  const handleDeleteItemList = (newValue) => {
    const updatedItems = ItemList.filter(
      (item) => item.ITEM_ID !== newValue.ITEM_ID
    );

    console.log(updatedItems);

    setItemList(updatedItems);
  };

  const handleNewAddress = () => {
    setShowModal("newaddress");
  };

  const handleEditClick = (rowData) => {
    setSelectedRowData(rowData);
    setShowModal("edit");
  };

  const handleDeleteClick = (rowData) => {
    setSelectedRowData(rowData);
    setShowModal("delete");
  };

  const submitNewCustomer = async () => {
    try {
      var arrCustomerAddrID = [];
      var arrAddrType = [];
      var arrAddr1Name = [];
      var arrAddr2Name = [];
      var arrAddr3Name = [];
      var arrAddr4Name = [];
      var arrPostCode = [];
      var arrCity = [];
      var arrState = [];
      var arrCountryName = [];
      var arrCountryCode = [];
      var arrContactName = [];
      var arrContactPhone = [];

      if (ItemList.length === 0) {
        handleShowAlert(
          "error",
          "New Customer",
          "Failed Saving New Customer :",
          ["Address list is empty!"]
        );
        return;
      }

      handleToggleBlocking(true);

      for (var i = 0; i < ItemList.length; i++) {
        arrCustomerAddrID.push(ItemList[i].CUSTOMER_ADDR_ID);
        arrAddrType.push(ItemList[i].ADDR_TYPE);
        arrAddr1Name.push(ItemList[i].ADDR1_NAME);
        arrAddr2Name.push(ItemList[i].ADDR2_NAME);
        arrAddr3Name.push(ItemList[i].ADDR3_NAME);
        arrAddr4Name.push(ItemList[i].ADDR4_NAME);
        arrPostCode.push(ItemList[i].POST_CODE);
        arrCity.push(ItemList[i].CITY);
        arrState.push(ItemList[i].STATE);
        arrCountryName.push(ItemList[i].COUNTRY_NAME);
        arrCountryCode.push(ItemList[i].COUNTRY_CODE);
        arrContactName.push(ItemList[i].CONTACT_NAME);
        arrContactPhone.push(ItemList[i].CONTACT_PHONE);
      }

      var jsonOb = {
        customer_name: CustomerName,
        debtor_code: DebtorCode,
        customer_email: CustomerEmail,
        customer_status: CustomerStatus,
        customer_addr_id: arrCustomerAddrID,
        addr_type: arrAddrType,
        addr1_name: arrAddr1Name,
        addr2_name: arrAddr2Name,
        addr3_name: arrAddr3Name,
        addr4_name: arrAddr4Name,
        post_code: arrPostCode,
        city: arrCity,
        state: arrState,
        country_name: arrCountryName,
        country_code: arrCountryCode,
        contact_name: arrContactName,
        contact_phone: arrContactPhone,
      };
      console.log(jsonOb);
      const response = await fetch(config.customer_create_link, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + getTokenFromLocalStorage(),
        },
        body: JSON.stringify(jsonOb),
      });

      const contentType = response.headers.get("content-type");
      const data =
        contentType && contentType.includes("application/json")
          ? await response.json()
          : await response.text();
      console.log("result :");
      console.log(data);
      if (data.message === "Access Denied! Invalid token") {
        clearUserNameAndTokenInLocalStorage();
        const state = { data: "Token expired, please login again" };
        navigate("/login", { state });
        throw data.message;
      } else if (data.message) {
        throw data.message;
      }
      if (response.status !== 200) {
        var errorMessages = data.error.map((item) => item.msg);
        handleShowAlert(
          "error",
          "New Customer",
          "Failed Saving New Customer :",
          errorMessages
        );
        //throw errorMessages.join("<br/>");
      } else {
        handleShowAlert(
          "ok",
          "New Customer",
          "Success Saving New Customer"
        );
        onClose();
        return "ok";
      }
    } catch (error) {
      console.error("Error while submitting New Customer:", error);
      handleShowAlert(
        "error",
        "New Customer",
        "Error while saving New Customer:",
        error
      );
    } finally {
      handleToggleBlocking(false);
    }
  };

  return (
    <Modal show={true} onHide={onClose} size="xl" backdrop="static" centered>
      <BlockUi blocking={blocking}>
        <Form onSubmit={handleSubmit(submitNewCustomer)}>
          <Modal.Header closeButton className="px-4">
            <Modal.Title className="ms-auto">New Customer</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="mb-2 justify-content-center">
              <Col md="8">
                <Form.Group as={Row} controlId="CustomerName">
                  <Form.Label column sm="4" className="fw-bold">
                    Customer Name :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      type="text"
                      placeholder="Customer Name"
                      value={CustomerName}
                      maxLength={250}
                      onInput={(e) => setCustomerName(e.target.value)}
                      className={`form-control ${
                        errors.CustomerName ? "is-invalid" : ""
                      }`}
                      {...register("CustomerName", {
                        pattern: {
                          value: /^.{3,}$/,
                          message: "Minimum 3 Character",
                        },
                        validate: (value) => {
                          return value !== "" || "This field is required";
                        },
                      })}
                    />
                    {errors.CustomerName && (
                      <p className="text-danger">
                        {errors.CustomerName.message}
                      </p>
                    )}
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-2 justify-content-center">
              <Col md="8">
                <Form.Group as={Row} controlId="CustomerEmail">
                  <Form.Label column sm="4" className="fw-bold">
                    Customer Email :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      type="email"
                      placeholder="Customer Email"
                      value={CustomerEmail}
                      maxLength={100}
                      onInput={(e) => setCustomerEmail(e.target.value)}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-2 justify-content-center">
              <Col md="8">
                <Form.Group as={Row} controlId="DebtorCode">
                  <Form.Label column sm="4" className="fw-bold">
                    Debtor Code :
                  </Form.Label>
                  <Col sm="5">
                    <Form.Control
                      type="text"
                      placeholder="Debtor Code"
                      value={DebtorCode}
                      maxLength={20}
                      onInput={(e) => setDebtorCode(e.target.value)}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-2 justify-content-center">
              <Col md="8">
                <Form.Group as={Row} controlId="CustomerStatus" className="align-items-center">
                  <Form.Label column sm="4" className="fw-bold">
                    Status :
                  </Form.Label>
                  <Col sm="7">
                    <Form.Check
                      inline
                      type="radio"
                      label="Active"
                      name="statusRadios"
                      id="statusRadios1"
                      checked={CustomerStatus === "A"}
                      onChange={() => setCustomerStatus("A")}
                    />
                    <Form.Check
                      inline
                      type="radio"
                      label="Inactive"
                      name="statusRadios"
                      id="statusRadios2"
                      checked={CustomerStatus === "I"} 
                      onChange={() => setCustomerStatus("I")} 
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <hr />
            <Row className="mb-4 align-items-center">
              <Col sm="9" className="text-center">
                <Form.Label>Addresses</Form.Label>
              </Col>
              <Col sm="3">
                <Button
                  className="me-2"
                  variant="secondary"
                  onClick={handleNewAddress}
                >
                  Add Address
                </Button>
              </Col>
            </Row>
            <CustomersAddressTable
              itemList={ItemList}
              onEditClick={handleEditClick}
              onDeleteClick={handleDeleteClick}
              fromEditModal={false} />
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={onClose} variant="secondary">
              Close
            </Button>
            <Button type="submit" variant="primary">
              Save
            </Button>
          </Modal.Footer>
        </Form>
        {showModal === "newaddress" ? (
          <CustomersNewAddressModal
            handleAddItemList={handleAddItemList}
            handleCloseAlert={handleCloseAlert}
            handleShowAlert={handleShowAlert}
            itemList={ItemList}
            rowData={selectedRowData}
            onClose={() => setShowModal(false)}
          />
        ) : (
          ""
        )}
        {showModal === "edit" ? (
          <CustomersEditAddressModal
            handleEditItemList={handleEditItemList}
            handleShowAlert={handleShowAlert}
            itemList={ItemList}
            rowData={selectedRowData}
            onClose={() => setShowModal(false)}
          />
        ) : (
          ""
        )}
        {showModal === "delete" ? (
          <DeleteAddressModal
            handleDeleteItemList={handleDeleteItemList}
            handleShowAlert={handleShowAlert}
            rowData={selectedRowData}
            onClose={() => setShowModal(false)}
          />
        ) : (
          ""
        )}
      </BlockUi>
    </Modal>
  );
}

export default CustomersNewProductModal;
