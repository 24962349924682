import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import DatePicker from "react-datepicker";
import FollowUpTable from './DeliveryStatus_FollowUpTable';
import moment from 'moment/moment';
import { clearUserNameAndTokenInLocalStorage, getUserNameFromLocalStorage, getTokenFromLocalStorage } from '../../lib/common';
import BlockUi from '@availity/block-ui';

const config = JSON.parse(process.env.REACT_APP_CONFIG);

function DeliveryStatusFollowUpModal(props) {
  const { rowData, onClose, handleShowAlert } = props;
  const [SleSoNum] = useState(rowData.SO_NUMBER);
  const [SlePoNum] = useState(rowData.PO_NUMBER);
  const [SleSoDate] = useState(new Date(rowData.SO_DATE));
  const [SlePoDate] = useState(new Date(rowData.PO_DATE));
  const [SleDoNum] = useState(() => GetSleDoNum());
  const [SleDoDate] = useState(() => GetSleDoDate());
  const [OrderType] = useState(rowData.SO_TYPE_DESC);
  const [Remarks, setRemarks] = useState("");
  const [Trigger, setTrigger] = useState("");
  const [blocking, setBlocking] = useState(true);

  const handleSubmitRemarks = async (event) => {
    event.preventDefault();

    try {
      await submitRemarks();
      setRemarks("");
      handleShowAlert("ok", "Delivery Status Follow-Up", "Success saving Follow-up");
    } catch (error){
      console.error("Error while saving Remarks:", error);
      handleShowAlert("error", "Delivery Status Follow-Up", "Error while saving Follow-up :", error);
    }

  }

  const handleToggleBlocking = () => {
    setBlocking(prevState => !prevState);
  };

  const handleToggleFromChild = (newValue) => {
    setBlocking(newValue);
  };

  function GetSleDoNum(){
    if (rowData.DO_NUMBER === null || rowData.DO_DATE === undefined){
      return("");
    } else {
      return rowData.DO_NUMBER;
    }
  }

  function GetSleDoDate(){
    if (rowData.DO_DATE === null || rowData.DO_DATE === undefined){
      return new Date();
    } else {
      return new Date(rowData.DO_DATE);
    }
  }

  const submitRemarks = async () => {
    try {
      const response = await fetch(config.deliverystatus_followup_create_link, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          "Authorization" : "Bearer " + getTokenFromLocalStorage()
        },
        body: JSON.stringify({
          do_number: SleDoNum,
          comments : Remarks,
          user_modify : getUserNameFromLocalStorage()
        })
      });
  
      const data = await response.json();
      console.log("submit remarks response : ");
      console.log(data);
      if (
        data.success !== true &&
        data.message === "Access Denied! Invalid token"
      ) {
        clearUserNameAndTokenInLocalStorage();
      }

      if (data.success !== true) {
        var errorMessages = data.error.map((item) => item.msg);
        // handleShowAlert("error", "New Invoice", "Failed Saving New Invoice :", errorMessages.join("<br>"));
        throw errorMessages;
      }
      if (data.success === true) {
        console.log("Success Submitting Delivery Status Follow-up");
        setTrigger(moment().unix());
        return "ok";
      } else {
        throw data;
      }
    } catch (error) {
      console.error("Error while submitting Delivery Status Follow-up:", error);
      throw error;
    }
  };

  return (
    <Modal show={true} onHide={onClose} size="lg" backdrop="static" centered>
      <BlockUi blocking={blocking}>
        <Form onSubmit = {handleSubmitRemarks}>
          <Modal.Header closeButton className="px-4">
            <Modal.Title className="ms-auto">Delivery Status Follow-up</Modal.Title>
          </Modal.Header>
          <Modal.Body>
              <Row className="mb-2 justify-content-center">
                <Col md="6">
                  <Form.Group as={Row} controlId="SLESONumber">
                    <Form.Label column sm="6" className="fw-bold">
                      SLE SO Number :
                    </Form.Label>
                    <Col sm="6">
                      <Form.Control readOnly className="no-border" type="text" placeholder="" value={SleSoNum}/>
                    </Col>
                  </Form.Group>
                </Col>

                <Col md="6">
                  <Form.Group as={Row} controlId="SLESODate">
                      <Form.Label column sm="6" className="fw-bold">
                        SLE SO Date :
                      </Form.Label>
                      <Col sm="6">
                        <DatePicker readOnly dateFormat="dd/MM/yyyy" className="form-control no-border" showIcon selected={SleSoDate}/>
                      </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-2 justify-content-center">
                <Col md="6">
                  <Form.Group as={Row} controlId="SLEPONumber">
                    <Form.Label column sm="6" className="fw-bold">
                      SLE PO Number :
                    </Form.Label>
                    <Col sm="6">
                      <Form.Control readOnly className="no-border" type="text" placeholder="" value={SlePoNum}/>
                    </Col>
                  </Form.Group>
                </Col>

                <Col md="6">
                  <Form.Group as={Row} controlId="SLEPODate">
                      <Form.Label column sm="6" className="fw-bold">
                        SLE PO Date :
                      </Form.Label>
                      <Col sm="6">
                        <DatePicker readOnly dateFormat="dd/MM/yyyy" className="form-control no-border" showIcon selected={SlePoDate} />
                      </Col>
                  </Form.Group>
                </Col>
              </Row>
              {SleDoNum !== "" ?
              <>
              <Row className="mb-2 justify-content-center">
                  <Col md="6">
                  <Form.Group as={Row} controlId="SLEDONumber">
                      <Form.Label column sm="6" className="fw-bold">
                      SLE DO Number :
                      </Form.Label>
                      <Col sm="6">
                      <Form.Control readOnly className="no-border" type="text" placeholder="" value={SleDoNum}/>
                      </Col>
                  </Form.Group>
                  </Col>

                  <Col md="6">
                  <Form.Group as={Row} controlId="SLEDODate">
                      <Form.Label column sm="6" className="fw-bold">
                          SLE DO Date :
                      </Form.Label>
                      <Col sm="6">
                          <DatePicker readOnly dateFormat="dd/MM/yyyy" className="form-control no-border" showIcon selected={SleDoDate} />
                      </Col>
                  </Form.Group>
                  </Col>
              </Row>
            </> : ""
            }
            <Row>
              <Col md="6">
                <Form.Group as={Row} controlId="OrderType">
                  <Form.Label column sm="6" className="fw-bold">
                    Order Type :
                  </Form.Label>
                  <Col sm="6">
                    <Form.Control readOnly className="no-border" type="text" placeholder="" value={OrderType}/>
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <hr/>
            <FollowUpTable rowData = {rowData} trigger={Trigger} onChildToggleChange={handleToggleFromChild}/>
            <hr/>
            <Form.Control as="textarea" rows={3} placeholder="New Follow-up Remarks" value={Remarks} onInput={(e) => setRemarks(e.target.value)}/>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={onClose} variant="secondary">Close</Button>
          <Button type="submit" variant="primary" onClick={handleToggleBlocking}>Save</Button>
        </Modal.Footer>
      </Form>
      </BlockUi>
    </Modal>
  );
}

export default DeliveryStatusFollowUpModal;