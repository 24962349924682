import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import NewPOItemsTable from "../page_components/NewPOAdhoc/NewPOAdhoc_ItemsTable";
import NewPOItemModal from "../page_components/NewPOAdhoc/NewPOAdhoc_NewPOItemModal";
import EditPOItemModal from "../page_components/NewPOAdhoc/NewPOAdhoc_EditPOItemModal";
import DeletePOItemModal from "../page_components/NewPOAdhoc/NewPOAdhoc_DeletePOItemModal";
import SLEPOTable from "../page_components/SLEPO/SLEPO_Table";
import SLEPOModal from "../page_components/SLEPO/SLEPO_Modal";
import { useNavigate } from "react-router-dom";
import {
  clearUserNameAndTokenInLocalStorage,
  getTokenFromLocalStorage,
  getUserNameFromLocalStorage,
} from "../lib/common";
import BlockUi from "@availity/block-ui";
import "@availity/block-ui/src/BlockUi.css";
import "@availity/block-ui/src/Loader.css";
import AlertModal from "../page_components/AlertModal";
import moment from "moment/moment";
import { IconContext } from "react-icons";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { useForm, Controller } from "react-hook-form";

const config = JSON.parse(process.env.REACT_APP_CONFIG);

function NewPoAdhoc() {
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
    setValue,
    getValues,
    trigger,
  } = useForm({
    defaultValues: {
      SupplierId: "",
      ExpectedDeliveryDate: new Date(),
      Currency: "MYR",
      DeliveryTo: "W",
    },
  });
  const [CustomerId, setCustomerId] = useState("");
  const [CustomerList, setCustomerList] = useState([]);
  const [ProjectId, setProjectId] = useState("");
  const [ProjectList, setProjectList] = useState([]);
  const [OrderType, setOrderType] = useState("C");
  const [OrderTypeDesc] = useState("Consignment");
  const [OrderTypeList, setOrderTypeList] = useState([]);
  const [PaymentTerm, setPaymentTerm] = useState("");
  const [CustomerRef, setCustomerRef] = useState("");
  const [ItemList, setItemList] = useState([]);
  const [searchProps, setSearchProps] = useState({});
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [ShowAlert, setShowAlert] = useState(false);
  const [AlertTitle, setAlertTitle] = useState("");
  const [AlertMessage, setAlertMessage] = useState("");
  const [ApiResponse, setApiResponse] = useState("");
  const [SupplierList, setSupplierList] = useState([]);
  const [SupplierId, setSupplierId] = useState("");
  const [PoDate, setPoDate] = useState(new Date());
  const [ExpectedDeliveryDate, setExpectedDeliveryDate] = useState(new Date());
  const [Remarks, setRemarks] = useState("");
  const [CurrencyList, setCurrencyList] = useState([]);
  const [Currency, setCurrency] = useState("MYR");
  const [DeliveryTo, setDeliveryTo] = useState("W");
  const [OrderTypeCode] = useState("");
  const [IsIssued, setIsIssued] = useState(false);
  const [blocking, setBlocking] = useState(false);
  const [minDate] = useState(new Date());
  const [maxDate] = useState(() => {
    const maxDate = new Date();
    maxDate.setMonth(new Date().getMonth() + 3);
    const formattedMinDate = maxDate;
    return formattedMinDate;
  });
  const navigate = useNavigate();

  useEffect(() => {
    if (getTokenFromLocalStorage() === null) {
      const state = { data: "Token expired, please login again" };
      navigate("/login", { state });
    }
  });

  useEffect(() => {
    fetch(config.currency_list_link, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getTokenFromLocalStorage(),
      },
      body: JSON.stringify({}),
    })
      .then((response) => response.json())
      .then((jsonData) => {
        if (jsonData.message === "Access Denied! Invalid token") {
          clearUserNameAndTokenInLocalStorage();
          throw new Error(jsonData.message);
        }
        return jsonData;
      })
      .then((data) => {
        setCurrencyList(data.data);
        console.log(data);
      })
      .catch((error) => console.error(error));
  }, []);

  useEffect(() => {
    fetch(config.sotype_list_link, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getTokenFromLocalStorage(),
      },
      body: JSON.stringify({}),
    })
      .then((response) => response.json())
      .then((jsonData) => {
        handleToggleFromChild(false);
        if (
          jsonData.success !== true &&
          jsonData.message === "Access Denied! Invalid token"
        ) {
          clearUserNameAndTokenInLocalStorage();
          throw new Error(jsonData.message);
        } else if (jsonData.success) {
          return jsonData.data;
        } else {
          throw new Error(jsonData.message);
        }
      })
      .then((data) => {
        console.log(data);
        setOrderTypeList(data);
      })
      .catch((error) => console.error(error));
  }, []);

  useEffect(() => {
    handleToggleBlocking(true);
    fetch(config.supplier_active_list_link, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getTokenFromLocalStorage(),
      },
      body: JSON.stringify({}),
    })
      .then((response) => response.json())
      .then((jsonData) => {
        handleToggleFromChild(false);
        if (
          jsonData.success !== true &&
          jsonData.message === "Access Denied! Invalid token"
        ) {
          clearUserNameAndTokenInLocalStorage();
          throw new Error(jsonData.message);
        } else if (jsonData.success) {
          return jsonData.data;
        } else {
          throw new Error(jsonData.message);
        }
      })
      .then((data) => {
        setSupplierList(data);
      })
      .catch((error) => console.error(error))
      .finally(() => {
        handleToggleBlocking(false);
      });
  }, []);

  useEffect(() => {
    fetch(config.customer_active_list_link, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getTokenFromLocalStorage(),
      },
      body: JSON.stringify({}),
    })
      .then((response) => response.json())
      .then((jsonData) => {
        handleToggleFromChild(false);
        if (
          jsonData.success !== true &&
          jsonData.message === "Access Denied! Invalid token"
        ) {
          clearUserNameAndTokenInLocalStorage();
          throw new Error(jsonData.message);
        } else if (jsonData.success) {
          return jsonData.data;
        } else {
          throw new Error(jsonData.message);
        }
      })
      .then((data) => {
        setCustomerList(data);
      })
      .catch((error) => console.error(error));
  }, []);

  useEffect(() => {
    if (CustomerId !== "") {
      fetch(config.project_list_link, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + getTokenFromLocalStorage(),
        },
        body: JSON.stringify({
          customer_id: CustomerId,
        }),
      })
        .then((response) => response.json())
        //   .then(res => console.log(res))
        .then((jsonData) => {
          if (
            jsonData.success !== true &&
            jsonData.message === "Access Denied! Invalid token"
          ) {
            clearUserNameAndTokenInLocalStorage();
            throw new Error(jsonData.message);
          } else if (jsonData.success) {
            return jsonData.data;
          } else {
            throw new Error(jsonData.message);
          }
        })
        .then((data) => {
          setProjectList(data);
          setValue("ProjectId", "");
          // trigger("ProjectId");
        })
        .catch((error) => console.error(error));
    }
  }, [CustomerId]);

  const handleShowAlert = async (status, title, message, apiResponse = []) => {
    setAlertTitle(title);
    if (status === "ok") {
      setAlertMessage(
        <>
          <p className="mb-1">
            <IconContext.Provider
              value={{
                color: "green",
                style: { fontSize: "40px", paddingRight: "5px" },
              }}
            >
              <AiOutlineCheckCircle />
              {message}
              <br></br>
            </IconContext.Provider>
          </p>
          <p className="ps-3">Sales Order Number : {apiResponse[0]}</p>
        </>
      );
    } else {
      setAlertMessage(
        <>
          <p className="mb-1">
            <IconContext.Provider
              value={{
                color: "red",
                style: { fontSize: "40px", paddingRight: "5px" },
              }}
            >
              <AiOutlineCloseCircle />
              {message}
            </IconContext.Provider>
          </p>
          {apiResponse.length > 0
            ? apiResponse.map((response) => (
                <p className="text-danger fst-italic ps-3">{response}</p>
              ))
            : ""}
        </>
      );
    }
    setShowAlert(true);
  };

  const handleCloseAlert = () => {
    setShowAlert(false);
  };

  const handleToggleBlocking = (value) => {
    setBlocking(value);
  };

  const handleToggleFromChild = (newValue) => {
    setBlocking(newValue);
  };

  const handleResetForm = () => {
    reset();
    setCustomerId("");
    setProjectId("");
    setProjectList([]);
    setOrderType("");
    setPaymentTerm("");
    setItemList([]);

    // const searchProperties = {
    //   SleSoDateFrom: "",
    //   SleSoDateTo: "",
    //   SleSoNum: "",
    // };
    // setSearchProps(searchProperties);
  };
  // const handleSearch = (event) => {
  //   event.preventDefault();
  //   const searchProperties = {
  //     SleSoDateFrom: SleSoDateFrom,
  //     SleSoDateTo: SleSoDateTo,
  //     SleSoNum: SleSoNum,
  //   };
  //   setSearchProps(searchProperties);
  // };

  // const handleIssuePOClick = (rowData) => {
  //   setSelectedRowData(rowData);
  //   setShowModal(true);
  // };

  const handleAddItemList = (newValue) => {
    setItemList([...ItemList, newValue]);
  };

  const handleEditItemList = (newValue) => {
    const updatedItems = ItemList.map((item) => {
      if (item.ITEM_ID === newValue.ITEM_ID) {
        return newValue; // Replace the matching item
      }
      return item; // Keep other items unchanged
    });

    setItemList(updatedItems);
    // setItemList([...ItemList, newValue]);
  };

  const handleDeleteItemList = (newValue) => {
    const updatedItems = ItemList.filter(
      (item) => item.ITEM_ID !== newValue.ITEM_ID
    );

    console.log(updatedItems);

    setItemList(updatedItems);
    // setItemList([...ItemList, newValue]);
  };

  const handleNewSo = () => {
    if (SupplierId !== "") {
      setShowModal("newpo");
    } else {
      handleShowAlert(
        "error",
        "New PO (Adhoc)",
        "Failed Adding New PO (Adhoc) Item :",
        ["Supplier is not selected!"]
      );
      throw new Error("Supplier is not selected!");
    }
  };
  const handleEditClick = (rowData) => {
    setSelectedRowData(rowData);
    setShowModal("edit");
  };

  const handleDeleteClick = (rowData) => {
    setSelectedRowData(rowData);
    setShowModal("delete");
  };

  const onSubmit = async () => {
    try {
      var arrProductId = [];
      var arrItemQty = [];
      var arrBrand = [];
      var arrUnitMeasure = [];
      var arrSupplierItemCode = [];
      var arrSupplierItemName = [];
      var arrCostPrice = [];

      if (ItemList.length === 0) {
        handleShowAlert(
          "error",
          "New Sales Order",
          "Failed Saving New Sales Order :",
          ["Order Item is empty!"]
        );
        throw new Error("Order Item is empty!");
      }

      handleToggleBlocking(true);

      for (var i = 0; i < ItemList.length; i++) {
        arrProductId.push(ItemList[i].PRODUCT_ID);
        arrItemQty.push(parseInt(ItemList[i].ITEM_QTY));
        arrBrand.push(ItemList[i].BRAND);
        arrUnitMeasure.push(ItemList[i].UNIT_MEASURE);
        arrSupplierItemCode.push(
          ItemList[i].SUPPLIER_ITEM_CODE ? ItemList[i].SUPPLIER_ITEM_CODE : ""
        );
        arrSupplierItemName.push(
          ItemList[i].SUPPLIER_ITEM_NAME ? ItemList[i].SUPPLIER_ITEM_NAME : ""
        );
        arrCostPrice.push(
          parseFloat(ItemList[i].COST_PRICE).toFixed(2) !== "NaN"
            ? parseFloat(ItemList[i].COST_PRICE).toFixed(2)
            : null
        );
      }

      var jsonOb = {
        po_type: "A",
        po_date: moment(PoDate).format("YYYY-MM-DD"),
        expected_delivery_date:
          moment(ExpectedDeliveryDate).format("YYYY-MM-DD"),
        currency_code: Currency,
        supplier_id: SupplierId,
        remarks: Remarks,
        product_id: arrProductId,
        item_qty: arrItemQty,
        purchase_price: arrCostPrice,
      };
      console.log(jsonOb);
      const response = await fetch(config.po_create_link, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + getTokenFromLocalStorage(),
        },
        body: JSON.stringify(jsonOb),
      });

      const contentType = response.headers.get("content-type");
      const data =
        contentType && contentType.includes("application/json")
          ? await response.json()
          : await response.text();
      console.log("result :");
      console.log(data);
      if (data.message === "Access Denied! Invalid token") {
        clearUserNameAndTokenInLocalStorage();
        throw data.message;
      } else if (data.message) {
        throw data.message;
      }
      if (response.status !== 200) {
        var errorMessages = data.error.map((item) => item.msg);
        handleShowAlert(
          "error",
          "New PO (Adhoc)",
          "Failed Saving New PO (Adhoc) :",
          errorMessages
        );
        throw errorMessages.join("<br/>");
      } else {
        handleShowAlert(
          "ok",
          "New PO (Adhoc)",
          "Success Saving New PO (Adhoc)",
          [data.po_number]
        );
        handleResetForm();
        return "ok";
      }
    } catch (error) {
      console.error("Error while submitting New PO (Adhoc):", error);
      throw error;
    } finally {
      handleToggleBlocking(false);
    }
  };

  return (
    <div>
      <Container className="mt-4">
        <BlockUi blocking={blocking}>
          <Row className="justify-content-center mb-4">
            <Col md={11}>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <Row className="mb-4">
                  <Col className="text-center">
                    <h2>New PO (Adhoc)</h2>
                  </Col>
                </Row>
                <div style={{ paddingLeft: "100px" }}>
                  <Row className="mb-2">
                    <Col md="12">
                      <Form.Group as={Row} controlId="SupplierId">
                        <Form.Label column sm="2" className="fw-bold">
                          Supplier :
                        </Form.Label>
                        <Col sm="8">
                          <Controller
                            name="SupplierId"
                            control={control}
                            rules={{ required: "This field is required" }}
                            defaultValue=""
                            className={`form-control ${
                              errors.SupplierId ? "is-invalid" : ""
                            }`}
                            render={({ field }) => (
                              <Form.Select
                                {...field}
                                onChange={(e) => {
                                  // setSupplierId(e.target.value);
                                  // setSupplierId(
                                  //   e.target.options[e.target.selectedIndex].text
                                  // );
                                  setValue("SupplierId", e.target.value);
                                  setSupplierId(e.target.value);
                                  trigger("SupplierId");
                                }}
                                className={`form-select ${
                                  errors.SupplierId ? "is-invalid" : ""
                                }`}
                              >
                                <option key={""} value="" disabled>
                                  Please select supplier name
                                </option>
                                {/* <option key={"HOWA"} value="HOWA">
                          HOWA
                        </option>
                        <option key={"GLINE"} value="GLINE">
                          GLINE
                        </option> */}
                                {SupplierList.map((items) => (
                                  <option
                                    key={items.SUPPLIER_ID}
                                    value={items.SUPPLIER_ID}
                                  >
                                    {items.SUPPLIER_NAME}
                                  </option>
                                ))}
                              </Form.Select>
                            )}
                          />
                          {errors.SupplierId && (
                            <Form.Text className="text-danger">
                              {errors.SupplierId.message}
                            </Form.Text>
                          )}
                          {/* <Form.Control
                      readOnly
                      className="no-border"
                      type="text"
                      placeholder=""
                      value={SleSoNum}
                    /> */}
                        </Col>
                      </Form.Group>
                    </Col>

                    {/* <Col md="6">
                <Form.Group as={Row} controlId="SLESODate">
                  <Form.Label column sm="4" className="fw-bold">
                    SLE SO Date :
                  </Form.Label>
                  <Col sm="7">
                    <DatePicker
                      readOnly
                      dateFormat="dd/MM/yyyy"
                      className="form-control no-border"
                      showIcon
                      selected={SleSoDate}
                    />
                  </Col>
                </Form.Group>
              </Col> */}
                  </Row>
                  <Row className="mb-2 ">
                    <Col md="5">
                      <Form.Group as={Row} controlId="SLESONumber">
                        <Form.Label column sm="4" className="fw-bold">
                          PO Date :
                        </Form.Label>
                        <Col sm="6" style={{ marginLeft: "32px" }}>
                          <DatePicker
                            readOnly
                            dateFormat="dd/MM/yyyy"
                            className="form-control no-border"
                            showIcon
                            selected={PoDate}
                          />
                        </Col>
                      </Form.Group>
                    </Col>

                    <Col md="6">
                      <Form.Group as={Row} controlId="SLESODate">
                        <Form.Label column sm="4" className="fw-bold">
                          Expected Delivery :
                        </Form.Label>
                        <Col sm="6">
                          <Controller
                            name="ExpectedDeliveryDate"
                            control={control}
                            rules={{
                              required: "This field is required",
                              pattern: {
                                value:
                                  /^(0[1-9]|1\d|2\d|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/,
                                message: "Invalid date format",
                              },
                            }}
                            render={({ field }) => (
                              <>
                                <DatePicker
                                  selected={field.value}
                                  onChange={(date) => {
                                    field.onChange(date);
                                    setValue("ExpectedDeliveryDate", date);
                                    setExpectedDeliveryDate(date);
                                    trigger("ExpectedDeliveryDate");
                                  }}
                                  minDate={minDate}
                                  maxDate={maxDate}
                                  dateFormat="dd/MM/yyyy"
                                  className={`form-control ${
                                    errors.ExpectedDeliveryDate
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                />
                                {errors.ExpectedDeliveryDate && (
                                  <Form.Text className="text-danger">
                                    {errors.ExpectedDeliveryDate.message}
                                  </Form.Text>
                                )}
                              </>
                            )}
                          />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="mb-2 ">
                    <Col md="5">
                      <Form.Group as={Row} controlId="SLESONumber">
                        <Form.Label column sm="4" className="fw-bold">
                          Currency :
                        </Form.Label>
                        <Col sm="6" style={{ marginLeft: "32px" }}>
                          {/* <Form.Control
                      readOnly
                      className="no-border"
                      type="text"
                      placeholder=""
                      value={CustomerName}
                    /> */}
                          <Controller
                            name="Currency"
                            control={control}
                            rules={{ required: "This field is required" }}
                            defaultValue=""
                            className={`form-control ${
                              errors.Brand ? "is-invalid" : ""
                            }`}
                            render={({ field }) => (
                              <Form.Select
                                {...field}
                                onChange={(e) => {
                                  // setSupplierId(e.target.value);
                                  // setSupplierId(
                                  //   e.target.options[e.target.selectedIndex].text
                                  // );
                                  setValue("Currency", e.target.value);
                                  setCurrency(e.target.value);
                                  trigger("Currency");
                                }}
                                className={`form-select ${
                                  errors.Currency ? "is-invalid" : ""
                                }`}
                              >
                                <option key={""} value="" disabled>
                                  Please select currency
                                </option>
                                {/* <option key={"HOWA"} value="HOWA">
                          HOWA
                        </option>
                        <option key={"GLINE"} value="GLINE">
                          GLINE
                        </option> */}
                                {CurrencyList.map((items) => (
                                  <option
                                    key={items.CURRENCY_CODE}
                                    value={items.CURRENCY_CODE}
                                  >
                                    {items.CURRENCY_NAME}
                                  </option>
                                ))}
                              </Form.Select>
                            )}
                          />
                          {errors.Currency && (
                            <Form.Text className="text-danger">
                              {errors.Currency.message}
                            </Form.Text>
                          )}
                        </Col>
                      </Form.Group>
                    </Col>

                    <Col md="6">
                      <Form.Group as={Row} controlId="SLESODate">
                        <Form.Label column sm="4" className="fw-bold">
                          Delivery To :
                        </Form.Label>
                        <Col sm="6">
                          <Controller
                            name="DeliveryTo"
                            control={control}
                            rules={{ required: "This field is required" }}
                            defaultValue=""
                            className={`form-control ${
                              errors.Brand ? "is-invalid" : ""
                            }`}
                            render={({ field }) => (
                              <Form.Select
                                {...field}
                                onChange={(e) => {
                                  // setSupplierId(e.target.value);
                                  // setSupplierId(
                                  //   e.target.options[e.target.selectedIndex].text
                                  // );
                                  setValue("DeliveryTo", e.target.value);
                                  setDeliveryTo(e.target.value);
                                  trigger("DeliveryTo");
                                }}
                                className={`form-select ${
                                  errors.DeliveryTo ? "is-invalid" : ""
                                }`}
                              >
                                {/* <option key={""} value="">
                            Please select currency
                          </option> */}
                                <option key={"CP"} value="CP" disabled>
                                  Client's Premise
                                </option>
                                <option key={"W"} value="W">
                                  Warehouse
                                </option>
                                {/* {CurrencyList.map((items) => (
                            <option
                              key={items.CURRENCY_CODE}
                              value={items.CURRENCY_CODE}
                            >
                              {items.CURRENCY_NAME}
                            </option>
                          ))} */}
                              </Form.Select>
                            )}
                          />
                          {errors.DeliveryTo && (
                            <Form.Text className="text-danger">
                              {errors.DeliveryTo.message}
                            </Form.Text>
                          )}
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <Form.Group as={Row} controlId="Remarks">
                        <Form.Label column sm="2" className="fw-bold">
                          Remarks :
                        </Form.Label>
                        <Col sm="8">
                          <Form.Control
                            as="textarea"
                            rows={5}
                            placeholder="Input remarks if needed"
                            value={Remarks}
                            onInput={(e) => setRemarks(e.target.value)}
                          />
                        </Col>
                      </Form.Group>
                    </Col>

                    {/* <Col md="6">
                <Form.Group as={Row} controlId="OrderType">
                  <Form.Label column sm="4" className="fw-bold">
                    Order Status :
                  </Form.Label>
                  <Col sm="7">
                    <Form.Control
                      readOnly
                      className="no-border"
                      type="text"
                      placeholder=""
                      value={PaymentTerm}
                    />
                  </Col>
                </Form.Group>
              </Col> */}
                  </Row>
                </div>
                <hr />
                {/* <br /> */}
                <Row className="mb-4 align-items-center">
                  <Col sm="9" className="text-center">
                    <Form.Label>[ Order Item ]</Form.Label>
                  </Col>
                  <Col sm="3">
                    <Button
                      className="me-2"
                      variant="secondary"
                      onClick={handleNewSo}
                    >
                      Add Item
                    </Button>
                  </Col>
                </Row>
                <NewPOItemsTable
                  itemList={ItemList}
                  onEditClick={handleEditClick}
                  onDeleteClick={handleDeleteClick}
                />
                <Row className="mt-5">
                  <Col className="text-center">
                    <Button
                      className="me-2"
                      variant="secondary"
                      onClick={handleResetForm}
                    >
                      Reset
                    </Button>
                    <Button type="submit" variant="primary">
                      Save
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
          {showModal === "newpo" ? (
            <NewPOItemModal
              handleAddItemList={handleAddItemList}
              handleCloseAlert={handleCloseAlert}
              handleShowAlert={handleShowAlert}
              supplierId={SupplierId}
              itemList={ItemList}
              rowData={selectedRowData}
              onClose={() => setShowModal(false)}
              orderType={OrderType}
            />
          ) : (
            ""
          )}
          {showModal === "edit" ? (
            <EditPOItemModal
              handleEditItemList={handleEditItemList}
              handleShowAlert={handleShowAlert}
              itemList={ItemList}
              rowData={selectedRowData}
              onClose={() => setShowModal(false)}
              orderType={OrderType}
            />
          ) : (
            ""
          )}
          {showModal === "delete" ? (
            <DeletePOItemModal
              handleDeleteItemList={handleDeleteItemList}
              handleShowAlert={handleShowAlert}
              rowData={selectedRowData}
              onClose={() => setShowModal(false)}
            />
          ) : (
            ""
          )}
        </BlockUi>
        {ShowAlert && (
          <AlertModal
            title={AlertTitle}
            message={AlertMessage}
            apiResponse={ApiResponse}
            onClose={handleCloseAlert}
          />
        )}
      </Container>
    </div>
  );
}

export default NewPoAdhoc;
