import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { clearUserNameAndTokenInLocalStorage, getUserNameFromLocalStorage, getTokenFromLocalStorage } from '../../lib/common';
import BlockUi from '@availity/block-ui';

const config = JSON.parse(process.env.REACT_APP_CONFIG);

function IssuePoConfirmationModal(props) {
    const { rowData, onClose, handleShowAlert, setShowModal } = props;
    const [SoNumber] = useState(rowData.SO_NUMBER);
    const [blocking, setBlocking] = useState(false);
    console.log(rowData);
    // const handleToggleBlocking = () => {
    //     setBlocking(prevState => !prevState);
    // };

    const handleShowIssuePOModal = async (event) => {
        event.preventDefault();
        onClose();
        console.log(rowData.SO_TYPE);
        if (rowData.SO_TYPE === "C"){
            setShowModal("issuepo_c");
        } else if (rowData.SO_TYPE === "N"){
            setShowModal("issuepo_n");
        } else {
            setShowModal("issuepo");
        }
        // try {
        //     await cancelSo();
        //     handleToggleBlocking();
        //     console.log("Success cancelling ");
        //     onClose();
        //     handleShowAlert("ok", "Cancel Sales Order", "Success cancelling Sales Order");
        // } catch (error) {
        //     handleToggleBlocking();
        //     console.error("Error while cancelling Sales Order :", error);
        //     handleShowAlert("error", "Cancel Sales Order", "Error while cancelling sales order: ", error.join("<br>"));
        //     return;
        // }

    };

    const cancelSo = async () => {
        try {
            const response = await fetch(config.so_cancel_link, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": "Bearer " + getTokenFromLocalStorage()
                },
                body: JSON.stringify({
                    so_number: SoNumber
                })
            });

            const data = await response.json();
            console.log("submit on hold response : ");
            console.log(data);
            if (
                data.success !== true &&
                data.message === "Access Denied! Invalid token"
            ) {
                clearUserNameAndTokenInLocalStorage();
            }

            if (data.success !== true) {
                var errorMessages = data.error.map((item) => item.msg);
                // handleShowAlert("error", "New Invoice", "Failed Saving New Invoice :", errorMessages.join("<br>"));
                throw errorMessages;
            }
            if (data.success === true) {
                console.log("Success Canceling Sales Order");
                // handleTrigger(moment().unix());
                return "ok";
            } else {
                throw data;
            }
        } catch (error) {
            console.error("Error while cancelling Sales Order:", error);
            throw error;
        }
    }



    return (
        <Modal show={true} onHide={onClose} size="lg" backdrop="static" centered>
            <BlockUi blocking={blocking}>

                <Form onSubmit={handleShowIssuePOModal}>
                    <Modal.Header closeButton className="px-4">
                        <Modal.Title className="ms-auto">Create Purchase Order ({rowData.SO_TYPE_DESC})</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row className="mb-2">
                            <Col md="12">
                                <div className='text-center fw-bold'>This is a {rowData.SO_TYPE_DESC}</div>
                                <div className='text-center'>Do you confirm to create PO to order stock from supplier?</div>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button type="submit" variant="primary">
                            Yes
                        </Button>
                        <Button onClick={onClose} variant="secondary">
                            No
                        </Button>
                    </Modal.Footer>
                </Form>
            </BlockUi>
        </Modal>
    );
}

export default IssuePoConfirmationModal;