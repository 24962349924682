import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";
import moment from "moment/moment";
import {
	clearUserNameAndTokenInLocalStorage,
	getTokenFromLocalStorage,
} from "../lib/common";
import BlockUi from "@availity/block-ui";
import "@availity/block-ui/src/BlockUi.css";
import "@availity/block-ui/src/Loader.css";
import AlertModal from "../page_components/AlertModal";
import { IconContext } from "react-icons";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { useForm, Controller } from "react-hook-form";
import NewPITable from "../page_components/NewPI/NewPI_Table";
import ManageGRNViewModal from "../page_components/ManageGoodsReceivedNote/ManageGRN_ViewModal";

const config = JSON.parse(process.env.REACT_APP_CONFIG);

function NewPurchaseInvoice() {
	const {
		control,
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		reset,
		trigger
	} = useForm({
		defaultValues: {
			SupplierId: "",
		},
	});
	const [showModal, setShowModal] = useState(false);
	const [ShowAlert, setShowAlert] = useState(false);
	const [AlertTitle, setAlertTitle] = useState("");
	const [AlertMessage, setAlertMessage] = useState("");
	const [ApiResponse, setApiResponse] = useState("");
	const [SupplierList, setSupplierList] = useState([]);
	const [SupplierId, setSupplierId] = useState("");
	const [SupplierInvNum, setSupplierInvNum] = useState("");
	const [SupplierInvDate, setSupplierInvDate] = useState("");
	const [selectedRows, setSelectedRows] = useState([]);
	const [selectedRowData, setSelectedRowData] = useState([]);
	const [PIDate] = useState(new Date());
	const [GrnList, setGrnList] = useState([]);
	const [blocking, setBlocking] = useState(false);
	const [searchProps, setSearchProps] = useState({});
	const [tableKey, setTableKey] = useState(Date.now());

	const navigate = useNavigate();

	useEffect(() => {
		if (getTokenFromLocalStorage() === null) {
			const state = { data: "Token expired, please login again" };
			navigate("/login", { state });
		}
	});

	useEffect(() => {
		handleToggleBlocking(true);
		fetch(config.pi_get_grn_link, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + getTokenFromLocalStorage(),
			},
			body: JSON.stringify({supplier_id: SupplierId}),
		})
			.then((response) => response.json())
			.then((jsonData) => {
				if (jsonData.message === "Access Denied! Invalid token") {
					clearUserNameAndTokenInLocalStorage();
					const state = { data: "Token expired, please login again" };
					navigate("/login", { state });
					throw new Error(jsonData.message);
				}
				return jsonData;
			})
			.then((data) => {
				setGrnList(data.data);
				console.log(data);
			})
			.catch((error) => console.error(error)).finally(() => {
				handleToggleBlocking(false);
			});;
	}, [SupplierId]);

	useEffect(() => {
		handleToggleBlocking(true);
		fetch(config.supplier_active_list_link, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + getTokenFromLocalStorage(),
			},
			body: JSON.stringify({}),
		})
			.then((response) => response.json())
			.then((jsonData) => {
				handleToggleFromChild(false);
				if (
					jsonData.success !== true &&
					jsonData.message === "Access Denied! Invalid token"
				) {
					clearUserNameAndTokenInLocalStorage();
					const state = { data: "Token expired, please login again" };
					navigate("/login", { state });
					throw new Error(jsonData.message);
				} else if (jsonData.success) {
					return jsonData.data;
				} else {
					throw new Error(jsonData.message);
				}
			})
			.then((data) => {
				setSupplierList(data);
			})
			.catch((error) => console.error(error))
			.finally(() => {
				handleToggleBlocking(false);
			});
	}, []);

	const handleSaveClick = async () => {
		handleToggleBlocking();
		console.log(selectedRows);
		if (selectedRows.length <= 0) {
			handleShowAlert(
				"error",
				"New PI",
				"Failed Saving New PI",
				["Please select a GRN"]
			);
			return;
		}
		var jsonOb = {
			supplier_id: SupplierId,
			pi_date: PIDate !== "" ? moment(PIDate).format("YYYY-MM-DD") : null,
			supplier_inv_number: SupplierInvNum,
			supplier_inv_date: SupplierInvDate !== "" ? moment(SupplierInvDate).format("YYYY-MM-DD") : null,
			grn_number: selectedRows,
		}
		handleToggleBlocking(true);
		console.log(jsonOb);
		try {
			const response = await fetch(config.pi_create_link, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + getTokenFromLocalStorage(),
			},
			body: JSON.stringify(jsonOb),
		});
		const data = await response.json();
		console.log(data);
		if (
			data.success !== true &&
			data.message === "Access Denied! Invalid token"
		) {
			clearUserNameAndTokenInLocalStorage();
			const state = { data: "Token expired, please login again" };
			navigate("/login", { state });
		}
		if (data.success !== true) {
			var errorMessages = data.error.map((item) => item.msg);
			handleShowAlert("error", "New Purchase Invoice", "Failed Saving New Purchase Invoice :", errorMessages.join("<br>"));
		}
		if (data.success === true) {
			handleShowAlert(
				"ok",
				"New PI",
				"Success add New PI",
				"The PI Number is: " + data.pi_number
			); 
			setTableKey(Date.now());
			setSelectedRows([]);
			return "ok";
		} else {
			throw data;
		}
		} catch (error) {
			console.error("Error while saving:", error);
			handleShowAlert(
				"error",
				"New PI",
				"Error while saving New PI",
				error
			);
            return;
		} finally {
			handleToggleBlocking(false);
		}
	};
	const handleShowAlert = async (status, title, message, apiResponse = []) => {
		setAlertTitle(title);
		if (status === "ok") {
		  setAlertMessage(
			<>
			  <p className="mb-1">
				<IconContext.Provider
				  value={{
					color: "green",
					style: { fontSize: "40px", paddingRight: "5px" },
				  }}
				>
				  <AiOutlineCheckCircle />
				  {message}
				</IconContext.Provider>
			  </p>
			  <p>{apiResponse}</p>
			</>
		  );
		} else {
		  setAlertMessage(
			<>
			  <p className="mb-1">
				<IconContext.Provider
				  value={{
					color: "red",
					style: { fontSize: "40px", paddingRight: "5px" },
				  }}
				>
				  <AiOutlineCloseCircle />
				  {message}
				</IconContext.Provider>
			  </p>
			  {apiResponse.length > 0
				? apiResponse.map((response) => (
					<p className="text-danger fst-italic ps-3">{response}</p>
				  ))
				: ""}
			</>
		  );
		}
		setShowAlert(true);
	};
	const handleCloseAlert = () => {
		setShowAlert(false);
	};
	const handleToggleBlocking = (value) => {
		setBlocking(value);
	};
	const handleToggleFromChild = (newValue) => {
		setBlocking(newValue);
	};

	const handleViewClick = (rowData) => {
		fetch(config.pi_get_grn_link, {
			method: "POST",
			headers: {
			  "Content-Type": "application/json",
			  Authorization: "Bearer " + getTokenFromLocalStorage(),
			},
			body: JSON.stringify({
				supplier_id: SupplierId,
			}),
		})
		.then((response) => response.json())
		.then((jsonData) => {
			if (
			jsonData.success !== true &&
			jsonData.message === "Access Denied! Invalid token"
			) {
			clearUserNameAndTokenInLocalStorage();
			const state = { data: "Token expired, please login again" };
			navigate("/login", { state });
			throw new Error(jsonData.message);
			} else if (jsonData.success) {
			setSelectedRowData(rowData);
			setShowModal("view");
			} else {
			throw new Error(jsonData.message);
			}
		})
		.catch((error) => console.error(error));
};

	const toggleRowSelection = (rowId) => {
		if (selectedRows.includes(rowId)) {
			setSelectedRows(selectedRows.filter((id) => id !== rowId));
		} else {
			setSelectedRows([...selectedRows, rowId]);
		}
	};
	
	const handleCloseForm = () => {
		reset();
		handleToggleBlocking();
		setSupplierId("");
		setSupplierInvNum("");
		setSupplierInvDate("");
		setSelectedRows([]);

		const searchProperties = {
			SupplierId: "",
			SupplierInvNum: "",
			SupplierInvDate: "",
			selectedRows: [],
		};
		setSearchProps(searchProperties);
	};

	return (
		<div>
			<Container className="mt-4" style={{ maxWidth: "80%" }}>
				<BlockUi blocking={blocking}>
				<Form onSubmit={handleSubmit(handleSaveClick)}>
					<Row className="justify-content-center mb-4">
						<Col md={8}>
							<Row className="mb-4">
								<Col className="text-center">
									<h2>New Purchase Invoice</h2>
								</Col>
							</Row>
							<Row className="mb-3">
								<Col md="12">
									<Form.Group as={Row} controlId="SupplierId">
										<Form.Label column sm="3" className="fw-bold">
											Supplier :
										</Form.Label>
										<Col sm="6">
											<Controller name="SupplierId" 
												control={control}
												value={SupplierId}
												defaultValue=""
												className={`form-control ${
													errors.SupplierId ? "is-invalid": ""
												}`}
												rules={{
													required:
														"This field is required",
												}}
												render={({ field }) => (
													<Form.Select
														{...field} onChange={(e) => {
															setValue("SupplierId",e.target.value);
															setSupplierId(e.target.value);
															trigger("SupplierId");
														}}
														className={`form-select ${
															errors.SupplierId ? "is-invalid": ""
														}`}
													>
														<option key={""} value="" disabled>
															Please select supplier name
														</option>
														{SupplierList.map(items => (
															<option key={items.SUPPLIER_ID} value={items.SUPPLIER_ID}>
																{items.SUPPLIER_NAME}
															</option>
														))}
													</Form.Select>
												)}
											/>
											{errors.SupplierId && (
												<Form.Text className="text-danger">
													{
														errors
															.SupplierId
															.message
													}
												</Form.Text>
											)}
										</Col>
									</Form.Group>
								</Col>
							</Row>
							<hr />
							<Row className="mb-3 ">
								<Col md="6">
									<Form.Group as={Row} controlId="SLESONumber" >
										<Form.Label column sm="6" className="fw-bold">
											PI Date :
										</Form.Label>
										<Col sm="6">
											<DatePicker
												disabled
												dateFormat="dd MMM yyyy"
												className="form-control"
												showIcon
												selected={PIDate}
											/>
										</Col>
									</Form.Group>
								</Col>
							</Row>
							<Row className="mb-3 ">
								<Col md="6">
									<Form.Group as={Row} controlId="SupplierInvNum">
										<Form.Label column sm="6" className="fw-bold">
											Supplier Inv Number :
										</Form.Label>
										<Col sm="6">
											<Form.Control type="text" 
												placeholder="Supplier Inv Number" 
												value={SupplierInvNum}
												onInput={(e) => {
													setSupplierInvNum(e.target.value);
												}}
												className={`form-control ${
													errors.SupplierInvNum ? "is-invalid" : ""
												}`}
												{...register("SupplierInvNum", {
													validate: (value) => {
														return value !== "" || "This field is required";
													},
												})}
											/>
											{errors.SupplierInvNum && (
												<p className="text-danger">
													{errors.SupplierInvNum.message}
												</p>
											)}
										</Col>
									</Form.Group>
								</Col>

								<Col md="6">
									<Form.Group as={Row} controlId="SupplierInvDate">
										<Form.Label column sm="6" className="fw-bold">
											Supplier Inv Date :
										</Form.Label>
										<Col sm="6">
											<Controller
												control={control}
												name="SupplierInvDate"
												rules={{ required: "This field is required" }}
												render={({ field }) => (
													<DatePicker
														showIcon
														dateFormat="dd MMM yyyy"
														className={`form-control ${
															errors.SupplierInvDate ? "is-invalid" : ""
														}`}
														selected={SupplierInvDate}
														onChange={(date) => {
															field.onChange(date);
															setSupplierInvDate(date);
														}}
														minDate={moment().subtract(1, "month").toDate()}
														maxDate={new Date()}
													/>
												)}
											/>
											{errors.SupplierInvDate && (
												<p className="text-danger">
													{errors.SupplierInvDate.message}
												</p>
											)}
										</Col>
									</Form.Group>
								</Col>
							</Row>
						</Col>
						<Col md={12}>
							<hr />
							<Row className="mb-4 align-items-center">
								<Col className="text-center">
									<Form.Label>[ GRN ]</Form.Label>
									{/* <NewPITable
										data={GrnList}
										rowData={{ SUPPLIER_ID: SupplierId }} 
										onChildToggleChange={handleToggleFromChild}
										onRowSelect={toggleRowSelection}
										onViewClick={handleViewClick}
										selectedRows={selectedRows}
										setSelectedRows = {setSelectedRows}
										toggleRowSelection = {toggleRowSelection}
									/> */}
									<NewPITable
										key={tableKey}
										rowData={{ SUPPLIER_ID: SupplierId }} 
										searchProps={searchProps}
										onChildToggleChange={handleToggleFromChild}
										onRowSelect={toggleRowSelection}
										toggleRowSelection={toggleRowSelection}
										selectedRows={selectedRows}
										setSelectedRows={setSelectedRows}
										onViewClick={handleViewClick}
									/>
								</Col>
							</Row>
							<Row className="mt-5">
								<Col className="text-center">
									<Button className="me-2" variant="secondary"
										onClick={handleCloseForm}>
										Reset
									</Button>
									<Button type="submit" variant="primary" disabled={GrnList.length === 0}>
										Save
									</Button>
								</Col>
							</Row>
						</Col>
					</Row>
				</Form>
					{showModal === "newpo" ? (
						''
					) : (
						""
					)}
					{showModal === "view" ? (
						<ManageGRNViewModal
						handleCloseAlert={handleCloseAlert}
						handleShowAlert={handleShowAlert}
						rowData={selectedRowData}
						onClose={() => setShowModal(false)}
						/>
					) : (
						""
					)}
				</BlockUi>
				<style>
					{`
						.form-control:disabled {
						background: #F8F8F8 !important;
						}
						.react-datepicker-wrapper{
							width: 100%;
						}
					`}
				</style>
				{ShowAlert && (
					<AlertModal
						title={AlertTitle}
						message={AlertMessage}
						apiResponse={ApiResponse}
						onClose={handleCloseAlert}
					/>
				)}
			</Container>
		</div>
	);
}

export default NewPurchaseInvoice;
