import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ManageSoTable from "../page_components/ManageSO/ManageSO_Table";
import ManageSoOnHoldModal from "../page_components/ManageSO/ManageSO_OnHoldModal";
import ManageSoRestockModal from "../page_components/ManageSO/ManageSO_RestockModal";
import ManageSoCancelModal from "../page_components/ManageSO/ManageSO_CancelModal";
import ManageSoViewModal from "../page_components/ManageSO/ManageSO_ViewModal";
import ManageSoEditModal from "../page_components/ManageSO/ManageSO_EditModal";
import ManageSoUnholdConfirmationModal from "../page_components/ManageSO/ManageSO_UnHoldConfirmationModal";
import ManageSoRestockToggleModal from "../page_components/ManageRestockRequest/ManageRestockRequest_FollowupToggleModal";
import { useNavigate } from "react-router-dom";
import {
  clearUserNameAndTokenInLocalStorage,
  getTokenFromLocalStorage,
} from "../lib/common";
import moment from "moment/moment";
import BlockUi from "@availity/block-ui";
import "@availity/block-ui/src/BlockUi.css";
import "@availity/block-ui/src/Loader.css";
import AlertModal from "../page_components/AlertModal";
import { IconContext } from "react-icons";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { AiOutlineCloseCircle } from "react-icons/ai";

const config = JSON.parse(process.env.REACT_APP_CONFIG);

function ManageSo() {
  const [SleSoDateFrom, setSleSoDateFrom] = useState("");
  const [SleSoDateTo, setSleSoDateTo] = useState("");
  const [SleSoNum, setSleSoNum] = useState("");
  const [CustomerName, setCustomerName] = useState("");
  const [CustomerList, setCustomerList] = useState([]);
  const [ProjectName, setProjectName] = useState("");
  const [ProjectList, setProjectList] = useState([]);
  const [SoTypeList, setSoTypeList] = useState([]);
  const [SoType, setSoType] = useState("");
  const [isPendingRestock, setIsPendingRestock] = useState(false);
  const [searchProps, setSearchProps] = useState({});
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [blocking, setBlocking] = useState(true);
  const [ShowAlert, setShowAlert] = useState(false);
  const [AlertTitle, setAlertTitle] = useState("");
  const [AlertMessage, setAlertMessage] = useState("");
  const [ApiResponse, setApiResponse] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (getTokenFromLocalStorage() === null) {
      const state = { data: "Token expired, please login again" };
      navigate("/login", { state });
    }
  });

  useEffect(() => {
    fetch(config.customer_list_link, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getTokenFromLocalStorage(),
      },
      body: JSON.stringify({}),
    })
      .then((response) => response.json())
      .then((jsonData) => {
        console.log(jsonData);
        if (
          jsonData.success !== true &&
          jsonData.message === "Access Denied! Invalid token"
        ) {
          clearUserNameAndTokenInLocalStorage();
          throw new Error(jsonData.message);
        } else if (jsonData.success) {
          return jsonData.data;
        } else {
          throw new Error(jsonData.message);
        }
      })
      .then((data) => setCustomerList(data))
      .catch((error) => console.error(error));
  }, []);

  useEffect(() => {
    if (CustomerName !== "") {
      fetch(config.project_list_link, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + getTokenFromLocalStorage(),
        },
        body: JSON.stringify({
          customer_id: CustomerName,
        }),
      })
        .then((response) => response.json())
        .then((jsonData) => {
          console.log(jsonData);
          if (
            jsonData.success !== true &&
            jsonData.message === "Access Denied! Invalid token"
          ) {
            clearUserNameAndTokenInLocalStorage();
            throw new Error(jsonData.message);
          } else if (jsonData.success) {
            return jsonData.data;
          } else {
            throw new Error(jsonData.message);
          }
        })
        .then((data) => setProjectList(data))
        .catch((error) => console.error(error));
    }
  }, [CustomerName]);

  useEffect(() => {
    fetch(config.sotype_list_link, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getTokenFromLocalStorage(),
      },
      body: JSON.stringify({}),
    })
      .then((response) => response.json())
      .then((jsonData) => {
        console.log(jsonData);
        if (
          jsonData.success !== true &&
          jsonData.message === "Access Denied! Invalid token"
        ) {
          clearUserNameAndTokenInLocalStorage();
          throw new Error(jsonData.message);
        } else if (jsonData.success) {
          return jsonData.data;
        } else {
          throw new Error(jsonData.message);
        }
      })
      .then((data) => setSoTypeList(data))
      .catch((error) => console.error(error));
  }, []);

  const handleShowAlert = async (status, title, message, apiResponse = "") => {
    setAlertTitle(title);
    if (status === "ok") {
      setAlertMessage(
        <>
          <p className="mb-1">
            <IconContext.Provider
              value={{
                color: "green",
                style: { fontSize: "40px", paddingRight: "5px" },
              }}
            >
              <AiOutlineCheckCircle />
              {message}
            </IconContext.Provider>
          </p>
        </>
      );
    } else {
      setAlertMessage(
        <>
          <p className="mb-1">
            <IconContext.Provider
              value={{
                color: "red",
                style: { fontSize: "40px", paddingRight: "5px" },
              }}
            >
              <AiOutlineCloseCircle />
              {message}
            </IconContext.Provider>
          </p>
          {apiResponse !== "" ? (
            <p className="text-danger fst-italic ps-3">{apiResponse}</p>
          ) : (
            ""
          )}
        </>
      );
    }
    setShowAlert(true);
  };

  const handleCheckboxChange = () => {
    setIsPendingRestock(!isPendingRestock);
  };

  const handleCloseAlert = () => {
    setShowAlert(false);
  };

  const handleToggleBlocking = () => {
    setBlocking((prevState) => !prevState);
  };

  const handleToggleFromChild = (newValue) => {
    setBlocking(newValue);
  };

  const handleResetForm = () => {
    handleToggleBlocking();

    setSleSoDateFrom("");
    setSleSoNum("");
    setSleSoDateTo("");
    setCustomerName("");
    setProjectName("");
    setProjectList([]);
    setSoType("");
    setIsPendingRestock(false);

    const searchProperties = {
      SleSoDateFrom: "",
      SleSoDateTo: "",
      SleSoNum: "",
      CustomerName: "",
      ProjectName: "",
      SoType: "",
      RestockStatus: false,
    };
    setSearchProps(searchProperties);
  };
  const handleSearch = (event) => {
    event.preventDefault();
    if (!SleSoDateFrom || !SleSoDateTo) {
      if (!SleSoDateFrom && !SleSoDateTo) {
      } else {
        handleShowAlert(
          "error",
          "Incomplete Date Value",
          "Please complete the date range!"
        );
        return;
      }
    }
    handleToggleBlocking();
    const searchProperties = {
      SleSoDateFrom:
        SleSoDateFrom !== ""
          ? moment(SleSoDateFrom).format("YYYY-MM-DD")
          : null,
      SleSoDateTo:
        SleSoDateTo !== "" ? moment(SleSoDateTo).format("YYYY-MM-DD") : null,
      SleSoNum: SleSoNum,
      CustomerName: CustomerName,
      ProjectName: ProjectName,
      RestockStatus: isPendingRestock,
      SoType: SoType,
    };
    setSearchProps(searchProperties);
  };

  const handleIssuePOClick = (rowData) => {
    setSelectedRowData(rowData);
    setShowModal(true);
  };

  const handleOnHoldClick = (rowData) => {
    console.log(rowData);
    setSelectedRowData(rowData);
    setShowModal("onhold");
  };

  const handleUnholdClick = (rowData) => {
    console.log(rowData);
    setSelectedRowData(rowData);
    setShowModal("unhold");
  };

  const handleRestockClick = (rowData) => {
    console.log(rowData);
    setSelectedRowData(rowData);
    setShowModal("restock");
  };

  const handleCancelClick = (rowData) => {
    console.log(rowData);
    setSelectedRowData(rowData);
    setShowModal("cancel");
  };

  const handleViewClick = (rowData) => {
    console.log(rowData);
    setSelectedRowData(rowData);
    setShowModal("view");
  };

  const handleEditClick = (rowData) => {
    console.log(rowData);
    setSelectedRowData(rowData);
    setShowModal("edit");
  };

  return (
    <div>
      <Container className="mt-4" style={{ maxWidth: "80%" }}>
        <BlockUi blocking={blocking}>
          <Row className="justify-content-center mb-4">
            <Col md={6}>
              <Form onSubmit={handleSearch}>
                <Row className="mb-4">
                  <Col className="text-center">
                    <h2>Manage Sales Order</h2>
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="SLESONumber"
                    >
                      <Form.Label column sm="3">
                        SO Number
                      </Form.Label>
                      <Col sm="7" style={{ paddingRight: "3px" }}>
                        <Form.Control
                          type="text"
                          placeholder="SLE SO Number"
                          value={SleSoNum}
                          onInput={(e) => setSleSoNum(e.target.value)}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col sm="6">
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="SLESODateFrom"
                    >
                      <Form.Label column sm="6">
                        SO Date From
                      </Form.Label>
                      <Col sm="6" style={{ paddingLeft: "12px" }}>
                        <DatePicker
                          className="form-control"
                          dateFormat="dd/MM/yyyy"
                          showIcon
                          selected={SleSoDateFrom}
                          onChange={(date) => setSleSoDateFrom(date)}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col sm="5">
                    <Form.Group
                      as={Row}
                      className="mb-3 text-center"
                      controlId="SLESODateTo"
                    >
                      <Form.Label column sm="3">
                        to
                      </Form.Label>
                      <Col sm="7" style={{ paddingRight: "15px" }}>
                        <DatePicker
                          className="form-control"
                          dateFormat="dd/MM/yyyy"
                          showIcon
                          selected={SleSoDateTo}
                          onChange={(date) => setSleSoDateTo(date)}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="CustomerName"
                    >
                      <Form.Label column sm="3">
                        Customer Name
                      </Form.Label>
                      <Col sm="7" style={{ paddingRight: "3px" }}>
                        <Form.Select
                          value={CustomerName}
                          onChange={(e) => setCustomerName(e.target.value)}
                        >
                          <option key={""} value="">
                            Please select customer name
                          </option>
                          {CustomerList.map((items) => (
                            <option
                              key={items.CUSTOMER_ID}
                              value={items.CUSTOMER_ID}
                            >
                              {items.CUSTOMER_NAME}
                            </option>
                          ))}
                        </Form.Select>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="ProjectName"
                    >
                      <Form.Label column sm="3">
                        Project Name
                      </Form.Label>
                      <Col sm="7" style={{ paddingRight: "3px" }}>
                        <Form.Select
                          value={ProjectName}
                          onChange={(e) => setProjectName(e.target.value)}
                        >
                          <option key={""} value="">
                            Please select project name
                          </option>
                          {ProjectList.map((items) => (
                            <option
                              key={items.PROJECT_ID}
                              value={items.PROJECT_ID}
                            >
                              {items.PROJECT_NAME}
                            </option>
                          ))}
                        </Form.Select>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col>
                    <Form.Group as={Row} className="mb-3" controlId="SoType">
                      <Form.Label column sm="3">
                        Sales Order Type
                      </Form.Label>
                      <Col sm="7" style={{ paddingRight: "3px" }}>
                        <Form.Select
                          value={SoType}
                          onChange={(e) => setSoType(e.target.value)}
                        >
                          <option key={""} value="">
                            Please select sales order type
                          </option>
                          {SoTypeList.map((items) => (
                            <option key={items.SO_TYPE} value={items.SO_TYPE}>
                              {items.SO_TYPE_DESC}
                            </option>
                          ))}
                        </Form.Select>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="ProjectName"
                    >
                      <Form.Label column sm="3">
                        Pending Restock
                      </Form.Label>
                      <Col sm="7" style={{ paddingRight: "3px" }}>
                        <Form.Check
                          type="checkbox"
                          // label={`disabled ${type}`}
                          checked={isPendingRestock}
                          onChange={handleCheckboxChange}
                          id="isPending"
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col className="text-center">
                    <Button
                      className="me-2"
                      variant="secondary"
                      onClick={handleResetForm}
                    >
                      Reset
                    </Button>
                    <Button type="submit" variant="primary">
                      Search
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
          <hr />
          <br />
          <ManageSoTable
            showAlert={ShowAlert}
            searchProps={searchProps}
            onChildToggleChange={handleToggleFromChild}
            onOnHoldClick={handleOnHoldClick}
            onRestockClick={handleRestockClick}
            onCancelClick={handleCancelClick}
            onViewClick={handleViewClick}
            onEditClick={handleEditClick}
          />
          {showModal === "onhold" ? (
            <ManageSoOnHoldModal
              handleCloseAlert={handleCloseAlert}
              handleShowAlert={handleShowAlert}
              rowData={selectedRowData}
              onClose={() => setShowModal(false)}
              handleUnholdClick={handleUnholdClick}
            />
          ) : (
            ""
          )}
          {showModal === "restock" ? (
            <ManageSoRestockModal
              handleCloseAlert={handleCloseAlert}
              handleShowAlert={handleShowAlert}
              rowData={selectedRowData}
              onClose={() => setShowModal(false)}
            />
          ) : (
            ""
          )}
          {showModal === "cancel" ? (
            <ManageSoCancelModal
              handleCloseAlert={handleCloseAlert}
              handleShowAlert={handleShowAlert}
              rowData={selectedRowData}
              onClose={() => setShowModal(false)}
            />
          ) : (
            ""
          )}

          {showModal === "view" ? (
            <ManageSoViewModal
              handleCloseAlert={handleCloseAlert}
              handleShowAlert={handleShowAlert}
              rowData={selectedRowData}
              onClose={() => setShowModal(false)}
            />
          ) : (
            ""
          )}
          {showModal === "edit" ? (
            <ManageSoEditModal
              handleCloseAlert={handleCloseAlert}
              handleShowAlert={handleShowAlert}
              rowData={selectedRowData}
              onClose={() => setShowModal(false)}
            />
          ) : (
            ""
          )}
          {showModal === "unhold" ? (
            <ManageSoUnholdConfirmationModal
              handleCloseAlert={handleCloseAlert}
              handleShowAlert={handleShowAlert}
              rowData={selectedRowData}
              onClose={() => setShowModal(false)}
              handleToggleBlocking={handleToggleBlocking}
            />
          ) : (
            ""
          )}
        </BlockUi>
        {ShowAlert && (
          <AlertModal
            title={AlertTitle}
            message={AlertMessage}
            apiResponse={ApiResponse}
            onClose={handleCloseAlert}
          />
        )}
      </Container>
    </div>
  );
}

export default ManageSo;
