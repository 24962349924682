import React from 'react';
import { Container} from 'react-bootstrap';
function Home() {
  return (
    <Container>
      <h4 className='mt-2'>Changelog :</h4>
      <p>22 Oct 2023 : invoice page working, deliverystatus page working</p>
      <p>20 Oct 2023 : invoice page init</p>
      <p>19 Oct 2023 : deliverystatus : followup init</p>
      <p>18 Oct 2023 : deliverystatus : status init</p>
      <p>17 Oct 2023 : searchdeliveryorder edit working</p>
      <p>16 Oct 2023 : search delivery order edit init</p>
      <p>15 Oct 2023 : search delivery order working</p>
      <p>14 Oct 2023 : init searchdeliveryorder</p>
      <p>13 Oct 2023 : create new non bulk delivery order working</p>
      <p>11 Oct 2023 : create new delivery order working</p>
      <p>10 Oct 2023 : new delivery order list</p>
      <p>08 Oct 2023 : auto refresh after delete working</p>
      <p>06 Oct 2023 : auto refresh after delete working</p>
      <p>05 Oct 2023 : add auto refresh after editing</p>
      <p>04 Oct 2023 : managebulkordermemo : edit memo working</p>
      <p>02 Oct 2023 : update in edit memo</p>
      <p>29 Sep 2023 : bugfix in managebulkordermemo</p>
      <p>27 Sep 2023 : history modal accordion and table working</p>
      <p>26 Sep 2023 : managebulkorder new memo working</p>
      <p>24 Sep 2023 : modify managebulkordermemo newmodal and table</p>
      <p>23 Sep 2023 : update historymodaltable</p>
      <p>22 Sep 2023 : test history modal accordion</p>
      <p>21 Sep 2023 : bugfix, add managebulkordermemo page</p>
      <p>20 Sep 2023 : manage po working</p>
      <p>18 Sep 2023 : issue po working</p>
      <p>15 Sep 2023 : fix manage so restock</p>
      <p>12 Sep 2023 : switch restock pending/filled working</p>
      <p>11 Sep 2023 : add table in followup</p>
      <p>10 Sep 2023 : modify restock modal</p>
      <p>09 Sep 2023 : create manage restock request follow up modal</p>
      <p>08 Sep 2023 : modify cancel so</p>
      <p>07 Sep 2023 : change API, endpoint link, Manage Restock Request Table working</p>
      <p>06 Sep 2023 : add manage restock request</p>
      <p>31 Aug 2023 : manage so restock modal update</p>
      <p>30 Aug 2023 : update onHoldModal</p>
      <p>29 Aug 2023 : add newSO success alert, init onHold Modal</p>
      <p>25 Aug 2023 : add delete so item modal</p>
      <p>24 Aug 2023 : add edit so item </p>
      <p>23 Aug 2023 : Change handling API response</p>
      <p>20 Aug 2023 : New SO submit data works</p>
      <p>17 Aug 2023 : modify New SO handle submit logic + error modal</p>
      <p>11 Aug 2023 : modify New SO Item pop up</p>
      <p>06 Aug 2023 : add New SO Item pop up</p>
      <p>06 Aug 2023 : add New SO Item pop up</p>
      <p>04 Aug 2023 : initialize New SO page</p>
      <p>04 Aug 2023 : initialize Manage SO page</p>
      <p>02 Aug 2023 : Product page is done</p>
      <p>29 Jul 2023 : Projects page is done</p>
      <p>26 Jul 2023 : Customers and Products page is done</p>
      <p>13 Jul 2023 : update navbar to megamenu and bugfixing</p>
      <p>11 Jul 2023 : upgrade to mdbootstrap</p>
      <p>28 May 2023 : add validation in SLE SO Page, add Actual Delivery Date in SLE Invoice Page</p>
      <p>23 May 2023 : bugfix, add logo, add reset button in every search form</p>
      <p>22 May 2023 : bugfix, small ui redesign, add favicon, add change password and logout</p>
      <p>21 May 2023 : add custom alert, add update table after submitting, modify blocking flow</p>
      <p>20 May 2023 : redefine alert, auto refresh table</p>
      <p>16 May 2023 : Implemented blocking on all necessary page</p>
      <p>14 May 2023 : Implemented protection in all page and api call</p>
      <p>13 May 2023 : Add changelog in /home</p>
      <p>11 May 2023 : Add login page</p>
      <p>10 May 2023 : First draft</p>
    </Container>
  );
}

export default Home;
