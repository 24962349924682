import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment/moment";
import {
  clearUserNameAndTokenInLocalStorage,
  getTokenFromLocalStorage,
} from "../../lib/common";
import BlockUi from "@availity/block-ui";
import { v4 as uuidv4 } from "uuid";
import { useForm, Controller } from "react-hook-form";

const config = JSON.parse(process.env.REACT_APP_CONFIG);

function ProductsNewSupplierModal(props) {
  const {
    control,
    register,
    handleSubmit,
    watch,
    setValue,
    trigger,
    formState: { errors },
  } = useForm({
    defaultValues: {
      SupplierId: "",
    },
  });
  const { onClose, handleAddItemList, itemList, orderType } = props;
  const [BrandList, setBrandList] = useState([]);
  const [Brand, setBrand] = useState("");
  const [ProductList, setProductList] = useState([]);
  const [ProductId, setProductId] = useState("");
  const [ProductName, setProductName] = useState("");
  const [SupplierList, setSupplierList] = useState([]);
  const [SupplierId, setSupplierId] = useState("");
  const [SupplierName, setSupplierName] = useState("");
  const [SupplierItemName, setSupplierItemName] = useState("");
  const [SupplierItemCode, setSupplierItemCode] = useState("");
  const [Status, setStatus] = useState("A");
  // const [SupplierId, setSupplierId] = useState("");
  const [OrderQuantity, setOrderQuantity] = useState("0");
  const [isRestockRequired, setIsRestockRequired] = useState(false);
  const [RestockQuantity, setRestockQuantity] = useState(0);
  const [UnitOfMeasurements, setUnitOfMeasurements] = useState("");
  const [UnitPrice, setUnitPrice] = useState("");
  const [MinSellPrice, setMinSellPrice] = useState("");
  const [SellingPrice, setSellingPrice] = useState("");
  const [CostPrice, setCostPrice] = useState("");
  const [MinMargin, setMinMargin] = useState(parseFloat(0));
  // const [objData, setObjData] = useState({})
  const [TargetDate, setTargetDate] = useState("");
  const [blocking, setBlocking] = useState(true);

  const handleToggleBlocking = (value) => {
    setBlocking(value);
  };

  // useEffect(() => {
  //   fetch(config.brand_list_link, {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: "Bearer " + getTokenFromLocalStorage(),
  //     },
  //     body: JSON.stringify({}),
  //   })
  //     .then((response) => response.json())
  //     .then((jsonData) => {
  //       console.log(jsonData);
  //       if (
  //         jsonData.success !== true &&
  //         jsonData.message === "Access Denied! Invalid token"
  //       ) {
  //         clearUserNameAndTokenInLocalStorage();
  //         throw new Error(jsonData.message);
  //       } else if (jsonData.success) {
  //         return jsonData.data;
  //       } else {
  //         throw new Error(jsonData.message);
  //       }
  //     })
  //     .then((data) => setBrandList(data))
  //     .catch((error) => console.error(error));
  // }, []);

  // useEffect(() => {
  //   if (Brand !== "") {
  //     fetch(config.product_by_brand_list_link, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: "Bearer " + getTokenFromLocalStorage(),
  //       },
  //       body: JSON.stringify({
  //         brand: Brand,
  //       }),
  //     })
  //       .then((response) => response.json())
  //       .then((jsonData) => {
  //         console.log(jsonData);
  //         if (
  //           jsonData.success !== true &&
  //           jsonData.message === "Access Denied! Invalid token"
  //         ) {
  //           clearUserNameAndTokenInLocalStorage();
  //           throw new Error(jsonData.message);
  //         } else if (jsonData.success) {
  //           return jsonData.data;
  //         } else {
  //           throw new Error(jsonData.message);
  //         }
  //       })
  //       .then((data) => {
  //         setProductList(data);
  //         setValue("ProductId", "");
  //       })
  //       .catch((error) => console.error(error));
  //   }
  // }, [Brand]);

  // useEffect(() => {
  //   if (Brand !== "") {
  //     fetch(config.consignment_margin_get_link, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: "Bearer " + getTokenFromLocalStorage(),
  //       },
  //       body: JSON.stringify({
  //         brand: Brand,
  //       }),
  //     })
  //       .then((response) => response.json())
  //       .then((jsonData) => {
  //         console.log(jsonData);
  //         if (
  //           jsonData.success !== true &&
  //           jsonData.message === "Access Denied! Invalid token"
  //         ) {
  //           clearUserNameAndTokenInLocalStorage();
  //           throw new Error(jsonData.message);
  //         } else if (jsonData.success) {
  //           return jsonData.data;
  //         } else {
  //           throw new Error(jsonData.message);
  //         }
  //       })
  //       .then((data) => {
  //         setMinMargin(parseFloat(data.MIN_MARGIN).toFixed(2));
  //         // setValue("ProductId", "");
  //       })
  //       .catch((error) => console.error(error));
  //   }
  // }, [Brand]);

  useEffect(() => {
    fetch(config.supplier_list_link, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getTokenFromLocalStorage(),
      },
      body: JSON.stringify({}),
    })
      .then((response) => response.json())
      //   .then(res => console.log(res))
      .then((jsonData) => {
        if (
          jsonData.success !== true &&
          jsonData.message === "Access Denied! Invalid token"
        ) {
          clearUserNameAndTokenInLocalStorage();
          throw new Error(jsonData.message);
        } else if (jsonData.success) {
          return jsonData.data;
        } else {
          throw new Error(jsonData.message);
        }
      })
      .then((data) => {
        setSupplierList(data);
        // setValue("ProductId", "");
        // trigger("ProductId")
      })
      .catch((error) => console.error(error))
      .finally(() => {
        handleToggleBlocking(false);
      });
  }, []);

  // useEffect(() => {
  //   fetch(config.supplier_list_link, {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: "Bearer " + getTokenFromLocalStorage(),
  //     },
  //     body: JSON.stringify({}),
  //   })
  //     .then((response) => response.json())
  //     .then((jsonData) => {
  //       if (jsonData.message === "Access Denied! Invalid token") {
  //         clearUserNameAndTokenInLocalStorage();
  //         throw new Error(jsonData.message);
  //       }
  //       return jsonData;
  //     })
  //     .then((data) => {
  //       setSupplierList(data.data);
  //       console.log(data);
  //     })
  //     .catch((error) => console.error(error));
  // }, []);

  // useEffect(() => {
  //   fetch(config.product_active_list_link, {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: "Bearer " + getTokenFromLocalStorage(),
  //     },
  //     body: JSON.stringify({
  //       // supplier_name: SupplierId,
  //     }),
  //   })
  //     .then((response) => response.json())
  //     .then((jsonData) => {
  //       console.log(jsonData);
  //       if (
  //         jsonData.success !== true &&
  //         jsonData.message === "Access Denied! Invalid token"
  //       ) {
  //         clearUserNameAndTokenInLocalStorage();
  //         throw new Error(jsonData.message);
  //       } else if (jsonData.success) {
  //         return jsonData.data;
  //       } else {
  //         throw new Error(jsonData.message);
  //       }
  //     })
  //     .then((data) => {
  //       setProductList(data);
  //       setValue("ProductId", "");
  //     })
  //     .catch((error) => console.error(error));
  // }, []);

  const handleCheckboxChange = () => {
    setIsRestockRequired(!isRestockRequired);

    setRestockQuantity(0);
    setValue("RestockQuantity", 0);
    setTargetDate("");
    setValue("TargetDate", "");
    trigger("RestockQuantity");
    trigger("TargetDate");
  };

  const handleAddItem = (event) => {
    // event.preventDefault();
    // const supplierDetails = ProductList.find(product => product.PRODUCT_ID === ProductId);

    var uuid = uuidv4();
    var objData = {
      ITEM_ID: uuid,
      SUPPLIER_ID: SupplierId,
      SUPPLIER_NAME: SupplierName,
      SUPPLIER_ITEM_CODE: SupplierItemCode !== "" ? SupplierItemCode : null,
      SUPPLIER_ITEM_NAME: SupplierItemName !== "" ? SupplierItemName : null,
      SUPPLIER_ITEM_STATUS: Status
    };
    console.log(objData);
    handleAddItemList(objData);
    onClose();
  };

  return (
    <Modal
      show={true}
      onHide={onClose}
      size="lg"
      backdrop="static"
      centered
      style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
    >
      <BlockUi blocking={blocking}>
        <Form onSubmit={handleSubmit(handleAddItem)}>
          <Modal.Header closeButton className="px-4">
            <Modal.Title className="ms-auto">
              New Supplier for Product
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="mb-2 justify-content-center">
              <Col md="8">
                <Form.Group as={Row} controlId="SupplierId">
                  <Form.Label column sm="4" className="fw-bold">
                    Supplier
                  </Form.Label>
                  <Col sm="7">
                    <Controller
                      name="SupplierId"
                      control={control}
                      rules={{ required: "This field is required" }}
                      render={({ field }) => (
                        <Form.Select
                          {...field}
                          onChange={(e) => {
                            setSupplierId(e.target.value);
                            setSupplierName(
                              e.target.options[e.target.selectedIndex].text
                            );
                            setValue("SupplierId", e.target.value);
                            trigger("SupplierId");
                          }}
                          className={`form-select ${errors.SupplierId ? "is-invalid" : ""
                            }`}
                        >
                          <option key={""} value="" disabled>
                            Please select supplier name
                          </option>
                          {SupplierList.map((items) =>
                            itemList.some(
                              (item) =>
                                item.SUPPLIER_ID === items.SUPPLIER_ID
                            ) === false ? (
                              <option
                                key={items.SUPPLIER_ID}
                                value={items.SUPPLIER_ID}
                              >
                                {items.SUPPLIER_NAME}
                              </option>
                            ) : (
                              ""
                            )
                          )}
                        </Form.Select>
                      )}
                    />
                    {errors.SupplierId && (
                      <Form.Text className="text-danger">
                        {errors.SupplierId.message}
                      </Form.Text>
                    )}
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-2 justify-content-center">
              <Col md="8">
                <Form.Group as={Row} controlId="SupplierItemCode">
                  <Form.Label column sm="4" className="fw-bold">
                    Supplier Item Code
                  </Form.Label>
                  <Col sm="7">
                    <Form.Control
                      type="text"
                      placeholder="Supplier Item Code"
                      value={SupplierItemCode}
                      onInput={(e) => setSupplierItemCode(e.target.value)}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-2 justify-content-center">
              <Col md="8">
                <Form.Group as={Row} controlId="SupplierItemName">
                  <Form.Label column sm="4" className="fw-bold">
                    Supplier Item Name
                  </Form.Label>
                  <Col sm="7">
                    <Form.Control
                      type="text"
                      placeholder="Supplier Item Name"
                      value={SupplierItemName}
                      onInput={(e) => setSupplierItemName(e.target.value)}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-2 justify-content-center">
              <Col md="8">
                <Form.Group
                  as={Row}
                  controlId="Status"
                  className="align-items-center"
                >
                  <Form.Label column sm="4" className="fw-bold">
                    Status
                  </Form.Label>
                  <Col sm="7">
                    <Form.Check
                      inline
                      type="radio"
                      label="Active"
                      id="activeRadioSupplier1"
                      name="Status"
                      value="A"
                      checked={Status === "A"}
                      onChange={(e) => setStatus(e.target.value)}
                    />
                    <Form.Check
                      inline
                      type="radio"
                      label="Inactive"
                      id="inactiveRadioSupplier2"
                      name="Status"
                      value="I"
                      checked={Status === "I"}
                      onChange={(e) => setStatus(e.target.value)}
                      style={{ paddingLeft: "75px" }}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={onClose} variant="secondary">
              Close
            </Button>
            <Button type="submit" variant="primary">
              Save
            </Button>
          </Modal.Footer>
        </Form>
      </BlockUi>
    </Modal>
  );
}

export default ProductsNewSupplierModal;
