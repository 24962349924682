import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Pagination from "react-bootstrap/Pagination";
import moment from "moment/moment";
import {
  clearUserNameAndTokenInLocalStorage,
  getTokenFromLocalStorage,
} from "../../lib/common";
const config = JSON.parse(process.env.REACT_APP_CONFIG);

function SleInvoiceTable(props) {
  const [data, setData] = useState([]);
  const [headerData] = useState([
    "SLE SO Number",
    "SLE SO Date",
    // "SLE PO Number",
    // "SLE PO Date",
    "SLE DO Number",
    "SLE DO Date",
    "Actual Delivery Date",
    "Action",
  ]);
  const [currentPage, setCurrentPage] = useState(1);
  const { onInvoiceClick } = props;
  const { searchProps } = props;
  const { handleShowAlert } = props;
  // const { trigger } = props;
  const { showAlert } = props;
  const ROWS_PER_PAGE = 10;
  const totalPages = Math.ceil(data.length / ROWS_PER_PAGE);

  useEffect(() => {
    if (
      searchProps.SleSoNum ||
      searchProps.SleSoDateFrom ||
      searchProps.SleSoDateTo ||
      // searchProps.SlePoNum ||
      // searchProps.SlePoDateFrom ||
      // searchProps.SlePoDateTo ||
      searchProps.SleDoNum ||
      searchProps.SleDoDateFrom ||
      searchProps.SleDoDateTo
    ) {
      getData(config.invoice_pending_link, searchProps);
    } else {
      getData(config.invoice_pending_link);
    }
  }, [searchProps, showAlert]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleToggleBlocking = () => {
    props.onChildToggleChange(false);
  };

  const renderTableData = () => {
    const startIndex = (currentPage - 1) * ROWS_PER_PAGE;
    const endIndex = startIndex + ROWS_PER_PAGE;
    console.log(data);
    return data.slice(startIndex, endIndex).map((row) => {
      const {
        SO_NUMBER,
        SO_DATE,
        // PO_NUMBER,
        // PO_DATE,
        DO_NUMBER,
        DO_DATE,
        ACTUAL_DELIVERY,
      } = row;
      return (
        <tr key={DO_NUMBER}>
          <td>{SO_NUMBER}</td>
          <td>{SO_DATE}</td>
          {/* <td>{PO_NUMBER}</td>
          <td>{PO_DATE}</td> */}
          <td>{DO_NUMBER}</td>
          <td>{DO_DATE}</td>
          <td>{ACTUAL_DELIVERY}</td>
          <td style={{ width: "90px" }}>
            <Button variant="primary" onClick={() => onInvoiceClick(row)}>
              Invoice
            </Button>
          </td>
        </tr>
      );
    });
  };

  const renderPageItems = () => {
    const pageItems = [];
    for (let i = 1; i <= totalPages; i++) {
      pageItems.push(
        <Pagination.Item
          key={i}
          active={i === currentPage}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </Pagination.Item>
      );
    }
    return pageItems;
  };

  const getData = async (url, params = {}) => {
    await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getTokenFromLocalStorage(),
      },
      body: JSON.stringify(
        params.length === 0
          ? params
          : {
              so_number: params.SleSoNum,
              so_date_from: params.SleSoDateFrom,
              so_date_to: params.SleSoDateTo,
              // po_number : params.SlePoNum,
              // po_date_from : params.SlePoDateFrom,
              // po_date_to : params.SlePoDateTo,
              do_number: params.SleDoNum,
              do_date_from: params.SleDoDateFrom,
              do_date_to: params.SleDoDateTo,
            }
      ),
    })
      .then((response) => response.json())
      .then((jsonData) => {
        console.log("invoice pending list");
        console.log(jsonData);
        handleToggleBlocking();
        if (jsonData.message === "Access Denied! Invalid token") {
          clearUserNameAndTokenInLocalStorage();
          throw new Error(jsonData.message);
        } else if (jsonData.message) {
          handleShowAlert("error", "Invoice Table", "an error occured", [
            jsonData.message,
          ]);
        }
        const newData = jsonData.data.map((item) => {
          const so_date = moment(item.SO_DATE).format("DD MMM yyyy");
          const po_date = moment(item.PO_DATE).format("DD MMM yyyy");
          const actual_delivery = moment(item.ACTUAL_DELIVERY).format(
            "DD MMM yyyy"
          );
          const do_date = moment(item.DO_DATE).format("DD MMM yyyy");
          item.SO_DATE = so_date;
          item.PO_DATE = po_date;
          item.ACTUAL_DELIVERY = actual_delivery;
          item.DO_DATE = do_date;
          return item;
        });
        return newData;
      })
      .then((data) => setData(data))
      .catch((error) => console.error(error));
  };

  if (data.length < 1) {
    return (
      <div className="text-center">
        <h3>No Data</h3>
      </div>
    );
  } else {
    return (
      <div>
        <Table striped bordered hover className="mb-4">
          <thead>
            <tr>
              {headerData.map((header) => (
                <th key={header}>{header}</th>
              ))}
            </tr>
          </thead>
          <tbody>{renderTableData()}</tbody>
        </Table>
        <Pagination className="d-flex justify-content-center">
          {renderPageItems()}
        </Pagination>
      </div>
    );
  }
}

export default SleInvoiceTable;
