import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import "react-datepicker/dist/react-datepicker.css";
import {
  clearUserNameAndTokenInLocalStorage,
  getUserNameFromLocalStorage,
  getTokenFromLocalStorage,
} from "../../lib/common";
import BlockUi from "@availity/block-ui";
import CustomersNewAddressModal from "./Customers_EditCustomerAddAddressModal";
import CustomersAddressTable from "./Customers_AddressTable";
import CustomersEditAddressModal from "./Customers_EditCustomerEditAddressModal";
import DeleteAddressModal from "./Customers_DeleteAddressModal";
import { v4 as uuidv4 } from "uuid";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

const config = JSON.parse(process.env.REACT_APP_CONFIG);

function CustomersEditCustomerModal(props) {
  const {
    rowData,
    onClose,
    handleShowAlert,
    handleCloseAlert,
    addressList
  } = props;

  const [AddressList, setAddressList] = useState(addressList);
  const [CustomerAddrId, setCustomerAddrId] = useState(rowData.CUSTOMER_ADDR_ID);
  const [CustomerId] = useState(rowData.CUSTOMER_ID);
  const [CustomerName, setCustomerName] = useState(rowData.CUSTOMER_NAME);
  const [CustomerStatus, setCustomerStatus] = useState(rowData.CUSTOMER_STATUS);
  const [DebtorCode, setDebtorCode] = useState(rowData.DEBTOR_CODE);
  const [CustomerEmail, setCustomerEmail] = useState(rowData.CUSTOMER_EMAIL);
  const [blocking, setBlocking] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState({});
  const [ItemList, setItemList] = useState([]);
  const navigate = useNavigate();

  const {
    handleSubmit,
    register,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      CustomerName: rowData.CUSTOMER_NAME,
      CustomerStatus: rowData.CUSTOMER_STATUS,
      DebtorCode: rowData.DEBTOR_CODE,
      CustomerEmail: rowData.CUSTOMER_EMAIL,
    },
  });


  useEffect(() => {
    handleToggleBlocking(true);
    fetch(config.customer_get_link, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getTokenFromLocalStorage(),
      },
      body: JSON.stringify({
        customer_id: CustomerId,
      }),
    })
      .then((response) => response.json())
      .then((jsonData) => {
        console.log(jsonData);
        if (
          jsonData.success !== true &&
          jsonData.message === "Access Denied! Invalid token"
        ) {
          clearUserNameAndTokenInLocalStorage();
          const state = { data: "Token expired, please login again" };
          navigate("/login", { state });
          throw new Error(jsonData.message);
        } else if (jsonData.success) {
          return jsonData.data;
        } else {
          throw new Error(jsonData.message);
        }
      })
      .then((data) =>
        setItemList(
          data.ADDRESSES.map((item) => ({
            ...item,
            ITEM_ID: uuidv4(),
          }))
        )
      )
      .catch((error) => console.error(error))
      .finally(()=>{
        handleToggleBlocking(false);
      });
  }, []);

  const handleToggleBlocking = (value) => {
    setBlocking(value);
  };

  const handleAddItemList = (newValue) => {
    setItemList([...ItemList, newValue]);
  };

  const handleEditItemList = (newValue) => {
    const updatedItems = ItemList.map((item) => {
      if (item.ITEM_ID === newValue.ITEM_ID) {
        return newValue;
      }
      return item;
    });

    setItemList(updatedItems);
  };

  const handleDeleteItemList = (newValue) => {
    const updatedItems = ItemList.filter(
      (item) => item.ITEM_ID !== newValue.ITEM_ID
    );

    setItemList(updatedItems);
  };

  const handleNewAddress = () => {
    setShowModal("newaddress");
  };

  const handleEditClick = (rowData) => {
    setSelectedRowData(rowData);
    setShowModal("edit");
  };

  const handleDeleteClick = (rowData) => {
    setSelectedRowData(rowData);
    setShowModal("delete");
  };

  const submitUpdateCustomer = async () => {
    try {
      var arrCustomerAddressID = [];
      var arrAddrType = [];
      var arrAddr1Name = [];
      var arrAddr2Name = [];
      var arrAddr3Name = [];
      var arrAddr4Name = [];
      var arrPostCode = [];
      var arrCity = [];
      var arrState = [];
      var arrCountryName = [];
      var arrCountryCode = [];
      var arrContactName = [];
      var arrContactPhone = [];

      if (ItemList.length === 0) {
        handleShowAlert("error", "Edit Customer", "Failed Updating Customer :", [
          "Address list is empty!",
        ]);
        return;
      }
      for (var i = 0; i < ItemList.length; i++) {
        arrCustomerAddressID.push(ItemList[i].CUSTOMER_ADDR_ID);
        arrAddrType.push(ItemList[i].ADDR_TYPE);
        arrAddr1Name.push(ItemList[i].ADDR1_NAME);
        arrAddr2Name.push(ItemList[i].ADDR2_NAME);
        arrAddr3Name.push(ItemList[i].ADDR3_NAME);
        arrAddr4Name.push(ItemList[i].ADDR4_NAME);
        arrPostCode.push(ItemList[i].POST_CODE);
        arrCity.push(ItemList[i].CITY);
        arrState.push(ItemList[i].STATE);
        arrCountryName.push(ItemList[i].COUNTRY_NAME);
        arrCountryCode.push(ItemList[i].COUNTRY_CODE);
        arrContactName.push(ItemList[i].CONTACT_NAME);
        arrContactPhone.push(ItemList[i].CONTACT_PHONE);
      }

      handleToggleBlocking(true);

      var jsonOb = {
        customer_id: CustomerId,
        customer_name: CustomerName,
        debtor_code: DebtorCode,
        customer_email: CustomerEmail,
        customer_status: CustomerStatus,
        customer_addr_id: arrCustomerAddressID,
        addr_type: arrAddrType,
        addr1_name: arrAddr1Name,
        addr2_name: arrAddr2Name,
        addr3_name: arrAddr3Name,
        addr4_name: arrAddr4Name,
        post_code: arrPostCode,
        city: arrCity,
        state: arrState,
        country_name: arrCountryName,
        country_code: arrCountryCode,
        contact_name: arrContactName,
        contact_phone: arrContactPhone,
      };
      console.log(jsonOb);
      const response = await fetch(config.customer_update_link, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + getTokenFromLocalStorage(),
        },
        body: JSON.stringify(jsonOb),
      });
      const contentType = response.headers.get("content-type");
      const data =
        contentType && contentType.includes("application/json")
          ? await response.json()
          : await response.text();
      // const data = await response.json();
      console.log("result :");
      console.log(data);
      if (data.success !== true && data.message === "Access Denied! Invalid token") {
        clearUserNameAndTokenInLocalStorage();
        const state = { data: "Token expired, please login again" };
        navigate("/login", { state });
      }
      if (data.success !== true) {
        console.log("print error : " + data.error);
        var errorMessages = data.error.map((item) => item.msg);
        throw errorMessages;
      }
      if (data.success === true) {
        console.log("Success Updating Customer");
        handleShowAlert(
          "ok", 
          "Edit Customer", 
          "Success editing Customer"
        );
        onClose();
        return "ok";
      } else {
        throw data;
      }
    } catch (error) {
      console.error("Error while Editing Customer:", error);
      handleShowAlert(
        "error",
        "Edit Customer",
        "Error while editing Customer:",
        error
      );
    } finally {
      handleToggleBlocking(false);
    }
  };

  return (
    <Modal show={true} onHide={onClose} size="xl" backdrop="static" centered>
      <BlockUi blocking={blocking}>
        <Form onSubmit={handleSubmit(submitUpdateCustomer)}>
          <Modal.Header closeButton className="px-4">
            <Modal.Title className="ms-auto">Edit Customer</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="mb-2 justify-content-center">
              <Col md="8">
                <Form.Group as={Row} controlId="CustomerName">
                  <Form.Label column sm="4" className="fw-bold">
                    Customer Name :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      type="text"
                      placeholder="Customer Name"
                      value={CustomerName}
                      maxLength={250}
                      onInput={(e) => setCustomerName(e.target.value)}
                      className={`form-control ${
                        errors.CustomerName ? "is-invalid" : ""
                      }`}
                      {...register("CustomerName", {
                        pattern: {
                          value: /^.{3,}$/,
                          message: "Minimum 3 Character",
                        },
                        validate: (value) => {
                          return value !== "" || "This field is required";
                        },
                      })}
                    />
                    {errors.CustomerName && (
                      <p className="text-danger">
                        {errors.CustomerName.message}
                      </p>
                    )}
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-2 justify-content-center">
              <Col md="8">
                <Form.Group as={Row} controlId="CustomerEmail" className="d-flex">
                  <Form.Label column sm="4" className="fw-bold">
                    Customer Email :
                  </Form.Label>
                  <Col sm="8" className="d-flex justify-content-between ">
                  <Form.Control
                        type="email"
                        placeholder="Customer Email"
                        value={CustomerEmail}
                        maxLength={100}
                        onInput={(e) => setCustomerEmail(e.target.value)}
                      />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-2 justify-content-center">
              <Col md="8">
                <Form.Group as={Row} controlId="DebtorCode" className="d-flex">
                  <Form.Label column sm="4" className="fw-bold">
                    Debtor Code :
                  </Form.Label>
                  <Col sm="5" className="d-flex justify-content-between ">
                    <Form.Control
                      type="text"
                      placeholder="Debtor Code"
                      value={DebtorCode}
                      maxLength={20}
                      onInput={(e) => setDebtorCode(e.target.value)}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-2 justify-content-center">
              <Col md="8">
                <Form.Group as={Row} controlId="CustomerStatus" className="align-items-center">
                  <Form.Label column sm="4" className="fw-bold">
                    Status :
                  </Form.Label>
                  <Col sm="7">
                    {/* <Form.Control type="text" placeholder="" value={Status} onInput={(e) => setStatus(e.target.value)} /> */}
                    {/* <Form.Control as="select" value={Status} onChange={(e) => setStatus(e.target.value)}>
                            <option value="">Please Select..</option>
                            <option value="A">Active</option>
                            <option value="I">Inactive</option>
                        </Form.Control> */}
                    {/* <Form.Select
                      value={Status}
                      onChange={(e) => setStatus(e.target.value)}
                    >
                      <option value="A">Active</option>
                      <option value="I">Inactive</option>
                    </Form.Select> */}
                    <Form.Check
                      inline
                      type="radio"
                      label="Active"
                      name="statusRadios"
                      id="statusRadios1"
                      checked={CustomerStatus === "A"}
                      onChange={() => setCustomerStatus("A")}
                    />
                    <Form.Check
                      inline
                      type="radio"
                      label="Inactive"
                      name="statusRadios"
                      id="statusRadios2"
                      checked={CustomerStatus === "I"} 
                      onChange={() => setCustomerStatus("I")} 
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <hr />
            <Row className="mb-4 align-items-center">
              <Col sm="9" className="text-center">
                <Form.Label>Addresses</Form.Label>
              </Col>
              <Col sm="3">
                <Button
                  className="me-2"
                  variant="secondary"
                  onClick={handleNewAddress}
                >
                  Add Address
                </Button>
              </Col>
            </Row>
            <CustomersAddressTable
              itemList={ItemList}
              onEditClick={handleEditClick}
              onDeleteClick={handleDeleteClick}
              fromEditModal={false}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={onClose} variant="secondary">
              Close
            </Button>
            <Button type="submit" variant="primary">
              Save
            </Button>
          </Modal.Footer>
        </Form>
        {showModal === "newaddress" ? (
          <CustomersNewAddressModal
            handleAddItemList={handleAddItemList}
            handleCloseAlert={handleCloseAlert}
            handleShowAlert={handleShowAlert}
            itemList={ItemList}
            rowData={selectedRowData}
            onClose={() => setShowModal(false)}
          />
        ) : (
          ""
        )}
        {showModal === "edit" ? (
          <CustomersEditAddressModal
            handleEditItemList={handleEditItemList}
            handleShowAlert={handleShowAlert}
            itemList={ItemList}
            rowData={selectedRowData}
            onClose={() => setShowModal(false)}
          />
        ) : (
          ""
        )}
        {showModal === "delete" ? (
          <DeleteAddressModal
            handleDeleteItemList={handleDeleteItemList}
            handleShowAlert={handleShowAlert}
            rowData={selectedRowData}
            onClose={() => setShowModal(false)}
          />
        ) : (
          ""
        )}
      </BlockUi>
    </Modal>
  );
}

export default CustomersEditCustomerModal;
