import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Accordion from "react-bootstrap/Accordion";
import SearchDeliveryOrderEditTable from "./SearchDeliveryOrder_EditTable";
// import ManageBulkOrderMemoHistoryDeleteConfirmationModal from "./ManageBulkOrderMemo_DeleteConfirmationModal";
import DatePicker from "react-datepicker";
import moment from "moment/moment";
import { useNavigate } from "react-router-dom";
import {
  clearUserNameAndTokenInLocalStorage,
  getUserNameFromLocalStorage,
  getTokenFromLocalStorage,
} from "../../lib/common";
import BlockUi from "@availity/block-ui";
import { useForm, Controller } from "react-hook-form";

const config = JSON.parse(process.env.REACT_APP_CONFIG);

function SearchDeliveryOrderEditModal(props) {
  const {
    rowData,
    selectedMemoNum,
    handleSetConstructedPayload,
    onClose,
    handleShowAlert,
    handleShowUpdateConfirmation,
    UpdateConfirmationText,
    handleCloseAlert,
    setTrigger,
    setRefreshHistoryTrigger,
  } = props;
  const [SleSoNum] = useState(rowData.SO_NUMBER);
  // const [SlePoNum, setSlePoNum] = useState(rowData.PO_NUMBER !== null ? rowData.PO_NUMBER : "");
  const [SleMemoNum, setSleMemoNum] = useState(selectedMemoNum);
  const [SleDoNum, setSleDoNum] = useState(rowData.DO_NUMBER);
  const [CustomerId] = useState(rowData.CUSTOMER_ID);
  const [CustomerName, setCustomerName] = useState(rowData.CUSTOMER_NAME);
  const [ProjectId] = useState(rowData.PROJECT_ID);
  const [ProjectName, setProjectName] = useState(rowData.PROJECT_NAME);
  const [PaymentTerm] = useState(rowData.PAYMENT_TERM);
  const [SleSoDate] = useState(
    rowData.SO_DATE ? new Date(rowData.SO_DATE) : ""
  );
  const [SleDoDate] = useState(rowData.DO_DATE ? new Date(rowData.DO_DATE) : "");

  // const [SlePoDate, setSlePoDate] = useState("");
  // const [SlePoDate, setSlePoDate] = useState(rowData.PO_DATE ? new Date(rowData.PO_DATE) : "");
  const [ScheduleDate, setScheduleDate] = useState(rowData.SCHEDULE_DELIVERY_DATE ? new Date(rowData.SCHEDULE_DELIVERY_DATE) : "");
  const [NewScheduleDate, setNewScheduleDate] = useState("");
  // const [PoDate, setPoDate] = useState("");
  // const [PoDate, setPoDate] = useState(new Date());
  const [isIncludeSleDo, setIsIncludeSleDo] = useState(false);
  const [OrderType] = useState(rowData.SO_TYPE_DESC);
  const [Remarks, setRemarks] = useState("");
  const [blocking, setBlocking] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [textInputs, setTextInputs] = useState({});
  const [selectedData, setSelectedData] = useState([]);
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] =
    useState(false);
  const [refreshTrigger, setRefreshTrigger] = useState("");
  const [minDate] = useState(new Date());
  const [maxDate] = useState(() => {
    const maxDate = new Date();
    maxDate.setFullYear(new Date().getFullYear() + 1);
    const formattedMaxDate = maxDate;
    return formattedMaxDate;
  });

  const {
    control,
    register,
    handleSubmit,
    watch,
    setValue,
    trigger,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      NewScheduleDate: "",
    },
  });

  const navigate = useNavigate();
  const handleIncludeSleDoCheckbox = (event) => {
    setIsIncludeSleDo(event.target.checked);
  };
  const handleToggleBlocking = () => {
    setBlocking((prevState) => !prevState);
  };

  const handleToggleFromChild = (newValue) => {
    setBlocking(newValue);
  };

  const handleDeleteClick = () => {
    setShowDeleteConfirmationModal(true);
  };

  const handleUpdateDeliveryOrder = async (event) => {
    // event.preventDefault();
    try {
      handleToggleBlocking();
      await updateDeliveryOrder();
      console.log("Success updating Delivery Order");
      onClose();
      handleShowAlert(
        "ok",
        "Update Delivery Order",
        "Success updating Delivery Order"
      );
    } catch (error) {
      handleToggleBlocking();
      console.error("Error while updating Delivery Order:", error);
      handleShowAlert(
        "error",
        "Update Delivery Order",
        "Error while updating Delivery Order: ",
        error.join("<br>")
      );
      return;
    }
  };

  const handleSave = () => {
    try {
      var item_qtis = Object.values(textInputs);
      var text = "";

      // if (item_qtis.length === 0){
      //   throw ["No selected data !"];
      // }
      console.log(item_qtis + " " + NewScheduleDate)
      if (item_qtis.length === 0 && NewScheduleDate !== "") {
        text = "Confirm to change Delivery Date only?";
      } else if (item_qtis.length !== 0 && NewScheduleDate === "") {
        text = "Do you confirm to update the selected Delivery Order ?";
      } else if (item_qtis.length !== 0 && NewScheduleDate !== "") {
        text = "Do you confirm to update the selected Delivery Order ?";
      } else if (item_qtis.length === 0 && NewScheduleDate === ""){
        throw ["No New Schedule Date ", "Please specify item(s) to be included in this DO"];
      } else {
        throw ["Unknown Error"];
      }

      const item_qty = () => {
        const array = [];
        for (var j=0; j<item_qtis.length; j++){
          if (j < selectedRows.length){
            array.push(parseFloat(item_qtis[j]).toFixed(4));
          }
        }
        return array;
      }

      console.log({
        do_number: SleDoNum,
        product_id: selectedRows,
        new_item_qty: item_qty(),
        new_schedule_delivery_date:
          NewScheduleDate !== "" && NewScheduleDate !== "null"
            ? moment(NewScheduleDate).format("YYYY-MM-DD")
            : "",
      })

      handleSetConstructedPayload({
        do_number: SleDoNum,
        product_id: selectedRows,
        new_item_qty: item_qty(),
        new_schedule_delivery_date:
          NewScheduleDate !== "" && NewScheduleDate !== "null"
            ? moment(NewScheduleDate).format("YYYY-MM-DD")
            : "",
      });

      handleShowUpdateConfirmation(text);
    } catch (error) {
      console.error(error);
      handleShowAlert(
        "error",
        "Update Delivery Order",
        "Error while updating Delivery Order: ",
        error
      );
      return;
    }
  };

  const updateDeliveryOrder = async () => {
    try {
      var item_qtis = Object.values(textInputs);
      console.log({
        item_id: selectedRows,
        new_item_qty: item_qtis.map((item_qty) => parseInt(item_qty)),
      });
      return 0;
      const response = await fetch(config.memo_item_update_link, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + getTokenFromLocalStorage(),
        },
        body: JSON.stringify({
          item_id: selectedRows,
          new_item_qty: item_qtis.map((item_qty) => parseInt(item_qty)),
        }),
      });

      const data = await response.json();
      console.log("update memo response : ");
      console.log(data);
      if (
        data.success !== true &&
        data.message === "Access Denied! Invalid token"
      ) {
        clearUserNameAndTokenInLocalStorage();
      }

      if (data.success !== true) {
        var errorMessages = data.error.map((item) => item.msg);
        // handleShowAlert("error", "New Invoice", "Failed Saving New Invoice :", errorMessages.join("<br>"));
        throw errorMessages;
      }
      setTrigger(moment().unix());
      if (data.success === true) {
        console.log("Success Updating Delivery Order");
        // handleTrigger(moment().unix());
        return "ok";
      } else {
        throw data;
      }
    } catch (error) {
      console.error("Error while Updating Delivery Order:", error);
      throw error;
    }
  };

  const toggleRowSelection = (rowId) => {
    if (selectedRows.includes(rowId)) {
      setSelectedRows(selectedRows.filter((id) => id !== rowId));
    } else {
      setSelectedRows([...selectedRows, rowId]);
    }
  };

  const handleInputChange = (rowId, value) => {
    setTextInputs({ ...textInputs, [rowId]: value });
  };

  const handleSaveSelectedData = (event) => {
    // event.preventDefault();
    var item_qtis = Object.values(textInputs);
    var dataSend = {
      product_id: selectedRows,
      item_qty: item_qtis.map((item_qty) => parseInt(item_qty)),
    };

    // You can perform additional actions with the selected data here
  };

  return (
    <Modal
      show={true}
      onHide={onClose}
      size="xl"
      backdrop="static"
      style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
      centered
    >
      <BlockUi blocking={blocking}>
        <Form onSubmit={handleSubmit(handleSave)}>
          <Modal.Header closeButton className="px-4">
            <Modal.Title className="ms-auto">Edit Delivery Order</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <Row className="mb-2 justify-content-center" style={{marginLeft : "120px"}}>
            <Col md="6">
              <Form.Group as={Row} controlId="SLEDONumber">
                <Form.Label column sm="4" className="fw-bold">
                  SLE DO Number :
                </Form.Label>
                <Col sm="6">
                  <Form.Control
                    readOnly
                    className="no-border"
                    type="text"
                    placeholder=""
                    value={SleDoNum}
                  />
                </Col>
              </Form.Group>
            </Col>

            <Col md="6">
              <Form.Group as={Row} controlId="SLEDODate">
                <Form.Label column sm="4" className="fw-bold">
                  SLE DO Date :
                </Form.Label>
                <Col sm="6">
                  <DatePicker
                    readOnly
                    dateFormat="dd/MM/yyyy"
                    className="form-control no-border"
                    showIcon
                    selected={SleDoDate}
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-2 justify-content-center" style={{marginLeft : "120px"}}>
            <Col md="6">
              <Form.Group as={Row} controlId="SLESONumber">
                <Form.Label column sm="4" className="fw-bold">
                  SLE SO Number :
                </Form.Label>
                <Col sm="6">
                  <Form.Control
                    readOnly
                    className="no-border"
                    type="text"
                    placeholder=""
                    value={SleSoNum}
                  />
                </Col>
              </Form.Group>
            </Col>

            <Col md="6">
              <Form.Group as={Row} controlId="SLESODate">
                <Form.Label column sm="4" className="fw-bold">
                  SLE SO Date :
                </Form.Label>
                <Col sm="6">
                  <DatePicker
                    readOnly
                    dateFormat="dd/MM/yyyy"
                    className="form-control no-border"
                    showIcon
                    selected={SleSoDate}
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>
          {/* <Row className="mb-2 justify-content-center">
            <Col md="6">
              <Form.Group as={Row} controlId="SLESONumber">
                <Form.Label column sm="6" className="fw-bold">
                  SLE PO Number :
                </Form.Label>
                <Col sm="6">
                  <Form.Control
                    readOnly
                    className="no-border"
                    type="text"
                    placeholder=""
                    value={SlePoNum}
                  />
                </Col>
              </Form.Group>
            </Col>

            <Col md="6">
              <Form.Group as={Row} controlId="SLESODate">
                <Form.Label column sm="6" className="fw-bold">
                  SLE PO Date :
                </Form.Label>
                <Col sm="6">
                  <DatePicker
                    readOnly
                    dateFormat="dd/MM/yyyy"
                    className="form-control no-border"
                    showIcon
                    selected={SlePoDate}
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row> */}
          <Row className="mb-2" style={{marginLeft : "120px"}}>
            <Col md="6">
              <Form.Group as={Row} controlId="OrderType">
                <Form.Label column sm="4" className="fw-bold">
                  Order Type :
                </Form.Label>
                <Col sm="6">
                  <Form.Control
                    readOnly
                    className="no-border"
                    type="text"
                    placeholder=""
                    value={OrderType}
                  />
                </Col>
              </Form.Group>
            </Col>

            <Col md="6">
              <Form.Group as={Row} controlId="OrderType">
                <Form.Label column sm="4" className="fw-bold">
                  Payment Term :
                </Form.Label>
                <Col sm="6">
                  <Form.Control
                    readOnly
                    className="no-border"
                    type="text"
                    placeholder=""
                    value={PaymentTerm}
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-2" style={{marginLeft : "120px"}}>
            <Col md="6">
              <Form.Group as={Row} controlId="SLESONumber">
                <Form.Label column sm="4" className="fw-bold">
                  Customer Name :
                </Form.Label>
                <Col sm="6">
                  <Form.Control
                    readOnly
                    className="no-border"
                    type="text"
                    placeholder=""
                    value={CustomerName}
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-2" style={{marginLeft : "120px"}}>
            <Col md="6">
              <Form.Group as={Row} controlId="SLESODate">
                <Form.Label column sm="4" className="fw-bold">
                  Project Name :
                </Form.Label>
                <Col sm="6">
                  <Form.Control
                    readOnly
                    className="no-border"
                    type="text"
                    placeholder=""
                    value={ProjectName}
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>
            <hr />
            <Row className="mb-2 text-center">
              <Col md="12">
                <Form.Group as={Row} controlId="OrderType">
                  <Form.Label column sm="4" className="fw-bold text-end">
                    Schedule Delivery Date :
                  </Form.Label>
                  <Col sm="3">
                    <DatePicker
                      readOnly
                      dateFormat="dd/MM/yyyy"
                      className="form-control no-border"
                      showIcon
                      selected={ScheduleDate}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-2 text-center">
              <Col md="12">
                <Form.Group as={Row} controlId="OrderType">
                  <Form.Label column sm="4" className="fw-bold text-end">
                    New Delivery Date :
                  </Form.Label>
                  <Col sm="3">
                    {/* <Controller
                    name="NewScheduleDate"
                    control={control}
                    rules={{
                      required: "New delivery date required",
                      pattern: {
                        value:
                          /^(0[1-9]|1\d|2\d|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/,
                        message: "Invalid date format",
                      },
                    }}
                    render={({ field }) => (
                      <> */}
                    <DatePicker
                      selected={NewScheduleDate}
                      onChange={(date) => setNewScheduleDate(date)}
                      // className={`form-control ${errors.NewScheduleDate ? "is-invalid" : ""
                      //   }`}
                      className="form-control"
                      dateFormat="dd/MM/yyyy"
                      minDate={minDate}
                      maxDate={maxDate}
                    />
                    {/* {errors.NewScheduleDate && (
                          <Form.Text className="text-danger">
                            {errors.NewScheduleDate.message}
                          </Form.Text>
                        )}
                      </>
                    )}
                  /> */}
                    {/* <DatePicker
                    dateFormat="dd/MM/yyyy"
                    className="form-control"
                    showIcon
                    selected={NewScheduleDate}
                    onChange={(date) => setNewScheduleDate(date)}
                  /> */}
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <hr />
            <Row className="mb-2 align-items-center">
              <Col sm="12" className="text-center">
                <Form.Label>[ Order Item ]</Form.Label>
              </Col>
            </Row>
            <SearchDeliveryOrderEditTable
              rowData={rowData}
              selectedMemoNum={selectedMemoNum}
              onChildToggleChange={handleToggleFromChild}
              onRowSelect={toggleRowSelection}
              onInputChange={handleInputChange}
              selectedRows={selectedRows}
              setSelectedRows={setSelectedRows}
              textInputs={textInputs}
              setTextInputs={setTextInputs}
              toggleRowSelection={toggleRowSelection}
              refreshTrigger={refreshTrigger}
              control={control}
              register={register}
              errors={errors}
              reset={reset}
              setValue={setValue}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={onClose} variant="secondary">
              Cancel
            </Button>
            {/* <Button onClick={handleDeleteClick} variant="secondary">
            Delete
          </Button> */}
            <Button
              type="submit"
              variant="primary"
              // onClick={() => handleUpdateDeliveryOrder()}
              // onClick={() => handleSave()}
            >
              Save
            </Button>
          </Modal.Footer>
          {/* {showDeleteConfirmationModal === true ? (
          <ManageBulkOrderMemoHistoryDeleteConfirmationModal
            handleCloseAlert={handleCloseAlert}
            handleShowAlert={handleShowAlert}
            selectedRows={selectedRows}
            setRefreshTrigger={setRefreshTrigger}
            onClose={() => {
              setShowDeleteConfirmationModal(false);
              setRefreshHistoryTrigger(moment().unix());
            }}
          />
        ) : (
          ""
        )} */}
        </Form>
      </BlockUi>
    </Modal>
  );
}

export default SearchDeliveryOrderEditModal;
