import React, { useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Pagination from 'react-bootstrap/Pagination';
import moment from 'moment/moment';
import { clearUserNameAndTokenInLocalStorage, getTokenFromLocalStorage } from '../../lib/common';
import { useForm, Controller, set } from 'react-hook-form';


const config = JSON.parse(process.env.REACT_APP_CONFIG);

function ManageGoodsReceivedNoteTable(props) {
    const [data, setData] = useState([]);
    const [pendingData, setPendingData] = useState([]);
    const [headerData] = useState(["Brand", "Product", "Supplier Item Code", "Supplier Item Name", "This GRN Qty", "PO Qty", "Total GRN Qty", "Bal Qty", "Qty Received"]);
    const [currentPage, setCurrentPage] = useState(1);
    const { showAlert, onRowSelect, selectedRows, setSelectedRows, textInputs, setTextInputs, onInputChange, toggleRowSelection, refreshTrigger, rowData } = props;
    const ROWS_PER_PAGE = 10;
    const totalPages = Math.ceil(data.length / ROWS_PER_PAGE);
    
    useEffect(() => 
        {
            let getDataReq = getData(config.seng_liy_backend_url +'/grn/get', rowData).then((data) => {
                console.log(data);
                let retData = data.GRN_ITEMS;
                console.log(retData);
                setSelectedRows(retData.map((row) => {return (row.PRODUCT_ID)}));
                setTextInputs(retData.reduce((acc, row) => {
                    acc[row.PRODUCT_ID] = row.THIS_GRN_QTY;
                    return acc;
                }, {}));
                setData(retData);
            });

            Promise.all([getDataReq]).then((values) => {
            });
        }, [showAlert, refreshTrigger]);

    const getData = (url, params = {}) => {
		return fetch(url, {
		  method: "POST",
		  headers: {
			"Content-Type": "application/json",
			Authorization: "Bearer " + getTokenFromLocalStorage(),
		  },
		  body: JSON.stringify({
			grn_number: params.GRN_NUMBER,
		  }),
		})
		  .then((response) => response.json())
		  .then((jsonData) => {
			if (
			  jsonData.success !== true &&
			  jsonData.message === "Access Denied! Invalid token"
			) {
			  clearUserNameAndTokenInLocalStorage();
			  throw new Error(jsonData.message);
			} else if (jsonData.success) {
				return jsonData.data;
			} else {
                setData([]);
			  throw new Error(jsonData.message);
			}
		  })
		  .catch((error) => console.error(error));
	  };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleToggleBlocking = () => {
        props.onChildToggleChange(false);
    };

    const handleInputChange = (event, rowId, row) => {
        const { name, value } = event.target;
        const { 
            ITEM_ID,
            PRODUCT_ID,
            THIS_GRN_QTY,
            BRAND,
            PRODUCT_NAME,
            SUPPLIER_ITEM_CODE,
            SUPPLIER_ITEM_NAME,
            PO_QTY,
            TOTAL_GRN_QTY,
         } = row;
         console.log(value);
        const decimalCount = countDecimalDigits(value);
        
        if(decimalCount > 4 || isNaN(value)){
            event.preventDefault();
            return;
        }else{
            const inputFloat = parseFloat(value);
            if(inputFloat > PO_QTY - (TOTAL_GRN_QTY - THIS_GRN_QTY)){
                event.preventDefault();
                return;
            }
            setTextInputs({ ...textInputs, [rowId]:  inputFloat});
            onInputChange(rowId, value); // Call the callback function in the parent component
        }
    };

    function countDecimalDigits(floatString) {
        // Convert the string to a floating-point number
        let floatValue = parseFloat(floatString);
    
        // Check if the input is a valid number
        if (isNaN(floatValue)) {
            return "Invalid input";
        }
    
        // Convert the floating-point number to a string
        let floatStringWithoutExponent = floatValue.toString();
    
        // Find the position of the decimal point
        let decimalIndex = floatStringWithoutExponent.indexOf('.');
    
        // If there's no decimal point, return 0
        if (decimalIndex === -1) {
            return 0;
        }
    
        // Count the number of characters after the decimal point
        return floatStringWithoutExponent.length - decimalIndex - 1;
    }

    const renderTableData = () => {
        const startIndex = (currentPage - 1) * ROWS_PER_PAGE;
        const endIndex = startIndex + ROWS_PER_PAGE;
        return data.slice(startIndex, endIndex).map((row, index) => {
            const { 
                ITEM_ID,
                PRODUCT_ID,
                THIS_GRN_QTY,
                BRAND,
                PRODUCT_NAME,
                SUPPLIER_ITEM_CODE,
                SUPPLIER_ITEM_NAME,
                PO_QTY,
                TOTAL_GRN_QTY,
             } = row;
             const ID = PRODUCT_ID
            return (
                <tr key={index}>
                    <td>
                        <Form.Check
                            type="checkbox"
                            onChange={(e) => {
                                if (e.target.checked) {
                                    setTextInputs({ ...textInputs, [ID]: textInputs[ID]});
                                }
                                toggleRowSelection(ID)}
                            }
                            checked={selectedRows.includes(ID)}
                        />
                    </td>
                    <td>{BRAND}</td>
                    <td>{PRODUCT_NAME}</td>
                    <td>{SUPPLIER_ITEM_CODE}</td>
                    <td>{SUPPLIER_ITEM_NAME}</td>
                    <td>{THIS_GRN_QTY}</td>
                    <td>{PO_QTY}</td>
                    <td>{TOTAL_GRN_QTY}</td>
                    <td>{(PO_QTY - TOTAL_GRN_QTY).toFixed(4)}</td>
                    <td>
                        <Form.Control
                            disabled={!selectedRows.includes(ID)}
                            type="number"
                            name={`textInput-${ID}`}
                            value={textInputs[ID]}
                            onChange={(e) => handleInputChange(e, ID, row)}
                            min={0}
                            max={PO_QTY - (TOTAL_GRN_QTY - THIS_GRN_QTY)}
                            step={0.0001}
                        />
                    </td>
                </tr>
            );
        });
    };

    const renderPageItems = () => {
        const pageItems = [];
        for (let i = 1; i <= totalPages; i++) {
            pageItems.push(
                <Pagination.Item key={i} active={i === currentPage} onClick={() => handlePageChange(i)}>
                    {i}
                </Pagination.Item>
            );
        }
        return pageItems;
    };

    

    if (data.length < 1) {
        return (
            <div className="text-center"><h3>No Data</h3></div>
        )
    } else {
        return (
            <div>
                <Table striped bordered hover className="mb-4">
                    <thead>
                        <tr>
                            <th>
                                <Form.Check
                                    type="checkbox"
                                    onChange={() => {
                                        if (selectedRows.length === data.length) {
                                            setTextInputs({})
                                            setSelectedRows([]);
                                        } else {
                                            setSelectedRows(data.map((row) => {return (row.PRODUCT_ID)}));
                                        }
                                    }}
                                    checked={selectedRows.length === data.length}
                                />
                            </th>
                            {headerData.map((header) => (
                                <th key={header}>{header}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>{renderTableData()}</tbody>
                </Table>
                <Pagination className="d-flex justify-content-center" >{renderPageItems()}</Pagination>
            </div>
        );
    }

}

export default ManageGoodsReceivedNoteTable;