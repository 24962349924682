import React, { useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Pagination from 'react-bootstrap/Pagination';
import moment from 'moment/moment';
import { clearUserNameAndTokenInLocalStorage, getTokenFromLocalStorage } from '../../lib/common';
import { useForm, Controller } from 'react-hook-form';


const config = JSON.parse(process.env.REACT_APP_CONFIG);

function ManageInvoice_ViewInvModalTable(props) {
    const [data, setData] = useState([]);
    const [headerData] = useState([
        "SLE DO Number", 
        "DO Ack Number", 
        "DO Ack Date",
        "Total DO Ack Amt",  
        "Action"
    ]);
    const [currentPage, setCurrentPage] = useState(1);
    const { onViewClick } = props;
    const { ItemList } = props;
    const { showAlert, rowData, refreshTrigger } = props;
    const ROWS_PER_PAGE = 10;
    const totalPages = Math.ceil(data.length / ROWS_PER_PAGE);
    const { control, handleSubmit, formState: { errors } } = useForm();
        
    useEffect(() => {
        setData(ItemList);
        console.log(ItemList);
    }, [ItemList]);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleToggleBlocking = () => {
        props.onChildToggleChange(false);
    };

    const renderTableData = () => {
        const startIndex = (currentPage - 1) * ROWS_PER_PAGE;
        const endIndex = startIndex + ROWS_PER_PAGE;
        return data.slice(startIndex, endIndex).map((row, index) => {
            const { 
                INVOICE_NUMBER,
                DO_NUMBER, 
                DO_ACK_NUMBER,
                DO_ACK_DATE,
                TOTAL_DO_ACK_AMOUNT,
            } = row;
            const formattedDoAckDate = moment(DO_ACK_DATE).format("DD MMM yyyy");
            const formatNumber = (num) => {
                return new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(num);
            };
            const formatTotalDoAckAmt = formatNumber(TOTAL_DO_ACK_AMOUNT);
            return (
                <tr key={INVOICE_NUMBER}>
                    <td>{DO_NUMBER}</td>
                    <td>{DO_ACK_NUMBER}</td>
                    <td>{formattedDoAckDate}</td>
                    <td className="text-end">{formatTotalDoAckAmt}</td>
                    <td style={{ width: "190px" }}>
                        <Button
                            variant="primary"
                            onClick={() => onViewClick(row)}
                        >
                            View
                        </Button>
                    </td>
                </tr>
            );
        });
    };

    const renderPageItems = () => {
        const pageItems = [];
        for (let i = 1; i <= totalPages; i++) {
            pageItems.push(
                <Pagination.Item key={i} active={i === currentPage} onClick={() => handlePageChange(i)}>
                    {i}
                </Pagination.Item>
            );
        }
        return pageItems;
    };

    if (data.length < 1) {
        return (
            <div className="text-center"><h3>No Data</h3></div>
        )
    } else {
        return (
            <div>
              <Table striped bordered hover className="mb-4">
                <thead>
                  <tr>
                    {headerData.map((header) => (
                      <th key={header}>{header}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>{renderTableData()}</tbody>
              </Table>
              <Pagination className="d-flex justify-content-center">
                {renderPageItems()}
              </Pagination>
            </div>
        );
    }

}

export default ManageInvoice_ViewInvModalTable;