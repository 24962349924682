import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import NewGoodsReceivedNoteTable from "./NewGoodsReceivedNote_EditTable";
import DatePicker from "react-datepicker";
import moment from "moment/moment";
import { useNavigate } from "react-router-dom";
import {
	clearUserNameAndTokenInLocalStorage,
	getTokenFromLocalStorage,
} from "../../lib/common";
import BlockUi from "@availity/block-ui";
import { useForm, Controller } from "react-hook-form";
import { CreateGRN } from "../../lib/api/GoodsReceivedNote";

const config = JSON.parse(process.env.REACT_APP_CONFIG);

function NewGoodsReceivedNoteEditModal(props) {
	const { handleShowAlert, handleCloseAlert, rowData, onClose } = props;
	const [SlePoNum, setSlePoNum] = useState(rowData.PO_NUMBER);
	const [SlePoDate, setSlePoDate] = useState(new Date(rowData.PO_DATE));
	const [SlePoType, setSlePoType] = useState(rowData.PO_TYPE);
	const SoNumber = rowData.SO_NUMBER;
	const [SleSupplierName, setSleSupplierName] = useState(
		rowData.SUPPLIER_NAME
	);
	const [GRNDate, setGRNDate] = useState(new Date());
	const [isIncludeSleDo, setIsIncludeSleDo] = useState(false);
	const [Remarks, setRemarks] = useState("");
	const [blocking, setBlocking] = useState(false);
	const [selectedRows, setSelectedRows] = useState([]);
	const [textInputs, setTextInputs] = useState({});
	const [selectedData, setSelectedData] = useState([]);
	const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] =
		useState(false);
	const [refreshTrigger, setRefreshTrigger] = useState("");
	const [supplier_do_number, set_supplier_do_number] = useState(undefined);
	const [supplier_do_date, set_supplier_do_date] = useState(new Date());
	const {
		control,
		register,
		handleSubmit,
		watch,
		setValue,
		trigger,
		formState: { errors },
	} = useForm({
		defaultValues: {
			supplier_do_number: "",
			supplier_do_date: "",
		},
	});

	const navigate = useNavigate();
	const handleIncludeSleDoCheckbox = (event) => {
		setIsIncludeSleDo(event.target.checked);
	};
	const handleToggleBlocking = () => {
		setBlocking((prevState) => !prevState);
	};

	const handleToggleFromChild = (newValue) => {
		setBlocking(newValue);
	};

	const handleDeleteClick = () => {
		setShowDeleteConfirmationModal(true);
	};

	const handleSubmitCreateGrn = async (event) => {
		// event.preventDefault();
		try {
			handleToggleBlocking();
			if(selectedRows.length === 0){
				throw new Error("Please select a PO Item.");
			}

			if(Object.keys(textInputs).length === 0){
				throw new Error("Please select a PO Item.");
			}
			console.log(textInputs);
			// Check each textInput is not empty
			for (const row in textInputs) {
				if (textInputs[row] === "" || textInputs[row] === undefined) {
					throw new Error("Please enter quantity for selected items");
				}
			}
			let submitResult = await submitCreateGrn().then((response) =>{ return response.json()});
			console.log(submitResult);
			if (
				submitResult.success === false &&
				submitResult.message === "Access Denied! Invalid token"
			) {
				clearUserNameAndTokenInLocalStorage();
				throw(new Error(submitResult.message));
			} else if (submitResult.success) {
				handleToggleBlocking();
				console.log("Success Create GRN");
				onClose();
				console.log(submitResult);
				handleShowAlert("ok", "Create GRN", `Success Creating GRN : ${submitResult.grn_number}`);

			} else {
				throw new Error(submitResult.message);
			}
		} catch (error) {
			handleToggleBlocking();
			console.error("Error while creating GRN:", error.message);
			handleShowAlert(
				"error",
				"Create GRN",
				"Error while Creating GRN: ",
				error.message
			);
			return;
		}
	};

	const submitCreateGrn = async () => {
		let productIds = [];
		let itemQtys = [];
		for (const row in textInputs) {
				productIds.push(row);
				console.log(textInputs[row]);
			if (isNaN(textInputs[row])) {
				console.log('invalid input');
				return false;
			}
			const itemQty = parseFloat(textInputs[row]);
			if(itemQty){
				itemQtys.push(itemQty);
			}else{
				throw(new Error("Invalid Item Qty"));
			}
		}

		let createNewGrnDTO = {
			po_number: SlePoNum,
			grn_date: moment(GRNDate).format("YYYY-MM-DD"),
			supplier_do_number: supplier_do_number,
			supplier_do_date: moment(supplier_do_date).format("YYYY-MM-DD"),
			product_id: productIds,
			item_qty: itemQtys,
			so_number: SoNumber
		};

        return fetch(config.seng_liy_backend_url + "/grn/create", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + getTokenFromLocalStorage(),
            },
            body: JSON.stringify(createNewGrnDTO),
        });
	};

	const toggleRowSelection = (rowId) => {
		if (selectedRows.includes(rowId)) {
			setSelectedRows(selectedRows.filter((id) => id !== rowId));
		} else {
			setSelectedRows([...selectedRows, rowId]);
		}
	};

	const handleInputChange = (rowId, value) => {
		setTextInputs({ ...textInputs, [rowId]: value });
	};

	const handleSaveSelectedData = (event) => {
		// event.preventDefault();
		var item_qtis = Object.values(textInputs);
		var dataSend = {
			product_id: selectedRows,
			item_qty: item_qtis.map((item_qty) => parseInt(item_qty)),
		};

		// You can perform additional actions with the selected data here
	};

	const todayDate = new Date();
	const daysToSubtract = 60;
	const twoMonthAgoDate = new Date(
		todayDate.valueOf() - daysToSubtract * 24 * 60 * 60 * 1000
	);

	return (
		<Modal show={true} onHide={onClose} size="xl" backdrop="static" centered>
			<BlockUi blocking={blocking}>
				{/* <Form onSubmit={handleUpdateMemo}> */}
				<Form onSubmit={handleSubmit(handleSubmitCreateGrn)}>
					<Modal.Header closeButton className="px-4">
						<Modal.Title className="ms-auto">New GRN</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Row className="mb-2 justify-content-center">
							<Col md="9">
								<Row>
									<Col md="6">
										<Form.Group as={Row} controlId="SLESONumber">
											<Form.Label
												column
												sm="6"
												className="fw-bold"
											>
												SLE PO Number :
											</Form.Label>
											<Col sm="6">
												<Form.Control
													disabled
													className="form-control"
													type="text"
													placeholder=""
													value={SlePoNum}
												/>
											</Col>
										</Form.Group>
									</Col>
									<Col md="6">
										<Form.Group as={Row} controlId="SLESODate">
											<Form.Label
												column
												sm="6"
												className="fw-bold"
											>
												PO Date :
											</Form.Label>
											<Col sm="6">
												<DatePicker
													disabled
													dateFormat="dd/MM/yyyy"
													className="form-control"
													showIcon
													selected={SlePoDate}
												/>
											</Col>
										</Form.Group>
									</Col>
								</Row>
							</Col>
						</Row>
						<Row className="mb-2 justify-content-center">
							<Col md="9">
								<Row>
									<Col md="6">
										<Form.Group as={Row} controlId="SLESONumber">
											<Form.Label
												column
												sm="6"
												className="fw-bold"
											>
												PO Type :
											</Form.Label>
											<Col sm="6">
												<Form.Control
													disabled
													className="form-control"
													type="text"
													placeholder=""
													value={SlePoType}
												/>
											</Col>
										</Form.Group>
									</Col>
								</Row>
							</Col>
						</Row>
						<Row className="mb-2 justify-content-center">
							<Col md="9">
								<Form.Group as={Row} controlId="SLESODate">
									<Form.Label column sm="3" className="fw-bold">
										Supplier :
									</Form.Label>
									<Col sm="9">
										<Form.Control
											disabled
											className="form-control"
											type="text"
											placeholder="Supplier"
											value={SleSupplierName}
										/>
									</Col>
								</Form.Group>
							</Col>
						</Row>
						<hr />
						<Row className="mb-2 justify-content-center">
							<Col md="9">
								<Row>
									<Col md="6">
										<Form.Group as={Row} controlId="SLESODate">
											<Form.Label
												column
												sm="6"
												className="fw-bold"
											>
												GRN Date :
											</Form.Label>
											<Col sm="6">
												<DatePicker
													disabled
													dateFormat="dd/MM/yyyy"
													className="form-control"
													showIcon
													selected={GRNDate}
												/>
											</Col>
										</Form.Group>
									</Col>
								</Row>
							</Col>
						</Row>
						<Row className="mb-2 justify-content-center">
							<Col md="9">
								<Row>
									<Col md="6">
										<Form.Group as={Row} controlId="SLESONumber">
											<Form.Label
												column
												sm="6"
												className="fw-bold"
											>
												Supplier DO Number :
											</Form.Label>
											<Col sm="6">
												<Form.Control
													type="text"
													placeholder=""
													value={supplier_do_number}
													onInput={(x) => {
													set_supplier_do_number(
														x.target.value
													);
													setValue("supplier_do_number", x.target.value)
													}}
													className={`form-control ${
													errors.supplier_do_number ? "is-invalid" : ""
													}`}
													{...register("supplier_do_number", {
													pattern: {
														value: /^.{3,}$/,
														message: "Minimum 3 Character",
													},
													validate: (value) => {
														return value !== "" || "This field is required";
													},
													})}
												/>
												{errors.supplier_do_number && (
													<Form.Text className="text-danger">
													{errors.supplier_do_number.message}
													</Form.Text>
												)}
											</Col>
										</Form.Group>
									</Col>
									<Col md="6">
										<Form.Group as={Row} controlId="SLESODate">
											<Form.Label
												column
												sm="6"
												className="fw-bold"
											>
												Supplier DO Date :
											</Form.Label>
											<Col sm="6">
												<DatePicker
													dateFormat="dd/MM/yyyy"
													className="form-control"
													showIcon
													selected={supplier_do_date}
													minDate={twoMonthAgoDate}
													maxDate={todayDate}
													onChange={(date) =>
														set_supplier_do_date(date)
													}
												/>
											</Col>
										</Form.Group>
									</Col>
								</Row>
							</Col>
						</Row>
						<hr />
						<Row className="mb-4 align-items-center">
							<Col sm="12" className="text-center">
								<Form.Label>[ PO Items Received ]</Form.Label>
							</Col>
						</Row>
						<NewGoodsReceivedNoteTable
							rowData={rowData}
							onChildToggleChange={handleToggleFromChild}
							onRowSelect={toggleRowSelection}
							onInputChange={handleInputChange}
							selectedRows={selectedRows}
							setSelectedRows={setSelectedRows}
							textInputs={textInputs}
							setTextInputs={setTextInputs}
							toggleRowSelection={toggleRowSelection}
							refreshTrigger={refreshTrigger}
						/>
					</Modal.Body>
					<Modal.Footer>
						<Button onClick={onClose} variant="secondary">
							Cancel
						</Button>
						<Button
							type="submit"
							variant="primary"
						>
							Save
						</Button>
					</Modal.Footer>
				</Form>
			</BlockUi>
			<style>
				{`
					.form-control:disabled {
					background: #F8F8F8 !important;
					}
				`}
			</style>
		</Modal>
	);
}

export default NewGoodsReceivedNoteEditModal;
