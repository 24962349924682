import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment/moment';
import Table from '../page_components/DeliveryStatus/DeliveryStatus_Table';
import StatusModal from '../page_components/DeliveryStatus/DeliveryStatus_StatusModal';
import FollowUpModal from '../page_components/DeliveryStatus/DeliveryStatus_FollowUpModal';
import ViewModal from '../page_components/DeliveryStatus/DeliveryStatus_ViewModal';
import CancelConfirmationModal from '../page_components/DeliveryStatus/DeliveryStatus_CancelConfirmationModal'; 
import { useNavigate } from 'react-router-dom';
import {
  clearUserNameAndTokenInLocalStorage,
  getTokenFromLocalStorage,
} from "../lib/common";
import BlockUi from '@availity/block-ui';
import "@availity/block-ui/src/BlockUi.css";
import "@availity/block-ui/src/Loader.css";
import AlertModal from '../page_components/AlertModal';
import { IconContext } from "react-icons";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { AiOutlineCloseCircle } from "react-icons/ai";
const config = JSON.parse(process.env.REACT_APP_CONFIG);

function DeliveryStatus() {
  const [DeliveryDateFrom, setDeliveryDateFrom] = useState("");
  const [DeliveryDateTo, setDeliveryDateTo] = useState("");
  const [SleSoNum, setSleSoNum] = useState("");
  // const [SlePoNum, setSlePoNum] = useState("");
  const [SleDoNum, setSleDoNum] = useState("");
  const [CustomerName, setCustomerName] = useState("");
  const [CustomerList, setCustomerList] = useState([]);
  const [ProjectName, setProjectName] = useState("");
  const [ProjectList, setProjectList] = useState([]);
  const [searchProps, setSearchProps] = useState({});
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [blocking, setBlocking] = useState(true);
  const [ShowAlert, setShowAlert] = useState(false);
  const [AlertTitle, setAlertTitle] = useState("");
  const [AlertMessage, setAlertMessage] = useState("");
  const [ApiResponse, setApiResponse] = useState("");
  const [ShowCancelConfirmationModal, setShowCancelConfirmationModal] =
  useState(false);
  const [ConstructedPayload, setConstructedPayload] = useState({});

  const navigate = useNavigate();

  useEffect(() => {
    if (getTokenFromLocalStorage() === null) {
      const state = { data: 'Token expired, please login again' };
      navigate("/login", { state });
    }
  });

  useEffect(() => {
    fetch(config.customer_list_link, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getTokenFromLocalStorage(),
      },
      body: JSON.stringify({}),
    })
      .then((response) => response.json())
      .then((jsonData) => {
        console.log(jsonData);
        if (
          jsonData.success !== true &&
          jsonData.message === "Access Denied! Invalid token"
        ) {
          clearUserNameAndTokenInLocalStorage();
          throw new Error(jsonData.message);
        } else if (jsonData.success) {
          return jsonData.data;
        } else {
          throw new Error(jsonData.message);
        }
      })
      .then((data) => setCustomerList(data))
      .catch((error) => console.error(error));
  }, []);

  useEffect(() => {
    if (CustomerName !== "") {
      fetch(config.project_list_link, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + getTokenFromLocalStorage(),
        },
        body: JSON.stringify({
          customer_id: CustomerName,
        }),
      })
        .then((response) => response.json())
        .then((jsonData) => {
          console.log(jsonData);
          if (
            jsonData.success !== true &&
            jsonData.message === "Access Denied! Invalid token"
          ) {
            clearUserNameAndTokenInLocalStorage();
            throw new Error(jsonData.message);
          } else if (jsonData.success) {
            return jsonData.data;
          } else {
            throw new Error(jsonData.message);
          }
        })
        .then((data) => setProjectList(data))
        .catch((error) => console.error(error));
    }
  }, [CustomerName]);

  const handleShowAlert = async (status, title, message, apiResponse = "") => {
    setAlertTitle(title);
    if (status === "ok") {
      setAlertMessage(
        <>
          <p className='mb-1'>
            <IconContext.Provider value={{ color: "green", style: { fontSize: "40px", paddingRight: "5px" } }}>
              <AiOutlineCheckCircle />
              {message}
            </IconContext.Provider>
          </p>
        </>
      );
    } else {
      setAlertMessage(
        <>
          <p className='mb-1'>
            <IconContext.Provider value={{ color: "red", style: { fontSize: "40px", paddingRight: "5px" } }}>
              <AiOutlineCloseCircle />
              {message}
            </IconContext.Provider>
          </p>
          {apiResponse !== "" ? <p className='text-danger fst-italic ps-3'>{apiResponse}</p>
            : ""}
        </>
      );
    }
    setShowAlert(true);
  };

  const handleCloseAlert = () => {
    setShowAlert(false);
  };

  const handleToggleBlocking = () => {
    setBlocking(prevState => !prevState);
  };

  const handleToggleFromChild = (newValue) => {
    setBlocking(newValue);
  };

  const handleResetForm = () => {
    handleToggleBlocking();

    setDeliveryDateFrom("");
    setDeliveryDateTo("");
    // setSlePoNum("");
    setSleSoNum("");
    setSleDoNum("");
    setCustomerName("");
    setProjectName("");

    const searchProperties = {
      DeliveryDateFrom: "",
      DeliveryDateTo: "",
      // SlePoNum: "",
      SleSoNum: "",
      SleDoNum: "",
      CustomerName: "",
      ProjectName: "",
    };
    setSearchProps(searchProperties);

  }

  const handleSearch = (event) => {
    event.preventDefault();
    handleToggleBlocking();
    const searchProperties = {
      DeliveryDateFrom: DeliveryDateFrom,
      DeliveryDateTo: DeliveryDateTo,
      // SlePoNum: SlePoNum,
      SleSoNum: SleSoNum,
      SleDoNum: SleDoNum,
      CustomerName: CustomerName,
      ProjectName: ProjectName,
    };
    setSearchProps(searchProperties);
  }

  const handleStatusClick = (rowData) => {
    setSelectedRowData(rowData);
    setShowModal("status");
  };
  const handleFollowUpClick = (rowData) => {
    setSelectedRowData(rowData);
    setShowModal("followup");
  };

  const handleViewClick = (rowData) => {
    setSelectedRowData(rowData);
    setShowModal("view");
  };

  const handleShowCancelConfirmation = (rowData) => {
    setSelectedRowData(rowData);
    setShowCancelConfirmationModal(true);
  };

  const handleOnClose = (isFinished) => {
    setShowCancelConfirmationModal(false);
    if (isFinished) {
      setShowModal(false);
    }
  }

  return (
    <div>
      <Container className="mt-4">
        <BlockUi blocking={blocking}>
          <Row className="justify-content-center mb-4">
            <Col md={6}>
              {/* <Form onSubmit={handleSearch}> */}
              <Row className="mb-4">
                <Col className="text-center">
                  <h2>Delivery Status</h2>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col>
                  <Form.Group as={Row} className="mb-3" controlId="SLESONumber">
                    <Form.Label column sm="4">
                      SLE SO Number
                    </Form.Label>
                    <Col sm="7" style={{ paddingRight: "13px" }}>
                      <Form.Control type="text" placeholder="SLE SO Number" value={SleSoNum} onInput={(e) => setSleSoNum(e.target.value)} />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              {/* <Row className="mb-2">
                <Col>
                  <Form.Group as={Row} className="mb-3" controlId="SLEPONumber">
                    <Form.Label column sm="4">
                      SLE PO Number
                    </Form.Label>
                    <Col sm="7" style={{ paddingRight: "13px" }}>
                      <Form.Control type="text" placeholder="SLE PO Number" value={SlePoNum} onInput={(e) => setSlePoNum(e.target.value)} />
                    </Col>
                  </Form.Group>
                </Col>
              </Row> */}
              <Row className="mb-2">
                <Col>
                  <Form.Group as={Row} className="mb-3" controlId="SLEPONumber">
                    <Form.Label column sm="4">
                      SLE DO Number
                    </Form.Label>
                    <Col sm="7" style={{ paddingRight: "13px" }}>
                      <Form.Control type="text" placeholder="SLE PO Number" value={SleDoNum} onInput={(e) => setSleDoNum(e.target.value)} />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col sm="7">
                  <Form.Group as={Row} className="mb-3" controlId="SLESODateFrom">
                    <Form.Label column sm="7">
                      Delivery Date From
                    </Form.Label>
                    <Col sm="5" style={{ paddingLeft: "8px" }}>
                      <DatePicker className="form-control" dateFormat="dd/MM/yyyy" showIcon selected={DeliveryDateFrom} onChange={(date) => setDeliveryDateFrom(date)} />
                    </Col>
                  </Form.Group>
                </Col>
                <Col sm="5">
                  <Form.Group as={Row} className="mb-3 text-center" controlId="SLESODateTo">
                    <Form.Label column sm="2">
                      to
                    </Form.Label>
                    <Col sm="7" style={{ paddingRight: "0px" }}>
                      <DatePicker className="form-control" dateFormat="dd/MM/yyyy" showIcon selected={DeliveryDateTo} onChange={(date) => setDeliveryDateTo(date)} />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="CustomerName"
                  >
                    <Form.Label column sm="4">
                      Customer Name
                    </Form.Label>
                    <Col sm="7" >
                      <Form.Select
                        value={CustomerName}
                        onChange={(e) => setCustomerName(e.target.value)}
                      >
                        <option key={""} value="">
                          Please select customer name
                        </option>
                        {CustomerList.map((items) => (
                          <option
                            key={items.CUSTOMER_ID}
                            value={items.CUSTOMER_ID}
                          >
                            {items.CUSTOMER_NAME}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="ProjectName"
                  >
                    <Form.Label column sm="4">
                      Project Name
                    </Form.Label>
                    <Col sm="7">
                      <Form.Select
                        value={ProjectName}
                        onChange={(e) => setProjectName(e.target.value)}
                      >
                        <option key={""} value="">
                          Please select project name
                        </option>
                        {ProjectList.map((items) => (
                          <option
                            key={items.PROJECT_ID}
                            value={items.PROJECT_ID}
                          >
                            {items.PROJECT_NAME}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col className="text-center">
                  <Button className='me-2' variant="secondary" onClick={handleResetForm}>Reset</Button>
                  <Button type="submit" variant="primary" onClick={handleSearch}>Search</Button>
                </Col>
              </Row>
              {/* </Form> */}
            </Col>
          </Row>
          <hr />
          <br />
          <Table showAlert={ShowAlert} searchProps={searchProps} onStatusClick={handleStatusClick} onChildToggleChange={handleToggleFromChild} onFollowUpClick={handleFollowUpClick} onViewClick={handleViewClick}/>
          {showModal === "status" ? <StatusModal handleShowAlert={handleShowAlert} rowData={selectedRowData} onClose={() => setShowModal(false)} /> : ""}
          {showModal === "followup" ? <FollowUpModal handleShowAlert={handleShowAlert} rowData={selectedRowData} onClose={() => setShowModal(false)} /> : ""}
          {showModal === "view" ? <ViewModal handleShowAlert={handleShowAlert} rowData={selectedRowData} handleShowCancelConfirmation={handleShowCancelConfirmation} onClose={() => setShowModal(false)} /> : ""}
          {ShowCancelConfirmationModal === true ? <CancelConfirmationModal handleShowAlert={handleShowAlert} rowData={selectedRowData} onClose={handleOnClose} /> : ""}
        </BlockUi>
        {ShowAlert && (
          <AlertModal
            title={AlertTitle}
            message={AlertMessage}
            apiResponse={ApiResponse}
            onClose={handleCloseAlert}
          />
        )}
      </Container>
    </div>
  );
}

export default DeliveryStatus;
