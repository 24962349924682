import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Accordion from "react-bootstrap/Accordion";
import DatePicker from "react-datepicker";
import moment from "moment/moment";
import ProductsSuppliersTable from "./Products_SuppliersTable";
import ProductsNewSupplierModal from "./Products_NewProductAddSupplierModal";
import ProductsEditSupplierModal from "./Products_NewProductEditSupplierModal";
import DeleteSupplierModal from "./Products_DeleteSupplierModal";

// import ManageBulkOrderMemoHistoryModalAccordion from "./ManageBulkOrderMemo_HistoryModalAccordion";
// import ManageBulkOrderMemoHistoryEditModal from "./ManageBulkOrderMemo_EditModal";

import { useNavigate } from "react-router-dom";
import {
  clearUserNameAndTokenInLocalStorage,
  getUserNameFromLocalStorage,
  getTokenFromLocalStorage,
} from "../../lib/common";
import BlockUi from "@availity/block-ui";
import { useForm, Controller } from "react-hook-form";

const config = JSON.parse(process.env.REACT_APP_CONFIG);

function ProductsNewProductModal(props) {
  const {
    control,
    handleSubmit,
    watch,
    reset,
    register,
    setValue,
    getValues,
    trigger,
    formState: { errors },
  } = useForm({
    defaultValues: {
      ProductName: "",
      BrandName: "",
      UnitPrice: "",
      MinSellingPrice: "",
      UnitOfMeasurements: "",
    },
  });
  const {
    rowData,
    onClose,
    handleShowAlert,
    handleCloseAlert,
    unitOfMeasurementsList,
  } = props;
  const [BrandList, setBrandList] = useState([]);
  const [BrandName, setBrandName] = useState("");
  const [ProductName, setProductName] = useState("");
  const [UnitOfMeasurementsList] = useState(unitOfMeasurementsList);
  const [UnitOfMeasurements, setUnitOfMeasurements] = useState("");
  const [MinSellingPrice, setMinSellingPrice] = useState("");
  const [SleSoNum] = useState("");
  const [SleSoStatus] = useState("");
  const [CustomerId, setCustomerId] = useState("");
  const [CustomerList, setCustomerList] = useState([]);
  const [ProjectList, setProjectList] = useState([]);
  const [ProjectId, setProjectId] = useState("");
  const [SoTypeList, setSoTypeList] = useState([]);
  const [PaymentTerm, setPaymentTerm] = useState("");
  const [isIncludeSleDo, setIsIncludeSleDo] = useState(false);
  const [OrderType, setOrderType] = useState("");
  const [blocking, setBlocking] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showSecondModal, setShowSecondModal] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState({});
  const [MinMargin, setMinMargin] = useState(parseFloat(0));
  const [Status, setStatus] = useState("A");
  const [ItemList, setItemList] = useState([]);

  //   useEffect(() => {
  //     // Create an object to store grouped data
  //     const groupedData = Data.reduce((result, item) => {
  //       const memoNumber = item.MEMO_NUMBER;

  //       // If the memoNumber is not in the result, create a new array for it
  //       if (!result[memoNumber]) {
  //         result[memoNumber] = [];
  //       }

  //       // Push the item into the array under the memoNumber key
  //       result[memoNumber].push(item);

  //       return result;
  //     }, {});

  //     // Convert the grouped data into an array
  //     const groupedArray = Object.values(groupedData);
  //     setProcessedData(groupedArray);
  //   }, [Data]);

  useEffect(() => {
    fetch(config.brand_list_link, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getTokenFromLocalStorage(),
      },
      body: JSON.stringify({}),
    })
      .then((response) => response.json())
      .then((jsonData) => {
        console.log(jsonData);
        if (
          jsonData.success !== true &&
          jsonData.message === "Access Denied! Invalid token"
        ) {
          clearUserNameAndTokenInLocalStorage();
          throw new Error(jsonData.message);
        } else if (jsonData.success) {
          return jsonData.data;
        } else {
          throw new Error(jsonData.message);
        }
      })
      .then((data) => setBrandList(data))
      .catch((error) => console.error(error));
  }, []);

  useEffect(() => {
    if (BrandName !== "") {
      fetch(config.consignment_margin_get_link, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + getTokenFromLocalStorage(),
        },
        body: JSON.stringify({
          brand: BrandName,
        }),
      })
        .then((response) => response.json())
        .then((jsonData) => {
          console.log(jsonData);
          if (
            jsonData.success !== true &&
            jsonData.message === "Access Denied! Invalid token"
          ) {
            clearUserNameAndTokenInLocalStorage();
            throw new Error(jsonData.message);
          } else if (jsonData.success) {
            return jsonData.data;
          } else {
            throw new Error(jsonData.message);
          }
        })
        .then((data) => {
          setMinMargin(parseFloat(data.MIN_MARGIN).toFixed(2));
          // setValue("ProductId", "");
        })
        .catch((error) => console.error(error));
    }
  }, [BrandName]);

  const handleIncludeSleDoCheckbox = (event) => {
    setIsIncludeSleDo(event.target.checked);
  };
  const handleToggleBlocking = (value) => {
    setBlocking(value);
  };

  const handleToggleFromChild = (newValue) => {
    setBlocking(newValue);
  };

  const handleToggleClick = (rowData) => {
    setSelectedRowData(rowData);
    setShowSecondModal("toggle");
  };

  const handleAddItemList = (newValue) => {
    setItemList([...ItemList, newValue]);
  };

  const handleEditItemList = (newValue) => {
    console.log("newValue");
    console.log(newValue);
    const updatedItems = ItemList.map((item) => {
      if (item.ITEM_ID === newValue.ITEM_ID) {
        return newValue; // Replace the matching item
      }
      return item; // Keep other items unchanged
    });

    setItemList(updatedItems);
    // setItemList([...ItemList, newValue]);
  };

  const handleDeleteItemList = (newValue) => {
    const updatedItems = ItemList.filter(
      (item) => item.ITEM_ID !== newValue.ITEM_ID
    );

    console.log(updatedItems);

    setItemList(updatedItems);
    // setItemList([...ItemList, newValue]);
  };

  const handleResetForm = () => {
    reset();
    setBrandName("");
    setProductName("");
    setUnitOfMeasurements([]);
    setMinSellingPrice("");
    setStatus("Active");
    setItemList([]);
  };

  const handleNewSupplier = () => {
    setShowModal("newso");
  };

  const handleEditClick = (rowData) => {
    setSelectedRowData(rowData);
    setShowModal("edit");
  };

  const handleDeleteClick = (rowData) => {
    setSelectedRowData(rowData);
    setShowModal("delete");
  };

  // useEffect(() => {
  //   // handleToggleBlocking();
  //   getData(config.so_get_link, rowData);
  // }, [rowData]);

  // useEffect(() => {
  //   fetch(config.customer_active_list_link, {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: "Bearer " + getTokenFromLocalStorage(),
  //     },
  //     body: JSON.stringify({}),
  //   })
  //     .then((response) => response.json())
  //     .then((jsonData) => {
  //       handleToggleFromChild(false);
  //       if (
  //         jsonData.success !== true &&
  //         jsonData.message === "Access Denied! Invalid token"
  //       ) {
  //         clearUserNameAndTokenInLocalStorage();
  //         throw new Error(jsonData.message);
  //       } else if (jsonData.success) {
  //         return jsonData.data;
  //       } else {
  //         throw new Error(jsonData.message);
  //       }
  //     })
  //     .then((data) => {
  //       setCustomerList(data);
  //     })
  //     .catch((error) => console.error(error));
  // }, []);

  // useEffect(() => {
  //   if (CustomerId !== "") {
  //     fetch(config.project_list_link, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: "Bearer " + getTokenFromLocalStorage(),
  //       },
  //       body: JSON.stringify({
  //         customer_id: CustomerId,
  //       }),
  //     })
  //       .then((response) => response.json())
  //       //   .then(res => console.log(res))
  //       .then((jsonData) => {
  //         if (
  //           jsonData.success !== true &&
  //           jsonData.message === "Access Denied! Invalid token"
  //         ) {
  //           clearUserNameAndTokenInLocalStorage();
  //           throw new Error(jsonData.message);
  //         } else if (jsonData.success) {
  //           return jsonData.data;
  //         } else {
  //           throw new Error(jsonData.message);
  //         }
  //       })
  //       .then((data) => {
  //         setProjectList(data);
  //         //   setValue("ProjectId", "");
  //         // trigger("ProjectId");
  //       })
  //       .catch((error) => console.error(error));
  //   }
  // }, [CustomerId]);

  // useEffect(() => {
  //   fetch(config.sotype_list_link, {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: "Bearer " + getTokenFromLocalStorage(),
  //     },
  //     body: JSON.stringify({}),
  //   })
  //     .then((response) => response.json())
  //     .then((jsonData) => {
  //       console.log(jsonData);
  //       if (
  //         jsonData.success !== true &&
  //         jsonData.message === "Access Denied! Invalid token"
  //       ) {
  //         clearUserNameAndTokenInLocalStorage();
  //         throw new Error(jsonData.message);
  //       } else if (jsonData.success) {
  //         return jsonData.data;
  //       } else {
  //         throw new Error(jsonData.message);
  //       }
  //     })
  //     .then((data) => setSoTypeList(data))
  //     .catch((error) => console.error(error));
  // }, []);

  const handleSubmitNewProduct = async () => {
    try {
      var arrSupplierItemCode = [];
      var arrSupplierItemName = [];
      var arrStatus = [];
      var arrSupplierId = [];

      if (ItemList.length === 0) {
        handleShowAlert(
          "error",
          "New Product",
          "Failed Saving New Product :",
          ["Supplier list is empty!"]
        );
        throw ("Supplier list is empty!");
      }

      handleToggleBlocking(true);

      for (var i = 0; i < ItemList.length; i++) {
        arrSupplierId.push(ItemList[i].SUPPLIER_ID)
        arrSupplierItemCode.push(ItemList[i].SUPPLIER_ITEM_CODE);
        arrSupplierItemName.push(ItemList[i].SUPPLIER_ITEM_NAME);
        arrStatus.push(ItemList[i].SUPPLIER_ITEM_STATUS);
      }

      if (!(arrStatus.includes("A"))) {
        handleShowAlert(
          "error",
          "New Product",
          "Failed Saving New Product :",
          ["Minimum 1 active Supplier needed!"]
        );
        throw ("Minimum 1 active Supplier needed!");
      }

      var jsonOb = {
        product_name: getValues("ProductName"),
        brand: getValues("BrandName"),
        unit_measure: getValues("UnitOfMeasurements"),
        min_sell_price: getValues("MinSellingPrice"),
        product_status : Status,
        supplier_id: arrSupplierId,
        supplier_item_code: arrSupplierItemCode,
        supplier_item_name: arrSupplierItemName,
        supplier_item_status: arrStatus
      };
      console.log(jsonOb);
      const response = await fetch(config.product_create_link, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + getTokenFromLocalStorage(),
        },
        body: JSON.stringify(jsonOb),
      });

      const contentType = response.headers.get("content-type");
      const data =
        contentType && contentType.includes("application/json")
          ? await response.json()
          : await response.text();
      console.log("result :");
      console.log(data);
      if (data.message === "Access Denied! Invalid token") {
        clearUserNameAndTokenInLocalStorage();
        throw data.message;
      } else if (data.message) {
        throw data.message;
      }
      if (response.status !== 200) {
        var errorMessages = data.error.map((item) => item.msg);
        handleShowAlert(
          "error",
          "New Product",
          "Failed Saving New Product :",
          errorMessages
        );
        throw errorMessages.join("<br/>");
      } else {
        handleShowAlert(
          "ok",
          "New Product",
          "Success Saving New Product"
          // [data.so_number]
        );
        handleResetForm();
        return "ok";
      }
    } catch (error) {
      console.error("Error while submitting New Product:", error);
      throw error;
    } finally {
      handleToggleBlocking(false);
    }
  };

  // const getData = async (url, params = {}) => {
  //   handleToggleBlocking(true);
  //   fetch(url, {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: "Bearer " + getTokenFromLocalStorage(),
  //     },
  //     body: JSON.stringify({
  //       so_number: params.SO_NUMBER,
  //     }),
  //   })
  //     .then((response) => response.json())
  //     .then((jsonData) => {
  //       console.log(jsonData);
  //       // setBlocking(false);
  //       if (
  //         jsonData.success !== true &&
  //         jsonData.message === "Access Denied! Invalid token"
  //       ) {
  //         clearUserNameAndTokenInLocalStorage();
  //         throw new Error(jsonData.message);
  //       } else if (jsonData.success && jsonData.data.SO_ITEMS.length > 0) {
  //         const newData = jsonData.data.SO_ITEMS.map((item) => {
  //           const date = moment(item.SO_DATE).format("DD MMM yyyy");
  //           item.SO_DATE = date;
  //           return item;
  //         });
  //         return newData;
  //       } else {
  //         setItemList([]);
  //         throw new Error(jsonData.message);
  //       }
  //     })
  //     .then((data) => {
  //       setItemList(data);
  //     })
  //     .catch((error) => console.error(error))
  //     .finally(() => {
  //       handleToggleBlocking(false);
  //     });
  // };

  return (
    <Modal show={true} onHide={onClose} size="xl" backdrop="static" centered>
      <BlockUi blocking={blocking}>
        <Form onSubmit={handleSubmit(handleSubmitNewProduct)}>
          <Modal.Header closeButton className="px-4">
            <Modal.Title className="ms-auto">New Product</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="mb-2 justify-content-center">
              <Col md="7">
                <Form.Group as={Row} controlId="Brand">
                  <Form.Label
                    column
                    sm="4"
                    className="fw-bold"
                    style={{ paddingLeft: "80px" }}
                  >
                    Brand Name:
                  </Form.Label>
                  <Col sm="6">
                    <Controller
                      name="BrandName"
                      control={control}
                      rules={{ required: "This field is required" }}
                      render={({ field }) => (
                        <Form.Select
                          {...field}
                          onChange={(e) => {
                            setBrandName(e.target.value);
                            setValue("BrandName", e.target.value);
                            trigger("BrandName");
                          }}
                          className={`form-select ${
                            errors.BrandName ? "is-invalid" : ""
                          }`}
                        >
                          <option key={""} value="" disabled={true}>
                            Please select brand name
                          </option>
                          {BrandList.map((items) => (
                            <option key={items.BRAND} value={items.BRAND}>
                              {items.BRAND}
                            </option>
                          ))}
                        </Form.Select>
                      )}
                    />
                    {errors.BrandName && (
                      <Form.Text className="text-danger">
                        {errors.BrandName.message}
                      </Form.Text>
                    )}
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-2 justify-content-center">
              <Col md="7">
                <Form.Group as={Row} controlId="ProductName">
                  <Form.Label
                    column
                    sm="4"
                    className="fw-bold"
                    style={{ paddingLeft: "80px" }}
                  >
                    Product Name:
                  </Form.Label>
                  <Col sm="6">
                    <Form.Control
                      type="text"
                      placeholder="Product Name"
                      value={ProductName}
                      onInput={(e) => setProductName(e.target.value)}
                      className={`form-control ${
                        errors.ProductName ? "is-invalid" : ""
                      }`}
                      {...register("ProductName", {
                        pattern: {
                          value: /^.{3,}$/,
                          message: "Minimum 3 Character",
                        },
                        validate: (value) => {
                          return value !== "" || "This field is required";
                        },
                      })}
                    />
                    {errors.ProductName && (
                      <Form.Text className="text-danger">
                        {errors.ProductName.message}
                      </Form.Text>
                    )}
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-2 justify-content-center">
              <Col md="7">
                <Form.Group as={Row} controlId="UnitOfMeasurements">
                  <Form.Label
                    column
                    sm="4"
                    className="fw-bold"
                    style={{ paddingLeft: "80px" }}
                  >
                    UOM:
                  </Form.Label>
                  <Col sm="6">
                    <Controller
                      name="UnitOfMeasurements"
                      control={control}
                      rules={{ required: "This field is required" }}
                      render={({ field }) => (
                        <Form.Select
                          {...field}
                          onChange={(e) => {
                            setUnitOfMeasurements(e.target.value);
                            setValue("UnitOfMeasurements", e.target.value);
                            trigger("UnitOfMeasurements");
                          }}
                          className={`form-select ${
                            errors.UnitOfMeasurements ? "is-invalid" : ""
                          }`}
                        >
                          <option key={""} value="" disabled={true}>
                            Please select unit of measurements
                          </option>
                          {UnitOfMeasurementsList.map((items) => (
                            <option
                              key={items.UOM_ID}
                              value={items.UNIT_MEASURE}
                            >
                              {items.UNIT_MEASURE}
                            </option>
                          ))}
                        </Form.Select>
                      )}
                    />
                    {errors.UnitOfMeasurements && (
                      <Form.Text className="text-danger">
                        {errors.UnitOfMeasurements.message}
                      </Form.Text>
                    )}
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-2 justify-content-center">
              <Col md="7">
                <Form.Group as={Row} controlId="MinSellingPrice">
                  <Form.Label
                    column
                    sm="4"
                    className="fw-bold"
                    style={{ paddingLeft: "80px" }}
                  >
                    Min Selling Price:
                  </Form.Label>
                  <Col sm="6">
                    <Form.Control
                      type="text"
                      placeholder="Minimum Selling Price"
                      value={MinSellingPrice}
                      className={`form-control ${
                        errors.MinSellingPrice ? "is-invalid" : ""
                      }`}
                      onInput={(e) => setMinSellingPrice(e.target.value)}
                      {...register("MinSellingPrice", {
                        required: false,
                        pattern: {
                          value: /^(?:\d+|\d*\.\d{1,2})$/,
                          // value: /^(?!^(\d+|\d*\.\d{0,1})$)\d+(\.\d{1,2})?$/,
                          message: "Needs integer or 2 decimal value",
                        },
                        // validate: (value) => {
                        //   const floatValue = parseFloat(value);

                        //   if (orderType === "C") {
                        //     return (
                        //       floatValue >=
                        //         parseFloat(CostPrice) +
                        //           parseFloat(CostPrice).toFixed(2) * MinMargin ||
                        //       `Value must have minimum ${MinMargin}% margin`
                        //     );
                        //     // return (
                        //     //   floatValue >=
                        //     //     parseFloat(CostPrice).toFixed(2) * 1.05 ||
                        //     //   `Value must have minimum 5% margin`
                        //     // );
                        //   } else {
                        //     return (
                        //       floatValue >= parseFloat(MinSellPrice).toFixed(2) ||
                        //       `At least same as Minimum Selling Price`
                        //     );
                        //   }
                        // },
                      })}
                    />
                    {errors.MinSellingPrice && (
                      <Form.Text className="text-danger">
                        {errors.MinSellingPrice.message}
                      </Form.Text>
                    )}
                    {/* <Form.Control
                      value={MinSellingPrice}
                      placeholder="Minimum Selling Price"
                      type="text"
                      onChange={(e) => setMinSellingPrice(e.target.value)}
                    ></Form.Control> */}
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-2 justify-content-center">
              <Col md="7">
                <Form.Group as={Row} controlId="Status" className="align-items-center">
                  <Form.Label
                    column
                    sm="4"
                    className="fw-bold"
                    style={{ paddingLeft: "80px" }}
                  >
                    Status:
                  </Form.Label>
                  <Col sm="6">
                      <Form.Check
                        inline
                        type="radio"
                        label="Active"
                        id="activeRadio1"
                        name="Status"
                        value="A"
                        checked={Status === 'A'}
                        onChange={(e) => setStatus(e.target.value)}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="Inactive"
                        id="inactiveRadio2"
                        name="Status"
                        value="I"
                        checked={Status === 'I'}
                        onChange={(e) => setStatus(e.target.value)}
                        style={{paddingLeft:"75px"}}
                      />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <hr />
            <Row className="mb-4 align-items-center">
              <Col sm="9" className="text-center">
                <Form.Label>[ Suppliers ]</Form.Label>
              </Col>
              <Col sm="3">
                <Button
                  className="me-2"
                  variant="secondary"
                  onClick={handleNewSupplier}
                >
                  Add Supplier
                </Button>
              </Col>
            </Row>
            <ProductsSuppliersTable
              itemList={ItemList}
              onEditClick={handleEditClick}
              onDeleteClick={handleDeleteClick}
              fromEditModal={false}
              //   setSelectedMemoNum={setSelectedMemoNum}
              //   setShowSecondModal={setShowSecondModal}
              // setSelectedRowData = {setSelectedRowData}
              // onChildToggleChange={handleToggleFromChild}
              // onRowSelect={toggleRowSelection}
              // onInputChange={handleInputChange}
              // selectedRows={selectedRows}
              // setSelectedRows={setSelectedRows}
              // textInputs={textInputs}
              // setTextInputs={setTextInputs}
              // toggleRowSelection={toggleRowSelection}
            />
            {/* {showSecondModal === "edit" ? (
              <ManageBulkOrderMemoHistoryEditModal
                handleCloseAlert={handleCloseAlert}
                handleShowAlert={handleShowAlert}
                rowData={rowData}
                selectedMemoNum={selectedMemoNum}
                onClose={() => setShowSecondModal(false)}
                setTrigger={setTrigger}
                setRefreshHistoryTrigger={setRefreshHistoryTrigger}
              />
            ) : (
              ""
            )} */}
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={onClose} variant="secondary">
              Close
            </Button>
            <Button type="submit" variant="primary">
              Save
            </Button>
          </Modal.Footer>
        </Form>
        {showModal === "newso" ? (
          <ProductsNewSupplierModal
            handleAddItemList={handleAddItemList}
            handleCloseAlert={handleCloseAlert}
            handleShowAlert={handleShowAlert}
            itemList={ItemList}
            rowData={selectedRowData}
            onClose={() => setShowModal(false)}
            // orderType={rowData.SO_TYPE}
          />
        ) : (
          ""
        )}
        {showModal === "edit" ? (
          <ProductsEditSupplierModal
            handleEditItemList={handleEditItemList}
            handleShowAlert={handleShowAlert}
            itemList={ItemList}
            rowData={selectedRowData}
            onClose={() => setShowModal(false)}
            // orderType={rowData.SO_TYPE}
          />
        ) : (
          ""
        )}
        {showModal === "delete" ? (
          <DeleteSupplierModal
            handleDeleteItemList={handleDeleteItemList}
            handleShowAlert={handleShowAlert}
            rowData={selectedRowData}
            onClose={() => setShowModal(false)}
          />
        ) : (
          ""
        )}
      </BlockUi>
    </Modal>
  );
}

export default ProductsNewProductModal;
