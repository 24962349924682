import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Pagination from "react-bootstrap/Pagination";
import Form from 'react-bootstrap/Form';
import moment from "moment/moment";
import {
  clearUserNameAndTokenInLocalStorage,
  getTokenFromLocalStorage,
} from "../../lib/common";
import { useNavigate } from "react-router-dom";
const config = JSON.parse(process.env.REACT_APP_CONFIG);

function NewPITable(props) {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [headerData] = useState([
    "",
    "GRN",
    "GRN Date",
    "PO",
    "Supplier",
    "Supplier DO Number",
    "Supplier DO Date",
    "Action",
  ]);

  const [currentPage, setCurrentPage] = useState(1);
  const { onViewClick } = props;
  const { searchProps } = props;
  const { showAlert, rowData, selectedRows, toggleRowSelection, refreshTrigger } = props;
  const ROWS_PER_PAGE = 10;
  const totalPages = Math.ceil(data.length / ROWS_PER_PAGE);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleToggleBlocking = () => {
    props.onChildToggleChange(false);
  };

  useEffect(() => {
    getData(config.pi_get_grn_link, {supplier_id : rowData.SUPPLIER_ID});
  }, [rowData.SUPPLIER_ID, showAlert, refreshTrigger]);
  
  const renderTableData = () => {
    const startIndex = (currentPage - 1) * ROWS_PER_PAGE;
    const endIndex = startIndex + ROWS_PER_PAGE;
    return data.slice(startIndex, endIndex).map((row) => {
      const {
        GRN_NUMBER,
        GRN_DATE,
        PO_NUMBER,
        SUPPLIER_NAME,
        SUPPLIER_DO_NUMBER,
        SUPPLIER_DO_DATE,
      } = row;
      const formattedGRNDate = moment(GRN_DATE).format("DD MMM yyyy");
      const formattedSupplierDoDate = moment(SUPPLIER_DO_DATE).format("DD MMM yyyy");
      return (
        // <tr key={GRN_NUMBER}>
        <tr key={`${GRN_NUMBER}-${PO_NUMBER}`}>
          <td>
            <Form.Check
              type="checkbox"
              onChange={() => toggleRowSelection(row.GRN_NUMBER)}
              checked={selectedRows.includes(row.GRN_NUMBER)}
            />
          </td>
          <td>{GRN_NUMBER}</td>
          <td>{formattedGRNDate}</td>
          <td>{PO_NUMBER}</td>
          <td>{SUPPLIER_NAME}</td>
          <td>{SUPPLIER_DO_NUMBER}</td>
          <td>{formattedSupplierDoDate}</td>
          <td>
            <Button
              size="sm"
              variant="primary"
              onClick={() => onViewClick(row)}
            >
              View GRN
            </Button>
          </td>
        </tr>
      );
    });
  };
  
  const renderPageItems = () => {
    const pageItems = [];
    for (let i = 1; i <= totalPages; i++) {
      pageItems.push(
        <Pagination.Item
          key={i}
          active={i === currentPage}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </Pagination.Item>
      );
    }
    return pageItems;
  };

  const getData = async (url, params = {}) => {
    console.log(params);
    await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getTokenFromLocalStorage(),
      },
      body: JSON.stringify(
        params.length === 0
          ? params
          : {
            supplier_id: params.supplier_id,
          }
      ),
    })
      .then((response) => response.json())
      .then((jsonData) => {
        console.log(jsonData);
        handleToggleBlocking();
        if (jsonData.message === "Access Denied! Invalid token") {
          clearUserNameAndTokenInLocalStorage();
          const state = { data: "Token expired, please login again" };
          navigate("/login", { state });
          throw new Error(jsonData.message);
        }
        const newData = jsonData.data.map((item) => {
          const date = moment(item.PI_DATE).utcOffset(480).format("DD MMM yyyy");
          item.PI_DATE = date;
          return item;
        });
        return newData;
      })
      .then((data) => setData(data))
      .catch((error) => console.error(error));
  };

  if (data.length < 1) {
    return (
      <div className="text-center">
        <h3>No Data</h3>
      </div>
    );
  } else {
    return (
      <div>
        <Table striped bordered hover className="mb-4">
          <thead>
            <tr>
              {headerData.map((header) => (
                <th key={header}>{header}</th>
              ))}
            </tr>
          </thead>
          <tbody>{renderTableData()}</tbody>
        </Table>
        <Pagination className="d-flex justify-content-center">
          {renderPageItems()}
        </Pagination>
      </div>
    );
  }
}

export default NewPITable;
