import React, { useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';
import Pagination from 'react-bootstrap/Pagination';

function ManageDOAcknowledgement_ViewAckModalTable(props) {
    const [data, setData] = useState([]);
    const { ItemList } = props;
    const [headerData] = useState([
        "Brand", 
        "Product ID", 
        "Product Name", 
        "This Ack Qty",
        "DO Qty", 
        "Total Ack Qty", 
        "Bal Qty", 
    ]);
    const [currentPage, setCurrentPage] = useState(1);

    const ROWS_PER_PAGE = 10;
    const totalPages = Math.ceil(data.length / ROWS_PER_PAGE);
    
    useEffect(() => {
        setData(ItemList);
        console.log(ItemList);
      }, [ItemList]);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const renderTableData = () => {
        const startIndex = (currentPage - 1) * ROWS_PER_PAGE;
        const endIndex = startIndex + ROWS_PER_PAGE;
        return data.slice(startIndex, endIndex).map((row, index) => {
            const { 
                ITEM_ID,
                BRAND,
                PRODUCT_ID,
                PRODUCT_NAME,
                THIS_DO_ACK_QTY,
                DO_QTY,
                TOTAL_ACK_QTY,
            } = row;
            return (
                <tr key={ITEM_ID}>
                    <td>{BRAND}</td>
                    <td>{PRODUCT_ID}</td>
                    <td>{PRODUCT_NAME}</td>
                    <td>{THIS_DO_ACK_QTY}</td>
                    <td>{DO_QTY}</td>
                    <td>{TOTAL_ACK_QTY}</td>
                    <td>{(DO_QTY - TOTAL_ACK_QTY).toFixed(4)}</td>
                </tr>
            );
        });
    };

    const renderPageItems = () => {
        const pageItems = [];
        for (let i = 1; i <= totalPages; i++) {
            pageItems.push(
                <Pagination.Item key={i} active={i === currentPage} onClick={() => handlePageChange(i)}>
                    {i}
                </Pagination.Item>
            );
        }
        return pageItems;
    };

    if (data.length < 1) {
        return (
            <div className="text-center"><h3>No Data</h3></div>
        )
    } else {
        return (
            <div>
              <Table striped bordered hover className="mb-4">
                <thead>
                  <tr>
                    {headerData.map((header) => (
                      <th key={header}>{header}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>{renderTableData()}</tbody>
              </Table>
              <Pagination className="d-flex justify-content-center">
                {renderPageItems()}
              </Pagination>
            </div>
        );
    }

}

export default ManageDOAcknowledgement_ViewAckModalTable;