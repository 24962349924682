import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Pagination from "react-bootstrap/Pagination";
import moment from "moment/moment";
import {
  clearUserNameAndTokenInLocalStorage,
  getTokenFromLocalStorage,
} from "../../lib/common";
import { useNavigate } from "react-router-dom";
const config = JSON.parse(process.env.REACT_APP_CONFIG);

function ManageInvoiceTable(props) {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [headerData] = useState([
    "Inv Number",
    "Inv Date",
    "Total Inv Amt",
    "Currency",
    "Customer",
    "Action",
  ]);
  const [currentPage, setCurrentPage] = useState(1);
  const { onViewClick } = props;
  const { onCancelClick } = props;
  const { searchProps } = props;
  const { showAlert } = props;
  const { handleShowAlert } = props;
  const ROWS_PER_PAGE = 10;
  const totalPages = Math.ceil(data.length / ROWS_PER_PAGE);

  useEffect(() => {
    if (
      searchProps.SleInvoiceNumber ||
      searchProps.SleSoNumber ||
      searchProps.SleDoNumber ||
      searchProps.DoAckNumber ||
      searchProps.SleInvoiceDateFrom ||
      searchProps.SleInvoiceDateTo ||
      searchProps.SleSoDateFrom ||
      searchProps.SleSoDateTo ||
      searchProps.SleDoDateFrom ||
      searchProps.SleDoDateTo ||
      searchProps.CustomerId ||
      searchProps.ProjectId
    ) {
      getData(config.invoice_list_link, searchProps);
    } else {
      getData(config.invoice_list_link);
    }
  }, [searchProps]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleToggleBlocking = () => {
    props.onChildToggleChange(false);
  };

  const renderTableData = () => {
    const startIndex = (currentPage - 1) * ROWS_PER_PAGE;
    const endIndex = startIndex + ROWS_PER_PAGE;
    return data.slice(startIndex, endIndex).map((row) => {
      const {
        INVOICE_NUMBER,
        INVOICE_DATE,
        INVOICE_AMOUNT_W_TAX,
        CURRENCY_CODE,
        CUSTOMER_NAME,
      } = row;
      const formattedInvDate = moment(INVOICE_DATE).format("DD MMM yyyy");
      const formatNumber = (num) => {
        return new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(num);
      };
      const formatInvAmtwT = formatNumber(INVOICE_AMOUNT_W_TAX);
      return (
        <tr key={INVOICE_NUMBER}>
          <td>{INVOICE_NUMBER}</td>
          <td>{formattedInvDate}</td>
          <td className="text-end">{formatInvAmtwT}</td>
          <td>{CURRENCY_CODE}</td>
          <td>{CUSTOMER_NAME}</td>
          <td style={{ width: "245px" }}>
            <Button
                variant="primary"
                style={{marginRight:"5px"}}
                onClick={() => onViewClick(row)}
            >
                View
            </Button>
            <Button variant="danger"
              onClick={() => onCancelClick(row)}>
              Delete
            </Button>
          </td>
        </tr>
      );
    });
  };

  const renderPageItems = () => {
    const pageItems = [];
    for (let i = 1; i <= totalPages; i++) {
      pageItems.push(
        <Pagination.Item
          key={i}
          active={i === currentPage}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </Pagination.Item>
      );
    }
    return pageItems;
  };

  const getData = async (url, params = {}) => {
    console.log(params);
    await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getTokenFromLocalStorage(),
      },
      body: JSON.stringify(
        params.length === 0
          ? params
          : {
            invoice_number: params.SleInvoiceNumber,
            so_number: params.SleSoNumber,
            do_number: params.SleDoNumber,
            do_ack_number: params.DoAckNumber,
            inv_date_from: params.SleInvoiceDateFrom ? moment(params.SleInvoiceDateFrom).format("YYYY-MM-DD") : null,
            inv_date_to: params.SleInvoiceDateTo ? moment(params.SleInvoiceDateTo).format("YYYY-MM-DD") : null,
            so_date_from: params.SleSoDateFrom ? moment(params.SleSoDateFrom).format("YYYY-MM-DD") : null,
            so_date_to: params.SleSoDateTo ? moment(params.SleSoDateTo).format("YYYY-MM-DD") : null,
            do_date_from: params.SleDoDateFrom ? moment(params.SleDoDateFrom).format("YYYY-MM-DD") : null,
            do_date_to: params.SleDoDateTo ? moment(params.SleDoDateTo).format("YYYY-MM-DD") : null,
            customer_id: params.CustomerId,
            project_id: params.ProjectId,
          }
      ),
    })
    .then(response => response.json())
    .then (jsonData => {
        console.log(jsonData)
      handleToggleBlocking();
      if (jsonData.success !== true && jsonData.message === 'Access Denied! Invalid token'){
        clearUserNameAndTokenInLocalStorage();
        const state = { data: "Token expired, please login again" };
        navigate("/login", { state });
        throw new Error (jsonData.message);
      } else if (jsonData.success){
        return jsonData.data;
      } else {
        handleShowAlert(
          "error",
          "Search Invoice",
          "Error while searching Invoice",
          jsonData.message
        );
        throw jsonData.message;
      }
    })
      .then((data) => setData(data))
      .catch((error) => {
        console.error(error)
        handleShowAlert(
          "error",
          "Search Invoice",
          "Error while searching Invoice",
          error
        );
      });
  };

  if (data.length < 1) {
    return (
      <div className="text-center">
        <h3>No Data</h3>
      </div>
    );
  } else {
    return (
      <div>
        <Table striped bordered hover className="mb-4">
          <thead>
            <tr>
              {headerData.map((header) => (
                <th key={header}>{header}</th>
              ))}
            </tr>
          </thead>
          <tbody>{renderTableData()}</tbody>
        </Table>
        <Pagination className="d-flex justify-content-center">
          {renderPageItems()}
        </Pagination>
      </div>
    );
  }
}

export default ManageInvoiceTable;
