import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import "react-datepicker/dist/react-datepicker.css";
import {
  clearUserNameAndTokenInLocalStorage,
  getUserNameFromLocalStorage,
  getTokenFromLocalStorage,
} from "../../lib/common";
import BlockUi from "@availity/block-ui";
import { useForm, Controller } from "react-hook-form";
import ProjectNewAddressModal from "./Projects_NewProjectAddAddressModal";
import ProjectEditAddressModal from "./Projects_NewProjectEditAddressModal";
import ProjectsAddressTable from "./Projects_AddressTable";
import DeleteAddressModal from "./Projects_DeleteAddressModal";

const config = JSON.parse(process.env.REACT_APP_CONFIG);

function ProjectsNewProjectModal(props) {
  const { rowData, onClose, handleShowAlert, customerList, handleCloseAlert } = props;
  const [CustomerList] = useState(customerList);
  const [CustomerId, setCustomerId] = useState("");
  const [ProjectName, setProjectName] = useState("");
  const [ProjectStatus, setProjectStatus] = useState("A");
  const [ItemList, setItemList] = useState([]);
  const [blocking, setBlocking] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState({});
  const {
    control,
    register,
    handleSubmit,
    setValue,
    trigger,
    formState: { errors },
  } = useForm({
    defaultValues: {
      CustomerId: "",
    },
  });

  const handleToggleBlocking = () => {
    setBlocking((prevState) => !prevState);
  };

  const submitNewProject = async () => {
    if (ItemList.length === 0) {
      handleShowAlert(
        "error",
        "New Project",
        "Failed Saving New Project :",
        ["Address list is empty!"]
      );
      return;
    }
    try {
      var arrProjectAddrID = [];
      var arrAddrType = [];
      var arrAddr1Name = [];
      var arrAddr2Name = [];
      var arrAddr3Name = [];
      var arrAddr4Name = [];
      var arrPostCode = [];
      var arrCity = [];
      var arrState = [];
      var arrCountryName = [];
      var arrCountryCode = [];
      var arrContactName = [];
      var arrContactPhone = [];

      for (var i = 0; i < ItemList.length; i++) {
        arrProjectAddrID.push(ItemList[i].PROJECT_ADDR_ID);
        arrAddrType.push(ItemList[i].ADDR_TYPE);
        arrAddr1Name.push(ItemList[i].ADDR1_NAME);
        arrAddr2Name.push(ItemList[i].ADDR2_NAME);
        arrAddr3Name.push(ItemList[i].ADDR3_NAME);
        arrAddr4Name.push(ItemList[i].ADDR4_NAME);
        arrPostCode.push(ItemList[i].POST_CODE);
        arrCity.push(ItemList[i].CITY);
        arrState.push(ItemList[i].STATE);
        arrCountryName.push(ItemList[i].COUNTRY_NAME);
        arrCountryCode.push(ItemList[i].COUNTRY_CODE);
        arrContactName.push(ItemList[i].CONTACT_NAME);
        arrContactPhone.push(ItemList[i].CONTACT_PHONE);
      }
      var jsonOb = {
        customer_id: CustomerId,
        project_name: ProjectName,
        project_status: ProjectStatus,
        project_addr_id: arrProjectAddrID,
        addr_type: arrAddrType,
        addr1_name: arrAddr1Name,
        addr2_name: arrAddr2Name,
        addr3_name: arrAddr3Name,
        addr4_name: arrAddr4Name,
        post_code: arrPostCode,
        city: arrCity,
        state: arrState,
        COUNTRY_NAME: arrCountryName,
        country_code: arrCountryCode,
        contact_name: arrContactName,
        contact_phone: arrContactPhone,
      };
      console.log(jsonOb);
      handleToggleBlocking();
      const response = await fetch(config.project_create_link, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + getTokenFromLocalStorage(),
        },
        body: JSON.stringify(jsonOb),
      });
      const data = await response.json();
      if (
        data.success !== true &&
        data.message === "Access Denied! Invalid token"
      ) {
        clearUserNameAndTokenInLocalStorage();
      }

      if (data.success !== true) {
        var errorMessages = data.error.map((item) => item.msg);
        handleShowAlert(
          "error",
          "Edit Project",
          "Error while editing Project: ",
          errorMessages
        );
      }
      if (data.success === true) {
        console.log("Success Submitting Project");
        handleShowAlert(
          "ok",
          "New Customer",
          "Success Saving New Customer"
        );
        onClose();
        return "ok";
      } else {
        throw data;
      }
    } catch (error) {
      console.error("Error while submitting new project: ", error);
      handleShowAlert(
        "error",
        "New Customer",
        "Error while saving New Customer:",
        error
      );
    } finally {
      handleToggleBlocking(false);
    }
  };
  const handleNewAddress = () => {
    setShowModal("newaddress");
  };
  const handleEditClick = (rowData) => {
    setSelectedRowData(rowData);
    setShowModal("edit");
  };

  const handleDeleteClick = (rowData) => {
    setSelectedRowData(rowData);
    setShowModal("delete");
  };

  const handleAddItemList = (newValue) => {
    setItemList([...ItemList, newValue]);
  };

  const handleEditItemList = (newValue) => {
    console.log("newValue");
    console.log(newValue);
    const updatedItems = ItemList.map((item) => {
      if (item.ITEM_ID === newValue.ITEM_ID) {
        return newValue;
      }
      return item; 
    });

    setItemList(updatedItems);
  };

  const handleDeleteItemList = (newValue) => {
    const updatedItems = ItemList.filter(
      (item) => item.ITEM_ID !== newValue.ITEM_ID
    );

    console.log(updatedItems);

    setItemList(updatedItems);
  };

  return (
    <Modal show={true} onHide={onClose} size="xl" backdrop="static" centered>
      <BlockUi blocking={blocking}>
        <Form onSubmit={handleSubmit(submitNewProject)}>
          <Modal.Header closeButton className="px-4">
            <Modal.Title className="ms-auto">New Project</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="mb-2 justify-content-center">
              <Col md="8">
                <Form.Group as={Row} controlId="CustomerId">
                  <Form.Label column sm="4" className="fw-bold">
                    Customer Name :
                  </Form.Label>
                  <Col sm="8">
                    {/* <Form.Select value={CustomerId} onChange={(e) => setCustomerId(e.target.value)}>
                            <option key={""} value="" disabled={true}>Please select customer name</option>
                            {CustomerList.map((items) => (
                              <option key={items.CUSTOMER_ID} value={items.CUSTOMER_ID}>{items.CUSTOMER_NAME}</option>
                            ))}
                      </Form.Select> */}
                    <Controller
                      name="CustomerId"
                      control={control}
                      rules={{ required: "This field is required" }}
                      render={({ field }) => (
                        <Form.Select
                          {...field}
                          onChange={(e) => {
                            setCustomerId(e.target.value);
                            setValue("CustomerId", e.target.value);
                            trigger("CustomerId");
                          }}
                          className={`form-select ${
                            errors.CustomerId ? "is-invalid" : ""
                          }`}
                        >
                          <option key={""} value="" disabled={true}>
                            Please select customer name
                          </option>
                          {CustomerList.map((items) => (
                            <option
                              key={items.CUSTOMER_ID}
                              value={items.CUSTOMER_ID}
                            >
                              {items.CUSTOMER_NAME}
                            </option>
                          ))}
                        </Form.Select>
                      )}
                    />
                    {errors.CustomerId && (
                      <Form.Text className="text-danger">
                        {errors.CustomerId.message}
                      </Form.Text>
                    )}
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-2 justify-content-center">
              <Col md="8">
                <Form.Group as={Row} controlId="ProjectName">
                  <Form.Label column sm="4" className="fw-bold">
                    Project Name :
                  </Form.Label>
                  <Col sm="8">
                  <Form.Control
                      type="text"
                      placeholder="Project Name"
                      value={ProjectName}
                      onInput={(e) => setProjectName(e.target.value)}
                      className={`form-control ${
                        errors.ProjectName ? "is-invalid" : ""
                      }`}
                      {...register("ProjectName", {
                        pattern: {
                          value: /^.{3,}$/,
                          message: "Minimum 3 Character",
                        },
                        validate: (value) => {
                          return value !== "" || "This field is required";
                        },
                      })}
                    />
                    {errors.ProjectName && (
                      <Form.Text className="text-danger">
                        {errors.ProjectName.message}
                      </Form.Text>
                    )}
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-2 justify-content-center">
              <Col md="8">
                <Form.Group as={Row} controlId="ProjectStatus" className="align-items-center">
                  <Form.Label column sm="4" className="fw-bold">
                    Status :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Check
                      inline
                      type="radio"
                      label="Active"
                      name="ProjectStatusRadios"
                      id="ProjectStatusRadios1"
                      checked={ProjectStatus === "A"}
                      onChange={() => setProjectStatus("A")}
                    />
                    <Form.Check
                      inline
                      type="radio"
                      label="Inactive"
                      name="ProjectStatusRadios"
                      id="ProjectStatusRadios2"
                      checked={ProjectStatus === "I"} 
                      onChange={() => setProjectStatus("I")} 
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <hr />
            <Row className="mb-4 align-items-center">
              <Col sm="9" className="text-center">
                <Form.Label>Addresses</Form.Label>
              </Col>
              <Col sm="3">
                <Button
                  className="me-2"
                  variant="secondary"
                  onClick={handleNewAddress}
                >
                  Add Address
                </Button>
              </Col>
            </Row>
            <ProjectsAddressTable
              itemList={ItemList}
              onEditClick={handleEditClick}
              onDeleteClick={handleDeleteClick}
              fromEditModal={false} />
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={onClose} variant="secondary">
              Close
            </Button>
            <Button type="submit" variant="primary">
              Save
            </Button>
          </Modal.Footer>
        </Form>
        {showModal === "newaddress" ? (
          <ProjectNewAddressModal
            handleAddItemList={handleAddItemList}
            handleCloseAlert={handleCloseAlert}
            handleShowAlert={handleShowAlert}
            itemList={ItemList}
            rowData={selectedRowData}
            onClose={() => setShowModal(false)}
          />
        ) : (
          ""
        )}
        {showModal === "edit" ? (
          <ProjectEditAddressModal
            handleEditItemList={handleEditItemList}
            handleShowAlert={handleShowAlert}
            itemList={ItemList}
            rowData={selectedRowData}
            onClose={() => setShowModal(false)}
          />
        ) : (
          ""
        )}
        {showModal === "delete" ? (
          <DeleteAddressModal
            handleDeleteItemList={handleDeleteItemList}
            handleShowAlert={handleShowAlert}
            rowData={selectedRowData}
            onClose={() => setShowModal(false)}
          />
        ) : (
          ""
        )}
      </BlockUi>
    </Modal>
  );
}

export default ProjectsNewProjectModal;
