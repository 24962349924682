import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import IssuePoConfirmationModal from "../page_components/PendingPO/PendingPO_IssuePOConfirmationModal";
import { useNavigate } from "react-router-dom";
import {
  clearUserNameAndTokenInLocalStorage,
  getTokenFromLocalStorage,
} from "../lib/common";
import BlockUi from "@availity/block-ui";
import "@availity/block-ui/src/BlockUi.css";
import "@availity/block-ui/src/Loader.css";
import AlertModal from "../page_components/AlertModal";
import { IconContext } from "react-icons";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { useForm, Controller } from "react-hook-form";
import PendingInvoiceTable from "../page_components/NewInvoice/PendingInvoice_table";

const config = JSON.parse(process.env.REACT_APP_CONFIG);

function PendingInvoice({ onNewInvClick }) {
    const {
      formState: { errors },
      reset,
    } = useForm({
      defaultValues: {
      },
    });
    const [SleSoNumber, setSleSoNumber] = useState("");
    const [SleDoNumber, setSleDoNumber] = useState("");
    const [SleSoDateFrom, setSleSoDateFrom] = useState("");
    const [SleSoDateTo, setSleSoDateTo] = useState("");
    const [SleDoDateFrom, setSleDoDateFrom] = useState("");
    const [SleDoDateTo, setSleDoDateTo] = useState("");
    const [CustomerName, setCustomerName] = useState("");
    const [CustomerList, setCustomerList] = useState([]);
    const [ProjectName, setProjectName] = useState("");
    const [ProjectList, setProjectList] = useState([]);
    const [InvoiceList, setInvoiceList] = useState([]);

    const [searchProps, setSearchProps] = useState({});
    const [selectedRowData, setSelectedRowData] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [blocking, setBlocking] = useState(true);
    const [ShowAlert, setShowAlert] = useState(false);
    const [AlertTitle, setAlertTitle] = useState("");
    const [AlertMessage, setAlertMessage] = useState("");
    const [ApiResponse, setApiResponse] = useState("");
    const navigate = useNavigate();
  
    useEffect(() => {
      if (getTokenFromLocalStorage() === null) {
        const state = { data: "Token expired, please login again" };
        navigate("/login", { state });
      }
    });
  
    useEffect(() => {
      fetch(config.customer_list_link, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + getTokenFromLocalStorage(),
        },
        body: JSON.stringify({}),
      })
        .then((response) => response.json())
        .then((jsonData) => {
          if (
            jsonData.success !== true &&
            jsonData.message === "Access Denied! Invalid token"
          ) {
            clearUserNameAndTokenInLocalStorage();
            throw new Error(jsonData.message);
          } else if (jsonData.success) {
            return jsonData.data;
          } else {
            throw new Error(jsonData.message);
          }
        })
        .then((data) => setCustomerList(data))
        .catch((error) => console.error(error));
    }, []);
  
    useEffect(() => {
      if (CustomerName !== "") {
        fetch(config.project_list_link, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + getTokenFromLocalStorage(),
          },
          body: JSON.stringify({
            customer_id: CustomerName,
          }),
        })
          .then((response) => response.json())
          .then((jsonData) => {
            if (
              jsonData.success !== true &&
              jsonData.message === "Access Denied! Invalid token"
            ) {
              clearUserNameAndTokenInLocalStorage();
              throw new Error(jsonData.message);
            } else if (jsonData.success) {
              return jsonData.data;
            } else {
              throw new Error(jsonData.message);
            }
          })
          .then((data) => setProjectList(data))
          .catch((error) => console.error(error));
      }
    }, [CustomerName]);
  
    const handleShowAlert = async (status, title, message, apiResponse = "") => {
      setAlertTitle(title);
      if (status === "ok") {
        setAlertMessage(
          <>
            <p className="mb-1">
              <IconContext.Provider
                value={{
                  color: "green",
                  style: { fontSize: "40px", paddingRight: "5px" },
                }}
              >
                <AiOutlineCheckCircle />
                {message}
              </IconContext.Provider>
            </p>
          </>
        );
      } else {
        setAlertMessage(
          <>
            <p className="mb-1">
              <IconContext.Provider
                value={{
                  color: "red",
                  style: { fontSize: "40px", paddingRight: "5px" },
                }}
              >
                <AiOutlineCloseCircle />
                {message}
              </IconContext.Provider>
            </p>
            {apiResponse.length > 0
              ? apiResponse.map((response) => (
                  <p className="text-danger fst-italic ps-3">{response}</p>
                ))
              : ""}
          </>
        );
      }
      setShowAlert(true);
    };
  
    const handleCloseAlert = () => {
      setShowAlert(false);
    };
  
    const handleToggleBlocking = () => {
      setBlocking((prevState) => !prevState);
    };
  
    const handleToggleFromChild = (newValue) => {
      setBlocking(newValue);
    };
  
    const handleResetForm = () => {
      handleToggleBlocking();
      setSleSoNumber("");
      setSleDoNumber("");
      setSleSoDateFrom("");
      setSleSoDateTo("");
      setSleDoDateFrom("");
      setSleDoDateTo("");
      setCustomerName("");
      setProjectName("");
      reset();
  
      const searchProperties = {
        SleDoNumber: "",
        SleDoNumber: "",
        SleDoDateFrom: "",
        SleDoDateTo: "",
        SleSoDateFrom: "",
        SleSoDateTo: "",
        CustomerName: "",
        ProjectName: "",
      };
      setSearchProps(searchProperties);
    };
    const handleSearch = (event) => {
      event.preventDefault();
      const searchProperties = {
        SleSoNumber: SleSoNumber,
        SleDoNumber: SleDoNumber,
        SleSoDateFrom: SleSoDateFrom,
        SleSoDateTo: SleSoDateTo,
        SleDoDateFrom: SleDoDateFrom,
        SleDoDateTo: SleDoDateTo,
        CustomerName: CustomerName,
        ProjectName: ProjectName,
      };
      setSearchProps(searchProperties);
    };
  
    const handleNewInvClick = (rowData) => {
      const customerId = rowData.CUSTOMER_ID;
      console.log(rowData);
      setSelectedRowData(rowData);
      onNewInvClick(customerId);
    };
  
    return (
      <div>
        <Container className="mt-4">
          <BlockUi blocking={blocking}>
            <Row className="justify-content-center mb-4">
              <Col md={7}>
                <Form onSubmit={handleSearch}>
                  <Row className="mb-4">
                    <Col className="text-center">
                      <h2>Pending Invoice</h2>
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col>
                      <Form.Group as={Row} className="mb-3" controlId="SleDoNumber" >
                        <Form.Label column sm="6" className="text-nowrap">
                          SLE DO Number :
                        </Form.Label>
                        <Col sm="6" style={{ paddingRight: "3px" }}>
                          <Form.Control
                            type="text"
                            placeholder="SLE DO Number"
                            value={SleDoNumber}
                            onInput={(e) => setSleDoNumber(e.target.value)}
                          />
                        </Col>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group as={Row} className="mb-3" controlId="SleSoNumber" >
                        <Form.Label column sm="6"  className="text-nowrap">
                          SLE SO Number
                        </Form.Label>
                        <Col sm="6" style={{ paddingRight: "3px" }}>
                          <Form.Control
                            type="text"
                            placeholder="SLE SO Number"
                            value={SleSoNumber}
                            onInput={(e) => setSleSoNumber(e.target.value)}
                          />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col sm="6">
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="SleDoDateFrom"
                      >
                        <Form.Label column sm="6">
                          SLE DO Date From
                        </Form.Label>
                        <Col sm="6" style={{ paddingLeft: "7px" }}>
                          <DatePicker
                            className="form-control"
                            dateFormat="dd MMM yyyy"
                            showIcon
                            selected={SleDoDateFrom}
                            onChange={(date) => setSleDoDateFrom(date)}
                          />
                        </Col>
                      </Form.Group>
                    </Col>
                    <Col sm="6">
                      <Form.Group  as={Row}  className="mb-3" controlId="SleDoDateTo"
                      >
                        <Form.Label column sm="2" className="text-center">
                          to
                        </Form.Label>
                        <Col sm="6">
                          <DatePicker
                            className="form-control"
                            dateFormat="dd MMM yyyy"
                            showIcon
                            selected={SleDoDateTo}
                            onChange={(date) => setSleDoDateTo(date)}
                          />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col sm="6">
                      <Form.Group as={Row} className="mb-3"  controlId="SleSoDateFrom">
                        <Form.Label column sm="6">
                          SLE SO Date From
                        </Form.Label>
                        <Col sm="6" style={{ paddingLeft: "7px" }}>
                          <DatePicker
                            className="form-control"
                            dateFormat="dd MMM yyyy"
                            showIcon
                            selected={SleSoDateFrom}
                            onChange={(date) => setSleSoDateFrom(date)}
                          />
                        </Col>
                      </Form.Group>
                    </Col>
                    <Col sm="6">
                      <Form.Group as={Row} className="mb" controlId="SleSoDateTo" >
                        <Form.Label column sm="2" className="text-center">
                          to
                        </Form.Label>
                        <Col sm="6">
                          <DatePicker
                            className="form-control"
                            dateFormat="dd MMM yyyy"
                            showIcon
                            selected={SleSoDateTo}
                            onChange={(date) => setSleSoDateTo(date)}
                          />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="CustomerName"
                      >
                        <Form.Label column sm="3">
                          Customer Name
                        </Form.Label>
                        <Col sm="7">
                          <Form.Select
                            value={CustomerName}
                            onChange={(e) => setCustomerName(e.target.value)}
                          >
                            <option key={""} value="" disabled>
                              Please select customer name
                            </option>
                            {CustomerList.map((items) => (
                              <option
                                key={items.CUSTOMER_ID}
                                value={items.CUSTOMER_ID}
                              >
                                {items.CUSTOMER_NAME}
                              </option>
                            ))}
                          </Form.Select>
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="ProjectName"
                      >
                        <Form.Label column sm="3">
                          Project Name
                        </Form.Label>
                        <Col sm="7">
                          <Form.Select
                            value={ProjectName}
                            onChange={(e) => setProjectName(e.target.value)}
                            placeholder="Please select project name"
                          >
                            <option key={""} value="" disabled>
                              Please select project name
                            </option>
                            {!CustomerName ? (
                              <option key={"selectcustomer"} value="" className="drop-down-message" disabled>
                                Please select customer name first
                              </option>
                            ) : ProjectList.length <= 0 ? (
                              <option key={"noprojects"} value="" className="drop-down-message" disabled>
                                This customer currently has no projects
                              </option>
                            ) : (
                              <>
                                {ProjectList.map((items) => (
                                  <option
                                    key={items.PROJECT_ID}
                                    value={items.PROJECT_ID}
                                  >
                                    {items.PROJECT_NAME}
                                  </option>
                                ))}
                              </>
                            )}
                          </Form.Select>
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="text-center">
                      <Button
                        className="me-2"
                        variant="secondary"
                        onClick={handleResetForm}
                      >
                        Reset
                      </Button>
                      <Button
                        type="submit"
                        variant="primary"
                        onClick={handleToggleBlocking}
                      >
                        Search
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
            <hr />
            <br />
            <PendingInvoiceTable
              showAlert={ShowAlert}
              searchProps={searchProps}
              onChildToggleChange={handleToggleFromChild}
              onNewInvClick={handleNewInvClick}
            />
          </BlockUi>
          <style>
            {`
              .drop-down-message {
                color: #dc4c64;
              }
            `}
          </style>
          {ShowAlert && (
            <AlertModal
              title={AlertTitle}
              message={AlertMessage}
              apiResponse={ApiResponse}
              onClose={handleCloseAlert}
            />
          )}
        </Container>
      </div>
    );
}

export default PendingInvoice;
