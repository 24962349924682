import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { clearUserNameAndTokenInLocalStorage } from '../lib/common';

function Logout() {
  const navigate = useNavigate();
  const [countdown, setCountdown] = useState(5); // Initial countdown value

  useEffect(() => {
    // Clear token and user in localStorage
    clearUserNameAndTokenInLocalStorage();

    // Redirect to login after 5 seconds
    const timeoutId = setTimeout(() => {
      navigate('/login');
    }, countdown * 1000); // Convert countdown to milliseconds

    // Update countdown every second
    const intervalId = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown - 1);
    }, 1000);

    // Clear the timeout and interval if the component is unmounted before the redirect
    return () => {
      clearTimeout(timeoutId);
      clearInterval(intervalId);
    };
  }, [countdown, navigate]);

  return (
    <Container>
      <h4 className="text-center mt-5">You have been logged out</h4>
      <p className="text-center">Redirecting to login page in {countdown} seconds...</p>
    </Container>
  );
}

export default Logout;
