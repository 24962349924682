import React, { useState } from "react";
import {
	Routes,
	Route,
	BrowserRouter,
	Link,
	Navigate,
	Outlet,
} from "react-router-dom";
import { useUser } from "./lib/customHooks";
import Login from "./pages/Login";
import Home from "./pages/Home";
import Customers from "./pages/Customers";
import Suppliers from "./pages/Suppliers";
import Projects from "./pages/Projects";
import Products from "./pages/Products";
import SleSo from "./pages/SLESO";
import ManageSo from "./pages/ManageSO";
import ManageRestockRequest from "./pages/ManageRestockRequest";
import NewSo from "./pages/NewSO";
import NewSoConsignment from "./pages/NewSOConsignment";
import NewSoNonConsignment from "./pages/NewSONonConsignment";
import NewPoAdhoc from "./pages/NewPOAdhoc";
import PendingPo from "./pages/PendingPO";
import ManagePo from "./pages/ManagePO";
import ManageBulkOrderMemo from "./pages/ManageBulkOrderMemo";
import NewDeliveryOrder from "./pages/NewDeliveryOrder";
import SearchDeliveryOrder from "./pages/SearchDeliveryOrder";
import SlePo from "./pages/SLEPO";
import DeliveryDate from "./pages/DeliveryDate";
import DeliveryStatus from "./pages/DeliveryStatus";
import SleInvoice from "./pages/SLEInvoice";
import SleManageInvoice from "./pages/SLEManageInvoice";
import Logout from "./pages/Logout";
import ChangePassword from "./pages/ChangePassword";
import NavigationBar from "./page_components/NavigationBar";
import { getTokenFromLocalStorage } from "./lib/common";
import NewGoodsReceivedNote from "./pages/NewGRN";
import ManageGrn from "./pages/ManageGRN";
import NewPurchaseInvoice from "./pages/NewPurchaseInvoice";
import ManagePurchaseInvoice from "./pages/ManagePurchaseInvoice";
import NewDOAcknowledgement from "./pages/NewDOAcknowledgement";
import ManageDOAcknowledgement from "./pages/ManageDOAcknowledgement";
import NewInvoice from "./pages/NewInvoice";
import PendingInvoice from "./pages/PendingInvoice";
import ManageInvoice from "./pages/ManageInvoice";

function App() {
	const [selectedCustomerId, setSelectedCustomerId] = useState("");

	const handleNewInvClick = (customerId) => {
		setSelectedCustomerId(customerId);
	};

	return (
		<div className="App">
			<BrowserRouter>
				<NavigationBar />
				<Routes>
					<Route path="/" element={<PrivateOutlet />}>
						<Route path="" element={<Home />} />
					</Route>
					<Route path="/customers" element={<PrivateOutlet />}>
						<Route path="" element={<Customers />} />
					</Route>
					<Route path="/suppliers" element={<PrivateOutlet />}>
						<Route path="" element={<Suppliers />} />
					</Route>
					<Route path="/projects" element={<PrivateOutlet />}>
						<Route path="" element={<Projects />} />
					</Route>
					<Route path="/products" element={<PrivateOutlet />}>
						<Route path="" element={<Products />} />
					</Route>
					<Route path="/manageso" element={<PrivateOutlet />}>
						<Route path="" element={<ManageSo />} />
					</Route>
					<Route
						path="/managerestockrequest"
						element={<PrivateOutlet />}
					>
						<Route path="" element={<ManageRestockRequest />} />
					</Route>
					<Route path="/newso" element={<PrivateOutlet />}>
						<Route path="" element={<NewSo />} />
					</Route>
					<Route path="/newsoconsignment" element={<PrivateOutlet />}>
						<Route path="" element={<NewSoConsignment />} />
					</Route>
					<Route
						path="/newsononconsignment"
						element={<PrivateOutlet />}
					>
						<Route path="" element={<NewSoNonConsignment />} />
					</Route>
					<Route path="/sleso" element={<PrivateOutlet />}>
						<Route path="" element={<SleSo />} />
					</Route>
					<Route path="/newpoadhoc" element={<PrivateOutlet />}>
						<Route path="" element={<NewPoAdhoc />} />
					</Route>
					<Route path="/pendingpo" element={<PrivateOutlet />}>
						<Route path="" element={<PendingPo />} />
					</Route>
					<Route path="/searchpo" element={<PrivateOutlet />}>
						<Route path="" element={<ManagePo />} />
					</Route>
					<Route
						path="/managebulkordermemo"
						element={<PrivateOutlet />}
					>
						<Route path="" element={<ManageBulkOrderMemo />} />
					</Route>
					<Route path="/newdeliveryorder" element={<PrivateOutlet />}>
						<Route path="" element={<NewDeliveryOrder />} />
					</Route>
					<Route
						path="/searchdeliveryorder"
						element={<PrivateOutlet />}
					>
						<Route path="" element={<SearchDeliveryOrder />} />
					</Route>
					<Route path="/deliverydate" element={<PrivateOutlet />}>
						<Route path="" element={<DeliveryDate />} />
					</Route>
					<Route path="/deliverystatus" element={<PrivateOutlet />}>
						<Route path="" element={<DeliveryStatus />} />
					</Route>
					<Route path="/sleinvoice" element={<PrivateOutlet />}>
						<Route path="" element={<SleInvoice />} />
					</Route>
					<Route path="/slemanageinvoice" element={<PrivateOutlet />}>
						<Route path="" element={<SleManageInvoice />} />
					</Route>
					<Route
						path="/newgoodsreceivednote"
						element={<PrivateOutlet />}
					>
						<Route path="" element={<NewGoodsReceivedNote />} />
					</Route>
					<Route
						path="/managegoodsreceivednote"
						element={<PrivateOutlet />}
					>
						<Route path="" element={<ManageGrn />} />
					</Route>
					
					<Route
						path="/newpurchasedinvoice"
						element={<PrivateOutlet />}
					>
						<Route path="" element={<NewPurchaseInvoice />} />
					</Route>
					<Route
						path="/managepurchasedinvoice"
						element={<PrivateOutlet />}
					>
						<Route path="" element={<ManagePurchaseInvoice />} />
					</Route>

					<Route
						path="/newdoacknowledgement"
						element={<PrivateOutlet />}
					>
						<Route path="" element={<NewDOAcknowledgement />} />
					</Route>
					<Route
						path="/managedoacknowledgement"
						element={<PrivateOutlet />}
					>
						<Route path="" element={<ManageDOAcknowledgement />} />
					</Route>

					<Route
						path="/pendinginvoice"
						element={<PrivateOutlet />}
					>
						<Route path="" element={<PendingInvoice  onNewInvClick={handleNewInvClick} />} />
						{/* <Route path="" element={<PendingInvoice />} /> */}
					</Route>
					<Route
						path="/newinvoice/:customerId"
						element={<PrivateOutlet />}
					>
						<Route path="" element={<NewInvoice />} />
					</Route>
					<Route
						path="/newinvoice"
						element={<PrivateOutlet />}
					>
						<Route path="" element={<NewInvoice />} />
					</Route>
					<Route
						path="/manageinvoice"
						element={<PrivateOutlet />}
					>
						<Route path="" element={<ManageInvoice />} />
					</Route>

					<Route path="/changepassword" element={<PrivateOutlet />}>
						<Route path="" element={<ChangePassword />} />
					</Route>
					<Route path="/login" element={<Login />} />
					<Route path="/logout" element={<Logout />} />
				</Routes>
			</BrowserRouter>
		</div>
	);
}

function PrivateOutlet() {
	const auth = useAuth();
	return auth ? <Outlet /> : <Navigate to="/login" />;
}

// function PrivateRoute({ children }) {
//   const auth = useAuth();
//   return auth ? children : <Navigate to="/login" />;
// }

function useAuth() {
	if (getTokenFromLocalStorage()) {
		return true;
	} else {
		return false;
	}
}

export default App;
