import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import DatePicker from "react-datepicker";
import SearchDeliveryOrderFollowUpModalTable from "./SearchDeliveryOrder_FollowUpModalTable";
import moment from "moment/moment";
import {
  clearUserNameAndTokenInLocalStorage,
  getUserNameFromLocalStorage,
  getTokenFromLocalStorage,
} from "../../lib/common";
import BlockUi from "@availity/block-ui";

const config = JSON.parse(process.env.REACT_APP_CONFIG);

function SearchDeliveryOrderFollowUpModal(props) {
  const { rowData, onClose, handleShowAlert } = props;
  const [SleSoNum] = useState(rowData.SO_NUMBER);
  // const [SlePoNum] = useState(rowData.PO_NUMBER);
  const [SleDoNum] = useState(rowData.DO_NUMBER);
  const [SleSoDate] = useState(new Date(rowData.SO_DATE));
  // const [SlePoDate] = useState(
  //   rowData.PO_DATE ? new Date(rowData.PO_DATE) : ""
  // );
  const [CustomerName, setCustomerName] = useState(rowData.CUSTOMER_NAME);
  const [ProjectName, setProjectName] = useState(rowData.PROJECT_NAME);
  const [OrderType] = useState(rowData.SO_TYPE_DESC);
  const [PaymentTerm] = useState(rowData.PAYMENT_TERM);
  const [Remarks, setRemarks] = useState("");
  const [Trigger, setTrigger] = useState("");
  const [blocking, setBlocking] = useState(true);

  const handleSubmitRemarks = async (event) => {
    event.preventDefault();
    try {
      handleToggleBlocking();
      await submitRemarks();
      setRemarks("");
      handleShowAlert(
        "ok",
        "Delivery Date Follow-Up",
        "Success saving Follow-up"
      );
    } catch (error) {
      console.error("Error while saving Follow-up:", error);
      handleShowAlert(
        "error",
        "Delivery Date Follow-Up",
        "Error while saving Follow-up :",
        error
      );
    }
  };

  const handleToggleBlocking = () => {
    setBlocking((prevState) => !prevState);
  };

  const handleToggleFromChild = (newValue) => {
    setBlocking(newValue);
  };

  const submitRemarks = async () => {
    try {
      const response = await fetch(config.do_followup_create_link, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + getTokenFromLocalStorage(),
        },
        body: JSON.stringify({
          do_number: SleDoNum,
          comments: Remarks,
          user_modify: getUserNameFromLocalStorage(),
        }),
      });
      const data = await response.json();
      console.log("update memo response : ");
      console.log(data);
      if (
        data.success !== true &&
        data.message === "Access Denied! Invalid token"
      ) {
        clearUserNameAndTokenInLocalStorage();
      }

      if (data.success !== true) {
        var errorMessages = data.error.map((item) => item.msg);
        // handleShowAlert("error", "New Invoice", "Failed Saving New Invoice :", errorMessages.join("<br>"));
        throw errorMessages;
      }
      setTrigger(moment().unix());
      if (data.success === true) {
        console.log("Success Updating Memo");
        // handleTrigger(moment().unix());
        return "ok";
      } else {
        throw data;
      }
    } catch (error) {
      console.error("Error while Updating Memo:", error);
      throw error;
    }
  };
  return (
    <Modal
      show={true}
      onHide={onClose}
      size="lg"
      backdrop="static"
      centered
      style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
    >
      <BlockUi blocking={blocking}>
        {/* <Form onSubmit = {handleSubmitRemarks}> */}
        <Modal.Header closeButton className="px-4">
          <Modal.Title className="ms-auto">Delivery Order Follow-up</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="mb-2 justify-content-center">
            <Col md="6">
              <Form.Group as={Row} controlId="SLESONumber">
                <Form.Label column sm="6" className="fw-bold">
                  SLE SO Number :
                </Form.Label>
                <Col sm="6">
                  <Form.Control
                    readOnly
                    className="no-border"
                    type="text"
                    placeholder=""
                    value={SleSoNum}
                  />
                </Col>
              </Form.Group>
            </Col>

            <Col md="6">
              <Form.Group as={Row} controlId="SLESODate">
                <Form.Label column sm="6" className="fw-bold">
                  SLE SO Date :
                </Form.Label>
                <Col sm="6">
                  <DatePicker
                    readOnly
                    dateFormat="dd/MM/yyyy"
                    className="form-control no-border"
                    showIcon
                    selected={SleSoDate}
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>
          {/* <Row className="mb-2 justify-content-center">
            <Col md="6">
              <Form.Group as={Row} controlId="SLEPONumber">
                <Form.Label column sm="6" className="fw-bold">
                  SLE PO Number :
                </Form.Label>
                <Col sm="6">
                  <Form.Control
                    readOnly
                    className="no-border"
                    type="text"
                    placeholder=""
                    value={SlePoNum}
                  />
                </Col>
              </Form.Group>
            </Col>

            <Col md="6">
              <Form.Group as={Row} controlId="SLEPODate">
                <Form.Label column sm="6" className="fw-bold">
                  SLE PO Date :
                </Form.Label>
                <Col sm="6">
                  <DatePicker
                    readOnly
                    dateFormat="dd/MM/yyyy"
                    className="form-control no-border"
                    showIcon
                    selected={SlePoDate}
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row> */}
          <Row className="mb-2">
            <Col md="12">
              <Form.Group as={Row} controlId="SLESONumber">
                <Form.Label column sm="3" className="fw-bold">
                  Customer Name :
                </Form.Label>
                <Col sm="6">
                  <Form.Control
                    readOnly
                    className="no-border"
                    type="text"
                    placeholder=""
                    value={CustomerName}
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col md="12">
              <Form.Group as={Row} controlId="SLESODate">
                <Form.Label column sm="3" className="fw-bold">
                  Project Name:
                </Form.Label>
                <Col sm="6">
                  <Form.Control
                    readOnly
                    className="no-border"
                    type="text"
                    placeholder=""
                    value={ProjectName}
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <Form.Group as={Row} controlId="OrderType">
                <Form.Label column sm="6" className="fw-bold">
                  Order Type :
                </Form.Label>
                <Col sm="6">
                  <Form.Control
                    readOnly
                    className="no-border"
                    type="text"
                    placeholder=""
                    value={OrderType}
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col md="12">
              <Form.Group as={Row} controlId="OrderType">
                <Form.Label column sm="3" className="fw-bold">
                  Payment Term :
                </Form.Label>
                <Col sm="6">
                  <Form.Control
                    readOnly
                    className="no-border"
                    type="text"
                    placeholder=""
                    value={PaymentTerm}
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <hr />
          <SearchDeliveryOrderFollowUpModalTable
            rowData={rowData}
            trigger={Trigger}
            onChildToggleChange={handleToggleFromChild}
          />
          <hr />
          <Form.Control
            as="textarea"
            rows={3}
            placeholder="New Follow-up Remarks"
            value={Remarks}
            onInput={(e) => setRemarks(e.target.value)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={onClose} variant="secondary">
            Close
          </Button>
          <Button
            type="submit"
            variant="primary"
            onClick={(e) => handleSubmitRemarks(e)}
          >
            Save
          </Button>
        </Modal.Footer>
        {/* </Form> */}
      </BlockUi>
    </Modal>
  );
}

export default SearchDeliveryOrderFollowUpModal;
