import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment/moment';
import Table from '../page_components/DeliveryDate/DeliveryDate_Table';
import DateModal from '../page_components/DeliveryDate/DeliveryDate_DateModal';
import RemarksModal from '../page_components/DeliveryDate/DeliveryDate_RemarksModal';
import { useNavigate } from 'react-router-dom';
import { getTokenFromLocalStorage } from '../lib/common';
import BlockUi from '@availity/block-ui';
import "@availity/block-ui/src/BlockUi.css";
import "@availity/block-ui/src/Loader.css";
import AlertModal from '../page_components/AlertModal';
import { IconContext } from "react-icons";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { AiOutlineCloseCircle } from "react-icons/ai";

function DeliveryDate() {
  const [SleSoDateFrom, setSleSoDateFrom] = useState("");
  const [SleSoDateTo, setSleSoDateTo] = useState("");
  const [SlePoDateFrom, setSlePoDateFrom] = useState("");
  const [SlePoDateTo, setSlePoDateTo] = useState("");
  const [SleSoNum, setSleSoNum] = useState("");
  const [SlePoNum, setSlePoNum] = useState("");
  const [searchProps, setSearchProps] = useState({});
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [blocking, setBlocking] = useState(true);
  const [ShowAlert, setShowAlert] = useState(false);
  const [AlertTitle, setAlertTitle] = useState("");
  const [AlertMessage, setAlertMessage] = useState("");
  const [ApiResponse, setApiResponse] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (getTokenFromLocalStorage() === null){
      const state = { data: 'Token expired, please login again' };
      navigate("/login", { state });
    }
  });

  const handleShowAlert = async (status, title, message, apiResponse = "") => {
    setAlertTitle(title);
    if (status === "ok"){
      setAlertMessage(
        <>
          <p className='mb-1'>
            <IconContext.Provider value={{ color: "green", style : {fontSize : "40px", paddingRight : "5px"}}}>
              <AiOutlineCheckCircle />
              {message}
            </IconContext.Provider>
          </p>
        </>
        );
    } else {
      setAlertMessage(
        <>
          <p className='mb-1'>
            <IconContext.Provider value={{ color: "red", style : {fontSize : "40px", paddingRight : "5px"}}}>
              <AiOutlineCloseCircle />
              {message}
            </IconContext.Provider>
          </p>
          {apiResponse !== "" ? <p className='text-danger fst-italic ps-3'>{apiResponse}</p>
          :""}
        </>
        );
    }
    setShowAlert(true);
  };

  const handleCloseAlert = () => {
    setShowAlert(false);
  };
  
  const handleToggleBlocking = () => {
    setBlocking(prevState => !prevState);
  };

  const handleToggleFromChild = (newValue) => {
    setBlocking(newValue);
  };
  const handleResetForm = () => {
    handleToggleBlocking();

    setSlePoDateFrom("");
    setSlePoDateTo("");
    setSlePoNum("");
    setSleSoDateFrom("");
    setSleSoDateTo("");
    setSleSoNum("");
    const searchProperties = {
      SlePoDateFrom : "",
      SlePoDateTo : "",
      SlePoNum : "",
      SleSoDateFrom : "",
      SleSoDateTo : "",
      SleSoNum : ""
    };
    setSearchProps(searchProperties);
  }
  const handleSearch = (event) => {
    event.preventDefault();
    const searchProperties = {
      SlePoDateFrom : SlePoDateFrom,
      SlePoDateTo : SlePoDateTo,
      SlePoNum : SlePoNum,
      SleSoDateFrom : SleSoDateFrom,
      SleSoDateTo : SleSoDateTo,
      SleSoNum : SleSoNum
    };
    setSearchProps(searchProperties);
  }

  const handleDateClick = (rowData) => {
    setSelectedRowData(rowData);
    setShowModal("date");
  };
  const handleRemarksClick = (rowData) => {
    setSelectedRowData(rowData);
    setShowModal("remarks");
  };

  return (
    <div>
      <Container className="mt-4">
        <BlockUi blocking={blocking}>
          <Row className="justify-content-center mb-4">
            <Col md={6}>
              <Form onSubmit={handleSearch}>
                <Row className="mb-4">
                  <Col className="text-center">
                    <h2>Pending Delivery Date</h2>
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col>
                    <Form.Group as={Row} className="mb-3" controlId="SLESONumber">
                      <Form.Label column sm="4">
                        SLE SO Number
                      </Form.Label>
                      <Col sm="7" style={{paddingRight : "13px"}}>
                        <Form.Control type="text" placeholder="SLE SO Number" value={SleSoNum} onInput={(e) => setSleSoNum(e.target.value)} />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col>
                    <Form.Group as={Row} className="mb-3" controlId="SLEPONumber">
                      <Form.Label column sm="4">
                        SLE PO Number
                      </Form.Label>
                      <Col sm="7" style={{paddingRight : "13px"}}>
                        <Form.Control type="text" placeholder="SLE PO Number" value={SlePoNum} onInput={(e) => setSlePoNum(e.target.value)} />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col sm="7">
                    <Form.Group as={Row} className="mb-3" controlId="SLESODateFrom">
                      <Form.Label column sm="7">
                        SLE SO Date From
                      </Form.Label>
                      <Col sm="5" style={{paddingLeft : "8px"}}>
                        <DatePicker className="form-control" dateFormat="dd/MM/yyyy" showIcon selected={SleSoDateFrom} onChange={(date) => setSleSoDateFrom(date)} />
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col sm="5">
                    <Form.Group as={Row} className="mb-3 text-center" controlId="SLESODateTo">
                      <Form.Label column sm="2">
                        to
                      </Form.Label>
                      <Col sm="7" style={{paddingRight : "0px"}}>
                        <DatePicker className="form-control" dateFormat="dd/MM/yyyy" showIcon selected={SleSoDateTo} onChange={(date) => setSleSoDateTo(date)} />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col sm="7">
                    <Form.Group as={Row} className="mb-3" controlId="SLEPODateFrom">
                      <Form.Label column sm="7">
                        SLE PO Date From
                      </Form.Label>
                      <Col sm="5" style={{paddingLeft : "8px"}}>
                        <DatePicker className="form-control" dateFormat="dd/MM/yyyy" showIcon selected={SlePoDateFrom} onChange={(date) => setSlePoDateFrom(date)} />
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col sm="5">
                    <Form.Group as={Row} className="mb-3 text-center" controlId="SLEPODateTo">
                      <Form.Label column sm="2">
                        to
                      </Form.Label>
                      <Col sm="7" style={{paddingRight : "0px"}}>
                        <DatePicker className="form-control" dateFormat="dd/MM/yyyy" showIcon selected={SlePoDateTo} onChange={(date) => setSlePoDateTo(date)} />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col className="text-center">
                    <Button className='me-2' variant="secondary" onClick={handleResetForm}>Reset</Button>{' '}
                    <Button type="submit" variant="primary" onClick={handleToggleBlocking}>Search</Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
          <hr/>
          <br/>
          <Table showAlert = {ShowAlert} searchProps = {searchProps} onDateClick={handleDateClick} onChildToggleChange={handleToggleFromChild} onRemarksClick = {handleRemarksClick} />
          {showModal==="date" ? <DateModal handleShowAlert = {handleShowAlert} rowData={selectedRowData} onClose={() => setShowModal(false)} /> : ""}
          {showModal==="remarks" ? <RemarksModal handleShowAlert = {handleShowAlert} rowData={selectedRowData} onClose={() => setShowModal(false)} /> : ""}
        </BlockUi>
        {ShowAlert && (
        <AlertModal
          title={AlertTitle}
          message={AlertMessage}
          apiResponse={ApiResponse}
          onClose={handleCloseAlert}
        />
      )}
      </Container>
    </div>
  );
}

export default DeliveryDate;
