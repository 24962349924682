import { PostData } from "./Requests";

export class CreateNewGrnDTO{
    po_number;
    grn_date;
    supplier_do_number;
    supplier_do_date;
    product_id;
    item_qty;
}

export class UpdateGrnDTO{
    grn_number;
    product_id;
    new_item_qty;
    new_supplier_do_number;
    new_supplier_do_date;
};

export function GetGRN(po_number){
    if(!process.env.REACT_APP_CONFIG){
        console.log('Error parsing config.')
        return;
    }
    const config = JSON.parse(process.env.REACT_APP_CONFIG);
    return PostData(config.seng_liy_backend_url + "/grn/pending/get-item", {po_number});
}

export async function CreateGRN(createNewGrnDTO){
    if(!process.env.REACT_APP_CONFIG){
        console.log('Error parsing config.')
        return;
    }
    const config = JSON.parse(process.env.REACT_APP_CONFIG);
    let createGrnReq = await PostData(config.seng_liy_backend_url + "/grn/create", createNewGrnDTO);
    console.log(createGrnReq);
    return createGrnReq;
}

export async function UpdateGRN(updateGrnDTO){
    if(!process.env.REACT_APP_CONFIG){
        console.log('Error parsing config.')
        return;
    }
    const config = JSON.parse(process.env.REACT_APP_CONFIG);
    let updateGrnReq = await PostData(config.seng_liy_backend_url + "/grn/update", updateGrnDTO);
    console.log(updateGrnReq);
    return updateGrnReq;
}