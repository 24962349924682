import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import "react-datepicker/dist/react-datepicker.css";
import {
  clearUserNameAndTokenInLocalStorage,
  getTokenFromLocalStorage,
} from "../../lib/common";
import BlockUi from "@availity/block-ui";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";

const config = JSON.parse(process.env.REACT_APP_CONFIG);

function CustomersEditAddressModal(props) {
    const { onClose, handleEditItemList, itemList, rowData } = props;
    const {
        control,
        register,
        handleSubmit,
        setValue,
        trigger,
        formState: { errors },
    } = useForm({
        defaultValues: {
            CountryCode: rowData.COUNTRY_CODE,
        },
    });
    const navigate = useNavigate();
    const [blocking, setBlocking] = useState(true);
    const [ItemId] = useState(rowData.ITEM_ID);
    const [ CustomerAddrId] = useState(rowData.CUSTOMER_ADDR_ID);
    const [ AddrType, setAddrType] = useState(rowData.ADDR_TYPE);
    const [ Addr1Name, setAddr1Name] = useState(rowData.ADDR1_NAME);
    const [ Addr2Name, setAddr2Name] = useState(rowData.ADDR2_NAME);
    const [ Addr3Name, setAddr3Name] = useState(rowData.ADDR3_NAME);
    const [ Addr4Name, setAddr4Name] = useState(rowData.ADDR4_NAME);
    const [ PostCode, setPostCode] = useState(rowData.POST_CODE);
    const [ City, setCity] = useState(rowData.CITY);
    const [ State, setState] = useState(rowData.STATE);
    const [ CountryList, setCountryList] = useState([]);
    const [ CountryName, setCountryName] = useState(rowData.COUNTRY_NAME);
    const [ CountryCode, setCountryCode] = useState(rowData.COUNTRY_CODE);
    const [ ContactName, setContactName] = useState(rowData.CONTACT_NAME);
    const [ ContactPhone, setContactPhone] = useState(rowData.CONTACT_PHONE);

    const handleToggleBlocking = (value) => {
        setBlocking(value);
    };

    useEffect(() => {
        fetch(config.country_code_get_link, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + getTokenFromLocalStorage(),
        },
        body: JSON.stringify({}),
        })
        .then((response) => response.json())
        .then((jsonData) => {
            if (
            jsonData.success !== true &&
            jsonData.message === "Access Denied! Invalid token"
            ) {
            clearUserNameAndTokenInLocalStorage();
            const state = { data: "Token expired, please login again" };
            navigate("/login", { state });
            throw new Error(jsonData.message);
            } else if (jsonData.success) {
            return jsonData.data;
            } else {
            throw new Error(jsonData.message);
            }
        })
        .then((data) => {
            setCountryList(data);
        })
        .catch((error) => console.error(error))
        .finally(() => {
            handleToggleBlocking(false);
        });
    }, []);

    const handleAddItem = (event) => {
        var objData = {
        ITEM_ID: ItemId,
        CUSTOMER_ADDR_ID: CustomerAddrId,
        ADDR_TYPE: AddrType,
        ADDR1_NAME: Addr1Name,
        ADDR2_NAME: Addr2Name,
        ADDR3_NAME: Addr3Name,
        ADDR4_NAME: Addr4Name,
        POST_CODE: PostCode,
        CITY: City,
        STATE: State,
        COUNTRY_NAME: CountryName,
        COUNTRY_CODE: CountryCode,
        CONTACT_NAME: ContactName,
        CONTACT_PHONE: ContactPhone,
        };
        console.log(objData);
        handleEditItemList(objData);
        onClose();
    };

    return (
        <Modal
        show={true}
        onHide={onClose}
        size="lg"
        backdrop="static"
        centered
        style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
        >
        <BlockUi blocking={blocking}>
            <Form onSubmit={handleSubmit(handleAddItem)}>
            <Modal.Header closeButton className="px-4">
                <Modal.Title className="ms-auto">
                    Edit Customer Address
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row className="mb-2 justify-content-center">
                <Col md="10">
                    <Form.Group as={Row} controlId="AddrType">
                    <Form.Label column sm="4" className="fw-bold">
                        Type:
                    </Form.Label>
                    <Col sm="3">
                        <Form.Select
                            value={AddrType}
                            onChange={(e) => {
                            setAddrType(e.target.value);
                            setValue("AddrType", e.target.value);
                            trigger("AddrType");
                            }}
                        >
                            <option key={"Billing"} value={"B"}>
                            {"Billing"}
                            </option>
                            <option key={"Delivery"} value={"D"}>
                            {"Delivery"}
                            </option>
                        </Form.Select>
                    </Col>
                    </Form.Group>
                </Col>
                </Row>
                <Row className="mb-2 justify-content-center">
                <Col md="10">
                    <Form.Group as={Row} controlId="Addr1Name">
                    <Form.Label column sm="4" className="fw-bold">
                        Address 1:
                    </Form.Label>
                    <Col sm="8">
                        <Form.Control
                            type="text"
                            placeholder="Address 1"
                            value={Addr1Name}
                            maxLength={100}
                            onInput={(e) => setAddr1Name(e.target.value)}
                            className={`form-control ${
                            errors.Addr1Name ? "is-invalid" : ""
                            }`}
                            {...register("Addr1Name", {
                            validate: (value) => {
                                return value !== "" || "This field is required";
                            },
                            })}
                        />
                        {errors.Addr1Name && (
                            <Form.Text className="text-danger">
                                {errors.Addr1Name.message}
                            </Form.Text>
                            )}
                    </Col>
                    </Form.Group>
                </Col>
                </Row>
                <Row className="mb-2 justify-content-center">
                <Col md="10">
                    <Form.Group as={Row} controlId="Addr2Name">
                    <Form.Label column sm="4" className="fw-bold">
                        Address 2:
                    </Form.Label>
                    <Col sm="8">
                        <Form.Control
                            type="text"
                            placeholder="Address 2"
                            value={Addr2Name}
                            maxLength={100}
                            onInput={(e) => setAddr2Name(e.target.value)}
                            className={`form-control ${
                                errors.Addr2Name ? "is-invalid" : ""
                              }`}
                              {...register("Addr2Name", {
                                validate: (value) => {
                                  return value !== "" || "This field is required";
                                },
                              })}
                            />
                            {errors.Addr2Name && (
                              <Form.Text className="text-danger">
                                  {errors.Addr2Name.message}
                              </Form.Text>
                              )}
                    </Col>
                    </Form.Group>
                </Col>
                </Row>
                <Row className="mb-2 justify-content-center">
                <Col md="10">
                    <Form.Group as={Row} controlId="Addr3Name">
                    <Form.Label column sm="4" className="fw-bold">
                        Address 3:
                    </Form.Label>
                    <Col sm="8">
                        <Form.Control
                            type="text"
                            placeholder="Address 3"
                            value={Addr3Name}
                            maxLength={100}
                            onInput={(e) => setAddr3Name(e.target.value)}
                            className={`form-control ${
                                errors.Addr3Name ? "is-invalid" : ""
                              }`}
                              {...register("Addr3Name", {
                                validate: (value) => {
                                  return value !== "" || "This field is required";
                                },
                              })}
                            />
                            {errors.Addr3Name && (
                              <Form.Text className="text-danger">
                                  {errors.Addr3Name.message}
                              </Form.Text>
                              )}
                    </Col>
                    </Form.Group>
                </Col>
                </Row>
                <Row className="mb-2 justify-content-center">
                <Col md="10">
                    <Form.Group as={Row} controlId="Addr4Name">
                    <Form.Label column sm="4" className="fw-bold">
                        Address 4:
                    </Form.Label>
                    <Col sm="8">
                        <Form.Control
                            type="text"
                            placeholder="Address 4"
                            value={Addr4Name}
                            maxLength={100}
                            onInput={(e) => setAddr4Name(e.target.value)}
                            className={`form-control ${
                                errors.Addr4Name ? "is-invalid" : ""
                              }`}
                              {...register("Addr4Name", {
                                validate: (value) => {
                                  return value !== "" || "This field is required";
                                },
                              })}
                            />
                            {errors.Addr4Name && (
                              <Form.Text className="text-danger">
                                  {errors.Addr4Name.message}
                              </Form.Text>
                              )}
                    </Col>
                    </Form.Group>
                </Col>
                </Row>
                <Row className="mb-2 justify-content-center">
                <Col md="10">
                    <Form.Group as={Row} controlId="CountryCode">
                    <Form.Label column sm="4" className="fw-bold">
                        Country:
                    </Form.Label>
                    <Col sm="5">
                        <Controller
                        name="CountryCode"
                        control={control}
                        rules={{ required: "This field is required" }}
                        render={({ field }) => (
                            <Form.Select
                            {...field}
                            onChange={(e) => {
                                setCountryCode(e.target.value);
                                setCountryName(
                                e.target.options[e.target.selectedIndex].text
                                );
                                setValue("CountryCode", e.target.value);
                                trigger("CountryCode");
                            }}
                            className={`form-select ${
                                errors.CountryCode ? "is-invalid" : ""
                            }`}
                            >
                            <option key={""} value="" disabled={true}>
                                Please select country
                            </option>
                            {CountryList.map((items) =>
                                    <option key={items.COUNTRY_CODE} value={items.COUNTRY_CODE}>
                                        {items.COUNTRY_NAME}
                                    </option>
                                )}
                            </Form.Select>
                        )}
                        />
                        {errors.CountryCode && (
                        <Form.Text className="text-danger">
                            {errors.CountryCode.message}
                        </Form.Text>
                        )}
                    </Col>
                    </Form.Group>
                </Col>
                </Row>
                <Row className="mb-2 justify-content-center">
                <Col md="10">
                    <Form.Group as={Row} controlId="State">
                    <Form.Label column sm="4" className="fw-bold">
                        State:
                    </Form.Label>
                    <Col sm="5">
                        <Form.Control
                            type="text"
                            placeholder="State"
                            value={State}
                            maxLength={50}
                            onInput={(e) => setState(e.target.value)}
                            className={`form-control ${
                            errors.State ? "is-invalid" : ""
                            }`}
                            {...register("State", {
                            validate: (value) => {
                                return value !== "" || "This field is required";
                            },
                            })}
                        />
                        {errors.State && (
                            <Form.Text className="text-danger">
                                {errors.State.message}
                            </Form.Text>
                            )}
                    </Col>
                    </Form.Group>
                </Col>
                </Row>
                <Row className="mb-2 justify-content-center">
                <Col md="10" >
                    <Form.Group as={Row} controlId="City">
                    <Form.Label column sm="4" className="fw-bold">
                        City:
                    </Form.Label>
                    <Col sm="5">
                        <Form.Control
                            type="text"
                            placeholder="City"
                            value={City}
                            maxLength={50}
                            onInput={(e) => setCity(e.target.value)}
                            className={`form-control ${
                            errors.City ? "is-invalid" : ""
                            }`}
                            {...register("City", {
                            validate: (value) => {
                                return value !== "" || "This field is required";
                            },
                            })}
                        />
                        {errors.City && (
                            <Form.Text className="text-danger">
                                {errors.City.message}
                            </Form.Text>
                            )}
                    </Col>
                    </Form.Group>
                </Col>
                </Row>
                <Row className="mb-2 justify-content-center">
                <Col md="10" >
                    <Form.Group as={Row} controlId="PostCode">
                    <Form.Label column sm="4" className="fw-bold">
                        Post Code:
                    </Form.Label>
                    <Col sm="3">
                        <Form.Control
                            type="text"
                            placeholder="Post Code"
                            value={PostCode}
                            maxLength={20}
                            onInput={(e) => setPostCode(e.target.value)}
                            className={`form-control ${
                            errors.PostCode ? "is-invalid" : ""
                            }`}
                            {...register("PostCode", {
                            validate: (value) => {
                                return value !== "" || "This field is required";
                            },
                            })}
                        />
                        {errors.PostCode && (
                            <Form.Text className="text-danger">
                                {errors.PostCode.message}
                            </Form.Text>
                            )}
                    </Col>
                    </Form.Group>
                </Col>
                </Row>
                <Row className="mb-2 justify-content-center">
                <Col md="10">
                    <Form.Group as={Row} controlId="ContactName">
                    <Form.Label column sm="4" className="fw-bold">
                        Contact Name:
                    </Form.Label>
                    <Col sm="8">
                        <Form.Control
                            type="text"
                            placeholder="Contact Name"
                            value={ContactName}
                            maxLength={100}
                            onInput={(e) => setContactName(e.target.value)}
                            className={`form-control ${
                            errors.ContactName ? "is-invalid" : ""
                            }`}
                            {...register("ContactName", {
                            validate: (value) => {
                                return value !== "" || "This field is required";
                            },
                            })}
                        />
                        {errors.ContactName && (
                            <Form.Text className="text-danger">
                                {errors.ContactName.message}
                            </Form.Text>
                            )}
                    </Col>
                    </Form.Group>
                </Col>
                </Row>
                <Row className="mb-2 justify-content-center">
                <Col md="10">
                    <Form.Group as={Row} controlId="ContactPhone">
                    <Form.Label column sm="4" className="fw-bold">
                        Contact Phone:
                    </Form.Label>
                    <Col sm="5">
                        <Form.Control
                            type="text"
                            placeholder="Contact Phone"
                            value={ContactPhone}
                            maxLength={20}
                            onInput={(e) => {
                                const phone = e.target.value.replace(/[^0-9]/g, '');
                                setContactPhone(phone);
                            }}
                            className={`form-control ${
                            errors.ContactPhone ? "is-invalid" : ""
                            }`}
                            {...register("ContactPhone", {
                            validate: (value) => {
                                return value !== "" || "This field is required";
                            },
                            })}
                            onInvalid={(e) => e.target.setCustomValidity("Please enter a valid phone number")}
                        />
                        {errors.ContactPhone && (
                            <Form.Text className="text-danger">
                                {errors.ContactPhone.message}
                            </Form.Text>
                            )}
                    </Col>
                    </Form.Group>
                </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onClose} variant="secondary">
                Close
                </Button>
                <Button type="submit" variant="primary">
                Save
                </Button>
            </Modal.Footer>
            </Form>
        </BlockUi>
        </Modal>
    );
}

export default CustomersEditAddressModal;
