import React, { useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Pagination from 'react-bootstrap/Pagination';
import moment from 'moment/moment';
import { clearUserNameAndTokenInLocalStorage, getTokenFromLocalStorage } from '../../lib/common';
const config = JSON.parse(process.env.REACT_APP_CONFIG);

function DeliveryDateTable(props) {
  const [data, setData] = useState([]);
  const [headerData] = useState(["SLE SO Number", "SLE SO Date", "SLE PO Number", "SLE PO Date", "Action"]);
  const [currentPage, setCurrentPage] = useState(1);
  const { onDateClick } = props;
  const { onRemarksClick } = props;
  const { searchProps } = props;
  const { showAlert} = props;
  const ROWS_PER_PAGE = 10;
  const totalPages = Math.ceil(data.length / ROWS_PER_PAGE);

  useEffect(() => {
    if (searchProps.SleSoNum || searchProps.SleSoDateFrom || searchProps.SleSoDateTo
      || searchProps.SlePoNum || searchProps.SlePoDateFrom || searchProps.SlePoDateTo){
      getData(config.pending_po_delivery_date_link, searchProps);
    } else {
      getData(config.pending_po_delivery_date_link);
    }
  }, [searchProps, showAlert]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleToggleBlocking = () => {
    props.onChildToggleChange(false);
  };

  const renderTableData = () => {
    const startIndex = (currentPage - 1) * ROWS_PER_PAGE;
    const endIndex = startIndex + ROWS_PER_PAGE;
    return data.slice(startIndex, endIndex).map((row) => {
      const { SO_NUMBER, SO_DATE, PO_NUMBER, PO_DATE} = row;
      return (
        <tr key={PO_NUMBER}>
          <td>{SO_NUMBER}</td>
          <td>{SO_DATE}</td>
          <td>{PO_NUMBER}</td>
          <td>{PO_DATE}</td>
          <td style={{width:"245px"}}>
            <Button style={{marginRight:"5px"}} variant="primary" onClick={() => onDateClick(row)}>Date</Button>
            <Button variant="primary" onClick={() => onRemarksClick(row)}>Follow-Up</Button>
          </td>
        </tr>
      );
    });
  };

  const renderPageItems = () => {
    const pageItems = [];
    for (let i = 1; i <= totalPages; i++) {
      pageItems.push(
        <Pagination.Item key={i} active={i === currentPage} onClick={() => handlePageChange(i)}>
          {i}
        </Pagination.Item>
      );
    }
    return pageItems;
  };
  const getData = async (url, params = {}) => {
    await fetch(url,{
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        "Authorization" : "Bearer " + getTokenFromLocalStorage()
      },
      body : JSON.stringify(params.length === 0 ? params : {
        so_number : params.SleSoNum,
        so_date_from : params.SleSoDateFrom,
        so_date_to : params.SleSoDateTo,
        po_number : params.SlePoNum,
        po_date_from : params.SlePoDateFrom,
        po_date_to : params.SlePoDateTo
      })
    })
    .then(response => response.json())
    .then (jsonData => {
      handleToggleBlocking();
      if (jsonData.message === 'Access Denied! Invalid token'){
        clearUserNameAndTokenInLocalStorage();
        throw new Error (jsonData.message);
      }
      const newData = jsonData.map(item => {
        const so_date = moment(item.SO_DATE).format("DD MMM yyyy");
        const po_date = moment(item.PO_DATE).format("DD MMM yyyy");
        item.SO_DATE = so_date;
        item.PO_DATE = po_date;
        return item;
      });
      return newData;
    })
    .then(data => setData(data))
    .catch(error => console.error(error));
  }

  if (data.length<1){
    return(
      <div className="text-center"><h3>No Data</h3></div>
    )
  } else {
    return (
      <div>
        <Table striped bordered hover className="mb-4">
          <thead>
            <tr>{headerData.map((header) => <th key={header}>{header}</th>)}</tr>
          </thead>
          <tbody>{renderTableData()}</tbody>
        </Table>
        <Pagination className="d-flex justify-content-center" >{renderPageItems()}</Pagination>
      </div>
    );
  }

}

export default DeliveryDateTable;