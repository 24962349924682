import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import DatePicker from "react-datepicker";
import BlockUi from "@availity/block-ui";
import { useForm, Controller } from "react-hook-form";
import moment from "moment/moment";
import {
  clearUserNameAndTokenInLocalStorage,
  getTokenFromLocalStorage,
} from "../../lib/common";
import ManageDOAcknowledgement_EditAckModalTable from "./ManageDOAcknowledgement_EditAckModalTable";
import { useNavigate } from "react-router-dom";

const config = JSON.parse(process.env.REACT_APP_CONFIG);

function ManageDOAcknowledgement_EditAckModal(props) {
    const navigate = useNavigate();
    const { rowData, onClose, handleShowAlert, handleCloseAlert } = props;
    const [blocking, setBlocking] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectedRowData, setSelectedRowData] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [ItemList, setItemList] = useState("");
    const [refreshTrigger, setRefreshTrigger] = useState("");
    const [textInputs, setTextInputs] = useState({});

    const [DoAckNumber, setDoAckNumber] = useState(rowData.DO_ACK_NUMBER || "");
    const [DoAckDate, setDoAckDate] = useState(rowData.DO_ACK_DATE ? new Date(rowData.DO_ACK_DATE) : null);
    const [SleDoNumber, setSleDoNumber] = useState(rowData.DO_NUMBER || "");
    const [SleDoDate, setSleDoDate] = useState(rowData.DO_DATE ? new Date(rowData.DO_DATE) : null);
    const [SleSoNumber, setSleSoNumber] = useState(rowData.SO_NUMBER || "");
    const [SleSoDate, setSleSoDate] = useState(rowData.SO_DATE ? new Date(rowData.SO_DATE) : null);
    const [OrderType, setOrderType] = useState(rowData.SO_TYPE_DESC || "");
    const [PaymentTerm, setPaymentTerm] = useState(rowData.PAYMENT_TERM || "");
    const [SupplierDoNumber, setSupplierDoNumber] = useState(rowData.SUPPLIER_DO_NUMBER || "");
    const [SupplierDoDate, setSupplierDoDate] = useState(rowData.SUPPLIER_DO_DATE ? new Date(rowData.SUPPLIER_DO_DATE) : null);
    const [CustomerName, setCustomerName] = useState(rowData.CUSTOMER_NAME || "");
    const [ProjectName, setProjectName] = useState(rowData.PROJECT_NAME || "");
    const [NewSupplierDoNumber, setNewSupplierDoNumber] = useState("");
    const [NewSupplierDoDate, setNewSupplierDoDate] = useState(null);
    const [row, setRow] = useState({});
    
    const {
        handleSubmit,
        formState: { errors },
    } = useForm({
        defaultValues: {
        },
    });

    useEffect(() => {
        getData(config.do_ack_get_link, rowData);
    }, [rowData]);

    const getData = async (url, params = {}) => {
        await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + getTokenFromLocalStorage(),
            },
            body: JSON.stringify({
                do_ack_number: params.DO_ACK_NUMBER,
            }),
        })
            .then((response) => response.json())
            .then((jsonData) => {
                console.log(jsonData);
                setRow(jsonData.data);
                setBlocking(false);
                if (
                    jsonData.success !== true &&
                    jsonData.message === "Access Denied! Invalid token"
                ) {
                    clearUserNameAndTokenInLocalStorage();
                    const state = { data: "Token expired, please login again" };
                    navigate("/login", { state });
                    throw new Error(jsonData.message);
                } else if (
                    jsonData.success &&
                    jsonData.data["DO ACKNOWLEDGE_ITEMS"]
                ) {
                    const newData = jsonData.data["DO ACKNOWLEDGE_ITEMS"].map(
                        (item) => {
                            const date = moment(item.DO_ACK_DATE)
                                .utcOffset(480)
                                .format("DD MMM yyyy");
                            item.DO_ACK_DATE = date;
                            return item;
                        }
                    );
                    return newData;
                } else {
                    setItemList([]);
                    throw new Error(jsonData.message);
                }
            })
            .then((data) => {
                setItemList(data);
            })
            .catch((error) => console.error(error));
    };
    
    useEffect(() => {
        setDoAckNumber(row.DO_ACK_NUMBER || "");
        setDoAckDate(row.DO_ACK_DATE ? new Date(row.DO_ACK_DATE) : null);
        setSleDoNumber(row.DO_NUMBER|| "");
        setSleDoDate(row.DO_DATE ? new Date(row.DO_DATE) : null);
        setSleSoNumber(row.SO_NUMBER|| "");
        setSleSoDate(row.SO_DATE ? new Date(row.SO_DATE) : null);
        setOrderType(row.SO_TYPE_DESC || "");
        setPaymentTerm(row.PAYMENT_TERM || "");
        setSupplierDoNumber(row.SUPPLIER_DO_NUMBER || "");
        setSupplierDoDate(row.SUPPLIER_DO_DATE ? new Date(row.SUPPLIER_DO_DATE) : null);
        setCustomerName(row.CUSTOMER_NAME || "");
        setProjectName(row.PROJECT_NAME || "");
    }, [row]);

    const handleToggleBlocking = () => {
        setBlocking((prevState) => !prevState);
    };

    const toggleRowSelection = (rowId) => {
        if (selectedRows.includes(rowId)) {
            setSelectedRows(selectedRows.filter((id) => id !== rowId));
        } else {
            setSelectedRows([...selectedRows, rowId]);
        }
    };

    const submitUpdateDOAcknowledgement = async (event) => {
        var item_qtis = Object.values(textInputs);
        if (selectedRows.length === 0) {
			handleShowAlert(
				"error",
				"New DO Acknowledgement",
				"Failed Saving New DO Acknowledgement",
				["Please select a Product"]
			);
			return;
		}
        const emptyQuantities = selectedRows.filter(rowId => !textInputs[rowId] || textInputs[rowId] === '');
        if (emptyQuantities.length > 0) {
            handleShowAlert(
                "error",
                "New DO Acknowledgement",
                "Failed Saving New DO Acknowledgement",
                ["Please enter quantity for selected items"]
            );
            return;
        }
        const jsonOb = {
            do_ack_number: DoAckNumber,
            product_id: selectedRows,
            new_item_qty: item_qtis.map((item_qty) => parseInt(item_qty)),
            new_supplier_do_number: NewSupplierDoNumber,
            new_supplier_do_date: moment(NewSupplierDoDate).isValid() ? moment(NewSupplierDoDate).format("YYYY-MM-DD") : null,
        };
        console.log(jsonOb);
        try {
            const response = await fetch(config.do_ack_update_link, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + getTokenFromLocalStorage(),
            },
            body: JSON.stringify(jsonOb),
            });
            const data = await response.json();
            console.log(data);
            if (
            data.success !== true &&
            data.message === "Access Denied! Invalid token"
            ) {
            clearUserNameAndTokenInLocalStorage();
            const state = { data: "Token expired, please login again" };
            navigate("/login", { state });
            }
            if (data.success !== true) {
            const errorMessages = data.error.map((item) => item.msg);
            handleShowAlert("error", "Edit DO Acknowledgement", "Failed Saving Edit DO Acknowledgement :", errorMessages.join("<br>"));
            }
            if (data.success === true) {
            console.log("Success Updating Purchase Invoice");
            handleShowAlert(
                "ok",
                "Edit DO Acknowledgement",
                "Success saving Edit DO Acknowledgement"
            );
            onClose();
            return "ok";
            } else {
            throw data;
            }
        } catch (error) {
            console.error("Error while saving:", error);
            handleShowAlert(
            "error",
            "Edit DO Acknowledgement",
            "Error while saving Edit DO Acknowledgement",
            error
            );
            return;
        }
    };
    const handleInputChange = (rowId, value) => {
		setTextInputs({ ...textInputs, [rowId]: value });
	};
    const handleToggleFromChild = (newValue) => {
		setBlocking(newValue);
	};

    return (
        <Modal show={true} onHide={onClose} size="xl" backdrop="static" centered>
        <BlockUi blocking={blocking}>
            <Form onSubmit={handleSubmit(submitUpdateDOAcknowledgement)}>
            <Modal.Header closeButton className="px-4">
                <Modal.Title className="ms-auto">Edit DO Acknowledgement</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row className="d-flex align-items-center justify-content-center">
                <Col md={10}>
                    <Row className="mb-2">
                    <Col md="7">
                        <Form.Group as={Row} controlId="DoAckNumber">
                        <Form.Label column sm="5" className="fw-bold">
                            DO Ack Number :
                        </Form.Label>
                        <Col sm="6">
                            <Form.Control
                                disabled
                                type="text"
                                value={DoAckNumber}
                            />
                        </Col>
                        </Form.Group>
                    </Col>
                    <Col md="5">
                        <Form.Group as={Row} controlId="DoAckDate">
                        <Form.Label column sm="6" className="fw-bold">
                            DO Ack Date :
                        </Form.Label>
                        <Col sm="6">
                            <DatePicker
                                disabled
                                dateFormat="dd MMM yyyy"
                                className="form-control"
                                showIcon
                                selected={DoAckDate}
                            />
                        </Col>
                        </Form.Group>
                    </Col>
                    </Row>

                    <Row className="mb-2">
                    <Col md="7">
                        <Form.Group as={Row} controlId="SleDoNumber">
                        <Form.Label column sm="5" className="fw-bold">
                            SLE DO Number :
                        </Form.Label>
                        <Col sm="6">
                            <Form.Control
                                disabled
                                type="text"
                                value={SleDoNumber}
                            />
                        </Col>
                        </Form.Group>
                    </Col>
                    <Col md="5">
                        <Form.Group as={Row} controlId="SleDoDate">
                            <Form.Label column sm="6" className="fw-bold">
                                DO Date :
                            </Form.Label>
                            <Col sm="6">
                                <DatePicker
                                    disabled
                                    dateFormat="dd MMM yyyy"
                                    className="form-control"
                                    showIcon
                                    selected={SleDoDate}
                                />
                            </Col>
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row className="mb-2">
                    <Col md="7">
                        <Form.Group as={Row} controlId="SleSoNumber">
                        <Form.Label column sm="5" className="fw-bold">
                            SLE SO Number :
                        </Form.Label>
                        <Col sm="6">
                            <Form.Control
                                disabled
                                type="text"
                                value={SleSoNumber}
                            />
                        </Col>
                        </Form.Group>
                    </Col>
                    <Col md="5">
                        <Form.Group as={Row} controlId="SleSoDate">
                            <Form.Label column sm="6" className="fw-bold">
                                SO Date :
                            </Form.Label>
                            <Col sm="6">
                                <DatePicker
                                    disabled
                                    dateFormat="dd MMM yyyy"
                                    className="form-control"
                                    showIcon
                                    selected={SleSoDate}
                                />
                            </Col>
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row className="mb-2">
                    <Col md="7">
                        <Form.Group as={Row} controlId="OrderType">
                        <Form.Label column sm="5" className="fw-bold">
                            Order Type :
                        </Form.Label>
                        <Col sm="6">
                            <Form.Control
                                disabled
                                type="text"
                                value={OrderType}
                            />
                        </Col>
                        </Form.Group>
                    </Col>
                    <Col md="5">
                        <Form.Group as={Row} controlId="PaymentTerm">
                            <Form.Label column sm="6" className="fw-bold">
                                Payment Term :
                            </Form.Label>
                            <Col sm="6">
                                <Form.Control
                                    disabled
                                    type="text"
                                    value={PaymentTerm}
                                />
                            </Col>
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row className="mb-2">
                    <Col md="7">
                        <Form.Group as={Row} controlId="SupplierDoNumber">
                        <Form.Label column sm="5" className="fw-bold">
                            Supplier Do Number :
                        </Form.Label>
                        <Col sm="6">
                            <Form.Control
                                disabled
                                type="text"
                                value={SupplierDoNumber}
                            />
                        </Col>
                        </Form.Group>
                    </Col>
                    <Col md="5">
                        <Form.Group as={Row} controlId="SupplierDoDate">
                            <Form.Label column sm="6" className="fw-bold">
                            Supplier DO Date :
                            </Form.Label>
                            <Col sm="6">
                                <DatePicker
                                    disabled
                                    dateFormat="dd MMM yyyy"
                                    className="form-control"
                                    showIcon
                                    selected={SupplierDoDate}
                                />
                            </Col>
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row className="mb-2">
                    <Col md="7">
                        <Form.Group as={Row} controlId="CustomerName">
                        <Form.Label column sm="5" className="fw-bold">
                            Customer Name :
                        </Form.Label>
                        <Col sm="7">
                            <Form.Control
                                disabled
                                type="text"
                                placeholder=""
                                value={CustomerName}
                            />
                        </Col>
                        </Form.Group>
                    </Col>
                    </Row>

                    <Row className="mb-2">
                    <Col md="7">
                        <Form.Group as={Row} controlId="ProjectName">
                        <Form.Label column sm="5" className="fw-bold">
                            Project Name :
                        </Form.Label>
                        <Col sm="7">
                            <Form.Control
                                disabled
                                type="text"
                                placeholder=""
                                value={ProjectName}
                            />
                        </Col>
                        </Form.Group>
                    </Col>
                    </Row>
                </Col>
                </Row>
                <hr />
                <Row className="d-flex align-items-center justify-content-center">
                    <Col md={10}>
                        <Row>
                            <Col md="7">
                                <Form.Group as={Row} controlId="NewSupplierDoNumber">
                                <Form.Label column sm="5" className="fw-bold">
                                    New Supplier DO Number :
                                </Form.Label>
                                <Col sm="6">
                                    <Form.Control
                                        type="text"
                                        placeholder="New Supplier Do Number"
                                        value={NewSupplierDoNumber}
                                        onInput={(e) => {
                                            setNewSupplierDoNumber(e.target.value);
                                        }}
                                    />
                                </Col>
                                </Form.Group>
                            </Col>
                            <Col md="5">
                                <Form.Group as={Row} controlId="NewSupplierDoDate">
                                    <Form.Label column sm="6" className="fw-bold">
                                        New Supplier DO Date :
                                    </Form.Label>
                                    <Col sm="6">
                                        <DatePicker
                                            dateFormat="dd MMM yyyy"
                                            className="form-control"
                                            showIcon
                                            selected={NewSupplierDoDate}
                                            onChange={(date) => setNewSupplierDoDate(date)}
                                            minDate={moment().subtract(1, "month").toDate()}
                                            maxDate={new Date()}
                                        />
                                    </Col>
                                    </Form.Group>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <hr />
                <Row className="mb-4 align-items-center">
                    <Col className="text-center">
                        <Form.Label>[ Item Acknowledgement ]</Form.Label>
                        <ManageDOAcknowledgement_EditAckModalTable
                            ItemList={ItemList}
							rowData={rowData}
							onChildToggleChange={handleToggleFromChild}
							onRowSelect={toggleRowSelection}
							onInputChange={handleInputChange}
							selectedRows={selectedRows}
							setSelectedRows={setSelectedRows}
							textInputs={textInputs}
							setTextInputs={setTextInputs}
							toggleRowSelection={toggleRowSelection}
							refreshTrigger={refreshTrigger}
						/>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onClose} variant="secondary">
                    Close
                </Button>
                <Button type="submit" variant="primary">
                    Save
                </Button>
            </Modal.Footer>
            </Form>
        </BlockUi>
        <style>
            {`
                .form-control:disabled {
                background: #F8F8F8 !important;
                }
            `}
        </style>
        </Modal>
    );
}

export default ManageDOAcknowledgement_EditAckModal;
