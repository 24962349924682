import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ProductsTable from "../page_components/Products/Products_ProductsTable";
import NewProductModal from "../page_components/Products/Products_NewProductModal";
import EditProductModal from "../page_components/Products/Products_EditProductModal";
import { useNavigate } from "react-router-dom";
import BlockUi from "@availity/block-ui";
import "@availity/block-ui/src/BlockUi.css";
import "@availity/block-ui/src/Loader.css";
import AlertModal from "../page_components/AlertModal";
import { IconContext } from "react-icons";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { v4 as uuidv4 } from "uuid";
import {
  clearUserNameAndTokenInLocalStorage,
  getTokenFromLocalStorage,
} from "../lib/common";
import { useForm, Controller } from "react-hook-form";

const config = JSON.parse(process.env.REACT_APP_CONFIG);

function Projects() {
  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      ProductName: "",
    },
  });
  const [SupplierId, setSupplierName] = useState("");
  const [BrandList, setBrandList] = useState([]);
  const [BrandName, setBrandName] = useState("");
  const [SupplierList, setSupplierList] = useState([]);
  const [SupplierItemCode, setSupplierItemCode] = useState("");
  const [SupplierItemName, setSupplierItemName] = useState("");
  const [UnitOfMeasurementsList, setUnitOfMeasurementsList] = useState([]);
  const [ProductName, setProductName] = useState("");
  const [Status, setStatus] = useState("");
  const [searchProps, setSearchProps] = useState({});
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [blocking, setBlocking] = useState(true);
  const [ShowAlert, setShowAlert] = useState(false);
  const [AlertTitle, setAlertTitle] = useState("");
  const [AlertMessage, setAlertMessage] = useState("");
  const [ApiResponse, setApiResponse] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (getTokenFromLocalStorage() === null) {
      const state = { data: "Token expired, please login again" };
      navigate("/login", { state });
    }
  });

  useEffect(() => {
    fetch(config.brand_list_link, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getTokenFromLocalStorage(),
      },
      body: JSON.stringify({}),
    })
      .then((response) => response.json())
      .then((jsonData) => {
        console.log(jsonData);
        if (
          jsonData.success !== true &&
          jsonData.message === "Access Denied! Invalid token"
        ) {
          clearUserNameAndTokenInLocalStorage();
          throw new Error(jsonData.message);
        } else if (jsonData.success) {
          return jsonData.data;
        } else {
          throw new Error(jsonData.message);
        }
      })
      .then((data) => setBrandList(data))
      .catch((error) => console.error(error));
  }, []);

  useEffect(() => {
    fetch(config.supplier_active_list_link, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getTokenFromLocalStorage(),
      },
      body: JSON.stringify({}),
    })
      .then((response) => response.json())
      .then((jsonData) => {
        console.log(jsonData);
        if (
          jsonData.success !== true &&
          jsonData.message === "Access Denied! Invalid token"
        ) {
          clearUserNameAndTokenInLocalStorage();
          throw new Error(jsonData.message);
        } else if (jsonData.success) {
          return jsonData.data;
        } else {
          throw new Error(jsonData.message);
        }
      })
      .then((data) => setSupplierList(data))
      .catch((error) => console.error(error));
  }, []);

  useEffect(() => {
    fetch(config.uom_list_link, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getTokenFromLocalStorage(),
      },
      body: JSON.stringify({}),
    })
      .then((response) => response.json())
      .then((jsonData) => {
        if (
          jsonData.success !== true &&
          jsonData.message === "Access Denied! Invalid token"
        ) {
          clearUserNameAndTokenInLocalStorage();
          throw new Error(jsonData.message);
        } else if (jsonData.success) {
          return jsonData.data;
        } else {
          throw new Error(jsonData.message);
        }
      })
      .then(async (res) => {
        const updatedData = await res.map((item) => {
          const newKey = "UOM_ID";
          const newValue = uuidv4();
          return { ...item, [newKey]: newValue };
        });
        return updatedData;
      })
      .then((data) => setUnitOfMeasurementsList(data))
      .catch((error) => console.error(error));
  }, []);

  const handleShowAlert = async (status, title, message, apiResponse = []) => {
    setAlertTitle(title);
    if (status === "ok") {
      setAlertMessage(
        <>
          <p className="mb-1">
            <IconContext.Provider
              value={{
                color: "green",
                style: { fontSize: "40px", paddingRight: "5px" },
              }}
            >
              <AiOutlineCheckCircle />
              {message}
            </IconContext.Provider>
          </p>
        </>
      );
    } else {
      setAlertMessage(
        <>
          <p className="mb-1">
            <IconContext.Provider
              value={{
                color: "red",
                style: { fontSize: "40px", paddingRight: "5px" },
              }}
            >
              <AiOutlineCloseCircle />
              {message}
            </IconContext.Provider>
          </p>
          {apiResponse.length > 0
            ? apiResponse.map((response) => (
                <p className="text-danger fst-italic ps-3">{response}</p>
              ))
            : ""}
        </>
      );
    }
    setShowAlert(true);
  };

  const handleCloseAlert = () => {
    setShowAlert(false);
  };

  const handleToggleBlocking = () => {
    setBlocking((prevState) => !prevState);
  };

  const handleToggleFromChild = (newValue) => {
    setBlocking(newValue);
  };

  const handleResetForm = () => {
    reset();
    handleToggleBlocking();
    setSupplierName("");
    setProductName("");
    setBrandName("");
    setStatus("");
    setSupplierItemCode("");
    setSupplierItemName("");

    const searchProperties = {
      SupplierId: "",
      ProductName: "",
      BrandName: "",
      Status: "",
      SupplierItemCode: "",
      SupplierItemName: ""
    };
    setSearchProps(searchProperties);
  };
  const handleSearch = (event) => {
    // event.preventDefault();
    handleToggleBlocking();
    const searchProperties = {
      SupplierId: SupplierId,
      ProductName: ProductName,
      BrandName: BrandName,
      Status: Status,
      SupplierItemCode: SupplierItemCode,
      SupplierItemName: SupplierItemName
    };
    setSearchProps(searchProperties);
  };

  //   const handleIssuePOClick = (rowData) => {
  //     setSelectedRowData(rowData);
  //     setShowModal(true);
  //   };

  const handleNewProduct = () => {
    setShowModal("newproject");
  };
  const handleEditClick = (rowData) => {
    setSelectedRowData(rowData);
    setShowModal("edit");
  };

  const customValidation = (e) => {
    e.preventDefault();
    // Perform your custom validation logic here
    if (
      SupplierId !== "" ||
      ProductName !== "" ||
      BrandName !== "" ||
      Status !== "" || 
      SupplierItemCode !== "" || 
      SupplierItemName !== ""
    ) {
      // Validation is successful, you can proceed with form submission
      handleSubmit(handleSearch)();
    } else {
      // Validation failed, show an error message or take appropriate action
      // For example, show an alert
      handleShowAlert("error", "Criteria not found", "Criteria not specified!");
    }
  };

  return (
    <div>
      <Container className="mt-4">
        <BlockUi blocking={blocking}>
          <Row className="justify-content-center mb-4">
            <Col md={6}>
              <Form>
                <Row className="mb-4">
                  <Col className="text-center">
                    <h2>Manage Product</h2>
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="SupplierId"
                    >
                      <Form.Label column sm="4">
                        Supplier Name
                      </Form.Label>
                      <Col sm="7" style={{ paddingRight: "3px" }}>
                        <Form.Select
                          value={SupplierId}
                          onChange={(e) => setSupplierName(e.target.value)}
                        >
                          <option key={""} value="">
                            Please select supplier name
                          </option>
                          {SupplierList.map((items) => (
                            <option
                              key={items.SUPPLIER_ID}
                              value={items.SUPPLIER_ID}
                            >
                              {items.SUPPLIER_NAME}
                            </option>
                          ))}
                        </Form.Select>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="SupplierId"
                    >
                      <Form.Label column sm="4">
                        Brand Name
                      </Form.Label>
                      <Col sm="7" style={{ paddingRight: "3px" }}>
                        <Form.Select
                          value={BrandName}
                          onChange={(e) => setBrandName(e.target.value)}
                        >
                          <option key={""} value="">
                            Please select brand name
                          </option>
                          {BrandList.map((items) => (
                            <option key={items.BRAND} value={items.BRAND}>
                              {items.BRAND}
                            </option>
                          ))}
                        </Form.Select>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="SupplierId"
                    >
                      <Form.Label column sm="4">
                        Product Name
                      </Form.Label>
                      <Col sm="7" style={{ paddingRight: "3px" }}>
                        <Form.Control
                          type="text"
                          placeholder="Product Name"
                          value={ProductName}
                          onInput={(e) => setProductName(e.target.value)}
                          {...register("ProductName", {
                            pattern: {
                              value: /^(|.{3,})$/,
                              message: "Minimum 3 Character",
                            },
                          })}
                        />
                        {errors.ProductName && (
                          <p className="text-danger">
                            {errors.ProductName.message}
                          </p>
                        )}
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="SupplierId"
                    >
                      <Form.Label column sm="4">
                        Supplier Item Code
                      </Form.Label>
                      <Col sm="7" style={{ paddingRight: "3px" }}>
                        <Form.Control
                          type="text"
                          placeholder="Supplier Item Code"
                          value={SupplierItemCode}
                          onInput={(e) => setSupplierItemCode(e.target.value)}
                          // {...register("ProductName", {
                          //   pattern: {
                          //     value: /^(|.{3,})$/,
                          //     message: "Minimum 3 Character",
                          //   },
                          // })}
                        />
                        {/* {errors.ProductName && (
                          <p className="text-danger">
                            {errors.ProductName.message}
                          </p>
                        )} */}
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="SupplierId"
                    >
                      <Form.Label column sm="4">
                        Supplier Item Name
                      </Form.Label>
                      <Col sm="7" style={{ paddingRight: "3px" }}>
                        <Form.Control
                          type="text"
                          placeholder="Supplier Item Name"
                          value={SupplierItemName}
                          onInput={(e) => setSupplierItemName(e.target.value)}
                          // {...register("ProductName", {
                          //   pattern: {
                          //     value: /^(|.{3,})$/,
                          //     message: "Minimum 3 Character",
                          //   },
                          // })}
                        />
                        {/* {errors.ProductName && (
                          <p className="text-danger">
                            {errors.ProductName.message}
                          </p>
                        )} */}
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="SupplierId"
                    >
                      <Form.Label column sm="4">
                        Status
                      </Form.Label>
                      <Col sm="7" style={{ paddingRight: "3px" }}>
                        <Form.Select
                          value={Status}
                          onChange={(e) => setStatus(e.target.value)}
                        >
                          <option key={""} value="">
                            Please select status
                          </option>
                          <option key={"A"} value="A">
                            Active
                          </option>
                          <option key={"I"} value="I">
                            Inactive
                          </option>
                        </Form.Select>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col className="text-center">
                    <Button
                      className="me-2"
                      variant="secondary"
                      onClick={handleResetForm}
                    >
                      Reset
                    </Button>
                    <Button
                      type="submit"
                      variant="primary"
                      onClick={customValidation}
                    >
                      Search
                    </Button>
                    <Button
                      className="ms-2"
                      variant="primary"
                      onClick={handleNewProduct}
                    >
                      New Product
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
          <hr />
          <br />
          <ProductsTable
            showAlert={ShowAlert}
            handleShowAlert={handleShowAlert}
            searchProps={searchProps}
            onChildToggleChange={handleToggleFromChild}
            onEditClick={handleEditClick}
          />
          {showModal === "newproject" ? (
            <NewProductModal
              unitOfMeasurementsList={UnitOfMeasurementsList}
              supplierList={SupplierList}
              handleShowAlert={handleShowAlert}
              onClose={() => setShowModal(false)}
            />
          ) : (
            ""
          )}
          {showModal === "edit" ? (
            <EditProductModal
              unitOfMeasurementsList={UnitOfMeasurementsList}
              supplierList={SupplierList}
              handleShowAlert={handleShowAlert}
              rowData={selectedRowData}
              onClose={() => setShowModal(false)}
            />
          ) : (
            ""
          )}
        </BlockUi>
        {ShowAlert && (
          <AlertModal
            title={AlertTitle}
            message={AlertMessage}
            apiResponse={ApiResponse}
            onClose={handleCloseAlert}
          />
        )}
      </Container>
    </div>
  );
}

export default Projects;
