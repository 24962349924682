import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import DatePicker from "react-datepicker";
import moment from "moment/moment";
import { useNavigate } from "react-router-dom";
import {
	clearUserNameAndTokenInLocalStorage,
	getTokenFromLocalStorage,
} from "../../lib/common";
import BlockUi from "@availity/block-ui";
import { useForm, Controller } from "react-hook-form";
import { CreateGRN, UpdateGRN, UpdateGrnDTO } from "../../lib/api/GoodsReceivedNote";
import ManageGoodsReceivedNoteTable from "./ManageGRN_EditModalTable";

const config = JSON.parse(process.env.REACT_APP_CONFIG);

function ManageGoodsReceivedNoteEditModal(props) {
	const { handleShowAlert, handleCloseAlert, rowData, onClose } = props;
	const [editGrnData, setEditGrnData] = useState({});
	const [Remarks, setRemarks] = useState("");
	const [blocking, setBlocking] = useState(false);
	const [selectedRows, setSelectedRows] = useState([]);
	const [textInputs, setTextInputs] = useState({});
	const [selectedData, setSelectedData] = useState([]);
	const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] =
		useState(false);
	const [refreshTrigger, setRefreshTrigger] = useState("");
	const [supplier_do_number, set_supplier_do_number] = useState("");
	const [supplier_do_date, set_supplier_do_date] = useState("");
	const [ItemList, setItemList] = useState([]);
	const {
		control,
		register,
		handleSubmit,
		watch,
		setValue,
		trigger,
		formState: { errors },
	} = useForm({
		defaultValues: {
			supplier_do_number: "",
			supplier_do_date: "",
		},
	});

	const navigate = useNavigate();

	const handleToggleBlocking = () => {
		setBlocking((prevState) => !prevState);
	};

	const handleToggleFromChild = (newValue) => {
		setBlocking(newValue);
	};

	const handleDeleteClick = () => {
		setShowDeleteConfirmationModal(true);
	};

	useEffect(() => {
		console.log('getting data');
		getData(config.seng_liy_backend_url +'/grn/get', rowData);
	  }, [rowData]);

	const getData = async (url, params = {}) => {
		await fetch(url, {
		  method: "POST",
		  headers: {
			"Content-Type": "application/json",
			Authorization: "Bearer " + getTokenFromLocalStorage(),
		  },
		  body: JSON.stringify({
			grn_number: params.GRN_NUMBER,
		  }),
		})
		  .then((response) => response.json())
		  .then((jsonData) => {
			setBlocking(false);
			if (
			  jsonData.success !== true &&
			  jsonData.message === "Access Denied! Invalid token"
			) {
			  clearUserNameAndTokenInLocalStorage();
			  throw new Error(jsonData.message);
			} else if (jsonData.success) {
				return jsonData.data;
			} else {
			  setItemList([]);
			  throw new Error(jsonData.message);
			}
		  }).then((data) => {
			setEditGrnData(data);
		  })
		  .catch((error) => console.error(error));
	  };

	const handleSubmitUpdateGrn = async (event) => {
		// event.preventDefault();
		try {
			handleToggleBlocking();
			// Check if any selected rows
			if(selectedRows.length === 0){
				throw new Error("Please select a PO Item.");
			}

			if(Object.keys(textInputs).length === 0){
				throw new Error("Please select a PO Item.");
			}
			
			// Check each textInput is not empty
			console.log(textInputs);
			for (const row in textInputs) {
				if (textInputs[row] === "" || textInputs[row] === undefined) {
					throw new Error("Please enter quantity for selected items");
				}
			}
			if((await submitUpdateGrn())){
				handleToggleBlocking();
				console.log("Success Create GRN");
				onClose();
				handleShowAlert("ok", "Create GRN", "Success Update GRN");
			}
		} catch (error) {
			handleToggleBlocking();
			console.error("Error while creating GRN:", error.message);
			handleShowAlert(
				"error",
				"Create GRN",
				"Error while updating GRN: ",
				error.message
			);
			return;
		}
	};

	const submitUpdateGrn = async () => {
		let productIds = [];
		let itemQtys = [];
		for (const row in textInputs) {
				productIds.push(row);
				console.log(textInputs[row]);
			if (isNaN(textInputs[row])) {
				console.log('invalid input');
				return false;
			}
			const itemQty = parseFloat(textInputs[row]);
			if(itemQty){
				itemQtys.push(itemQty);
			}else{
				return false;
			}
		}

		let updateGrnDTO = new UpdateGrnDTO();
		updateGrnDTO = {
			grn_number: editGrnData.GRN_NUMBER,
			product_id: productIds,
			new_item_qty: itemQtys,
		}
		if(supplier_do_number !== ""){
			updateGrnDTO.new_supplier_do_number = supplier_do_number;
		}
		if(supplier_do_date !== ""){
			// Convert supplier_do_date to string in local time
			updateGrnDTO.new_supplier_do_date = moment(supplier_do_date).format("YYYY-MM-DD");
		}

		let createGRNRequest = await UpdateGRN(updateGrnDTO);
		if(!createGRNRequest.success){
			throw(createGRNRequest.message);
		}
		return createGRNRequest.success;
	};

	const toggleRowSelection = (rowId) => {
		console.log(rowId);
		console.log(selectedRows);
		if (selectedRows.includes(rowId)) {
			setSelectedRows(selectedRows.filter((id) => id !== rowId));
		} else {
			setSelectedRows([...selectedRows, rowId]);
		}
	};

	const handleInputChange = (rowId, value) => {
		setTextInputs({ ...textInputs, [rowId]: value });
	};

	const handleSaveSelectedData = (event) => {
		// event.preventDefault();
		var item_qtis = Object.values(textInputs);
		var dataSend = {
			product_id: selectedRows,
			item_qty: item_qtis.map((item_qty) => parseInt(item_qty)),
		};

		// You can perform additional actions with the selected data here
	};

	const todayDate = new Date();
	const daysToSubtract = 60;
	const twoMonthAgoDate = new Date(
		todayDate.valueOf() - daysToSubtract * 24 * 60 * 60 * 1000
	);

	return (
		<Modal show={true} onHide={onClose} size="xl" backdrop="static" centered>
			<BlockUi blocking={blocking}>
				{/* <Form onSubmit={handleUpdateMemo}> */}
				<Form onSubmit={handleSubmit(handleSubmitUpdateGrn)}>
					<Modal.Header closeButton className="px-4">
						<Modal.Title className="ms-auto">Edit GRN</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Row className="mb-2 justify-content-center">
							<Col md="9">
								<Row>
									<Col md="6">
										<Form.Group as={Row} controlId="SLESONumber">
											<Form.Label
												column
												sm="6"
												className="fw-bold"
											>
												GRN Number :
											</Form.Label>
											<Col sm="6">
												<Form.Control
													disabled
													className="form-control"
													type="text"
													placeholder=""
													value={editGrnData.GRN_NUMBER}
												/>
											</Col>
										</Form.Group>
									</Col>
									<Col md="6">
										<Form.Group as={Row} controlId="SLESODate">
											<Form.Label
												column
												sm="6"
												className="fw-bold"
											>
												GRN Date :
											</Form.Label>
											<Col sm="6">
												<DatePicker
													disabled
													dateFormat="dd/MM/yyyy"
													className="form-control"
													showIcon
													selected={editGrnData.GRN_DATE ? new Date(editGrnData.GRN_DATE) : ""}
												/>
											</Col>
										</Form.Group>
									</Col>
								</Row>
							</Col>
						</Row>
						<Row className="mb-2 justify-content-center">
							<Col md="9">
								<Row>
									<Col md="6">
										<Form.Group as={Row} controlId="SLESONumber">
											<Form.Label
												column
												sm="6"
												className="fw-bold"
											>
												SLE PO Number :
											</Form.Label>
											<Col sm="6">
												<Form.Control
													disabled
													className="form-control"
													type="text"
													placeholder=""
													value={editGrnData.PO_NUMBER}
												/>
											</Col>
										</Form.Group>
									</Col>
									<Col md="6">
										<Form.Group as={Row} controlId="SLESODate">
											<Form.Label
												column
												sm="6"
												className="fw-bold"
											>
												PO Date :
											</Form.Label>
											<Col sm="6">
												<DatePicker
													disabled
													dateFormat="dd/MM/yyyy"
													className="form-control"
													showIcon
													selected={editGrnData.PO_DATE ? new Date(editGrnData.PO_DATE) : ""}
												/>
											</Col>
										</Form.Group>
									</Col>
								</Row>
							</Col>
						</Row>

						<Row className="mb-2 justify-content-center">
							<Col md="9">
								<Row>
									<Col md="6">
										<Form.Group as={Row} controlId="SLESONumber">
											<Form.Label
												column
												sm="6"
												className="fw-bold"
											>
												Supplier DO Number:
											</Form.Label>
											<Col sm="6">
												<Form.Control
													disabled
													className="form-control"
													type="text"
													placeholder=""
													value={editGrnData.SUPPLIER_DO_NUMBER}
												/>
											</Col>
										</Form.Group>
									</Col>
									<Col md="6">
										<Form.Group as={Row} controlId="SLESODate">
											<Form.Label
												column
												sm="6"
												className="fw-bold"
											>
												Supplier DO Date :
											</Form.Label>
											<Col sm="6">
												<DatePicker
													disabled
													dateFormat="dd/MM/yyyy"
													className="form-control"
													showIcon
													selected={editGrnData.SUPPLIER_DO_DATE ? new Date(editGrnData.SUPPLIER_DO_DATE) :""}
												/>
											</Col>
										</Form.Group>
									</Col>
								</Row>
							</Col>
						</Row>
						<Row className="mb-2 justify-content-center">
							<Col md="9">
								<Row>
									<Col md="6">
										<Form.Group as={Row} controlId="SLESONumber">
											<Form.Label
												column
												sm="6"
												className="fw-bold"
											>
												Supplier :
											</Form.Label>
											<Col sm="6">
												<Form.Control
													disabled
													className="form-control"
													type="text"
													placeholder=""
													value={editGrnData.SUPPLIER_NAME}
												/>
											</Col>
										</Form.Group>
									</Col>
								</Row>
							</Col>
						</Row>

						<hr />
						<Row className="mb-2 justify-content-center">
							<Col md="9">
								<Row>
									<Col md="6">
										<Form.Group as={Row} controlId="SLESONumber">
											<Form.Label
												column
												sm="6"
												className="fw-bold"
											>
												New Supplier DO Number:
											</Form.Label>
											<Col sm="6">
												<Form.Control
													className="form-control"
													type="text"
													placeholder=""
													value={supplier_do_number}
													onInput={(e) =>{set_supplier_do_number(e.target.value)}}
												/>
											</Col>
										</Form.Group>
									</Col>
									<Col md="6">
										<Form.Group as={Row} controlId="SLESODate">
											<Form.Label
												column
												sm="6"
												className="fw-bold"
											>
												New Supplier DO Date :
											</Form.Label>
											<Col sm="6">
												<DatePicker
													dateFormat="dd/MM/yyyy"
													className="form-control"
													showIcon
													selected={supplier_do_date}
													onChange={(date) => set_supplier_do_date(date)}
													minDate={moment().subtract(1, "month").toDate()}
													maxDate={new Date()}
												/>
											</Col>
										</Form.Group>
									</Col>
								</Row>
							</Col>
						</Row>

						<hr />
						<Row className="mb-4 align-items-center">
							<Col sm="12" className="text-center">
								<Form.Label>[ PO Items Received ]</Form.Label>
							</Col>
						</Row>
						<ManageGoodsReceivedNoteTable
							onChildToggleChange={handleToggleFromChild}
							onRowSelect={toggleRowSelection}
							onInputChange={handleInputChange}
							selectedRows={selectedRows}
							setSelectedRows={setSelectedRows}
							textInputs={textInputs}
							setTextInputs={setTextInputs}
							toggleRowSelection={toggleRowSelection}
							refreshTrigger={refreshTrigger}
							itemList={ItemList}
							rowData={rowData}
						/>
					</Modal.Body>
					<Modal.Footer>
						<Button onClick={onClose} variant="secondary">
							Cancel
						</Button>
						<Button
							type="submit"
							variant="primary"
						>
							Save
						</Button>
					</Modal.Footer>
				</Form>
			</BlockUi>
			<style>
				{`
					.form-control:disabled {
					background: #F8F8F8 !important;
					}
				`}
			</style>
		</Modal>
	);
}

export default ManageGoodsReceivedNoteEditModal;
