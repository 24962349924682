import {
	clearUserNameAndTokenInLocalStorage,
	getTokenFromLocalStorage,
} from "../common";

export class SengLiyServerResponse {
	success = false;
	data = undefined;
    message = undefined;
}

export async function PostData(
	url,
	requestBody
) {
    let serverResponse = new SengLiyServerResponse();
    try {
        let fetchRequest = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + getTokenFromLocalStorage(),
            },
            body: JSON.stringify(requestBody),
        });
        let jsonResponse = await fetchRequest.json();
        if (
            jsonResponse.success === false &&
            jsonResponse.message === "Access Denied! Invalid token"
        ) {
            clearUserNameAndTokenInLocalStorage();
            serverResponse.success = jsonResponse.success;
            serverResponse.data = jsonResponse.data;
            serverResponse.message = jsonResponse.message;
        } else if (jsonResponse.success) {
            serverResponse.success = jsonResponse.success;
            if(jsonResponse.data){
                serverResponse.data = jsonResponse.data;
            }
        } else {
            throw new Error(jsonResponse.message);
        }
    } catch (error) {
        serverResponse.success = false;
        serverResponse.data = undefined;
        serverResponse.message = "An error occurred while fetching data."
        console.log(error);
    }
    
    console.log(serverResponse);
    return serverResponse;
}
