const config = JSON.parse(process.env.REACT_APP_CONFIG);

export function storeUserNameAndTokenInLocalStorage(username, token) {
  localStorage.setItem('username', username);
  localStorage.setItem('token', token);
  const event = new Event('tokenChange');
  window.dispatchEvent(event);
}

export function clearUserNameAndTokenInLocalStorage(){
  localStorage.removeItem('username');
  localStorage.removeItem('token');
  const event = new Event('tokenChange');
  window.dispatchEvent(event);
}

export function getTokenFromLocalStorage() {
  return localStorage.getItem('token');
}

export function getUserNameFromLocalStorage() {
  return localStorage.getItem('username');
}

export async function getAuthenticatedUser() {
  const defaultReturnObject = { authenticated: false, user: null };
  try {
    const token = getTokenFromLocalStorage();
    if (!token) {
      return defaultReturnObject;
    } else {
      return ({authenticated : true});
    }
    // const response = await fetch({
    //   method: 'POST',
    //   url: config.GET_USER,
    //   headers: {
    //     "Content-Type": "application/json",
    //     "Authorization": `Bearer ${token}`
    //   }
    // });
    // const { authenticated = false } = response.data;
    // return authenticated ? response.data : false;
  }
  catch (err) {
    console.log('getAuthenticatedUser, Something Went Wrong', err);
    return defaultReturnObject;
  }
}